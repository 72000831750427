import EventApply from '../../components/community/event_apply/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <EventApply name="Apply Events" />
        </>
    );
}