import React from "react";
import "../../../../../nav/components/location/Component";

// Card for Areas, Amenities
function Component({ data = '', head = '' }) {
    return (
        <>
            <div class="container">
                <div class="row">
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4'>
                        <div className='cabins-card'>
                            {/* <div class="cabin-sideline"></div> */}
                            <div class='cabin-sideline1'></div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='cabin-sheet'>
                                        <div className='cabin-number'>
                                            <i class='ri-keyboard-box-line keybord-icn'></i>
                                        </div>
                                        <div className='cabin-text'>
                                            <p className='cabin-totalseat'>{head[0]}</p>
                                            {/* <p className='cabin-totalseat'>{data.subscription_desk !== null ? data.subscription_desk : 0}</p> */}
                                        </div>
                                    </div>
                                    <div className='botflx-cafit'>
                                        <button className="view-btn-2"><p className='cabin-totalseat'>{data.subscription_desk !== null ? data.subscription_desk : 0}</p></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4'>
                        <div className='cabins-card'>
                            {/* <div class="cabin-sideline"></div> */}
                            <div class='cabin-sideline1'></div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='cabin-sheet'>
                                        <div className='cabin-number'>
                                            <i class='ri-keyboard-box-line keybord-icn'></i>
                                        </div>
                                        <div className='cabin-text'>
                                            <p className='cabin-totalseat'>{head[1]}</p>
                                            {/* <p className='cabin-totalseat'>{data.subscription_validity}</p> */}
                                        </div>
                                    </div>
                                    <div className='botflx-cafit'>
                                        <button className="view-btn-2">
                                            <p className='cabin-totalseat'>{data.subscription_validity}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4'>
                        <div className='cabins-card'>
                            {/* <div class="cabin-sideline"></div> */}
                            <div class='cabin-sideline1'></div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='cabin-sheet'>
                                        <div className='cabin-number'>
                                            <i class='ri-keyboard-box-line keybord-icn'></i>
                                        </div>
                                        <div className='cabin-text'>
                                            <p className='cabin-totalseat'>{head[2]}</p>
                                            {/* <p className='cabin-totalseat'>{data.isSubscriptionValid ? "Active" : "Inactive"}</p> */}
                                        </div>
                                    </div>
                                    <div className='botflx-cafit'>
                                        <button className="view-btn-2">
                                            <p className='cabin-totalseat'>{data.isSubscriptionValid ? "Active" : "Inactive"}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Component;
