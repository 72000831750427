import React, { useState, useEffect, useRef } from 'react';
import './component.css'; // Import your CSS file

function Component() {
  const sidebarRef = useRef();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [mastersDropdownOpen1, setMastersDropdownOpen1] = useState(false);
  const [mastersDropdownOpen, setMastersDropdownOpen] = useState(false);
  const [mastersDropdownOpeninvoice, setMastersDropdownOpeninvoice] = useState(false);
  const [mastersDropdownOpenexpense, setMastersDropdownOpenexpense] = useState(false);
  const [mastersDropdownOpenvisitor, setMastersDropdownOpenvisitor] = useState(false);
  const [mastersDropdownOpenenqurie, setMastersDropdownOpenenqurie] = useState(false);
  const [mastersDropdownOpenoccupancy, setMastersDropdownOpenoccupancy] = useState(false);
  const [mastersDropdownOpenlocation, setMastersDropdownOpenlocation] = useState(false);
  const [mastersDropdownOpensetting, setMastersDropdownOpensetting] = useState(false);
  const [mastersDropdownOpenbooking, setMastersDropdownOpenbooking] = useState(false);
  const [mastersDropdownOpensupport, setMastersDropdownOpensupport] = useState(false);
  const [mastersDropdownOpenclient, setMastersDropdownOpenclient] = useState(false);
  const [mastersDropdownOpencommunity, setMastersDropdownOpencommunity] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      // If the click is outside the sidebar, close the sidebar
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);
  const toggleDropdown = () => {
    setMastersDropdownOpen(!mastersDropdownOpen);
  };
  const toggleDropdowninvoice = () => {
    setMastersDropdownOpeninvoice(!mastersDropdownOpeninvoice);
  };
  const toggleDropdownexpense = () => {
    setMastersDropdownOpenexpense(!mastersDropdownOpenexpense);
  };
  const toggleDropdownvisitor = () => {
    setMastersDropdownOpenvisitor(!mastersDropdownOpenvisitor);
  };
  const toggleDropdownenqurie = () => {
    setMastersDropdownOpenenqurie(!mastersDropdownOpenenqurie);
  };
  const toggleDropdownlocation = () => {
    setMastersDropdownOpenlocation(!mastersDropdownOpenlocation);
  };
  const toggleDropdown1 = () => {
    setMastersDropdownOpen1(!mastersDropdownOpen1);
  };
  const toggleDropdownoccupancy = () => {
    setMastersDropdownOpenoccupancy(!mastersDropdownOpenoccupancy);
  };
  const toggleDropdownsetting = () => {
    setMastersDropdownOpensetting(!mastersDropdownOpensetting);
  };
  const toggleDropdownbooking = () => {
    setMastersDropdownOpenbooking(!mastersDropdownOpenbooking);
  };
  const toggleDropdownsupport = () => {
    setMastersDropdownOpensupport(!mastersDropdownOpensupport);
  };
  const toggleDropdownclient = () => {
    setMastersDropdownOpenclient(!mastersDropdownOpenclient);
  };
  const toggleDropdowncommunity = () => {
    setMastersDropdownOpencommunity(!mastersDropdownOpencommunity);
  };


  return (
    <div>
      <div id="mySidenav" className={`sidenav ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
        <a href="javascript:void(0)" className="closebtn" onClick={() => setIsSidebarOpen(false)}>&times;</a>
        <div className="desk-flx">
          <img src="/img/deskbox.png" className="img-fluid logo" alt="Desk Logo" />
        </div>

        {/* <div className="side-flx">
          <div className="side-flx1">
            <img src="/img/profile.png" className="img-fluid profile" alt="Profile" />
          </div>
          <div className="side-flx1">
            <p className="side-txt">Amit Kumar</p>
            <p className="side-txt1">Active <i className="mstr ri-checkbox-blank-circle-fill" style={{ color: "green", fontSize: "10px", marginLeft: "7px", marginTop: "2.5px" }}></i></p>
          </div>
        </div> */}

        <div className="top-marginn">
          <a className="pages active"><i className="mstr ri-home-4-line"></i>Dashboard</a>
          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
              <i className="mstr ri-archive-stack-line mstr"></i>Masters <i className={mastersDropdownOpen ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"} ></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpen ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Cabins</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Seats</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Meeting Rooms</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Areas</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Ammenities</a>
            </div>
          </div>
          {/* <a href="#" className="pages"><i className="mstr ri-map-pin-line "></i>Locations</a> */}

          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpenlocation ? 'open' : ''}`} onClick={toggleDropdownlocation}>
              <i className="mstr ri-map-pin-line"></i>Resources <i className={mastersDropdownOpenlocation ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenlocation ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Locations</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Floors</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Assets Mapping </a>
            </div>
          </div>
          {/* <a href="#" className="pages"><i className="mstr ri-team-line"></i></a> */}

          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpen1 ? 'open' : ''}`} onClick={toggleDropdown1}>
              <i className="mstr ri-team-line"></i>Team <i className={mastersDropdownOpen1 ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpen1 ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Roles</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Staff </a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Attendance</a>
              {/* <a href="#" className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Ammenities</a> */}

            </div>
          </div>
          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpenoccupancy ? 'open' : ''}`} onClick={toggleDropdownoccupancy}>
              <i className="mstr ri-bill-line"></i>Occupancy <i className={mastersDropdownOpenoccupancy ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenoccupancy ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Reports</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Analytics </a>
            </div>
          </div>
          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpenclient ? 'open' : ''}`} onClick={toggleDropdownclient}>
              <i className="mstr ri-group-line"></i>Clients <i className={mastersDropdownOpenclient ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenclient ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Companies</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Members </a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Contracts</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Credits History</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Parking</a>
            </div>
          </div>

          {/* <a href="#" className="pages"><i className="mstr ri-group-2-line"></i> Community</a> */}

          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpencommunity ? 'open' : ''}`} onClick={toggleDropdowncommunity}>
              <i className="mstr ri-group-2-line"></i>Community <i className={mastersDropdownOpencommunity ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpencommunity ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Posts</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Events </a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Offers</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Bulk Email</a>

            </div>
          </div>

          {/* <a href="#" className="pages"><i className="mstr ri-file-list-3-line"></i>Invoices</a> */}
          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpeninvoice ? 'open' : ''}`} onClick={toggleDropdowninvoice}>
              <i className="mstr ri-file-list-3-line mstr"></i>Invoices <i className={mastersDropdownOpeninvoice ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"} ></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpeninvoice ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>All Invoices</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Pending Invoices</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Paid Invoices</a>
            </div>
          </div>
          {/* <a href="#" className="pages"><i className="mstr ri-money-rupee-circle-line"></i>Expenses</a> */}
          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpenexpense ? 'open' : ''}`} onClick={toggleDropdownexpense}>
              <i className="mstr ri-money-rupee-circle-line mstr"></i>Expenses <i className={mastersDropdownOpenexpense ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"} ></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenexpense ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>All Expenses</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Pending Expenses</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Paid Expenses</a>
            </div>
          </div>
          {/* <a href="#" className="pages"><i className="mstr ri-calendar-event-line"></i>Bookings</a> */}

          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpenbooking ? 'open' : ''}`} onClick={toggleDropdownbooking}>
              <i className="mstr ri-calendar-event-line"></i>Bookings <i className={mastersDropdownOpenbooking ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenbooking ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Meeting Rooms</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Day Pass</a>
            </div>
          </div>


          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpenvisitor ? 'open' : ''}`} onClick={toggleDropdownvisitor}>
              <i className="mstr ri-user-follow-fill mstr"></i>Visitors <i className={mastersDropdownOpenvisitor ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"} ></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenvisitor ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>All Visitors</a>
            </div>
          </div>

          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpenenqurie ? 'open' : ''}`} onClick={toggleDropdownenqurie}>
              <i className="mstr ri-article-fill mstr"></i>Enquiries <i className={mastersDropdownOpenenqurie ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"} ></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenenqurie ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>All Enquiries</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Pending Enquiries</a>
              {/* <a href="#" className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Converted Enquries</a> */}
            </div>
          </div>

          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpensupport ? 'open' : ''}`} onClick={toggleDropdownsupport}>
              <i className="mstr ri-customer-service-2-line"></i>Support <i className={mastersDropdownOpensupport ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpensupport ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Clients Support</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>DeskBox Support </a>

            </div>
          </div>

          {/* <a href="#" className="pages"><i className="mstr ri-settings-3-line"></i></a> */}

          <div className="dropdown">
            <a className={`pages ${mastersDropdownOpensetting ? 'open' : ''}`} onClick={toggleDropdownsetting}>
              <i className="mstr ri-settings-3-line"></i>Settings <i className={mastersDropdownOpensetting ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpensetting ? 'open' : ''}`}>
              {/* Dropdown options go here */}
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Profile</a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Subscription </a>
              <a className="pages1"><i className="mstr ri-circle-fill fillicn "></i>Activity Logs</a>

            </div>
          </div>
          <a className="pages"><i className="mstr ri-shut-down-line"></i>Logout</a>
          {/* <a href="#" className="pages"><i className="mstr ri-customer-service-2-line"></i>Support</a> */}



        </div>
      </div>

      <div className="logo-flx">
        <div className="logo-flx1">
          <img src="/img/deskbox.png" className="img-fluid logo2" alt="Desk Logo" />
        </div>
        <div className="logo-flx2">
          <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={toggleSidebar}>&#9776;</span>

        </div>
      </div>
    </div>
  );
}

export default Component;
