import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="section2">
            


      <div className="container">
       
        <div className="row feature1-top">
          <div className="col-lg-6"> 
          <div className="feature-padd">
         <p className="deskbox-txt2">Visitors & Enquiries Management</p>
         <p className="deskbox-descri">Deskbox ensures that all visitors are handled efficiently and inquiries are addressed promptly.</p>
         <div className="desk-top-2">
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Visitors Management & Tracking</p>
                <p className="feature1-txt2">This state-of-the-art tool will allow you to easily manage and keep track of all your visitors.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Enquiries Management & Followups</p>
                <p className="feature1-txt2">With comprehensive tracking capabilities, you'll never have to worry about missing a enquiry again.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Multiple Visitor's App</p>
                <p className="feature1-txt2">You can download and share white-labeled applications for multiple locations.</p>
            </div>
         </div>
         </div>
           </div>
           </div>
          <div className="col-lg-6">
          <center><img src="img/visitors.png" className="img-fluid feature-img"/></center>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Component;
