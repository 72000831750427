import React, { useState } from 'react';
import './component.css'; // Import your CSS file
import Sidenav from '../sidenav/Component.js';
import Top from '../top/Component.js';
import { Button, Modal } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';

function Component() {

  const text = "&Work Plot No. 5B, Sector - 15A, First Floor, Neelam";
  let limitedText = text.slice(0, 17);

  if (text.length > 17) {
    limitedText += ' ...';
  }


  const [isExpanded, setIsExpanded] = useState(false);

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedLocation, setSelectedLocation] = useState("");

  const [isOpen1, setIsOpen1] = useState(false);
  const handleSelect1 = (location) => {
    setSelectedLocation(location);
    setIsOpen1(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };
  const [activeButton, setActiveButton] = useState('All');

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const invoice = {
    companyName: "Encrobytes Technologies (OPC) Pvt. Ltd.",
    companyAddress: "209/5 Bassa Para, Old Faridabad Faridabad Haryana IN 121002",
    gstn: "06AAFCE3515L1ZO",
    billDate: "01st December 2023",
    dueDate: "06th December 2023",
    contractNumber: "1148",
    invoiceNumber: "INV-001",
    items: [
      {
        description: "1 Seater Private Office 1st floor",
        hsnSacCode: "997212",
        quantity: 1,
        pricePerUnit: 15000.00,
        discountPercentage: 13.33,
      }
    ],
    subtotal: 13000.00,
    cgst: 1170,
    sgst: 1170,
    total: 15340.00,
    modeOfPayment: "Bank Transfer",
    bankName: "Citi Bank",
    accountNumber: "0006221483",
    beneficiaryName: "Landsworth Infrastructure",
    bankAddress: "Crown Plaza Mall",
    ifscCode: "CITI0000027",
    contactEmail: "info@andwork.in",
    contactPhone: "9821568960",
  };

  return (
    <>
      <div className="container-fluid side-padd dashboardfont">
        <div className="row main-parent">
          <div className="col_parent_class col-xl-2 col-lg-3 first_child_width">
            <Sidenav />
          </div>
          <div className="col_parent_class col-xl-10 col-lg-9 second_child_width">
            <Top />

            <div className="inner-padding">
              <br />
              {/* spaces */}
              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Spaces</p>
                </div>
                <div className="featured-flx1">
                  <button className="circle-btn-add icon" ><i className="ri-add-line"></i></button>

                </div>
              </div>
              <div className='row'>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="space1-card">
                    <div className='row'>

                      <div className='col-12'>
                        <button className="space-circle-btn-A Aicon">N</button>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space1-txt1">Landsworth infrastructure</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Your subscription for 100 desks is active, and it will remain valid until May 1, 2024.
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                {/* <i class="ri-pencil-line pencil-icn"></i>
                              <i class="ri-delete-bin-6-line trash-icn"></i> */}
                              </div>
                              <button className="view-btn-2">Access Dashboard</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="space1-card">
                    <div className='row'>

                      <div className='col-12'>
                        <button className="space-circle-btn-A Aicon">N</button>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space1-txt1">Landsworth infrastructure</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Your subscription for 100 desks is active, and it will remain valid until May 1, 2024.
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                {/* <i class="ri-pencil-line pencil-icn"></i>
                              <i class="ri-delete-bin-6-line trash-icn"></i> */}
                              </div>
                              <button className="view-btn-2">Access Dashboard</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
              <hr />

              {/* spaces */}




              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Locations</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon" data-bs-toggle="modal" data-bs-target="#myModal"><i className="ri-add-line"></i></button>
                </div>
              </div>

              <div className="row">

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="main-location-card">
                    <div className='row'>
                      <div className='col-2'>
                        <button className="circle-btn-A Aicon">N</button>
                      </div>
                      <div className='col-10'>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space-txt">Neelam Chowk</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Plot No. 5B, Sector - 15A, First Floor, Neelam Ajronda Chowk Metro Station, Faridabad, Haryana 121007
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                <i class="ri-pencil-line pencil-icn"></i>
                                <i class="ri-delete-bin-6-line trash-icn"></i>
                              </div>
                              <button className="view-btn-2">Manage Floors</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="main-location-card">
                    <div className='row'>
                      <div className='col-2'>
                        <button className="circle-btn-A Aicon">N</button>
                      </div>
                      <div className='col-10'>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space-txt">Neelam Chowk</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Plot No. 5B, Sector - 15A, First Floor, Neelam Ajronda Chowk Metro Station, Faridabad, Haryana 121007
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                <i class="ri-pencil-line pencil-icn"></i>
                                <i class="ri-delete-bin-6-line trash-icn"></i>
                              </div>
                              <button className="view-btn-2">Manage Floors</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="main-location-card">
                    <div className='row'>
                      <div className='col-2'>
                        <button className="circle-btn-A Aicon">N</button>
                      </div>
                      <div className='col-10'>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space-txt">Neelam Chowk</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Plot No. 5B, Sector - 15A, First Floor, Neelam Ajronda Chowk Metro Station, Faridabad, Haryana 121007
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                <i class="ri-pencil-line pencil-icn"></i>
                                <i class="ri-delete-bin-6-line trash-icn"></i>
                              </div>
                              <button className="view-btn-2">Manage Floors</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="main-location-card">
                    <div className='row'>
                      <div className='col-2'>
                        <button className="circle-btn-A Aicon">N</button>
                      </div>
                      <div className='col-10'>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space-txt">Neelam Chowk</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Plot No. 5B, Sector - 15A, First Floor, Neelam Ajronda Chowk Metro Station, Faridabad, Haryana 121007
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                <i class="ri-pencil-line pencil-icn"></i>
                                <i class="ri-delete-bin-6-line trash-icn"></i>
                              </div>
                              <button className="view-btn-2">Manage Floors</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="main-location-card">
                    <div className='row'>
                      <div className='col-2'>
                        <button className="circle-btn-A Aicon">N</button>
                      </div>
                      <div className='col-10'>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space-txt">Neelam Chowk</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Plot No. 5B, Sector - 15A, First Floor, Neelam Ajronda Chowk Metro Station, Faridabad, Haryana 121007
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                <i class="ri-pencil-line pencil-icn"></i>
                                <i class="ri-delete-bin-6-line trash-icn"></i>
                              </div>
                              <button className="view-btn-2">Manage Floors</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="main-location-card">
                    <div className='row'>
                      <div className='col-2'>
                        <button className="circle-btn-A Aicon">N</button>
                      </div>
                      <div className='col-10'>
                        <div className="row">
                          <div className="col-12">
                            <div className="location-top">
                              <div className="location-top2">
                                <p className="space-txt">Neelam Chowk</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="location-txt">
                              Plot No. 5B, Sector - 15A, First Floor, Neelam Ajronda Chowk Metro Station, Faridabad, Haryana 121007
                            </p>
                            <div className='botflx'>
                              <div className=" editicn">
                                <i class="ri-pencil-line pencil-icn"></i>
                                <i class="ri-delete-bin-6-line trash-icn"></i>
                              </div>
                              <button className="view-btn-2">Manage Floors</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Other location cards */}
              </div>


              <div className='page-flx'>
                <div className='page-flx2'>
                  <p className='page-txt1'>Showing 1 to 10 of 100 entries</p>
                </div>
                <div class="button-container">
                  <div class="button" id="button1"><i class="ri-arrow-left-s-line"></i></div>
                  <div class="button" id="button2">1</div>
                  <div class="button active" id="button1">0</div>
                  <div class="button" id="button3">2</div>
                  <div class="button" id="button4">3</div>
                  <div class="button" id="button4"><i class="ri-arrow-right-s-line"></i></div>
                </div>
              </div>
              <hr />

              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Cabins</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>
              <div className="row">


                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="cabins-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='cabin-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='cabin-text'>
                            <p className='cabin-totalseat'>
                              Four Seater
                            </p>

                          </div>

                        </div>
                        <div className='botflx-cabin'>
                          <div className='cabin-count'>
                            10 Desks
                          </div>
                          <div className="cabin-editicn">
                            <i class="ri-pencil-line pencil-icn"></i>
                            <i class="ri-delete-bin-6-line trash-icn"></i>

                          </div>
                          {/* <button className="view-btn-2">Manage Floors</button> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="cabins-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='cabin-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='cabin-text'>
                            <p className='cabin-totalseat'>
                              Four Seater
                            </p>

                          </div>

                        </div>
                        <div className='botflx-cabin'>
                          <div className='cabin-count'>
                            10 Desks
                          </div>
                          <div className="cabin-editicn">
                            <i class="ri-pencil-line pencil-icn"></i>
                            <i class="ri-delete-bin-6-line trash-icn"></i>

                          </div>
                          {/* <button className="view-btn-2">Manage Floors</button> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="cabins-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='cabin-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='cabin-text'>
                            <p className='cabin-totalseat'>
                              Four Seater
                            </p>

                          </div>

                        </div>
                        <div className='botflx-cabin'>
                          <div className='cabin-count'>
                            10 Desks
                          </div>
                          <div className="cabin-editicn">
                            <i class="ri-pencil-line pencil-icn"></i>
                            <i class="ri-delete-bin-6-line trash-icn"></i>

                          </div>
                          {/* <button className="view-btn-2">Manage Floors</button> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="cabins-card">
                    {/* <div class="cabin-sideline"></div> */}
                    <div class="cabin-sideline1"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='cabin-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='cabin-text'>
                            <p className='cabin-totalseat'>
                              Cafeteria
                            </p>

                          </div>

                        </div>
                        <div className='botflx-cafit'>

                          <div className="cabin-editicn">
                            <i class="ri-pencil-line pencil-icn"></i>
                            <i class="ri-delete-bin-6-line trash-icn"></i>

                          </div>
                          {/* <button className="view-btn-2">Manage Floors</button> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                {/* Other location cards */}
              </div>
              <div className='page-flx'>
                <div className='page-flx2'>
                  <p className='page-txt1'>Showing 1 to 10 of 100 entries</p>
                </div>
                <div class="button-container">
                  <div class="button" id="button1"><i class="ri-arrow-left-s-line"></i></div>
                  <div class="button" id="button2">1</div>
                  <div class="button active" id="button1">0</div>
                  <div class="button" id="button3">2</div>
                  <div class="button" id="button4">3</div>
                  <div class="button" id="button4"><i class="ri-arrow-right-s-line"></i></div>
                </div>
              </div>

              <hr />

              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Resources</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>

              <div class="filter-box">
                <div class="filter-sideline"></div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='select-data1'>
                      <p className='slt-txt'> Location  </p>
                      <div className={`custom-select ${isOpen1 ? 'open' : ''}`}>
                        <div className="form-group">
                          <div className="select-top" onClick={() => setIsOpen1(!isOpen1)}>
                            <span>{selectedLocation ? selectedLocation : 'Select Location'}</span>
                            <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                          </div>
                          <ul className="select-options">
                            <li onClick={() => handleSelect1("GroundFloor")}>Ground Floor</li>
                            <li onClick={() => handleSelect1("1stFloor")}>1st Floor</li>
                            <li onClick={() => handleSelect1("2ndFloor")}>2nd Floor</li>
                            <li onClick={() => handleSelect1("3rdFloor")}>3rd Floor</li>
                            <li onClick={() => handleSelect1("4thFloor")}>4th Floor</li>
                            <li onClick={() => handleSelect1("5thFloor")}>5th Floor</li>
                            <li onClick={() => handleSelect1("6thFloor")}>6th Floor</li>
                            <li onClick={() => handleSelect1("7thFloor")}>7th Floor</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='select-data1'>

                      <div className="select-data1">
                        <p className="slt-txt">Floor</p>
                        <div className={`custom-select ${isOpen ? 'open' : ''}`}>
                          <div className="form-group">
                            <div className="select-top" onClick={() => setIsOpen(!isOpen)}>
                              <span>{selectedFloor ? selectedFloor : 'Select Floor'}</span>
                              <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                            </div>
                            <ul className="select-options">
                              <li onClick={() => handleSelect("GroundFloor")}>Ground Floor</li>
                              <li onClick={() => handleSelect("1stFloor")}>1st Floor</li>
                              <li onClick={() => handleSelect("2ndFloor")}>2nd Floor</li>
                              <li onClick={() => handleSelect("3rdFloor")}>3rd Floor</li>
                              <li onClick={() => handleSelect("4thFloor")}>4th Floor</li>
                              <li onClick={() => handleSelect("5thFloor")}>5th Floor</li>
                              <li onClick={() => handleSelect("6thFloor")}>6th Floor</li>
                              <li onClick={() => handleSelect("7thFloor")}>7th Floor</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'></div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="seatdesk-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='seatdesk-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='seatdesk-editicn'>
                            {/* <p className='cabin-totalseat'>
                              Four Seater
                            </p> */}
                            <i class="ri-pencil-line seatdeskpencil-icn"></i>
                            <i class="ri-delete-bin-6-line seatdesktrash-icn"></i>
                          </div>
                        </div>
                        <p class="seatdesk-totalseat">Four Seater</p>
                        <p class="seatdesk-desc-totalseat">Type:<span className='type-of-cabin'> Cabin</span></p>
                        <div className='box-back'>
                          <div className='row'>
                            <div className='col-6 rgt-bdr'>
                              <p className='seatbox-txt'>
                                Location
                              </p>
                              <p className='seatbox-desc'>
                                United Kingdom
                              </p>

                            </div>
                            <div className='col-3 rgt-bdr'>
                              <center>
                                <p className='seatbox-txt'>
                                  Floor
                                </p>
                                <p className='seatbox-desc'>
                                  5
                                </p></center>
                            </div>
                            <div className='col-3 p-0'>
                              <center>
                                <p className='seatbox-txt'>
                                  Units
                                </p>
                                <p className='seatbox-desc'>
                                  80
                                </p>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="seatdesk-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='seatdesk-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='seatdesk-editicn'>
                            {/* <p className='cabin-totalseat'>
                              Four Seater
                            </p> */}
                            <i class="ri-pencil-line seatdeskpencil-icn"></i>
                            <i class="ri-delete-bin-6-line seatdesktrash-icn"></i>
                          </div>
                        </div>
                        <p class="seatdesk-totalseat">Four Seater</p>
                        <p class="seatdesk-desc-totalseat">Type:<span className='type-of-cabin'> Cabin</span></p>
                        <div className='box-back'>
                          <div className='row'>
                            <div className='col-6 rgt-bdr'>
                              <p className='seatbox-txt'>
                                Location
                              </p>
                              <p className='seatbox-desc'>
                                United Kingdom
                              </p>

                            </div>
                            <div className='col-3 rgt-bdr'>
                              <center>
                                <p className='seatbox-txt'>
                                  Floor
                                </p>
                                <p className='seatbox-desc'>
                                  5
                                </p></center>
                            </div>
                            <div className='col-3 p-0'>
                              <center>
                                <p className='seatbox-txt'>
                                  Units
                                </p>
                                <p className='seatbox-desc'>
                                  80
                                </p>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="seatdesk-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='seatdesk-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='seatdesk-editicn'>
                            {/* <p className='cabin-totalseat'>
                              Four Seater
                            </p> */}
                            <i class="ri-pencil-line seatdeskpencil-icn"></i>
                            <i class="ri-delete-bin-6-line seatdesktrash-icn"></i>
                          </div>
                        </div>
                        <p class="seatdesk-totalseat">Four Seater</p>
                        <p class="seatdesk-desc-totalseat">Type:<span className='type-of-cabin'> Cabin</span></p>
                        <div className='box-back'>
                          <div className='row'>
                            <div className='col-6 rgt-bdr'>
                              <p className='seatbox-txt'>
                                Location
                              </p>
                              <p className='seatbox-desc'>
                                United Kingdom
                              </p>

                            </div>
                            <div className='col-3 rgt-bdr'>
                              <center>
                                <p className='seatbox-txt'>
                                  Floor
                                </p>
                                <p className='seatbox-desc'>
                                  5
                                </p></center>
                            </div>
                            <div className='col-3 p-0'>
                              <center>
                                <p className='seatbox-txt'>
                                  Units
                                </p>
                                <p className='seatbox-desc'>
                                  80
                                </p>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="seatdesk-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='seatdesk-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='seatdesk-editicn'>
                            {/* <p className='cabin-totalseat'>
                              Four Seater
                            </p> */}
                            <i class="ri-pencil-line seatdeskpencil-icn"></i>
                            <i class="ri-delete-bin-6-line seatdesktrash-icn"></i>
                          </div>
                        </div>
                        <p class="seatdesk-totalseat">Four Seater</p>
                        <p class="seatdesk-desc-totalseat">Type:<span className='type-of-cabin'> Cabin</span></p>
                        <div className='box-back'>
                          <div className='row'>
                            <div className='col-6 rgt-bdr'>
                              <p className='seatbox-txt'>
                                Location
                              </p>
                              <p className='seatbox-desc'>
                                United Kingdom
                              </p>

                            </div>
                            <div className='col-3 rgt-bdr'>
                              <center>
                                <p className='seatbox-txt'>
                                  Floor
                                </p>
                                <p className='seatbox-desc'>
                                  5
                                </p></center>
                            </div>
                            <div className='col-3 p-0'>
                              <center>
                                <p className='seatbox-txt'>
                                  Units
                                </p>
                                <p className='seatbox-desc'>
                                  80
                                </p>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="seatdesk-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='seatdesk-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='seatdesk-editicn'>
                            {/* <p className='cabin-totalseat'>
                              Four Seater
                            </p> */}
                            <i class="ri-pencil-line seatdeskpencil-icn"></i>
                            <i class="ri-delete-bin-6-line seatdesktrash-icn"></i>
                          </div>
                        </div>
                        <p class="seatdesk-totalseat">Four Seater</p>
                        <p class="seatdesk-desc-totalseat">Type:<span className='type-of-cabin'> Cabin</span></p>
                        <div className='box-back'>
                          <div className='row'>
                            <div className='col-6 rgt-bdr'>
                              <p className='seatbox-txt'>
                                Location
                              </p>
                              <p className='seatbox-desc'>
                                United Kingdom
                              </p>

                            </div>
                            <div className='col-3 rgt-bdr'>
                              <center>
                                <p className='seatbox-txt'>
                                  Floor
                                </p>
                                <p className='seatbox-desc'>
                                  5
                                </p></center>
                            </div>
                            <div className='col-3 p-0'>
                              <center>
                                <p className='seatbox-txt'>
                                  Units
                                </p>
                                <p className='seatbox-desc'>
                                  80
                                </p>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>




                {/* Other location cards */}
              </div>
              <div className='page-flx'>
                <div className='page-flx2'>
                  <p className='page-txt1'>Showing 1 to 10 of 100 entries</p>
                </div>
                <div class="button-container">
                  <div class="button" id="button1"><i class="ri-arrow-left-s-line"></i></div>
                  <div class="button" id="button2">1</div>
                  <div class="button active" id="button1">0</div>
                  <div class="button" id="button3">2</div>
                  <div class="button" id="button4">3</div>
                  <div class="button" id="button4"><i class="ri-arrow-right-s-line"></i></div>
                </div>
              </div>

              <hr />

              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Floor</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>

              <div class="filter-box">
                <div class="filter-sideline"></div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='select-data1'>
                      <p className='slt-txt'> Location  </p>
                      <div className={`custom-select ${isOpen1 ? 'open' : ''}`}>
                        <div className="form-group">
                          <div className="select-top" onClick={() => setIsOpen1(!isOpen1)}>
                            <span>{selectedLocation ? selectedLocation : 'Select Location'}</span>
                            <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                          </div>
                          <ul className="select-options">
                            <li onClick={() => handleSelect1("GroundFloor")}>Ground Floor</li>
                            <li onClick={() => handleSelect1("1stFloor")}>1st Floor</li>
                            <li onClick={() => handleSelect1("2ndFloor")}>2nd Floor</li>
                            <li onClick={() => handleSelect1("3rdFloor")}>3rd Floor</li>
                            <li onClick={() => handleSelect1("4thFloor")}>4th Floor</li>
                            <li onClick={() => handleSelect1("5thFloor")}>5th Floor</li>
                            <li onClick={() => handleSelect1("6thFloor")}>6th Floor</li>
                            <li onClick={() => handleSelect1("7thFloor")}>7th Floor</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-3'></div>
                  <div className='col-sm-6'></div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="seatdesk-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='floordesk-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>
                          <div className='seatdesk-editicn'>
                            <div class="cabin-text"><p class="cabin-totalseat">Four Seater</p></div>

                          </div>
                        </div>
                        <div class="floor-botflx"><div class=" editicn"><i class="ri-pencil-line floor-pencil-icn"></i><i class="ri-delete-bin-6-line floor-trash-icn"></i></div><button class="floor-btn-2">Manage Floors</button></div>
                        <div className='box-back'>
                          <div className='row'>
                            <div className='col-6 rgt-bdr'>
                              <p className='seatbox-txt'>
                                Location
                              </p>
                              <p className='seatbox-desc'>
                                United Kingdom
                              </p>

                            </div>
                            <div className='col-6 '>
                              <center>
                                <p className='seatbox-txt'>
                                  Floor
                                </p>
                                <p className='seatbox-desc'>
                                  5
                                </p></center>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>




                {/* Other location cards */}
              </div>
              <div className='page-flx'>
                <div className='page-flx2'>
                  <p className='page-txt1'>Showing 1 to 10 of 100 entries</p>
                </div>
                <div class="button-container">
                  <div class="button" id="button1"><i class="ri-arrow-left-s-line"></i></div>
                  <div class="button" id="button2">1</div>
                  <div class="button active" id="button1">0</div>
                  <div class="button" id="button3">2</div>
                  <div class="button" id="button4">3</div>
                  <div class="button" id="button4"><i class="ri-arrow-right-s-line"></i></div>
                </div>
              </div>

              <hr />




              {/* table data */}

              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Visitors</p>

                </div>

                <div className="featured-flx1">

                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>


              <div className='tables-top'>
                <form className="navbar-form navbar-left" action="#">
                  <div className="input-group">
                    <input type="text" className="form-control search-inp" placeholder="Search Features" name="search" />
                    <button className="btn btn-default" type="submit">
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                </form>

                <div className='select-upr'>
                  <select id="locationSelect" className='location-select'>
                    <option value="location1">Location 1</option>
                    <option value="location2">Location 2</option>
                    <option value="location3">Location 3</option>
                    <option value="location4">Location 4</option>
                    <option value="location5">Location 5</option>
                  </select>
                </div>
              </div>

              <div className='side-shadow'>
                <div className='table-responsive'>
                  <table className="table table-default table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Visitor ID <i class="ri-arrow-up-down-line"></i></th>
                        <th>Name <i class="ri-arrow-up-down-line"></i></th>
                        <th>Company <i class="ri-arrow-up-down-line"></i></th>
                        <th>Date & Time <i class="ri-arrow-up-down-line"></i></th>
                        <th>Status  <i class="ri-arrow-up-down-line"></i></th>
                        <th>Actions  <i class="ri-arrow-up-down-line"></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#5438</td>
                        <td>Doe</td>
                        <td>deskbox.com</td>
                        <td>2024-03-29 10:00 AM</td>
                        <td><button class="btn btn-success approved">Approved</button></td>
                        <td>
                          <div className='edt-flx'>
                            <i class="ri-pencil-line edt-icn"></i>
                            <i class="ri-eye-line view-icn"></i>
                            <i class="ri-delete-bin-6-line dlt-icn"></i>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>#5438</td>
                        <td>Doe</td>
                        <td>deskbox.com</td>
                        <td>2024-03-29 10:00 AM</td>
                        <td><button class="btn btn-danger approved">Rejected</button></td>
                        <td>
                          <div className='edt-flx'>
                            <i class="ri-pencil-line edt-icn"></i>
                            <i class="ri-eye-line view-icn"></i>
                            <i class="ri-delete-bin-6-line dlt-icn"></i>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>#5438</td>
                        <td>Doe</td>
                        <td>deskbox.com</td>
                        <td>2024-03-29 10:00 AM</td>
                        <td><button class="btn btn-success approved">Approved</button></td>
                        <td>
                          <div className='edt-flx'>
                            <i class="ri-pencil-line edt-icn"></i>
                            <i class="ri-eye-line view-icn"></i>
                            <i class="ri-delete-bin-6-line dlt-icn"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div className='page-flx'>
                <div className='page-flx2'>
                  <p className='page-txt1'>Showing 1 to 10 of 100 entries</p>
                </div>
                <div class="button-container">
                  <div class="button" id="button1"><i class="ri-arrow-left-s-line"></i></div>
                  <div class="button" id="button2">1</div>
                  <div class="button active" id="button1">0</div>
                  <div class="button" id="button3">2</div>
                  <div class="button" id="button4">3</div>
                  <div class="button" id="button4"><i class="ri-arrow-right-s-line"></i></div>
                </div>
              </div>

              {/* table data */}




              {/* Dashboard data */}
              <div className='row'>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard-flx'>
                      <p className='dash-data'>50.6 %</p>
                      <p className='dash-data1'>Desks</p>
                    </div>
                    <center><hr className='dash-line' /></center>
                    <div className='dashboard-flx'>
                      <p className='dash-data'>0%</p>
                      <p className='dash-data1'>Area</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard_secondcard'>
                      <p className='dash-data'>3.2 M</p>
                      <p className='dash-data1'>Monthly Revenue</p>

                    </div>

                  </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard_secondcard'>
                      <p className='dash-data'>3.2 M</p>
                      <p className='dash-data1'>Monthly Revenue</p>

                    </div>

                  </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard_secondcard'>
                      <p className='dash-data'>3.2 M</p>
                      <p className='dash-data1'>Monthly Revenue</p>

                    </div>

                  </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard_secondcard'>
                      <p className='dash-data'>3.2 M</p>
                      <p className='dash-data1'>Monthly Revenue</p>
                    </div>
                  </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard_secondcard'>
                      <p className='dash-data'>3.2 M</p>
                      <p className='dash-data1'>Monthly Revenue</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard_secondcard'>
                      <p className='dash-data'>3.2 M</p>
                      <p className='dash-data1'>Monthly Revenue</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                  <div className='dashboard-card'>
                    <div className="dashboard-icn">
                      <i class="ri-dashboard-line des-icn"></i>
                    </div>
                    <div className='dashboard_secondcard'>
                      <p className='dash-data'>3.2 M</p>
                      <p className='dash-data1'>Monthly Revenue</p>

                    </div>

                  </div>
                </div>
              </div>
              <hr />
              {/* Dashboard data */}


              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Posts</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>
              {/* posts */}
              <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='post-card'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" className='img-fluid post-image' />
                    <div className='row over-dropdown'>
                      <div className='col-10'></div>
                      <div className='col-2'>
                        <div class="dropdown droptop ">
                          <div type="button" class="show" data-bs-toggle="dropdown">
                            <i className="ri-more-2-line more-detail"></i>
                          </div>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Link 1</a></li>
                            <li><a class="dropdown-item" href="#">Link 2</a></li>
                            <li><a class="dropdown-item" href="#">Link 3</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='post-inner-txt'>
                      <p class="posts-title">Title 1</p>
                      <p class="posts-txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                      <div className='posts-public'>
                        <div className='comment'>
                          <i class="ri-heart-3-line likeicn"></i> <p className='postcount'>1000</p>
                        </div>
                        <div className='comment'>
                          <i class="ri-chat-1-line likeicn"></i> <p className='postcount'>99+</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='post-card'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" className='img-fluid post-image' />
                    <div className='row over-dropdown'>
                      <div className='col-10'></div>
                      <div className='col-2'>
                        <div class="dropdown droptop ">
                          <div type="button" class="show" data-bs-toggle="dropdown">
                            <i className="ri-more-2-line more-detail"></i>
                          </div>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Link 1</a></li>
                            <li><a class="dropdown-item" href="#">Link 2</a></li>
                            <li><a class="dropdown-item" href="#">Link 3</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='post-inner-txt'>
                      <p class="posts-title">Title 1</p>
                      <p class="posts-txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                      <div className='posts-public'>
                        <div className='comment'>
                          <i class="ri-heart-3-line likeicn"></i> <p className='postcount'>1000</p>
                        </div>
                        <div className='comment'>
                          <i class="ri-chat-1-line likeicn"></i> <p className='postcount'>99+</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='post-card'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" className='img-fluid post-image' />
                    <div className='row over-dropdown'>
                      <div className='col-10'></div>
                      <div className='col-2'>
                        <div class="dropdown droptop ">
                          <div type="button" class="show" data-bs-toggle="dropdown">
                            <i className="ri-more-2-line more-detail"></i>
                          </div>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Link 1</a></li>
                            <li><a class="dropdown-item" href="#">Link 2</a></li>
                            <li><a class="dropdown-item" href="#">Link 3</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='post-inner-txt'>
                      <p class="posts-title">Title 1</p>
                      <p class="posts-txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                      <div className='posts-public'>
                        <div className='comment'>
                          <i class="ri-heart-3-line likeicn"></i> <p className='postcount'>1000</p>
                        </div>
                        <div className='comment'>
                          <i class="ri-chat-1-line likeicn"></i> <p className='postcount'>99+</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='post-card'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" className='img-fluid post-image' />
                    <div className='row over-dropdown'>
                      <div className='col-10'></div>
                      <div className='col-2'>
                        <div class="dropdown droptop ">
                          <div type="button" class="show" data-bs-toggle="dropdown">
                            <i className="ri-more-2-line more-detail"></i>
                          </div>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Link 1</a></li>
                            <li><a class="dropdown-item" href="#">Link 2</a></li>
                            <li><a class="dropdown-item" href="#">Link 3</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='post-inner-txt'>
                      <p class="posts-title">Title 1</p>
                      <p class="posts-txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                      <div className='posts-public'>
                        <div className='comment'>
                          <i class="ri-heart-3-line likeicn"></i> <p className='postcount'>1000</p>
                        </div>
                        <div className='comment'>
                          <i class="ri-chat-1-line likeicn"></i> <p className='postcount'>99+</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='post-card'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" className='img-fluid post-image' />
                    <div className='row over-dropdown'>
                      <div className='col-10'></div>
                      <div className='col-2'>
                        <div class="dropdown droptop ">
                          <div type="button" class="show" data-bs-toggle="dropdown">
                            <i className="ri-more-2-line more-detail"></i>
                          </div>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Link 1</a></li>
                            <li><a class="dropdown-item" href="#">Link 2</a></li>
                            <li><a class="dropdown-item" href="#">Link 3</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='post-inner-txt'>
                      <p class="posts-title">Title 1</p>
                      <p class="posts-txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                      <div className='posts-public'>
                        <div className='comment'>
                          <i class="ri-heart-3-line likeicn"></i> <p className='postcount'>1000</p>
                        </div>
                        <div className='comment'>
                          <i class="ri-chat-1-line likeicn"></i> <p className='postcount'>99+</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/* posts */}

              {/* Events */}
              <hr />

              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Events</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>

              <div class="filter-box">
                <div class="filter-sideline"></div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='select-data1'>
                      <p className='slt-txt'> Events  </p>
                      <div className={`custom-select ${isOpen1 ? 'open' : ''}`}>
                        <div className="form-group">
                          <div className="select-top" onClick={() => setIsOpen1(!isOpen1)}>
                            <span>{selectedLocation ? selectedLocation : 'Select Location'}</span>
                            <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                          </div>
                          <ul className="select-options">
                            <li onClick={() => handleSelect1("GroundFloor")}>Ground Floor</li>
                            <li onClick={() => handleSelect1("1stFloor")}>1st Floor</li>
                            <li onClick={() => handleSelect1("2ndFloor")}>2nd Floor</li>
                            <li onClick={() => handleSelect1("3rdFloor")}>3rd Floor</li>
                            <li onClick={() => handleSelect1("4thFloor")}>4th Floor</li>
                            <li onClick={() => handleSelect1("5thFloor")}>5th Floor</li>
                            <li onClick={() => handleSelect1("6thFloor")}>6th Floor</li>
                            <li onClick={() => handleSelect1("7thFloor")}>7th Floor</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='select-data1'>

                      <div className="select-data1">
                        <p className="slt-txt">Floor</p>
                        <div className={`custom-select ${isOpen ? 'open' : ''}`}>
                          <div className="form-group">
                            <div className="select-top" onClick={() => setIsOpen(!isOpen)}>
                              <span>{selectedFloor ? selectedFloor : 'Select Floor'}</span>
                              <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                            </div>
                            <ul className="select-options">
                              <li onClick={() => handleSelect("GroundFloor")}>Ground Floor</li>
                              <li onClick={() => handleSelect("1stFloor")}>1st Floor</li>
                              <li onClick={() => handleSelect("2ndFloor")}>2nd Floor</li>
                              <li onClick={() => handleSelect("3rdFloor")}>3rd Floor</li>
                              <li onClick={() => handleSelect("4thFloor")}>4th Floor</li>
                              <li onClick={() => handleSelect("5thFloor")}>5th Floor</li>
                              <li onClick={() => handleSelect("6thFloor")}>6th Floor</li>
                              <li onClick={() => handleSelect("7thFloor")}>7th Floor</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <p class="slt-txt">Status</p>
                    <div className='event-select-top'>
                      <button
                        className={`active-btn ${activeButton === 'All' ? 'active' : ''}`}
                        onClick={() => handleClick('All')}
                      >
                        All
                      </button>
                      <button
                        className={`active-btn ${activeButton === 'Upcoming' ? 'active' : ''}`}
                        onClick={() => handleClick('Upcoming')}
                      >
                        Upcoming
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='post-card'>
                    <div class="post-sideline1"></div>
                    <img src="/img/events.jpg" className='img-fluid post-image' />
                    <div className='row over-dropdown'>
                      <div className='col-10'></div>
                      <div className='col-2'>
                        <div class="dropdown droptop ">
                          <div type="button" class="show" data-bs-toggle="dropdown">
                            <i className="ri-more-2-line more-detail"></i>
                          </div>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Link 1</a></li>
                            <li><a class="dropdown-item" href="#">Link 2</a></li>
                            <li><a class="dropdown-item" href="#">Link 3</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='post-inner-txt'>
                      <p class="posts-title">Title 1</p>
                      <p class="posts-txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>

                    </div>
                    <div className='box-back'>
                      <div className='row'>
                        <div className='col-6 rgt-bdr'>
                          <p className='seatbox-txt'>
                            Location
                          </p>
                          <p className='seatbox-desc'>
                            United Kingdom
                          </p>

                        </div>
                        <div className='col-6 '>
                          <center>
                            <p className='seatbox-txt'>
                              Floor
                            </p>
                            <p className='seatbox-desc'>
                              5
                            </p></center>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Events */}
              {/* occupancy */}
              <hr />

              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Occupancy</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>

              <div class="filter-box">
                <div class="filter-sideline"></div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='select-data1'>
                      <p className='slt-txt'> Location  </p>
                      <div className={`custom-select ${isOpen1 ? 'open' : ''}`}>
                        <div className="form-group">
                          <div className="select-top" onClick={() => setIsOpen1(!isOpen1)}>
                            <span>{selectedLocation ? selectedLocation : 'Select Location'}</span>
                            <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                          </div>
                          <ul className="select-options">
                            <li onClick={() => handleSelect1("GroundFloor")}>Ground Floor</li>
                            <li onClick={() => handleSelect1("1stFloor")}>1st Floor</li>
                            <li onClick={() => handleSelect1("2ndFloor")}>2nd Floor</li>
                            <li onClick={() => handleSelect1("3rdFloor")}>3rd Floor</li>
                            <li onClick={() => handleSelect1("4thFloor")}>4th Floor</li>
                            <li onClick={() => handleSelect1("5thFloor")}>5th Floor</li>
                            <li onClick={() => handleSelect1("6thFloor")}>6th Floor</li>
                            <li onClick={() => handleSelect1("7thFloor")}>7th Floor</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='select-data1'>

                      <div className="select-data1">
                        <p className="slt-txt">Floor</p>
                        <div className={`custom-select ${isOpen ? 'open' : ''}`}>
                          <div className="form-group">
                            <div className="select-top" onClick={() => setIsOpen(!isOpen)}>
                              <span>{selectedFloor ? selectedFloor : 'Select Floor'}</span>
                              <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                            </div>
                            <ul className="select-options">
                              <li onClick={() => handleSelect("GroundFloor")}>Ground Floor</li>
                              <li onClick={() => handleSelect("1stFloor")}>1st Floor</li>
                              <li onClick={() => handleSelect("2ndFloor")}>2nd Floor</li>
                              <li onClick={() => handleSelect("3rdFloor")}>3rd Floor</li>
                              <li onClick={() => handleSelect("4thFloor")}>4th Floor</li>
                              <li onClick={() => handleSelect("5thFloor")}>5th Floor</li>
                              <li onClick={() => handleSelect("6thFloor")}>6th Floor</li>
                              <li onClick={() => handleSelect("7thFloor")}>7th Floor</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'></div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="seatdesk-card">
                    <div class="cabin-sideline"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className='occupancy-sheet'>
                          <div className='cabin-number'>
                            <i class="ri-keyboard-box-line keybord-icn"></i>
                          </div>

                        </div>
                        <div className='row center_seater'>
                          <div className='col-sm-6'>
                            <p class="seatdesk-totalseat">Four Seater</p>
                            <p class="seatdesk-desc-totalseat">Type:<span className='type-of-cabin'> Cabin</span></p>
                          </div>
                          <div className='col-sm-6'>
                            <div className="manzoku">
                              <p className="right">80%</p>
                              <p className="left">20%</p>

                            </div>
                          </div>
                        </div>
                        <div className='box-back'>
                          <div className='row'>
                            <div className='col-4 rgt-bdr'>
                              <center>
                                <p className='seatbox-txt'>
                                  Total
                                </p>
                                <p className='seatbox-desc'>
                                  100
                                </p>
                              </center>

                            </div>
                            <div className='col-4 rgt-bdr'>
                              <center>
                                <p className='seatbox-txt'>
                                  Occupied
                                </p>
                                <p className='seatbox-desc'>
                                  80
                                </p></center>
                            </div>
                            <div className='col-4 p-0'>
                              <center>
                                <p className='seatbox-txt'>
                                  Vacant
                                </p>
                                <p className='seatbox-desc'>
                                  20
                                </p>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              {/* Occupancy */}
              {/* team */}


              <div className="featured-flx">
                <div className="featured-flx1">
                  <p className="main_heading1">Teams</p>

                </div>

                <div className="featured-flx1">
                  <div className="search-container">
                    <input
                      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                      type="text"
                      placeholder="Search Locations"
                    />
                    <button className="search-button" onClick={handleButtonClick}>
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button className="circle-btn-add icon"><i className="ri-add-line"></i></button>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                  <div className='team-cards'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" class="img-fluid team-image" />
                    <div className='active1-dot'>
                      <i class="ri-circle-fill red-active1"></i>
                    </div>
                    <div class="row over-dropdown"><div class="col-10"></div><div class="col-2"><div class="dropdown droptop "><div type="button" class="show" data-bs-toggle="dropdown"><i class="ri-more-2-line more-detail"></i></div><ul class="dropdown-menu"><li><a class="dropdown-item" href="#">Link 1</a></li><li><a class="dropdown-item" href="#">Link 2</a></li><li><a class="dropdown-item" href="#">Link 3</a></li></ul></div></div></div>
                    <p class="team-totalseat">Mr. Karan Verma</p>
                    <p class="team-desc-totalseat">Ceo</p>
                    <div class="box-back"><div class="row"><div class="col-6 rgt-bdr"><p class="seatbox-txt">Location</p><p class="seatbox-desc">United Kingdom</p></div><div class="col-6 "><center><p class="seatbox-txt">Role</p><p class="seatbox-desc">Managing Director </p></center></div></div></div>

                  </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                  <div className='team-cards'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" class="img-fluid team-image" />
                    <div className='active1-dot'>
                      <i class="ri-circle-fill red-active1"></i>
                    </div>
                    <div class="row over-dropdown"><div class="col-10"></div><div class="col-2"><div class="dropdown droptop "><div type="button" class="show" data-bs-toggle="dropdown"><i class="ri-more-2-line more-detail"></i></div><ul class="dropdown-menu"><li><a class="dropdown-item" href="#">Link 1</a></li><li><a class="dropdown-item" href="#">Link 2</a></li><li><a class="dropdown-item" href="#">Link 3</a></li></ul></div></div></div>
                    <p class="team-totalseat">Mr. Karan Verma</p>
                    <p class="team-desc-totalseat">Ceo</p>
                    <div class="box-back"><div class="row"><div class="col-6 rgt-bdr"><p class="seatbox-txt">Location</p><p class="seatbox-desc">United Kingdom</p></div><div class="col-6 "><center><p class="seatbox-txt">Role</p><p class="seatbox-desc">Managing Director </p></center></div></div></div>

                  </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                  <div className='team-cards'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" class="img-fluid team-image" />
                    <div className='active1-dot'>
                      <i class="ri-circle-fill red-active1"></i>
                    </div>
                    <div class="row over-dropdown"><div class="col-10"></div><div class="col-2"><div class="dropdown droptop "><div type="button" class="show" data-bs-toggle="dropdown"><i class="ri-more-2-line more-detail"></i></div><ul class="dropdown-menu"><li><a class="dropdown-item" href="#">Link 1</a></li><li><a class="dropdown-item" href="#">Link 2</a></li><li><a class="dropdown-item" href="#">Link 3</a></li></ul></div></div></div>
                    <p class="team-totalseat">Mr. Karan Verma</p>
                    <p class="team-desc-totalseat">Ceo</p>
                    <div class="box-back"><div class="row"><div class="col-6 rgt-bdr"><p class="seatbox-txt">Location</p><p class="seatbox-desc">United Kingdom</p></div><div class="col-6 "><center><p class="seatbox-txt">Role</p><p class="seatbox-desc">Managing Director </p></center></div></div></div>

                  </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                  <div className='team-cards'>
                    <div class="post-sideline1"></div>
                    <img src="/img/post.png" class="img-fluid team-image" />
                    <div className='active1-dot'>
                      <i class="ri-circle-fill red-active1"></i>
                    </div>
                    <div class="row over-dropdown"><div class="col-10"></div><div class="col-2"><div class="dropdown droptop "><div type="button" class="show" data-bs-toggle="dropdown"><i class="ri-more-2-line more-detail"></i></div><ul class="dropdown-menu"><li><a class="dropdown-item" href="#">Link 1</a></li><li><a class="dropdown-item" href="#">Link 2</a></li><li><a class="dropdown-item" href="#">Link 3</a></li></ul></div></div></div>
                    <p class="team-totalseat">Mr. Karan Verma</p>
                    <p class="team-desc-totalseat">Ceo</p>
                    <div class="box-back"><div class="row"><div class="col-6 rgt-bdr"><p class="seatbox-txt">Location</p><p class="seatbox-desc">United Kingdom</p></div><div class="col-6 "><center><p class="seatbox-txt">Role</p><p class="seatbox-desc">Managing Director </p></center></div></div></div>

                  </div>
                </div>
              </div>
              {/* team */}
              <div className="invoice">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="invoice-title">
                            <h4 className="float-end font-size-15">Invoice #DS0204 <span className="badge bg-success font-size-12 ms-2">Paid</span></h4>
                            <div className="mb-4">
                              <h2 className="mb-1 text-muted">Deskbox.com</h2>
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">3184 Spruce Drive Pittsburgh, PA 15201</p>
                              <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i> xyz@987.com</p>
                              <p><i className="uil uil-phone me-1"></i> 012-345-6789</p>
                            </div>
                          </div>

                          <hr className="my-4" />

                          <div className="row">
                            <div className="col-sm-6">
                              <div className="text-muted">
                                <h5 className="font-size-16 mb-3">Billed To:</h5>
                                <h5 className="font-size-15 mb-2">Preston Miller</h5>
                                <p className="mb-1">4068 Post Avenue Newfolden, MN 56738</p>
                                <p className="mb-1">PrestonMiller@armyspy.com</p>
                                <p>001-234-5678</p>
                              </div>
                            </div>
                            {/* <!-- end col --> */}
                            <div className="col-sm-6">
                              <div className="text-muted text-sm-end">
                                <div>
                                  <h5 className="font-size-15 mb-1">Invoice No:</h5>
                                  <p>#DZ0112</p>
                                </div>
                                <div className="mt-4">
                                  <h5 className="font-size-15 mb-1">Invoice Date:</h5>
                                  <p>12 Oct, 2020</p>
                                </div>
                                <div className="mt-4">
                                  <h5 className="font-size-15 mb-1">Order No:</h5>
                                  <p>#1123456</p>
                                </div>
                              </div>
                            </div>
                            {/* <!-- end col --> */}
                          </div>
                          {/* <!-- end row --> */}

                          <div className="py-2">
                            <h5 className="font-size-15">Order Summary</h5>

                            <div className="table-responsive">
                              <table className="table align-middle table-nowrap table-centered mb-0">
                                <thead>
                                  <tr>
                                    <th style={{ width: "70px" }}>No.</th>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                  </tr>
                                </thead>
                                {/* <!-- end thead --> */}
                                <tbody>
                                  <tr>
                                    <th scope="row">01</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">Black Strap A012</h5>

                                      </div>
                                    </td>
                                    <td>$ 245.50</td>
                                    <td>1</td>
                                    <td >$ 245.50</td>
                                  </tr>
                                  {/* <!-- end tr --> */}
                                  <tr>
                                    <th scope="row">02</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">Stainless Steel S010</h5>

                                      </div>
                                    </td>
                                    <td>$ 245.50</td>
                                    <td>2</td>
                                    <td >$491.00</td>
                                  </tr>
                                  {/* <!-- end tr --> */}
                                  <tr>
                                    <th scope="row" colspan="4" className="text-end">Sub Total</th>
                                    <td>$732.50</td>
                                  </tr>
                                  {/* <!-- end tr --> */}
                                  <tr>
                                    <th scope="row" colspan="4" className="border-0 text-end">
                                      Discount :</th>
                                    <td className="border-0 text-end">- $25.50</td>
                                  </tr>
                                  {/* <!-- end tr --> */}
                                  <tr>
                                    <th scope="row" colspan="4" className="border-0 text-end">
                                      Shipping Charge :</th>
                                    <td className="border-0 text-end">$20.00</td>
                                  </tr>
                                  {/* <!-- end tr --> */}
                                  <tr>
                                    <th scope="row" colspan="4" className="border-0 text-end">
                                      Tax</th>
                                    <td className="border-0 text-end">$12.00</td>
                                  </tr>
                                  {/* <!-- end tr --> */}
                                  <tr>
                                    <th scope="row" colspan="4" className="border-0 text-end">Total</th>
                                    <td className="border-0 text-end"><h4 className="m-0 fw-semibold">$739.00</h4></td>
                                  </tr>
                                  {/* <!-- end tr --> */}
                                </tbody>
                                {/* <!-- end tbody --> */}
                              </table>
                              {/* <!-- end table --> */}
                            </div>
                            {/* <!-- end table responsive --> */}
                            <div className="d-print-none mt-4">
                              <div className="float-end">
                                <a href="javascript:window.print()" className="btn btn-success me-1">Print</a>
                                {/* <a href="#" className="btn btn-primary w-md">Send</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- end col --> */}
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>


      </div>
      <div className="modal fade " id="myModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">

            {/* Modal Header */}
            <div className="modal-header modalhead">
              <h5 className="modal-title">Add a company</h5>
              <button type="button" className="btn-close modalclose" data-bs-dismiss="modal"> <i class="ri-close-fill closeicn11"></i></button>
            </div>

            {/* Modal body */}
            <div className="modal-body">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item  " role="presentation">
                  <a className="nav-link  tab-txt1 active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="true">Tab 1</a>
                </li>
                <li className="nav-item " role="presentation">
                  <a className="nav-link tab-txt1 " id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="false">Tab 2</a>
                </li>
                <li className="nav-item " role="presentation">
                  <a className="nav-link tab-txt1 " id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-2" aria-selected="false">Tab 3</a>
                </li>
              </ul>
              <div className="tab-content pt-3" id="tab-content">
                <div className="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">

                  <div className='form-side'>
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div class="form-group">
                            <label for="email" className="form-headingdata">Company Name</label>
                            <input type="text" className="form-control input-top" id="email" placeholder="Company Name" name="c" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div class="form-group">
                            <label for="email" className="form-headingdata"> Company Email:</label>
                            <input type="email" className="form-control input-top" id="email" placeholder="Company Email:" name="email" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div class="form-group">
                            <label for="email" className="form-headingdata">Contact no.</label>
                            <input type="text" className="form-control input-top" id="email" placeholder="Contact no." name="Contact no." />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div class="form-group" className="form-headingdata">
                            <label for="email">Website Url</label>
                            <input type="Text" className="form-control input-top" id="email" placeholder="Website Url" name="Website Url" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div class="form-group">
                            <label for="email" className="form-headingdata">Refrence:</label>
                            <input type="email" className="form-control input-top" id="email" placeholder="Enter email" name="email" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div class="form-group">
                            <label for="email" className="form-headingdata">Location:</label>
                            <select className='form-control input-top'>
                              <option>hii</option>
                              <option>hii</option>
                              <option>hii</option>
                            </select>
                            {/* <input type="email" className="form-control" id="email" placeholder="Enter email" name="email"/> */}
                          </div>
                        </div>
                      </div>


                    </form>
                  </div>
                </div>
                <div className="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">Tab 2 selected</div>
                <div className="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">Tab 3 selected</div>
              </div>
            </div>

            {/* Modal footer */}
            <div className="modal-footer">
              <button type="submit" class="btn btn-primary">Submit</button>
              {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
            </div>
          </div>
        </div>
      </div>
    </>


  );
}

export default Component;