import Members from '../../components/member/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <Members name="All Teams" />
        </>
    );
}