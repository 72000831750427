import { useState, useEffect, useRef, useContext } from "react";
import { LoadingMutatingDots } from "../../components/ui/Loadings";
import { url, whoAmIHeaders, getDecryptedDataFromLocalStorage, clientpoints, client_prefix } from "../../../../lib/lib";
import { AuthUserContext } from "../../../../lib/AuthUserContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../../../nav/components/location/component.css";
import AssetCard from "../../components/resource/asset_mapping/cards/Component";
import { ToastContainer, toast } from "react-toastify";
import EditModal from "../../components/resource/asset_mapping/modals/EditModal";

import Select from "react-select";

function AssetsMapping() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const selectRef1 = useRef(null);
  const { spaceId } = useParams();
  const [myLocations, setMyLocations] = useState([]);
  const [myFloors, setMyFloors] = useState([]);
  const [resources, setResources] = useState([]);

  const locationData = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const locationIdFromUrl = urlParams.get("locationId");
  const floorIdFromUrl = urlParams.get("floorId");

  const [selectedLocation, setSelectedLocation] = useState(locationIdFromUrl);
  const [selectedFloor, setSelectedFloor] = useState(floorIdFromUrl);
  const [selectedValues, setSelectedValues] = useState({
    location_id: "",
    floor_id: "",
  });

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 12,
    totalPages: 0,
  });

  const [selectedItem, setSelectedItem] = useState();
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (myLocations.length === 1) {
      setSelectedLocation(myLocations[0].value);
    } else if (myLocations.length !== 0 && !locationIdFromUrl) {
      setSelectedLocation("All");
    }
  }, [myLocations, locationIdFromUrl]);

  useEffect(() => {
    if (selectedLocation && selectedLocation !== "All") {
      if (myFloors.length === 1) {
        setSelectedFloor(myFloors[0].value);
      }
    } else {
      setSelectedFloor("All");
    }
  }, [myFloors, selectedLocation]);

  useEffect(() => {
    const location = myLocations.find((item) => item.value === selectedLocation);
    setSelectedValues((prev) => ({ ...prev, location_id: location ? location : { label: "All", value: "All" } }));
  }, [myLocations, selectedLocation]);

  useEffect(() => {
    const floor = myFloors.find((item) => item.value === selectedFloor);
    setSelectedValues((prev) => ({ ...prev, floor_id: floor ? floor : { label: "All", value: "All" } }));
  }, [myFloors, selectedFloor]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (selectRef1.current && !selectRef1.current.contains(event.target)) {
        setIsOpen1(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef, selectRef1]);

  const handleSelect1 = (locationId) => {
    if (selectedLocation !== locationId || locationId === "All") {
      setMyFloors([]);
      setSelectedFloor("All");
    }

    if (locationId === "All") {
      setShowFilterMsg(false);
    }

    setSelectedLocation(locationId);
    setIsOpen1(false);
  };

  const handleSelect = (floorId) => {
    setSelectedFloor(floorId);
    setIsOpen(false);
  };

  useEffect(() => {
    fetchMyLocations();
  }, []);

  useEffect(() => {
    if (selectedLocation && selectedLocation !== "All") {
      fetchMyFloorsByLocation();
    }
  }, [selectedLocation]);

  const handleSearchExpand = () => {
    setIsExpanded(true);
    inputRef.current.focus();
  };

  const handleSearchClose = () => {
    setShowFilterMsg(false);
    setIsExpanded(false);
    setSearchTerm("");

    if (myLocations.length > 1) {
      setSelectedLocation("All");
    }

    fetchData();
  };

  const fetchMyLocations = async () => {
    try {
      const requestBody = {
        urlName: spaceId,
      };

      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setMyLocations(
        data.Data.map((item) => ({
          label: item.location,
          value: item._id,
        }))
      );
      // setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      // setIsLoading(false);
    }
  };

  const fetchMyFloorsByLocation = async () => {
    try {
      const requestBody = {
        urlName: spaceId,
        filters: { location_id: selectedLocation },
      };

      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setMyFloors(
        data.Data.map((item) => ({
          label: item.floor_name,
          value: item._id,
        }))
      );

      // setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      // setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const sortingObject = { floor_number: 1 };

      const requestBody = {
        urlName: spaceId,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        // sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.mapping_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }
      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(data.totalCount / prev.pageSize),
      }));

      const resourcesArr = formatMappingData(data.Data);

      // console.log("resources array", resourcesArr);

      setResources(resourcesArr);

      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  function formatMappingData(data) {
    const resourcesArr = data.flatMap((obj) => {
      const { location_id, floor_id, ...rest } = obj;

      const seats = obj.seat_mapping.map((item) => ({ ...item, type: "seat" }));
      const cabins = obj.cabin_mapping.map((item) => ({ ...item, type: "cabin" }));
      const meetings = obj.meeting_mapping.map((item) => ({ ...item, type: "meeting" }));

      return {
        ...rest,
        location_id,
        floor_id,
        resources: [...seats, ...cabins, ...meetings],
      };
    });

    // console.log("formatted mapping data", resourcesArr);

    return resourcesArr;
  }

  useEffect(() => {
    if (selectedLocation) {
      if (selectedLocation === "All" && !locationIdFromUrl) {
        fetchData();
      } else {
        handleSearch();
      }
    }

    // fetchData();
  }, [pagination.pageIndex, pagination.pageSize, spaceId, selectedLocation, selectedFloor, floorIdFromUrl, locationIdFromUrl]);

  const addFunc = async (mappingData, namesData) => {
    const filteredMappingData = {
      ...mappingData,
      urlName: spaceId,
      seat_mapping: mappingData.seat_mapping.filter((item) => item.seat_quantity !== 0),
      cabin_mapping: mappingData.cabin_mapping.filter((item) => item.cabin_quantity !== 0),
      meeting_mapping: mappingData.meeting_mapping.filter((item) => item.meeting_quantity !== 0),
    };

    // console.log("mapping data to add", filteredMappingData);

    const cabinSeatsNames = namesData.filter((item) => item.type !== "meeting").map(({ id, ...rest }) => rest);
    const meetingNames = namesData.filter((item) => item.type !== "cabin" && item.type !== "seat").map(({ id, ...rest }) => rest);

    // console.log("cabin and seat names", cabinSeatsNames);

    // console.log("meeting room names", meetingNames);

    try {
      const response = await fetch(url + clientpoints.mapping_add, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ ...filteredMappingData }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      // console.log("dataaaaa receiced", data);

      if (data.Status) {
        const response1 = await fetch(url + "/cabin_seat_name/add", {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(cabinSeatsNames),
        });

        if (!response1.ok) {
          throw new Error("Failed to add cabin seat names");
        }

        const response2 = await fetch(url + "/meeting_name/add", {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(meetingNames),
        });

        if (!response2.ok) {
          throw new Error("Failed to add meeting room names");
        }
        toast.success("Added successfully!");
        handleRefresh();
      } else {
        toast.error("Something went wrong!");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const updateFunc = async (mappingData, namesData) => {
    const filteredMappingData = {
      // ...mappingData,
      // urlName: spaceId,
      seat_mapping: mappingData.seat_mapping.filter((item) => item.seat_quantity !== 0),
      cabin_mapping: mappingData.cabin_mapping.filter((item) => item.cabin_quantity !== 0),
      meeting_mapping: mappingData.meeting_mapping.filter((item) => item.meeting_quantity !== 0),
      // area_mapping: mappingData.area_mapping,
      // amenitie_mapping: mappingData.amenitie_mapping,
    };

    // console.log("Filtered mapping data", filteredMappingData);

    const allCabinSeatsNames = namesData.filter((item) => item.type !== "meeting").map(({ id, ...rest }) => rest);
    const allMeetingNames = namesData.filter((item) => item.type !== "cabin" && item.type !== "seat").map(({ id, ...rest }) => rest);

    // console.log("all cabin seat names", allCabinSeatsNames);
    // console.log("all meeting names", allMeetingNames);

    const meetingNamesToUpdate = allMeetingNames.filter((item) => item._id);

    const meetingNamesToAdd = allMeetingNames.filter((item) => !item._id);

    const cabinSeatNamesToUpdate = allCabinSeatsNames.filter((item) => item._id);

    const cabinSeatNamesToAdd = allCabinSeatsNames.filter((item) => !item._id);

    // console.log("Meeting names to add", meetingNamesToAdd);
    // console.log("Meeting names to update", meetingNamesToUpdate);

    // console.log("Cabin seat names to add", cabinSeatNamesToAdd);
    // console.log("Cabin seat names to update", cabinSeatNamesToUpdate);

    try {
      const response = await fetch(url + clientpoints.mapping_edit, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: filteredMappingData._id, floor_id: mappingData.floor_id }, data: { ...filteredMappingData } }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      if (data.Status) {
        if (cabinSeatNamesToAdd.length > 0) {
          const response1 = await fetch(url + "/cabin_seat_name/add", {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(cabinSeatNamesToAdd),
          });

          if (!response1.ok) {
            throw new Error("Failed to add cabin seat names");
          }
        }

        if (meetingNamesToAdd.length > 0) {
          const response2 = await fetch(url + "/meeting_name/add", {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(meetingNamesToAdd),
          });

          if (!response2.ok) {
            throw new Error("Failed to add meeting room names");
          }
        }

        if (cabinSeatNamesToUpdate.length > 0) {
          const response3 = await fetch(url + "/cabin_seat_name/edit", {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(cabinSeatNamesToUpdate),
          });

          if (!response3.ok) {
            throw new Error("Failed to update cabin seat names");
          }
        }

        if (meetingNamesToUpdate.length > 0) {
          const response4 = await fetch(url + "/meeting_name/edit", {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(meetingNamesToUpdate),
          });

          if (!response4.ok) {
            throw new Error("Failed to update meeting room names");
          }
        }

        toast.success("Updated successfully!");
      } else {
        toast.error("Something went wrong!");
      }
      handleRefresh();
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  function handleRefresh() {
    if (isExpanded) {
      setSearchTerm("");
      setIsExpanded(false);
      setShowFilterMsg(false);
    }
    handleHardRefresh();
  }

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = {
        floor_number: 1,
      };

      const requestBody = {
        page: 1,
        size: 12,
        urlName: spaceId,
        // sorting: sortingObject,
      };
      const response = await fetch(url + "/mapping/view", {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        pageSize: 12,
        totalPages: Math.ceil(data.totalCount / pagination.pageSize),
      }));
      const resourcesArr = formatMappingData(data.Data);

      setResources(resourcesArr);

      // console.log("fetched mapping data", resourcesArr);

      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        search: searchTerm,
        page: 1,
        size: pagination.pageSize,
        urlName: spaceId,
        filters: {
          location_id: selectedLocation !== "All" ? selectedLocation : undefined,
          floor_id: selectedFloor !== "All" ? selectedFloor : undefined,
        },
        sorting: {
          floor_number: 1,
        },
      };

      const response = await fetch(url + clientpoints.mapping_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();
      // console.log(responseData);

      const resourcesArr = formatMappingData(responseData.Data);

      setResources(resourcesArr);

      // setResources(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPageRange = () => {
    let startIndex;

    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {
      startIndex = 0;
    }

    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

    if (startIndex > endIndex) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }));
      return;
    }

    let filterText = "";
    if ((showFilterMsg && filter != 0 && myLocations.length > 1 && selectedLocation !== "All") || (searchTerm && showFilterMsg && filter != 0)) {
      filterText = ` of total ${total} (filtered ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex}${filterText} entries`;
  };

  return (
    <>
      <ToastContainer />

      {isAddModal && <EditModal isModalOpen={isAddModal} setIsModalOpen={setIsAddModal} addFunc={addFunc} updateFunc={updateFunc} view={"add"} locations={myLocations} />}

      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>Resources</p>
          </div>

          <div className='featured-flx1'>
            <div className='search-container'>
              <input ref={inputRef} className={`search-bar ${isExpanded ? "expanded" : ""}`} type='text' placeholder='Search Floors' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={handleSearchSubmit} />
              {!isExpanded && (
                <button className='search-button' onClick={handleSearchExpand}>
                  <i className='ri-search-line'></i>
                </button>
              )}
              {isExpanded && (
                <button className='search-button' onClick={handleSearchClose}>
                  <i className='ri-close-line'></i>
                </button>
              )}
            </div>

            {whoAmI === "team" ? (
              <>
                {typeTeamRoleObject?.assets?.add && typeTeamRoleObject?.assets?.edit && (
                  <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                    <i class='ri-pencil-line'></i>
                  </button>
                )}
              </>
            ) : (
              <>
                <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                  <i class='ri-pencil-line'></i>
                </button>
              </>
            )}
          </div>
        </div>

        <div class='filter-box'>
          <div class='filter-sideline'></div>
          <div className='row'>
            <div className='col-sm-3'>
              <label htmlFor='location_id' className='col-form-label'>
                Location
              </label>
              <Select
                options={myLocations.length > 1 ? [{ label: "All", value: "All" }, ...myLocations] : [...myLocations]}
                value={selectedValues?.location_id}
                onChange={(val) => setSelectedLocation(val.value)}
                placeholder='Select Location...'
                className='flex-1 w-full'
                styles={customStyles}
                menuPortalTarget={document.body}
                // required
              />
              {/* <div className='select-data1'>
                <p className='slt-txt'> Location </p>
                <div className={`custom-select ${isOpen1 ? "open" : ""}`} ref={selectRef1}>
                  <div className='form-group'>
                    <div className='select-top' onClick={() => setIsOpen1(!isOpen1)}>
                      {<span>{selectedLocation === "All" ? "All" : myLocations?.find((loc) => loc._id === selectedLocation)?.location}</span>}
                      <i className='ri-arrow-down-s-line'></i> 
                    </div>
                    <ul
                      className='select-options'
                      style={{
                        maxHeight: "50vh",
                        overflowY: "auto",
                      }}
                    >
                      <li onClick={() => handleSelect1("All")}>All</li>
                      {myLocations?.map((loc) => (
                        <li key={loc._id} onClick={() => handleSelect1(loc._id)}>
                          {loc.location}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
            <div className='col-sm-3'>
              <label htmlFor='floor_id' className='col-form-label'>
                Floor
              </label>
              <Select
                options={myFloors.length > 1 ? [{ label: "All", value: "All" }, ...myFloors] : [...myFloors]}
                value={selectedValues?.floor_id}
                onChange={(val) => setSelectedFloor(val.value)}
                placeholder='Select Floor...'
                className='flex-1 w-full'
                styles={customStyles}
                menuPortalTarget={document.body}
                // required
              />
              {/* <div className='select-data1'>
                <div className='select-data1'>
                  <p className='slt-txt'>Floor</p>
                  <div className={`custom-select ${isOpen ? "open" : ""}`} ref={selectRef}>
                    <div className='form-group'>
                      <div className='select-top' onClick={() => setIsOpen(!isOpen)}>
                        {<span>{selectedFloor === "All" ? "All" : myFloors?.find((floor) => floor._id === selectedFloor)?.floor_name}</span>}
                        <i class='ri-arrow-down-s-line'></i>
                      </div>
                      <ul
                        className='select-options'
                        style={{
                          maxHeight: "50vh",
                          overflowY: "auto",
                        }}
                      >
                        <li onClick={() => handleSelect("All")}>All</li>
                        {myFloors?.map((floor) => (
                          <li key={floor._id} onClick={() => handleSelect(floor._id)}>
                            {floor.floor_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className='col-sm-6'></div>
          </div>
        </div>

        {isLoading ? (
          <LoadingMutatingDots />
        ) : (
          <>
            <div className='row'>
              {resources?.map((item) => (
                <AssetCard key={item._id} data={item.resources} location_id={item.location_id} floor_id={item.floor_id} mapping_id={item._id} setSelectedItem={setSelectedItem} setIsEditModal={setIsEditModal} />
              ))}
            </div>

            <div className='page-flx'>
              <div className='page-flx2'>
                <p className='page-txt1'>{renderPageRange()}</p>
              </div>
              {pagination.totalPages !== 1 && pagination.totalPages !== 0 && (
                <div className='button-container'>
                  {pagination.pageIndex !== 1 && (
                    <button
                      className='button'
                      id='button1'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex - 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-left-s-line'></i>
                    </button>
                  )}

                  <div className='button active' id='button1'>
                    {pagination.pageIndex}
                  </div>

                  {pagination.pageIndex !== pagination.totalPages && (
                    <button
                      className='button'
                      id='button4'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex + 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-right-s-line'></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AssetsMapping;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
