import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, useNavigate } from "react-router-dom";
import MemberPrivateRoute from "./utils/MemberPrivateRoute.js";
import MemberPublicRoutes from "./utils/MemberPublicRoute.js";
import Login from "../../website/screens/MemberLogin.js";
import { ToastContainer } from "react-toastify";

import Dashboard from "./screens/dashboard/Dashboard.js";

import Teams from "./screens/team/Teams.js";

import Invoices from "./screens/invoice/Invoice.js";

import Posts from "./screens/community/Post.js";
import Events from "./screens/community/Event.js";
import Offers from "./screens/community/Offer.js";

import MeetingRooms from "./screens/booking/MeetingRooms.js";
import MeetingRoomsBooking from "./screens/booking/MeetingRoomsBooking.js";
import DayPass from "./screens/booking/DayPass.js";

import Visitors from "./screens/visitor/Visitor.js";
import Support from "./screens/support/ClientSupport.js";
import Setting from "./screens/setting/Profile.js";

const MemberRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { spaceId } = useParams();

  // URL Convert into normal text
  function normalizeURL(url) {
    const decodedURL = decodeURIComponent(url);
    const normalizedURL = decodedURL.replace(/%20/g, ' ');
    return normalizedURL;
  }

  if (normalizeURL(pathname) === `/${spaceId}/member/login`)
    return (
      <>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<MemberPublicRoutes />}>
            <Route path='login' element={<Login />} />
          </Route>
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<MemberPrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="teams" element={<Teams />} />
          <Route path="invoices" element={<Invoices />} />

          <Route path="posts" element={<Posts />} />
          <Route path="events" element={<Events />} />
          <Route path="offers" element={<Offers />} />

          <Route path="meeting-rooms" element={<MeetingRooms />} />
          <Route path="meeting-rooms-booking" element={<MeetingRoomsBooking />} />
          <Route path="day-pass" element={<DayPass />} />
          <Route path="visitors" element={<Visitors />} />
          <Route path="support" element={<Support />} />
          <Route path="setting" element={<Setting />} />
        </Route>
      </Routes>
    </>
  );
};

export default MemberRoutes;
