import React, { useState, useEffect, useContext, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { url, clientpoints, memberHeader, client_prefix } from "../../../../lib/lib.js";
import DashboardCard from "../../components/dashboard/Component.js";
import { AuthUserContext } from "../../../../lib/AuthUserContext.js";
import { LoadingMutatingDots } from "../../../spaces/components/ui/Loadings.js";

const Component = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState("");
  const { spaceId } = useParams();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const requestBody = { urlName: spaceId };

      const response = await fetch(url + clientpoints.dashboard, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setRecord(data.Data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (memberHeader) {
      fetchData();
    }
  }, []);
  const handleRefresh = () => {
    fetchData();
  };

  return (
    <>
      <ToastContainer />
      <div className='dashbord-option'>
        <div className='inner-padding'>
          <div className='featured-flx mb-0'>
            <div className='featured-flx1'>
              <p className='main_heading1'>Dashboard Member</p>
            </div>

            <div className='featured-flx1'>
              <button className='circle-btn-add icon' type='button' onClick={handleRefresh}>
                {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}
              </button>
            </div>
          </div>

          <br />
          {isLoading ? (
            <LoadingMutatingDots />
          ) : (
            <div className='row'>
              {/* <DashboardCard getTitle="Total Member" getValue={record.team} page="team/staff" />
                            <DashboardCard getTitle="Total Visitor" getValue={record.active_space} page="visitors/all-visitors" filters="true" />
                            <DashboardCard getTitle="Open Ticket" getValue={record.client_ticket} page="support/client-support" /> */}
              <DashboardCard getTitle='Total Member' getValue={record.member} page='team/staff' />
              <DashboardCard getTitle='Total Visitor' getValue={record.visitor} page='visitors/all-visitors' filters='true' />
              <DashboardCard getTitle='Open Ticket' getValue={record.ticket} page='support/client-support' />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Component;
