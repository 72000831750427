import React, { useEffect, useState } from "react";
import { url, adminpoints, admin_prefix, adminHeader } from "../../../lib/lib.js";
import DashboardCard from "../components/dashboard/Component.js";
import { useNavigate } from "react-router-dom";
import { LoadingMutatingDots } from "../../spaces/components/ui/Loadings.js";

const Dashboard = () => {
  const navigate = useNavigate();
  const [record, setRecord] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRefresh = () => {
    fetchDashboardData();
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(url + adminpoints.dashboard, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      setRecord(data.Data);
    } catch (error) {
      navigate(`${admin_prefix}dashboard`);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className='new1'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <h3>Dashboard</h3>
          </div>

          <div className='featured-flx1'>
            <button className='circle-btn-add icon' type='button' onClick={handleRefresh}>
              {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}
            </button>
          </div>
        </div>
      </div>
      <br />
      <div className=''>
        {isLoading ? (
          <>
            <LoadingMutatingDots />
            {/* <div className='d-flex justify-content-center align-items-center myload' style={{ height: "55vh" }}>
              <div className='spinner-border text-primary ' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div> */}
          </>
        ) : (
          <div className='row'>
            <DashboardCard getTitle='Total Clients' getValue={record.client} page='client' />
            <DashboardCard getTitle='Total Spaces' getValue={record.total_space} page='space' />
            <DashboardCard getTitle='Active Spaces' getValue={record.active_space} page='space' filters='true' />
            <DashboardCard getTitle='Inactive Spaces' getValue={record.in_active_space} page='space' filters='false' />
            <DashboardCard getTitle='Open Tickets' getValue={record.client_ticket} page='support' />
            <DashboardCard getTitle='Total Revenue' getValue={record.revenue} page='subscription' />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
