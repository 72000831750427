import DayPass from '../../components/booking/day_pass/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <DayPass name="All Day Pass" />
        </>
    );
}