import React, { useMemo, useState, useEffect, useContext } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix, memberHeader } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";

import { toast } from "react-toastify";
import AddModalComponent from "../add_modal/Component.js";
import EditModalComponent from "../edit_modal/Component.js";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import "../../../../../nav/components/location/component.css";
import { LoadingMutatingDots } from "../../../../../spaces/components/ui/Loadings.js";

const Component = ({ name = "All" }) => {
  const navigate = useNavigate();
  const { spaceId } = useParams();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [spaceList, setSpaceList] = useState([]);

  const [spaceItem, setSpaceItem] = useState(null);
  const [clientItem, setClientItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [createdAt, setCreatedAt] = useState("");

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const [sorting, setSorting] = useState({ column: null, direction: "desc" });
  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [searchTerm, setSearchTerm] = useState("");

  const [clientName, setClientName] = useState("");
  const [spaceName, setSpaceName] = useState("");

  // For Select Dropdown
  const [searchValueClient, setSearchValueClient] = useState("All Clients");
  const [suggestionsClient, setSuggestionsClient] = useState([]);
  const [showDropdownClient, setShowDropdownClient] = useState("All Clients");

  const [searchValue, setSearchValue] = useState("All Spaces");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("All Spaces");

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      let obj = { urlName: spaceId };

      const requestBody = {
        ...obj,
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.billing_view, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({ ...prevPagination, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
    } catch (error) {
      setIsError(true);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [spaceName, clientName, pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = {};
      setSearchTerm("");
      setSpaceName("");
      setClientName("");
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
        filters: { ...(spaceId && { urlName: spaceId }) },
      };
      const response = await fetch(url + clientpoints.billing_view, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      // setPagination(prevPagination => ({ ...prevPagination, pageIndex: 1, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
      setPagination({ pageIndex: 1, pageSize: 10, totalPages: 0 });
      setSearchValue("All Spaces");
      setShowDropdown("All Spaces");
      setSearchValueClient("All Clients");
      setShowDropdownClient("All Clients");
    } catch (error) {
      console.error("Error searching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleDelete = async (deleteItemId, type) => {
    let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    if (deleteConfirm == type) {
      try {
        const response = await fetch(url + clientpoints.member_delete, {
          method: "POST",
          headers: memberHeader,
          credentials: "include",
          body: JSON.stringify({ urlName: spaceId, filters: { _id: deleteItemId } }),
        });
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error("Failed to delete item");
        }

        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }

        setData((prevData) => prevData.filter((item) => item._id !== deleteItemId));
        fetchData();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  return (
    <>
      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>{name}</p>
          </div>

          <div className='featured-flx1'>
            {/* <button className="circle-btn-add icon" type="button" onClick={handleOpenModalAdd} >{isLoading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>) : (<i className="ri-add-line"></i>)}</button> */}
          </div>
        </div>

        <div className='featured-flx1'>
          <div className='row'>
            <div className='suport'>
              <div className='table-responsive mt-2 tablebord'>
                <div className='d-flex justify-content-between align-items-center mb-1'>
                  <div className='d-flex'>
                    <button onClick={handleRefresh} className='btn btn-primary'>
                      {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}
                      &#160; Refresh
                    </button>
                    &#160;&nbsp;&nbsp;
                    <button className='btn btn-primary' onClick={handleHardRefresh}>
                      <i className='ri-gradienter-line'></i> Reset
                    </button>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex justify-content-between align-items-center m-1'>
                      <input
                        type='text'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyUp={handleSearchSubmit}
                        className='search-input form-control search-inp'
                      />
                    </div>
                  </div>
                </div>
                <div className='support_table'>
                  <div className='scroll_table-space'>
                    <Table hover table-responsive>
                      <thead>
                        <tr>
                          <th>S. No </th>
                          <th>Company Name </th>
                          <th onClick={() => handleSort("name")}>
                            Name <FaFilter />
                            &nbsp; {sorting.column === "name" && (sorting.direction === "asc" ? <FaSortAmountUpAlt /> : <FaSortAmountDownAlt />)}
                          </th>
                          <th>Email </th>
                          <th>Phone </th>
                          <th>Username </th>
                          <th>Password </th>
                          <th onClick={() => handleSort("createdAt")}>
                            Date <FaFilter />
                            &nbsp; {sorting.column === "createdAt" && (sorting.direction === "asc" ? <FaSortAmountUpAlt /> : <FaSortAmountDownAlt />)}
                          </th>
                          {/* <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <LoadingMutatingDots />
                            {/* <div className='d-flex justify-content-center align-items-center myload'>
                              <div className='spinner-border text-primary ' role='status'>
                                <span className='sr-only'>Loading...</span>
                              </div>
                            </div> */}
                          </>
                        ) : data.length > 0 ? (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>{adjustedStartIndex + index}</td>
                              <td>{item.company_id && item.company_id.name}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.phone}</td>
                              <td>{item.username}</td>
                              <td>{item.password}</td>
                              <td>{new Date(item.createdAt).toLocaleString()}</td>
                              {/* <td>
                                <div className="mybots">
                                  <button variant="primary edit-btn" className="edit-btn1" onClick={() => handleEditClick(item)}><i className="ri-pencil-line"></i></button>
                                  <button variant="danger edit-btn" className="delete-btn1" onClick={() => handleDelete(item._id, item.name)}><i className="ri-delete-bin-line"></i></button>
                                </div>
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr className='middledata'>
                            <td colSpan={9}>
                              <div className='nodatafound1'>No data found</div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className='pagination-container d-flex justify-content-between align-items-center mt-1 page-flx1'>
                  <div className='mknk'>
                    <Form.Label>Rows per page:</Form.Label>
                    <select as='select' onChange={handlePageSizeChange} value={pagination.pageSize} className='dropon'>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                  </div>

                  <div className=' flex-pagination'>
                    <div>{renderPageRange()}</div>

                    <div className='mypage1'>
                      {pagination.pageIndex !== 1 && data.length !== 0 && (
                        <button
                          disabled={pagination.pageIndex === 1}
                          onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex - 1 }))}
                          class='button left-clickbtn'
                          id='button1'
                        >
                          <i className='ri-arrow-left-s-line'></i>
                        </button>
                      )}

                      <div class='button active' id='button1'>
                        {pagination.pageIndex}
                      </div>

                      {pagination.pageIndex !== pagination.totalPages && data.length !== 0 && (
                        <button
                          disabled={pagination.pageIndex === pagination.totalPages}
                          onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex + 1 }))}
                          class='button left-clickbtn'
                          id='button4'
                        >
                          <i className='ri-arrow-right-s-line'></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpenAdd && <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Add Invoice"} onRefresh={handleRefresh} />}
        {isModalOpen && <EditModalComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={selectedItem} modalName={"Edit Invoice"} onRefresh={handleRefresh} />}
      </div>
    </>
  );
};

export default Component;
