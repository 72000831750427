import React, { useEffect, useState, useContext } from "react";
import Sidenav from "../sidenav/Component";
import "../../../../client/components/SpaceCard/Component.css";
import Top from "../top/Component";

import { useParams, useNavigate } from "react-router-dom";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../lib/lib";
import { AuthUserContext } from "../../../../../lib/AuthUserContext";

function Component({ children }) {
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  const { setTypeTeamRoleObject } = useContext(AuthUserContext);

  let pageHeaders;
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
    fetchData();
  }, []);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [spaceName, setSpaceName] = useState('');
  const { spaceId } = useParams();

  const [dataRole, setDataRole] = useState('');
  const fetchData = async () => {
    try {

      const requestBody = { urlName: spaceId, };
      const response = await fetch(url + clientpoints.space_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      if (data.Status) {

        if (whoAmI == "team" || getLocalStorageData == "team") {

          const responseTeam = await fetch(url + clientpoints.team_view, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(requestBody),
          });

          const dataTeam = await responseTeam.json();
          setName(dataTeam.Data[0].name);
          setSpaceName(dataTeam.Data[0].space_id.space_name);


          let team_role_id = dataTeam.Data[0].role_id;
          const responseRole = await fetch(url + clientpoints.role_view, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify({ urlName: spaceId, filters: { _id: team_role_id } }),
          });

          if (!responseRole.ok) {
            navigate(`${client_prefix}`);
          }

          let dataResponseRole = await responseRole.json();
          let dataRole = dataResponseRole.Data[0];
          setDataRole(dataRole);
          setTypeTeamRoleObject(dataRole);
        } else if (whoAmI == "client" || getLocalStorageData == "client") {
          setName(data.Data[0].client_id.name);
          setSpaceName(data.Data[0].space_name);
        } else {
          setName('Deskbox');
          setSpaceName('Deskbox');
        }

      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };


  return (
    <div className='container-fluid side-padd dashboardfont'>
      <div className='row main-parent'>
        <div className='col_parent_class col-xl-2 col-lg-3 first_child_width'>
          <Sidenav roleObject={dataRole} role={whoAmI} />
        </div>
        <div className='col_parent_class col-xl-10 col-lg-9 second_child_width'>
          <Top name={name} spaceName={spaceName} />
          {/* <br /> */}
          {children}
        </div>
      </div>
    </div>
  );
}

export default Component;
