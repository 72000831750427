import { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { LoadingMutatingDots } from "../../../../components/ui/Loadings";

import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext";
import { validateSpaceIdForTeam } from "../../../../../../utils/ValidateSpaceId";
import RolesCard from "../card/Cards";
import "./component.css";

import AddModal from "../add_modal/Component";
import EditModal from "../edit_modal/Component";
import ConfirmModal from "../../../ui/modals/ConfirmDeleteModal";

export default function Component({ name = "All" }) {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [staff, setStaff] = useState([]);
  const { spaceId } = useParams();

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [myRole, setMyRole] = useState([]);
  const [myLocation, setMyLocation] = useState([]);
  const [selectedRole, setSelectedRole] = useState("All");

  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 12,
    totalPages: 0,
  });

  const [selectedItem, setSelectedItem] = useState();
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleSearchExpand = () => {
    setIsExpanded(true);
    inputRef.current.focus();
  };

  const handleSearchClose = () => {
    setSearchTerm("");
    setIsExpanded(false);
    setShowFilterMsg(false);
    fetchData();
  };

  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        urlName: spaceId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.team_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      console.log("dataaaaa", data);

      setTotal(data.totalCount);
      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(data.totalCount / prev.pageSize),
      }));
      setStaff(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, spaceId]);

  useEffect(() => {
    const fetchDataAndValidateSpace = async () => {
      const response = await validateSpaceIdForTeam(spaceId);
      if (response) {
        if (selectedRole === "All") {
          fetchData();
        } else {
          handleSearch();
        }
      } else {
        navigate("/spaces");
      }
    };

    fetchDataAndValidateSpace();
  }, [pagination.pageIndex, pagination.pageSize, spaceId, selectedRole]);

  function handleRefresh() {
    if (isExpanded) {
      setSearchTerm("");
      setIsExpanded(false);
      setShowFilterMsg(false);
    }
    handleHardRefresh();
  }

  const fetchMyRole = async () => {
    try {
      const requestBody = {
        urlName: spaceId,
      };

      const response = await fetch(url + clientpoints.role_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setMyRole(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };
  const fetchMyLocation = async () => {
    try {
      const requestBody = {
        urlName: spaceId,
      };

      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setMyLocation(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        page: 1,
        size: 12,
        urlName: spaceId,
        sorting: sortingObject,
      };
      const response = await fetch(url + clientpoints.team_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        pageSize: 12,
        totalPages: Math.ceil(data.totalCount / pagination.pageSize),
      }));
      setStaff(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (deleteItemId, type) => {
    setDeleteItemId(deleteItemId);
    setDeleteItemType(type);
    setIsDeleteModal(true);
  };

  const handleDelete = async (deleteItemId, type) => {
    // let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    // if (deleteConfirm == type) {
    try {
      const response = await fetch(url + clientpoints.team_delete, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: deleteItemId } }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      if (responseData.Status) {
        if (isExpanded) {
          handleSearchClose();
        } else {
          fetchData();
        }
        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    // }
  };

  useEffect(() => {
    fetchMyRole();
    fetchMyLocation();
  }, [isAddModal, isEditModal, isViewModal]);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    try {
      const requestBody = {
        search: searchTerm,
        page: 1,
        size: pagination.pageSize,
        urlName: spaceId,
        sorting: { createdAt: -1 },
      };

      setIsLoading(true);
      const response = await fetch(url + clientpoints.team_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();
      setStaff(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPageRange = () => {
    let startIndex;

    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {
      startIndex = 0;
    }

    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

    if (startIndex > endIndex) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }));
      return;
    }

    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` of total ${total} (filtered ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex}${filterText} entries`;
  };

  return (
    <>
      <ToastContainer />
      {isAddModal && <AddModal isModalOpen={isAddModal} setIsModalOpen={setIsAddModal} modalName={"Add Team"} onRefresh={handleRefresh} role={myRole.map((item) => ({ label: item.name, value: item._id }))} location={myLocation.map((item) => ({ label: item.location, value: item._id }))} />}
      {isEditModal && (
        <EditModal
          isModalOpen={isEditModal}
          setIsModalOpen={setIsEditModal}
          modalName={"Team"}
          onRefresh={handleRefresh}
          data={selectedItem}
          role={myRole.map((item) => ({ label: item.name, value: item._id }))}
          d
          location={myLocation.map((item) => ({ label: item.location, value: item._id }))}
          view='edit'
        />
      )}

      {isViewModal && (
        <EditModal
          isModalOpen={isViewModal}
          setIsModalOpen={setIsViewModal}
          modalName={"Team"}
          onRefresh={handleRefresh}
          data={selectedItem}
          role={myRole.map((item) => ({ label: item.name, value: item._id }))}
          d
          location={myLocation.map((item) => ({ label: item.location, value: item._id }))}
          view='view'
        />
      )}

      {isDeleteModal && <ConfirmModal isModalOpen={isDeleteModal} setIsModalOpen={setIsDeleteModal} modalName='Confirm Deletion' deleteItemId={deleteItemId} setDeleteItemId={setDeleteItemId} deleteItemType={deleteItemType} setDeleteItemType={setDeleteItemType} handleDelete={handleDelete} />}

      <div className='inner-padding staff-table-parent'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>{name}</p>
          </div>

          <div className='featured-flx1'>
            <div className='search-container'>
              <input ref={inputRef} className={`search-bar ${isExpanded ? "expanded" : ""}`} type='text' placeholder='Search staff' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={handleSearchSubmit} />
              {!isExpanded && (
                <button className='search-button' onClick={handleSearchExpand}>
                  <i className='ri-search-line'></i>
                </button>
              )}
              {isExpanded && (
                <button className='search-button' onClick={handleSearchClose}>
                  <i className='ri-close-line'></i>
                </button>
              )}
            </div>
            {whoAmI === "team" ? (
              <>
                {typeTeamRoleObject?.team?.add && (
                  <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                    <i className='ri-add-line'></i>
                  </button>
                )}
              </>
            ) : (
              <>
                <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                  <i className='ri-add-line'></i>
                </button>
              </>
            )}
          </div>
        </div>
        {isLoading ? (
          <LoadingMutatingDots />
        ) : (
          <>
            <div className='row'>
              {staff.length > 0 ? (
                staff?.map((item) => <RolesCard data={item} setSelectedItem={setSelectedItem} setIsViewModal={setIsViewModal} setIsEditModal={setIsEditModal} handleDelete={handleDeleteClick} typeTeamRoleObject={typeTeamRoleObject} whoAmI={whoAmI} tableName='team' />)
              ) : (
                <>
                  <div className='No-data'>No Data</div>
                </>
              )}
            </div>
            <div className='page-flx'>
              <div className='page-flx2'>
                <p className='page-txt1'>{renderPageRange()}</p>
              </div>
              {pagination.totalPages !== 1 && pagination.totalPages !== 0 && (
                <div className='button-container'>
                  {pagination.pageIndex !== 1 && (
                    <button
                      className='button'
                      id='button1'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex - 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-left-s-line'></i>
                    </button>
                  )}

                  <div className='button active' id='button1'>
                    {pagination.pageIndex}
                  </div>

                  {pagination.pageIndex !== pagination.totalPages && (
                    <button
                      className='button'
                      id='button4'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex + 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-right-s-line'></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
