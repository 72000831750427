import React, { useState, useEffect, useContext } from 'react';
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from '../../../../../../lib/lib.js';
import { AuthUserContext } from '../../../../../../lib/AuthUserContext.js';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from "react-router-dom";
import './component.css';

function Component({ isChatOpen, setIsChatOpen, chatItem, modalName }) {

  const navigate = useNavigate();
  const { spaceId } = useParams();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);


  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fetchData, setFetchData] = useState('');
  const [status, setStatus] = useState(false);

  useEffect(() => {
    fetchChatData();
  }, [chatItem]);


  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);

  let ticketId = chatItem;  // Client Ticket Id

  const fetchChatData = async () => {
    try {
      setLoading(true);
      const response = await fetch(url + clientpoints.event_apply_view, {
        method: 'POST',
        headers: pageHeaders,
        credentials: 'include',
        body: JSON.stringify({ urlName: spaceId, filters: { _id: ticketId } })
      });
      if (!response.ok) {
        navigate(`${client_prefix}`);
      }
      const data = await response.json();

      const eventData = data.Data[0];
      const eventQuestions = eventData.event_id.question || [];
      const eventAnswers = eventData.question || [];

      setQuestions(eventQuestions);
      setAnswers(eventAnswers);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching client data:', error);
    } finally {
      setLoading(false);
    }
  };

  const [errors, setErrors] = useState({});

  const handleCloseModal = () => {
    setIsChatOpen(false);
  };


  const renderChatBubbles = () => {
    return (
      <>
        {questions.map((question, index) => (
          <div key={question.id}>
            <p><strong>Question:</strong> {question}</p>
            {/* Assuming answers are stored in a corresponding array */}
            <p><strong>Answer:</strong> {answers[index]}</p>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {isChatOpen && (
        <div className="modal fade show" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{modalName}</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className='scroll_chat'>
                      {renderChatBubbles()}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div >
      )}
    </>
  );

}

export default Component;