import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="sec1tion2">
            
      <div className="container">
        <div className="row feature-top02">
          <div className="col-lg-6">
          <center><img src="img/support.png" className="img-fluid feature-img2"/></center>
          </div>
          <div className="col-lg-6"> 

          <div className="feature-padd2">
         <p className="deskbox-txt2">Analytics & Support Management</p>
         <p className="deskbox-descri">Gain valuable insights into your business operations with our powerful management features.</p>
         <div class="desk-top-2">
                   <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Reports & Analytics</p>
                <p className="feature1-txt2">Our advanced reporting & analytics capabilities will provide you with the insights you need.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Activity Logs Tracking</p>
                <p className="feature1-txt2">Keep track of all the members activities and retrieve the information you need from the logs.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Support & Help Center Management</p>
                <p className="feature1-txt2">With our support management tools, you'll be able to provide top-notch customer support.</p>
            </div>
         </div>
           </div>
           </div>

           </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Component;
