import React, { useState, useEffect, useContext } from "react";
import "../components/SpaceCard/Component.css";
import SpaceCard from "../components/SpaceCard/Component";
import Navbar from "../components/Navbar/Component";
import { url, whoAmIHeaders } from "../../../lib/lib";
import { LoadingMutatingDots } from "../../spaces/components/ui/Loadings";
import { AuthUserContext } from "../../../lib/AuthUserContext";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const { whoAmI } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  const navigate = useNavigate();
  const [mySpaces, setMySpaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientName, setClientName] = useState('');

  useEffect(() => {
    async function fetchSpaces() {
      try {
        setIsLoading(true);
        const response = await fetch(url + "/space/view", {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          navigate("/login");
        }

        const data = await response.json();
        setClientName(data.Data[0].client_id.name)
        setIsLoading(false);
        setMySpaces(data.Data);
      } catch (error) {

        console.error("Error:", error.message);
        setIsLoading(false);
      }
    }

    fetchSpaces();
  }, []);

  if (pageHeaders.type !== 'client') {
    navigate(`/`);
  }

  return (
    <>
      {isLoading ? (
        <LoadingMutatingDots />
      ) : (
        <>
          <Navbar name={clientName} />
          <div className="space-option">
            <div className='inner-padding1 dashboardfont'>
              <div className='side-space-data'>
                <div className='featured-flx'>
                  <div className='featured-flx1'><p className='main_heading1'>Coworking Spaces</p></div>
                </div>
                <div className='row'>{mySpaces.length < 0 ? (<p>No Spaces Found!</p>) : (mySpaces.map((data) => <SpaceCard key={data._id} data={data} />))}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
