import React, { useMemo, useState, useEffect, useContext } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import ChatComponent from "../question/Component.js";
import {
  FaEdit,
  FaTrashAlt,
  FaSyncAlt,
  FaSort,
  FaSearch,
  FaDna,
  FaFilter,
  FaSortUp,
  FaSortDown,
  FaSortAmountUpAlt,
  FaSortAmountDownAlt,
} from "react-icons/fa";

import { toast } from "react-toastify";
import AddModalComponent from "../add_modal/Component.js";
import EditModalComponent from "../edit_modal/Component.js";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import "../../../../../nav/components/location/component.css";

const Component = ({ name = "All" }) => {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = urlParams.get('id');

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [spaceList, setSpaceList] = useState([]);

  const [spaceItem, setSpaceItem] = useState(null);
  const [clientItem, setClientItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [chatItem, setChatItem] = useState(null);

  const [createdAt, setCreatedAt] = useState("");

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const [sorting, setSorting] = useState({ column: null, direction: "desc" });
  const adjustedStartIndex =
    (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [searchTerm, setSearchTerm] = useState("");

  const [clientName, setClientName] = useState("");
  const [spaceName, setSpaceName] = useState("");

  // For Select Dropdown
  const [searchValueClient, setSearchValueClient] = useState("All Clients");
  const [suggestionsClient, setSuggestionsClient] = useState([]);
  const [showDropdownClient, setShowDropdownClient] = useState("All Clients");

  const [searchValue, setSearchValue] = useState("All Spaces");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("All Spaces");
  const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

  let event_id = idFromUrl;
  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {

      let obj = {
        urlName: spaceId,
      };

      const requestBody = {
        ...obj,
        filters: {
          event_id: event_id,
        },
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.event_apply_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      setIsError(true);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    spaceName,
    clientName,
    pagination.pageIndex,
    pagination.pageSize,
    sorting.column,
    sorting.direction,
  ]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      setSearchTerm("");
      setSpaceName("");
      setClientName("");
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
        urlName: spaceId,
        filters: {
          event_id: event_id,
        }
      };
      const response = await fetch(url + clientpoints.event_apply_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination(prevPagination => ({ ...prevPagination, pageIndex: 1, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
      // setPagination({ pageIndex: 1, pageSize: 10, totalPages: 0 });
      setSearchValue("All Spaces");
      setShowDropdown("All Spaces");
      setSearchValueClient("All Clients");
      setShowDropdownClient("All Clients");
    } catch (error) {
      console.error("Error searching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction:
        prevSorting.column === column && prevSorting.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleDelete = async (deleteItemId, type) => {
    let deleteConfirm = prompt(
      `Do you want to delete this record. \nPlease enter "${type}"`
    );
    if (deleteConfirm == type) {
      try {
        const response = await fetch(url + clientpoints.event_apply_delete, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({ filters: { _id: deleteItemId } }),
        });
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error("Failed to delete item");
        }

        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }

        setData((prevData) =>
          prevData.filter((item) => item._id !== deleteItemId)
        );
        fetchData();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };

  const handleChatModel = (item) => {
    setChatItem(item._id);
    setIsChatOpen(true);
  };

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  return (
    <>
      <div className="inner-padding">
        <div className="featured-flx">
          <div className="featured-flx1">
            <p className="main_heading1">{name}</p>
          </div>

          <div className="featured-flx1">

            {whoAmI === 'team' ? (
              <>
                {typeTeamRoleObject?.visitor?.add && (<button className="circle-btn-add icon" type="button" onClick={handleOpenModalAdd}>{isLoading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>) : (<i className="ri-add-line"></i>)}</button>)}
              </>
            ) : (
              <>
                <button className="circle-btn-add icon" type="button" onClick={handleOpenModalAdd}>{isLoading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>) : (<i className="ri-add-line"></i>)}</button>
              </>
            )}

          </div>
        </div>

        <div className="featured-flx1">
          <div className="row w-100 m-0">
            <div className="suport p-0">
              <div className="table-responsive mt-2 tablebord">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div className="d-flex">
                    <button onClick={handleRefresh} className="btn btn-primary">
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <i className="ri-refresh-line"></i>
                      )}
                      &#160; Refresh
                    </button>
                    &#160;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={handleHardRefresh}
                    >
                      <i className="ri-gradienter-line"></i> Reset
                    </button>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center m-1">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyUp={handleSearchSubmit}
                        className="search-input form-control search-inp"
                      />
                    </div>
                  </div>
                </div>
                <div className="support_table">
                  <div className="scroll_table-space">
                    <Table hover table-responsive>
                      <thead>
                        <tr>
                          <th>S. No </th>
                          {/* <th>Company Name </th> */}
                          <th onClick={() => handleSort("name")}>
                            Name <FaFilter />
                            &nbsp;{" "}
                            {sorting.column === "name" &&
                              (sorting.direction === "asc" ? (
                                <FaSortAmountUpAlt />
                              ) : (
                                <FaSortAmountDownAlt />
                              ))}
                          </th>
                          <th>Email </th>
                          <th>Phone </th>
                          <th onClick={() => handleSort("createdAt")}>
                            Date <FaFilter />
                            &nbsp;{" "}
                            {sorting.column === "createdAt" &&
                              (sorting.direction === "asc" ? (
                                <FaSortAmountUpAlt />
                              ) : (
                                <FaSortAmountDownAlt />
                              ))}
                          </th>
                          <th>Question </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-items-center myload">
                            <div
                              className="spinner-border text-primary "
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : data.length > 0 ? (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>{adjustedStartIndex + index}</td>
                              {/* <td>{item.client_id && item.client_id.name}</td>
                                  <td>{item.space_id && item.space_id.space_name}</td> */}
                              {/* <td>{item.member_id && item.company_id}</td> */}
                              <td>{item.member_id && item.member_id.name}</td>
                              <td>{item.member_id && item.member_id.email}</td>
                              <td>{item.member_id && item.member_id.phone}</td>
                              {/* <td>{item.name}</td> */}
                              {/* <td>{item.email}</td> */}
                              {/* <td>{item.phone}</td> */}
                              <td>
                                {new Date(item.createdAt).toLocaleString()}
                              </td>
                              <td>
                                <div className="mybots">
                                  <button variant="primary edit-btn" className="edit-btn1" onClick={() => handleChatModel(item)}><i className="ri-chat-1-fill"></i></button>
                                </div>
                              </td>
                              <td>
                                <div className="mybots">
                                  {whoAmI === 'team' ? (
                                    <>
                                      {/* {typeTeamRoleObject?.visitor?.edit && (<button variant="primary edit-btn" className="edit-btn1" onClick={() => handleEditClick(item)} ><i className="ri-pencil-line"></i></button>)} */}
                                      {typeTeamRoleObject?.visitor?.delete && (<button variant="danger edit-btn" className="delete-btn1" onClick={() => handleDelete(item._id, item.name)}><i className="ri-delete-bin-line"></i></button>)}
                                    </>
                                  ) : (
                                    <>
                                      {/* <button variant="primary edit-btn" className="edit-btn1" onClick={() => handleEditClick(item)} ><i className="ri-pencil-line"></i></button> */}
                                      <button variant="danger edit-btn" className="delete-btn1" onClick={() => handleDelete(item._id, item.member_id.name)}><i className="ri-delete-bin-line"></i></button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="middledata">
                            <td colSpan={10}>
                              <div className="nodatafound1">No data found</div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="pagination-container d-flex justify-content-between align-items-center mt-1 page-flx1">
                  <div className="mknk">
                    <Form.Label>Rows per page:</Form.Label>
                    <select
                      as="select"
                      onChange={handlePageSizeChange}
                      value={pagination.pageSize}
                      className="dropon"
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                  </div>

                  <div className=" flex-pagination">
                    <div>{renderPageRange()}</div>
                    <div className="mypage1">
                      {pagination.pageIndex !== 1 && data.length !== 0 && (
                        <button
                          disabled={pagination.pageIndex === 1}
                          onClick={() =>
                            setPagination((prevPagination) => ({
                              ...prevPagination,
                              pageIndex: prevPagination.pageIndex - 1,
                            }))
                          }
                          class="button left-clickbtn"
                          id="button1"
                        >
                          <i className="ri-arrow-left-s-line"></i>
                        </button>
                      )}

                      <div class="button active" id="button1">
                        {pagination.pageIndex}
                      </div>
                      {pagination.pageIndex !== pagination.totalPages &&
                        data.length !== 0 && (
                          <button
                            disabled={
                              pagination.pageIndex === pagination.totalPages
                            }
                            onClick={() =>
                              setPagination((prevPagination) => ({
                                ...prevPagination,
                                pageIndex: prevPagination.pageIndex + 1,
                              }))
                            }
                            class="button left-clickbtn"
                            id="button4"
                          >
                            <i className="ri-arrow-right-s-line"></i>
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpenAdd && (
          <AddModalComponent
            isModalOpen={isModalOpenAdd}
            setIsModalOpen={setIsModalOpenAdd}
            modalName={"Add Visitor"}
            onRefresh={handleRefresh}
          />
        )}

        {isModalOpen && (
          <EditModalComponent
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            data={selectedItem}
            modalName={"Edit Visitor"}
            onRefresh={handleRefresh}
          />
        )}

        {isChatOpen && (<ChatComponent isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} chatItem={chatItem} modalName={"Question Answer"} />)}
      </div>
    </>
  );
};

export default Component;