import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName }) {
  const { spaceId } = useParams();
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "",
    space_dashboard: { add: false, edit: false, delete: false, view: false },
    cabin: { add: false, edit: false, delete: false, view: false },
    seat: { add: false, edit: false, delete: false, view: false },
    meeting: { add: false, edit: false, delete: false, view: false },
    area: { add: false, edit: false, delete: false, view: false },
    amenitie: { add: false, edit: false, delete: false, view: false },
    location: { add: false, edit: false, delete: false, view: false },
    floor: { add: false, edit: false, delete: false, view: false },
    assets: { add: false, edit: false, delete: false, view: false },
    role: { add: false, edit: false, delete: false, view: false },
    team: { add: false, edit: false, delete: false, view: false },
    company: { add: false, edit: false, delete: false, view: false },
    member: { add: false, edit: false, delete: false, view: false },
    contract: { add: false, edit: false, delete: false, view: false },
    credit: { add: false, edit: false, delete: false, view: false },
    parking: { add: false, edit: false, delete: false, view: false },
    invoice: { add: false, edit: false, delete: false, view: false },
    occupancy: { add: false, edit: false, delete: false, view: false },
    post: { add: false, edit: false, delete: false, view: false },
    event: { add: false, edit: false, delete: false, view: false },
    offer: { add: false, edit: false, delete: false, view: false },
    notification: { add: false, edit: false, delete: false, view: false },
    meeting_room: { add: false, edit: false, delete: false, view: false },
    day_pass: { add: false, edit: false, delete: false, view: false },
    visitor: { add: false, edit: false, delete: false, view: false },
    enquiry: { add: false, edit: false, delete: false, view: false },
    expense: { add: false, edit: false, delete: false, view: false },
    ticket: { add: false, edit: false, delete: false, view: false },
    client_ticket: { add: false, edit: false, delete: false, view: false },
    space: { add: false, edit: false, delete: false, view: false },
    subscription: { add: false, edit: false, delete: false, view: false },
    log_view: { add: false, edit: false, delete: false, view: false },
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = (role, action) => {
    setFormData({
      ...formData,
      [role]: {
        ...formData[role],
        [action]: !formData[role][action],
      },
    });
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let roleId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && roleId) {
      setLoading(true);
      fetchData(roleId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, roleId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.role_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          ...response_data,
        });
      }
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.role_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            filters: { _id: current_id },
            data: {
              ...formData,
            },
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        
        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const handleHeadingClick = (action) => {
    const updatedFormData = { ...formData };
    for (const [roleName, roleData] of Object.entries(updatedFormData)) {
      if (roleName === "name" || roleName === "updatedAt" || roleName === "space_id" ||
        roleName === "client_id" || roleName === "delete_status" ||
        roleName === "__v" || roleName === "_id" || roleName === "createdAt") continue;
      updatedFormData[roleName] = {
        ...roleData,
        [action]: !roleData[action] // Toggle the action property
      };
    }
    setFormData(updatedFormData); // Update the state with the modified formData
  };


  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body' style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                    <div className='row'>
                      <div className="col-md-12 mb-3" style={{ marginTop: '-20px' }}>
                        <label htmlFor="name" className="col-form-label">Role Name</label>
                        <input type='text' name='name' value={formData.name} onChange={handleChange} className='form-control flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                      </div>

                      {formData && typeof formData === "object" && (
                        <div className='flex flex-col items-center'>
                          <table className='border-collapse w-full'>
                            <thead>
                              <tr className='bg-gray-200'>
                                <th className='border border-gray-400'>Name</th>
                                <th className='border border-gray-400' onClick={() => handleHeadingClick("add")}>Add</th>
                                <th className='border border-gray-400' onClick={() => handleHeadingClick("edit")}>Edit</th>
                                <th className='border border-gray-400' onClick={() => handleHeadingClick("view")}>View</th>
                                <th className='border border-gray-400' onClick={() => handleHeadingClick("delete")}>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(formData)
                                .filter(
                                  ([roleName, roleData]) =>
                                    roleName === "space_dashboard" ||
                                    roleName === "cabin" ||
                                    roleName === "seat" ||
                                    roleName === "meeting" ||
                                    roleName === "area" ||
                                    roleName === "amenitie" ||
                                    roleName === "location" ||
                                    roleName === "floor" ||
                                    roleName === "assets" ||
                                    roleName === "role" ||
                                    roleName === "team" ||
                                    roleName === "company" ||
                                    roleName === "member" ||
                                    roleName === "contract" ||
                                    roleName === "credit" ||
                                    roleName === "parking" ||
                                    roleName === "invoice" ||
                                    roleName === "occupancy" ||
                                    roleName === "post" ||
                                    roleName === "event" ||
                                    roleName === "offer" ||
                                    roleName === "notification" ||
                                    roleName === "meeting_room" ||
                                    roleName === "day_pass" ||
                                    roleName === "visitor" ||
                                    roleName === "enquiry" ||
                                    roleName === "expense" ||
                                    roleName === "ticket" ||
                                    roleName === "client_ticket" ||
                                    roleName === "space" ||
                                    roleName === "subscription" ||
                                    roleName === "log_view"
                                )
                                .map(([roleName, roleData]) => (
                                  <tr key={roleName}>
                                    <td className='border border-gray-400'>{roleName}</td>
                                    <td className='border border-gray-400'><input type='checkbox' checked={roleData.add} onChange={() => handleCheckboxChange(roleName, "add")} /></td>
                                    <td className='border border-gray-400'><input type='checkbox' checked={roleData.edit} onChange={() => handleCheckboxChange(roleName, "edit")} /></td>
                                    <td className='border border-gray-400'><input type='checkbox' checked={roleData.view} onChange={() => handleCheckboxChange(roleName, "view")} /></td>
                                    <td className='border border-gray-400'><input type='checkbox' checked={roleData.delete} onChange={() => handleCheckboxChange(roleName, "delete")} /></td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                      Close
                    </button>
                    {/* <button type="submit" className="btn btn-primary">Update</button> */}
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                      {isSubmitting ? "Loading..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
