import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import PrivateRoutes from "../../utils/PrivateRoute.js";
import PublicRoutes from "../../utils/PublicRoute.js";
import Login from "../../website/screens/Login.js";

import { ToastContainer } from "react-toastify";
import "./main.css";

const ClientRoutes = () => {
  const { pathname } = useLocation();
  function removeTrailingSlash(url) {
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }

  if (removeTrailingSlash(pathname) === "/login")
    return (
      <>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<PublicRoutes />}>
            <Route path='login' element={<Login />} />
          </Route>
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer />
      <Routes>
        {/* Private Routes */}
        <Route path='/' element={<PrivateRoutes />}>
          <Route path='/spaces' element={<Dashboard />} />
        </Route>
      </Routes>
    </>
  );
};

export default ClientRoutes;
