import Event from '../../components/community/event/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <Event name="All Events" />
        </>
    );
}