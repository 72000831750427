import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./cards.css";

function Card1({ data, setSelectedItem, setIsModalOpenEdit, setIsViewModal, handleDelete, typeTeamRoleObject, whoAmI, tableName }) {
  const { spaceId } = useParams();
  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 roleClass'>
      <div className='post-card event'>
        <Link className='blackcolor' to={`/space/${spaceId}/backend/community/event?id=${data._id}`}>
          <div class='post-sideline1'>
            <i className='ri-eye-line more-detail'></i>
          </div>
        </Link>

        <img src='/img/events.jpg' className='img-fluid post-image' />
        <div className='row over-dropdown'>
          <div className='col-12'>
            <div class='droptop '>
              <div type='button' class='show'>
                <div className='cabin-editicn'>
                  {whoAmI === "team" ? (
                    <>
                      {typeTeamRoleObject?.[tableName]?.view && (
                        <i
                          class='ri-eye-line view-icn'
                          onClick={() => {
                            setSelectedItem(data);
                            setIsViewModal(true);
                          }}
                        ></i>
                      )}
                      {typeTeamRoleObject?.[tableName]?.edit && (
                        <i
                          class='ri-pencil-line pencil-icn'
                          onClick={() => {
                            setSelectedItem(data);
                            setIsModalOpenEdit(true);
                          }}
                        ></i>
                      )}
                      {typeTeamRoleObject?.[tableName]?.delete && (
                        <i
                          class='ri-delete-bin-6-line trash-icn'
                          onClick={() => {
                            handleDelete(data._id, data?.title);
                          }}
                        ></i>
                      )}
                    </>
                  ) : (
                    <>
                      <i
                        class='ri-eye-line view-icn'
                        onClick={() => {
                          setSelectedItem(data);
                          setIsViewModal(true);
                        }}
                      ></i>
                      <i
                        class='ri-pencil-line pencil-icn'
                        onClick={() => {
                          setSelectedItem(data);
                          setIsModalOpenEdit(true);
                        }}
                      ></i>
                      <i
                        class='ri-delete-bin-6-line trash-icn'
                        onClick={() => {
                          handleDelete(data._id, data?.title);
                        }}
                      ></i>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='post-inner-txt'>
          <p class='posts-title'>{data.title && data.title}</p>
          <p class='posts-txt'>{data.description && data.description}</p>
        </div>
        <div className='box-back'>
          <div className='row'>
            <div className='col-6 rgt-bdr'>
              <p className='seatbox-txt'>Location</p>
              <p className='seatbox-desc'>{data.location_id && data.location_id.location}</p>
            </div>
            <div className='col-6 '>
              <center>
                <p className='seatbox-txt'>Floor</p>
                <p className='seatbox-desc'>{data.floor_id && data.floor_id.floor_name}</p>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card1;
