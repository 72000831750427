import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, memberHeader, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { LoadingMutatingDots } from "../../../../../spaces/components/ui/Loadings.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName }) {
  const navigate = useNavigate();
  const { spaceId } = useParams();
  const [additionalFields, setAdditionalFields] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let clientId = data;

  const [locationName, setLocationName] = useState("");
  const [searchValueLocation, setSearchValueLocation] = useState("Select Location");
  const [suggestionsLocation, setSuggestionsLocation] = useState([]);
  const [showDropdownLocation, setShowDropdownLocation] = useState("Select Location");

  const [floorName, setFloorName] = useState("");
  const [searchValueFloor, setSearchValueFloor] = useState("Select Floor");
  const [suggestionsFloor, setSuggestionsFloor] = useState([]);
  const [showDropdownFloor, setShowDropdownFloor] = useState("Select Floor");

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // if (!locationName.trim()) {
    //   alert('Location is required');
    //   errors.locationName = "Location is required";
    //   isValid = false;
    // }
    // if (!floorName.trim()) {
    //   alert('Floor is required');
    //   errors.floorName = "Floor is required";
    //   isValid = false;
    // }
    // if (!formData.title.trim()) {
    //   errors.title = "Title is required";
    //   isValid = false;
    // }
    // if (!formData.description.trim()) {
    //   errors.description = "Description is required";
    //   isValid = false;
    // }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && clientId) {
      setLoading(true);
      fetchData(clientId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, clientId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.event_view, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          title: response_data.title,
          description: response_data.description,
        });
        setLocationName(response_data.location_id._id);
        setSearchValueLocation(response_data.location_id.location);
        setShowDropdownLocation(response_data.location_id.location);
        setFloorName(response_data.floor_id._id);
        setSearchValueFloor(response_data.floor_id.floor_name);
        setShowDropdownFloor(response_data.floor_id.floor_name);
        setAdditionalFields(response_data.question);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);

        const response = await fetch(url + clientpoints.event_apply_add, {
          method: "POST",
          headers: memberHeader,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            event_id: current_id,
            question: answers,
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const removeField = (index) => {
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  // const handleChangeDynamicFields = (event, index) => {
  //   const { value } = event.target;
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index] = value;
  //   setAdditionalFields(updatedFields);
  // };

  const [answers, setAnswers] = useState(Array(additionalFields.length).fill(""));

  // Function to update answers as user types
  const handleChangeDynamicFields = (event, index) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = event.target.value;
    setAnswers(updatedAnswers);
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg event_apply_modal'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <LoadingMutatingDots />
                    {/* <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div> */}
                  </div>
                ) : (
                  <div className='modal-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label htmlFor='title' className='col-form-label'>
                          Title
                        </label>
                        <input type='text' className={`form-control`} id='title' name='title' value={formData.title} onChange={handleChange} disabled />
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='description' className='col-form-label'>
                          Description
                        </label>
                        <textarea className={`form-control`} id='description' name='description' value={formData.description} onChange={handleChange} disabled />
                      </div>

                      {/* {additionalFields.map((question, index) => (
                        <div key={index} className="col-md-12 mb-3">

                          <label htmlFor={`additionalField${index}`} className="col-form-label">{index+1}. {question}</label>
                          <div className="input-group">
                            <input
                              type="text"
                              placeholder="Answer"
                              className="form-control"
                              id={`additionalField${index}`}
                              name={`additionalField${index}`}
                              value={question}
                              onChange={(event) => handleChangeDynamicFields(event, index)}
                            />
                          </div>
                        </div>
                      ))} */}

                      {additionalFields.map((question, index) => (
                        <div key={index} className='col-md-12 mb-3'>
                          <label htmlFor={`additionalField${index}`} className='col-form-label'>
                            {index + 1}. {question}
                          </label>
                          <div className='input-group'>
                            <input
                              type='text'
                              placeholder='Answer'
                              className='form-control'
                              id={`additionalField${index}`}
                              name={`additionalField${index}`}
                              value={answers[index]} // Use answers[index] instead of question
                              onChange={(event) => handleChangeDynamicFields(event, index)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                      {" "}
                      Close{" "}
                    </button>
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                      {" "}
                      {isSubmitting ? "Loading..." : "Apply"}{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
