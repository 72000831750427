import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getDecryptedDataFromLocalStorage } from "./lib/lib.js";
import { AuthUserContext } from "./lib/AuthUserContext.js";

// Start Website Route
import Home from "./website/screens/Home.js";
import Privacy from "./website/screens/Privacy.js";
import Terms from "./website/screens/Terms.js";
import NotFound from "./website/screens/NotFound.js";
// End Website Route

import AdminRoute from "./panels/admin/admin.js";
import ClientRoutes from "./panels/client/client.js";
import TeamRoutes from "./panels/team/team.js";
import MemberRoutes from "./panels/member/member.js";
import SpacesRoutes from "./panels/spaces/spaces.js";
// import MemberRoutes from "./panels/member/member.js";
import DashboardTemp from "./panels/nav/screens/Dashboard.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const [whoAmI, setWhoAmI] = useState('');   // client, team, member as a type
  const [typeTeamRoleObject, setTypeTeamRoleObject] = useState({});       //  Team Role Object 
  useEffect(() => {
    const getLocalStorageData = getDecryptedDataFromLocalStorage('type');
    if (whoAmI === null && !getLocalStorageData) {
      window.location.reload();   // Refresh the page if both whoAmI and local storage data are null
    } else if (getLocalStorageData) {
      setWhoAmI(getLocalStorageData);
    }
  }, []);

  return (
    <>
      <AuthUserContext.Provider value={{ whoAmI, setWhoAmI, typeTeamRoleObject, setTypeTeamRoleObject }}>
        <Router>
          <Routes>

            <Route path='Br!Y7o&p45fM/portal/*' element={<AdminRoute />} />         {/* Admin Route */}
            <Route exact path='/*' element={<ClientRoutes />} />                          {/* Client Route */}
            <Route path='/:spaceId/team/*' element={<TeamRoutes />} />              {/* Team Route */}
            <Route path='/:spaceId/member/*' element={<MemberRoutes />} />          {/* Team Route */}
            <Route path='/space/:spaceId/backend/*' element={<SpacesRoutes />} />   {/* Space Route */}

            {/* All Website Route */}
            <Route exact path='/' element={<Home />} />
            <Route exact path='/privacy' element={<Privacy />} />
            <Route exact path='/terms' element={<Terms />} />
            {/* All Website Route */}

            <Route path='*' element={<NotFound />} />

            <Route path='129/temp/2024' element={<DashboardTemp />} />

            {/* <Route path='129/temp/2025' element={<DashboardTempOne />} /> */}
            {/* <Route path='member/*' element={<MemberRoutes />} /> */}
            {/* <Route exact path='/login' element={<Login />} /> */}
            {/* Private routes space panel */}
            {/* <Route path='/space' element={<PrivateRoutes />}> */}
            {/* </Route> */}
          </Routes>
        </Router>
      </AuthUserContext.Provider>
    </>
  );
}

root.render(<App />);
