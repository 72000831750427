import { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import useScrollBlock from "../../../utils/useScrollBlock";
import { ToastContainer, toast } from "react-toastify";
import "../../../../nav/components/location/component.css";

import { url, clientpoints, getDecryptedDataFromLocalStorage, whoAmIHeaders, client_prefix } from "../../../../../lib/lib";
import { AuthUserContext } from "../../../../../lib/AuthUserContext";

// import Card from '../cards/Card2';
import Table from '../subscription/table/Component';
import Card from './card/Component';

function Component() {
    const navigate = useNavigate();

    const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
    const { typeTeamRoleObject } = useContext(AuthUserContext);
    let pageHeaders = whoAmIHeaders(whoAmI);
    let getLocalStorageData;

    useEffect(() => {
        if (!whoAmI) {
            getLocalStorageData = getDecryptedDataFromLocalStorage('type');
            setWhoAmI(getLocalStorageData);
            pageHeaders = whoAmIHeaders(getLocalStorageData);
        } else {
            pageHeaders = whoAmIHeaders(whoAmI);
        }
    }, []);

    const inputRef = useRef(null);
    const selectRef = useRef(null);
    const { spaceId } = useParams();
    const location = useLocation();
    const [myLocations, setMyLocations] = useState([]);
    const [floors, setFloors] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState(0);
    const [showFilterMsg, setShowFilterMsg] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 12,
        totalPages: 0,
    });

    const [selectedItem, setSelectedItem] = useState();
    const [isAddModal, setIsAddModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const disableScroll = useScrollBlock();

    const [isLoading, setIsLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState("All");
    const [isOpen1, setIsOpen1] = useState(false);

    const [subscriptionData, setSubscriptionData] = useState(null); // State to hold the fetched data
    const [spaceData, setSpaceData] = useState(''); // State to hold the fetched data
    const headers = ['Subscription Name', 'Subscription Desk', 'Subscription Validity', 'Base Amount', 'Payment Date'];
    const cardHead = ['Number of Desk', 'Subscription Validity', 'Subscription Status'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url + clientpoints.space_view, {
                    method: "POST",
                    headers: pageHeaders,
                    credentials: 'include',
                    body: JSON.stringify({ urlName: spaceId, }),
                });

                if (!response.ok) {
                    navigate(`${client_prefix}`);
                }

                const result = await response.json(); // Convert response to JSON
                setSpaceData(result.Data[0]);
                setSubscriptionData(result.Data); // Set state with fetched data
            } catch (error) {
                console.error('Fetching error:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs only once after the initial render

    if (!subscriptionData) {
        return <div>Loading...</div>;
    }


    return (
        <>
            <ToastContainer />
            <div className="inner-padding">
                <Card data={spaceData} head={cardHead} />
                {/* <Table data={subscriptionData} head={"Subscription"} header={headers} /> */}
                {whoAmI === 'team' ? (
                    <>
                        {typeTeamRoleObject?.subscription?.view && (<Table head={"Subscription"} header={headers} />)}
                    </>
                ) : (
                    <>
                        <Table head={"Subscription"} header={headers} />
                    </>
                )}
            </div>
        </>
    );
}

export default Component;
