import Login from "../components/login/Component.js";
import { Helmet } from "react-helmet";

import "./../css/style.css";

const Component = () => {
    return (
        <>
            <Helmet>
                <title>Login - DeskBox</title>
            </Helmet>
            <Login />
        </>
    );
};

export default Component;
