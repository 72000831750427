import Expenses from '../../components/expense/all_expense/table/Component';
import { toast, ToastContainer } from "react-toastify";
export default function Component() {

    return (
        <>
            <ToastContainer />
            <Expenses name="All Expenses" />
        </>
    );
}