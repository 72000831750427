import { useState } from "react";
import { toast } from "react-toastify";

export default function ConfirmModal({ isModalOpen, setIsModalOpen, modalName, deleteItemId, setDeleteItemId, deleteItemType, setDeleteItemType, handleDelete }) {
  const [inputValue, setInputValue] = useState("");

  const cancelDelete = () => {
    setDeleteItemId("");
    setDeleteItemType("");
    setInputValue("");
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    if (!inputValue.trim()) {
      toast.warn("Please enter value to confirm deletion!");
      return;
    }

    if (inputValue === deleteItemType) {
      handleDelete(deleteItemId);
    } else {
      toast.error("Input does not match! Please try again.");
    }
    setIsModalOpen(false);
    setInputValue("");
    setDeleteItemId("");
    setDeleteItemType("");
  };

  return (
    <>
      <div class='modal fade show' id='staticBackdrop' data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true' style={{ display: "block" }}>
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='staticBackdropLabel'>
                {modalName}
              </h5>
              <button onClick={() => setIsModalOpen(false)} type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div class='modal-body'>
              <p>
                Do you want to delete this record? <br /> Please enter "{deleteItemType}"{" "}
              </p>
              <input className='form-control' type='text' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
            </div>
            <div class='modal-footer'>
              <button type='button' class='btn btn-secondary' data-bs-dismiss='modal' onClick={cancelDelete}>
                Cancel
              </button>
              <button type='button' class='btn btn-primary' onClick={confirmDelete} disabled={inputValue !== deleteItemType}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}
