import React, { useState, useEffect } from "react";
import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { toast } from "react-toastify";
import "./component.css";
import { useNavigate } from "react-router-dom";
import { LoadingMutatingDots } from "../../../../spaces/components/ui/Loadings.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh }) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingError, setIsSubmittingError] = useState(false);
  let clientId = data;

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    const newValue = value.slice(0, 10);
    setPhone(newValue);
  };

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }

    if (!phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(phone)) {
      errors.phone = "Phone number must be 10 digits";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isModalOpen && clientId) {
      setLoading(true);
      fetchData(clientId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, clientId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setCurrent_id(response_data._id);
        setName(response_data.name || "");
        setEmail(response_data.email || "");
        setPhone(response_data.phone || "");
        setDate(response_data.createdAt || "");
      }
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const checkExistsInDataBase = async (key, value, msg, table) => {
    try {
      const response = await fetch(url + adminpoints[table], {
        method: "POST",
        headers,
        body: JSON.stringify({ filters: { _id: { $ne: clientId }, [key]: value } }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.Count >= 1) {
        toast.warning(`${msg} exist!`);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Error");
      return true;
    }
  };

  const handleEmailBlur = async () => {
    let tmp_email = email.trim();
    if (tmp_email) {
      const exists = await checkExistsInDataBase("email", email, "Email", "client_count");
      if (exists) {
        setErrors({ ...errors, email: "Email Name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, email: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const handlePhoneBlur = async () => {
    let tmp_phone = phone.trim();
    if (tmp_phone) {
      const exists = await checkExistsInDataBase("phone", phone, "phone", "client_count");
      if (exists) {
        setErrors({ ...errors, phone: "Phone already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, phone: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    if (validateForm()) {
      try {
        const response = await fetch(url + adminpoints.client_edit, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({ filters: { _id: current_id }, data: { name: name, email: email, phone: phone } }),
        });

        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setName("");
          setEmail("");
          setPhone("");
          setIsModalOpen(false);
          onRefresh();
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        navigate(`${admin_prefix}login`);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Edit Client
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <>
                    <LoadingMutatingDots />
                    {/* <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div> */}
                  </>
                ) : (
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='name' className='col-form-label'>
                        Name:
                      </label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' value={name} onChange={handleNameChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='email' className='col-form-label'>
                        Email:
                      </label>
                      <input type='text' className={`form-control ${errors.email ? "is-invalid" : ""}`} id='email' value={email} onChange={handleEmailChange} onBlur={handleEmailBlur} />
                      {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='phone' className='col-form-label'>
                        Phone:
                      </label>
                      <input
                        type='number'
                        className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                        id='phone'
                        value={phone}
                        onChange={handlePhoneChange}
                        maxLength={10}
                        pattern='[0-9]*'
                        onBlur={handlePhoneBlur}
                      />
                      {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                      Close
                    </button>
                    {/* <button type="submit" className="btn btn-primary">Update</button> */}
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting || isSubmittingError}>
                      {isSubmitting ? "Loading..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
