import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from '../../../../../../lib/lib.js';
import { AuthUserContext } from '../../../../../../lib/AuthUserContext.js';
import { toast } from 'react-toastify';
import './component.css';

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { spaceId } = useParams();

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [spaceList, setSpaceList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState('');

  useEffect(() => {
    fetchClientData();
  }, []);



  const fetchClientData = async () => {
    try {
      const response = await fetch(url + clientpoints.space_view, {
        method: 'POST',
        headers: pageHeaders,
        body: JSON.stringify({ urlName: spaceId, filters: { space_id: spaceId }, }),
        credentials: 'include',
      });
      if (!response.ok) {
        navigate(`${client_prefix}`);
      }
      const data = await response.json();
      setSpaceList(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const [formData, setFormData] = useState({
    title: '',
    comment: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.title.trim()) { errors.title = 'Title is required'; isValid = false; }
    if (!formData.comment.trim()) { errors.comment = 'Comment is required'; isValid = false; }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(url + clientpoints.client_ticket_add, {
          method: 'POST',
          headers: pageHeaders,
          credentials: 'include',
          body: JSON.stringify({
            urlName: spaceId,
            title: formData.title
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();
        if (responseData.Status) {
          let client_ticket_id = responseData._id
          const response_history = await fetch(url + clientpoints.client_ticket_history_add, {
            method: 'POST',
            headers: pageHeaders,
            credentials: 'include',
            body: JSON.stringify({
              urlName: spaceId,
              client_ticket_id: client_ticket_id,
              comment: formData.comment,
              type: "client"
            }),
          });
          setFormData({
            title: '',
            comment: ''
          });
          const responseDataHistory = await response_history.json();
          if (responseDataHistory.Status) {
            toast.success(responseDataHistory.Message);
          } else {
            toast.error(responseDataHistory.Message);
          }
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      title: '',
      comment: ''
    });
  };
  return (
    <>
      {isModalOpen && (
        <div className="modal fade show" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{modalName}</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className='row'>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="title" className="col-form-label">Title</label>
                      <input type="text" className={`form-control ${errors.title ? 'is-invalid' : ''}`} id="title" name='title' value={formData.title} onChange={handleChange} />
                      {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="comment" className="col-form-label">Comment:</label>
                      <textarea type="text" className={`form-control ${errors.comment ? 'is-invalid' : ''}`} id="comment" value={formData.comment} name='comment' onChange={handleChange} />
                      {errors.comment && <div className="invalid-feedback">{errors.comment}</div>}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div >
      )
      }
    </>
  );

}

export default Component;