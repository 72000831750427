export const validate = (obj = {}, fields = [], returnObject = false) => {
  const emptyFields = [];

  // Check each field in the formData object
  fields.forEach((item) => {
    const keys = item.key.split("."); // Split the key by '.'
    let value = obj;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) break; // Break if any nested key is undefined
    }
    if (typeof value !== "boolean" && (!value || (Array.isArray(value) && value.length === 0))) {
      emptyFields.push(item.name);
    }
  });

  if (emptyFields.length > 0)
    return returnObject
      ? {
          success: false,
          message: `Please provide values for ${emptyFields.join(", ")}`,
          fields: emptyFields,
        }
      : {
          success: false,
          message: `Please provide values for ${emptyFields.join(", ")}`,
        };
  else return { success: true, message: "" };
};

// export const validate = (obj = {}, fields = [], returnObject = false) => {
//   const emptyFields = [];

//   console.log("filedssss", fields);
//   console.log("obj", obj);

//   // Check each field in the formData object
//   fields.forEach((item) => {
//     const value = obj[item?.key];
//     if (typeof value !== "boolean" && (!value || (Array.isArray(value) && value.length === 0))) {
//       emptyFields.push(item?.name);
//     }
//   });
//   console.log(emptyFields);
//   if (emptyFields.length > 0)
//     return returnObject
//       ? {
//           success: false,
//           message: `Please provide values for ${emptyFields.join(", ")}`,
//           fields: emptyFields,
//         }
//       : {
//           success: false,
//           message: `Please provide values for ${emptyFields.join(", ")}`,
//         };
//   else return { success: true, message: "" };
// };
