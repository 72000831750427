import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
      <div className="pricing-section" id="pricing">
        <center><p className="deskbox-feature">
          Pricing
        </p>
          <hr className="pricing-line"></hr>
        </center>
        <div className="container-fluid">
          <div className="row price-padd">
            <div className="col-lg-4 col-sm-6">
              <div className="price-card1">
                <center><p className="price-txt">Monthly Billing Plan</p></center>
                <center><p className="price-txt1">₹60</p></center>
                <center><p className="price-txt2">Per Desk / Month</p></center>
                <hr className="line2" />
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Unlimited Multiple Locations</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Custom Features Development</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Website Customisation</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Custom Branded Web Portals</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">White Labeled Mobile Apps</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Team Access Control</p>
                  </div>

                </div>
                <center>
                  <a href="#contact"><button className="pricing-bot">Get  Free Trial</button></a>
                </center>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="price-card1">
                <center><p className="price-txt">Yearly Billing Plan</p></center>
                <center><p className="price-txt1">₹50</p></center>
                <center><p className="price-txt2">Per Desk / Month</p></center>
                <hr className="line2" />
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Unlimited Multiple Locations</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Custom Features Development</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Website Customisation</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Custom Branded Web Portals</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">White Labeled Mobile Apps</p>
                  </div>

                </div>
                <div className="pricing-flx">
                  <div className="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div className="pricing-flx">
                    <p className="price-txt3">Team Access Control</p>
                  </div>

                </div>
                <center>
                  <a href="#contact"><button className="pricing-bot">Get Free Trial</button></a>
                </center>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <p className="pricing-mnth">
                Get One Week Free Trial
              </p>
              <p>Experience our amazing service for a whole week, absolutely free.</p>
              <a href="#contact"><button className="pricing1-bot">Get Free Trial</button></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
