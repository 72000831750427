import React from "react";
// import "../../../../../nav/components/location/component.css";
import "./cards.css";

// Card for Cabins, Seats, Meeting Rooms
function Card1({ data, setSelectedItem, setIsViewModal, setIsEditModal, handleDelete, typeTeamRoleObject, whoAmI, tableName }) {
  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 roleClass'>
      <div className='team-cards'>
        <div class='post-sideline1'></div>
        <img src='/img/post.png' class='img-fluid team-image' />
        <div className='active1-dot'>
          <i class='ri-circle-fill red-active1'></i>
        </div>
        <p class='team-totalseat'>{data?.name}</p>
        <p class='team-desc-totalseat'>{data.email}</p>

        <div className='cabin-editicn option-edit-delete'>
          {whoAmI === "team" ? (
            <>
              {typeTeamRoleObject?.[tableName]?.view && (
                <i
                  class='ri-eye-line floor-view-icn mb-2'
                  onClick={() => {
                    setSelectedItem(data);
                    setIsViewModal(true);
                  }}
                ></i>
              )}
              {typeTeamRoleObject?.[tableName]?.edit && (
                <i
                  class='ri-pencil-line floor-pencil-icn mb-2'
                  onClick={() => {
                    setSelectedItem(data);
                    setIsEditModal(true);
                  }}
                ></i>
              )}
              {typeTeamRoleObject?.[tableName]?.delete && (
                <i
                  class='ri-delete-bin-6-line floor-trash-icn'
                  onClick={() => {
                    handleDelete(data._id, data?.name);
                  }}
                ></i>
              )}
            </>
          ) : (
            <>
              <i
                class='ri-eye-line floor-view-icn mb-2'
                onClick={() => {
                  setSelectedItem(data);
                  setIsViewModal(true);
                }}
              ></i>
              <i
                class='ri-pencil-line floor-pencil-icn mb-2'
                onClick={() => {
                  setSelectedItem(data);
                  setIsEditModal(true);
                }}
              ></i>
              <i
                class='ri-delete-bin-6-line floor-trash-icn'
                onClick={() => {
                  handleDelete(data._id, data?.name);
                }}
              ></i>
            </>
          )}
        </div>

        <div class='box-back'>
          <div class='row'>
            <div class='col-6 rgt-bdr'>
              <p class='seatbox-txt'>Location</p>
              {/* <p class="seatbox-desc">{(data.location_id && Array.isArray(data.location_id)) && data.location_id.map((list) => list.location + ' ')}</p> */}
              <p class='seatbox-desc'>{data.location_id && Array.isArray(data.location_id) && data.location_id.map((list, index) => (index ? " | " : "") + list.location)}</p>
            </div>
            <div class='col-6 '>
              <center>
                <p class='seatbox-txt'>Role</p>
                <p class='seatbox-desc'>{data.role_id && data.role_id.name}</p>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card1;
