import DeskboxSupport from '../../components/support/deskbox_support/table/Component';
import { toast, ToastContainer } from "react-toastify";
export default function Component() {

    return (
        <>
            <ToastContainer />
            <DeskboxSupport />
        </>
    );
}