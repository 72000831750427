import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, memberHeader, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import SearchableLocationSelect from "../location_select/Component.js";
import SearchableFloorSelect from "../floor_select/Component.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, }) {
  const navigate = useNavigate();
  const { spaceId } = useParams();

  const [date, setDate] = useState("");
  const [bookingObject, setBookingObject] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  let clientId = data;
  let meetingId = data._id;
  let working_hours_start = data.location_id.working_hours_start;
  let working_hours_end = data.location_id.working_hours_end;
  const [selectedSlots, setSelectedSlots] = useState([]);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!selectedSlots || selectedSlots.length === 0) {
      errors.selectedSlots = "Slots is required";
      isValid = false;
    }

    // if (!floorName.trim()) {
    //   alert('Floor is required');
    //   errors.floorName = "Floor is required";
    //   isValid = false;
    // }
    // if (!formData.title.trim()) {
    //   errors.title = "Title is required";
    //   isValid = false;
    // }
    // if (!formData.description.trim()) {
    //   errors.description = "Description is required";
    //   isValid = false;
    // }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && clientId) {
      setLoading(true);
      fetchData(meetingId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, meetingId]);


  const fetchData = async (id, date = '') => {
    // const currentDate = new Date().toISOString().slice(0, 10);
    // const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })).toISOString().slice(0, 10);
    // const currentDate = new Date().toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata", day: "2-digit", month: "2-digit", year: "numeric" }).replace(/(\d{1,2})\/(\d{1,2})\/(\d{4})/, '$1-$2-$3');
    const currentDate = new Date().toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata", day: "2-digit", month: "2-digit", year: "numeric" }).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');

    try {
      const response = await fetch(url + clientpoints.meeting_room_booking_view, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { meeting_id: id, date: date || currentDate } }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        let extractedData = data.Data.map(item => ({
          time: convertTo12HourFormat(item.time),
          date: item.date,
          booking_status: item.booking_status
        }));
        setBookingObject(extractedData);
      } else {
        setBookingObject('');
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      try {
        setIsSubmitting(true);

        // let sortedMeetingData = selectedSlots.map(meeting => ({
        //   meeting_id: meetingId,
        //   time: meeting,
        //   urlName: spaceId,
        //   date: selectedDate,
        // }));

        const response = await fetch(url + clientpoints.meeting_room_booking_add, {
          method: "POST",
          headers: memberHeader,
          credentials: "include",
          body: JSON.stringify({
            meeting_id: meetingId,
            time: selectedSlots,
            date: selectedDate,
          }), // Send the array directly
        });

        // if (!response.ok) {
        //   navigate(`${client_prefix}`);
        // }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error(errors.selectedSlots);
      setIsSubmitting(false);
    }
  };

  //////////////////////////////////////////////////////////////
  const generateTimeSlots = (start, end) => {
    const startTime = convertTo24Hour(start);
    const endTime = convertTo24Hour(end);

    let timeSlots = [];
    let currentTime = { hours: startTime.hours, minutes: startTime.minutes };

    while (currentTime.hours < endTime.hours || (currentTime.hours === endTime.hours && currentTime.minutes <= endTime.minutes)) {
      timeSlots.push(convertTo12Hour(currentTime));
      currentTime = addMinutes(currentTime, 30);
    }

    return timeSlots;
  };

  // Helper function to convert AM/PM time to 24-hour format
  const convertTo24Hour = (time12h) => {
    const [time, period] = time12h.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return { hours, minutes };
  };

  // Helper function to convert 24-hour format to AM/PM format
  const convertTo12Hour = (time24h) => {
    let hours = time24h.hours;
    const period = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${time24h.minutes.toString().padStart(2, '0')} ${period}`;
  };

  // Helper function to add minutes to a time object
  const addMinutes = (time, minutesToAdd) => {
    let { hours, minutes } = time;
    minutes += minutesToAdd;

    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;

    return { hours, minutes };
  };

  const timeSlots = generateTimeSlots(working_hours_start, working_hours_end);



  const handleSlotClick = (slot) => {
    if (selectedSlots.includes(slot)) {
      setSelectedSlots(selectedSlots.filter(item => item !== slot));
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  const convertTo12HourFormat = (time24) => {
    if (!time24) return '';

    const [hours, minutes] = time24.split(':');
    let hoursNum = parseInt(hours, 10);
    const suffix = hoursNum >= 12 ? 'PM' : 'AM';
    hoursNum = hoursNum % 12 || 12;
    return `${hoursNum}:${minutes} ${suffix}`;
  };


  const isSlotBooked = (slot) => {
    if (!Array.isArray(bookingObject)) {
      return false;
    }
    return bookingObject.some(item => item.time === slot);
  };

  //////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////
  const [currentDate, setCurrentDate] = useState('');
  const [futureDate, setFutureDate] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');

  useEffect(() => {
    // Function to get today's date in YYYY-MM-DD format
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setCurrentDate(getTodayDate());

    // Function to calculate date 30 days from today
    const calculateFutureDate = () => {
      const today = new Date();
      const future = new Date(today.setDate(today.getDate() + 30));
      const year = future.getFullYear();
      const month = String(future.getMonth() + 1).padStart(2, '0');
      const day = String(future.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setFutureDate(calculateFutureDate());
  }, []);

  // Function to generate date slots between currentDate and futureDate
  const generateDateSlots = () => {
    const dateSlots = [];
    const startDate = new Date(currentDate);
    const endDate = new Date(futureDate);

    let currentDateIterator = startDate;
    while (currentDateIterator <= endDate) {
      const year = currentDateIterator.getFullYear();
      const month = String(currentDateIterator.getMonth() + 1).padStart(2, '0');
      const day = String(currentDateIterator.getDate()).padStart(2, '0');
      const date = `${year}-${month}-${day}`;
      dateSlots.push(date);

      // Move to the next day
      currentDateIterator.setDate(currentDateIterator.getDate() + 1);
    }

    return dateSlots;
  };

  const dateSlots = generateDateSlots();

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    // const currentDate = new Date().toISOString().slice(0, 10);
    // const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })).toISOString().slice(0, 10);
    // const currentDate = new Date().toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata", day: "2-digit", month: "2-digit", year: "numeric" }).replace(/(\d{1,2})\/(\d{1,2})\/(\d{4})/, '$1-$2-$3');
    const currentDate = new Date().toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata", day: "2-digit", month: "2-digit", year: "numeric" }).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');
    setSelectedDate(currentDate);
  }, []);

  const handleDateClick = (slot) => {
    if (selectedDate === slot) {
      setSelectedDate(null);
      fetchData(meetingId, '');
    } else {
      setSelectedDate(slot);
      fetchData(meetingId, slot);
    }
  };

  const isSelected = (slot) => { return selectedDate === slot; };

  //////////////////////////////////////////////////////////////

  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg event_apply_modal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">

                    <div>
                      <h2>Today's Date:</h2>
                      <div className="row date_scroll">
                        {dateSlots.map((slot, index) => (
                          <div key={index} className="data_row">
                            <div
                              onClick={() => handleDateClick(slot)}
                              className={`card date_card ${isSelected(slot) ? 'selected' : ''}`}
                            >
                              <div className="card-body ">
                                <h6 className="card-title">{slot}</h6>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <h2>Select Time Slots:</h2>
                    <div className="time-slots-container">
                      <div className="row">

                        {timeSlots.map((slot, index) => (
                          <div
                            key={index}
                            onClick={() => !isSlotBooked(slot) && handleSlotClick(slot)}
                            className={`col-md-4 mb-3 time-slot-item`}
                          >
                            <div className={`card ${isSlotBooked(slot) ? 'disabled noSelected' : (selectedSlots.includes(slot) ? 'selected' : '')}`}>
                              <div className="card-body">
                                <h5 className="card-title">{slot}</h5>
                                {isSlotBooked(slot) && <h6 className="text-danger">Booked</h6>}
                              </div>
                            </div>
                          </div>
                        ))}

                      </div>
                    </div>

                    <div>
                      <h3>Selected Slots: {selectedSlots.length}</h3>
                      <ul>{selectedSlots.map((slot, index) => (<li key={index}>{slot}</li>))}</ul>
                    </div>

                  </div>
                )}
                <div className="modal-footer ">
                  <div className="d-flex gap-2">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal} > Close </button>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting} > {isSubmitting ? "Loading..." : "Apply"} </button>
                  </div>
                </div>
              </form>
            </div>
          </div >
        </div >
      )
      }
    </>
  );
}

export default Component;
