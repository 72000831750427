import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../../../../../nav/components/location/component.css";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { LoadingMutatingDots } from "../../../ui/Loadings.js";

import FloorCard from "../cards/Component";
import AddModal from "../add_modal/Component";
import EditModal from "../edit_modal/Component";
import ConfirmModal from "../../../ui/modals/ConfirmDeleteModal.js";

import Select from "react-select";

const Component = ({ name = "All" }) => {
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const { spaceId } = useParams();
  const location = useLocation();
  const [myLocations, setMyLocations] = useState([]);
  const [floors, setFloors] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 12,
    totalPages: 0,
  });

  const [selectedItem, setSelectedItem] = useState();
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = urlParams.get("locationId");

  // const initialSelectedLocation = location.state !== undefined && location.state ? "All" : location.state;

  const [selectedLocation, setSelectedLocation] = useState(idFromUrl);
  const [selectedValues, setSelectedValues] = useState({
    location_id: "",
  });

  useEffect(() => {
    if (myLocations.length === 1) {
      setSelectedLocation(myLocations[0].value);
    } else if (myLocations.length !== 0 && !idFromUrl) {
      setSelectedLocation("All");
    }
  }, [myLocations, idFromUrl]);

  useEffect(() => {
    const location = myLocations.find((item) => item.value === selectedLocation);
    setSelectedValues((prev) => ({ ...prev, location_id: location ? location : { label: "All", value: "All" } }));
  }, [myLocations, selectedLocation]);

  const [isOpen1, setIsOpen1] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen1(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleSelect1 = (locationId) => {
    if (locationId === "All") {
      setShowFilterMsg(false);
    }

    setSelectedLocation(locationId);
    setIsOpen1(false);
  };

  // useEffect(() => {
  //   if (location.state) {
  //     setSelectedLocation(location.state);
  //   } else {
  //     setSelectedLocation("All");
  //   }
  // }, [location.state]);

  useEffect(() => {
    fetchMyLocations();
  }, []);

  const handleSearchExpand = () => {
    setIsExpanded(true);
    inputRef.current.focus();
  };

  const handleSearchClose = () => {
    setShowFilterMsg(false);
    setIsExpanded(false);
    setSearchTerm("");

    if (myLocations.length > 1) {
      setSelectedLocation("All");
    }

    fetchData();
  };

  const fetchMyLocations = async () => {
    try {
      const requestBody = {
        urlName: spaceId,
      };

      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setMyLocations(
        data.Data.map((item) => ({
          label: item.location,
          value: item._id,
        }))
      );
      // setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      // setIsLoading(false);
    }
  };

  const sortingObject = { floor_number: 1 };
  const fetchData = async () => {
    try {
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        urlName: spaceId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(data.totalCount / prev.pageSize),
      }));

      setFloors(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      if (selectedLocation === "All" && !idFromUrl) {
        fetchData();
      } else {
        handleSearch();
      }
    }
  }, [pagination.pageIndex, pagination.pageSize, spaceId, selectedLocation, idFromUrl]);

  function handleRefresh() {
    if (isExpanded) {
      setSearchTerm("");
      setIsExpanded(false);
      setShowFilterMsg(false);
    }
    handleHardRefresh();
  }

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);

      const requestBody = {
        page: 1,
        size: 12,
        urlName: spaceId,
        sorting: sortingObject,
      };
      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        pageSize: 12,
        totalPages: Math.ceil(data.totalCount / pagination.pageSize),
      }));
      setFloors(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (deleteItemId, type) => {
    setDeleteItemId(deleteItemId);
    setDeleteItemType(type);
    setIsDeleteModal(true);
  };

  const handleDelete = async (deleteItemId) => {
    // let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    // if (deleteConfirm == type) {
    try {
      const response = await fetch(url + clientpoints.floor_delete, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: deleteItemId } }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      if (responseData.Status) {
        if (isExpanded) {
          handleSearchClose();
        } else {
          fetchData();
        }
        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    // }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        search: searchTerm,
        page: 1,
        size: pagination.pageSize,
        urlName: spaceId,
        filters: {
          location_id: selectedLocation !== "All" ? selectedLocation : undefined,
        },
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();
      setFloors(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPageRange = () => {
    let startIndex;

    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {
      startIndex = 0;
    }

    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

    if (startIndex > endIndex) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }));
      return;
    }

    let filterText = "";

    if ((showFilterMsg && filter != 0 && myLocations.length > 1 && selectedLocation !== "All") || (searchTerm && showFilterMsg && filter != 0)) {
      filterText = ` of total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }

    return `Showing ${startIndex} to ${endIndex}${filterText} entries`;
  };

  return (
    <>
      {isAddModal && (
        <AddModal
          isModalOpen={isAddModal}
          setIsModalOpen={setIsAddModal}
          modalName={"Add Floor"}
          onRefresh={handleRefresh}
          locations={myLocations}
          // locations={myLocations.map((item) => ({
          //   label: item.location,
          //   value: item._id,
          // }))}
        />
      )}

      {isEditModal && (
        <EditModal
          isModalOpen={isEditModal}
          setIsModalOpen={setIsEditModal}
          modalName={"Floor"}
          onRefresh={handleRefresh}
          data={selectedItem}
          locations={myLocations}
          view='edit'
          // locations={myLocations.map((item) => ({
          //   label: item.location,
          //   value: item._id,
          // }))}
        />
      )}

      {isViewModal && (
        <EditModal
          isModalOpen={isViewModal}
          setIsModalOpen={setIsViewModal}
          modalName={"Floor"}
          onRefresh={handleRefresh}
          data={selectedItem}
          locations={myLocations}
          view='view'
          // locations={myLocations.map((item) => ({
          //   label: item.location,
          //   value: item._id,
          // }))}
        />
      )}

      {isDeleteModal && <ConfirmModal isModalOpen={isDeleteModal} setIsModalOpen={setIsDeleteModal} modalName='Confirm Deletion' deleteItemId={deleteItemId} setDeleteItemId={setDeleteItemId} deleteItemType={deleteItemType} setDeleteItemType={setDeleteItemType} handleDelete={handleDelete} />}

      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>{name}</p>
          </div>

          <div className='featured-flx1'>
            <div className='search-container'>
              <input ref={inputRef} className={`search-bar ${isExpanded ? "expanded" : ""}`} type='text' placeholder='Search Floors' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={handleSearchSubmit} />
              {!isExpanded && (
                <button className='search-button' onClick={handleSearchExpand}>
                  <i className='ri-search-line'></i>
                </button>
              )}
              {isExpanded && (
                <button className='search-button' onClick={handleSearchClose}>
                  <i className='ri-close-line'></i>
                </button>
              )}
            </div>

            {whoAmI === "team" ? (
              <>
                {typeTeamRoleObject?.floor?.add && (
                  <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                    <i className='ri-add-line'></i>
                  </button>
                )}
              </>
            ) : (
              <>
                <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                  <i className='ri-add-line'></i>
                </button>
              </>
            )}
          </div>
        </div>

        <div className='filter-box'>
          <div className='filter-sideline'></div>
          <div className='row'>
            <div className='col-sm-3'>
              <label htmlFor='location_id' className='col-form-label'>
                Location
              </label>
              <Select
                options={myLocations.length > 1 ? [{ label: "All", value: "All" }, ...myLocations] : [...myLocations]}
                value={selectedValues?.location_id}
                onChange={(val) => setSelectedLocation(val.value)}
                placeholder='Select Location...'
                className='flex-1 w-full'
                styles={customStyles}
                menuPortalTarget={document.body}
                // required
              />
              {/* <div className='select-data1' ref={selectRef}>
                <p className='slt-txt'> Location </p>
                <div className={`custom-select ${isOpen1 ? "open" : ""}`}>
                  <div className='form-group'>
                    <div className='select-top' onClick={() => setIsOpen1(!isOpen1)}>
                      {<span>{selectedLocation === "All" ? "All" : myLocations?.find((loc) => loc._id === selectedLocation)?.location}</span>}
            
                      <i className='ri-arrow-down-s-line'></i> 
                    </div>
                    <ul
                      className='select-options'
                      style={{
                        maxHeight: "50vh",
                        overflowY: "auto",
                      }}
                    >
                      <li onClick={() => handleSelect1("All")}>All</li>
                      {myLocations?.map((loc) => (
                        <li key={loc._id} onClick={() => handleSelect1(loc._id)}>
                          {loc.location}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>

            <div className='col-sm-3'></div>
            <div className='col-sm-6'></div>
          </div>
        </div>

        {isLoading ? (
          <LoadingMutatingDots />
        ) : (
          <>
            <div className='row'>
              {floors?.map((item) => (
                <FloorCard key={item._id} data={item} setSelectedItem={setSelectedItem} setIsEditModal={setIsEditModal} setIsViewModal={setIsViewModal} handleDelete={handleDeleteClick} typeTeamRoleObject={typeTeamRoleObject} whoAmI={whoAmI} tableName='location' />
              ))}
            </div>

            <div className='page-flx'>
              <div className='page-flx2'>
                <p className='page-txt1'>{renderPageRange()}</p>
              </div>
              {pagination.totalPages !== 1 && pagination.totalPages !== 0 && (
                <div className='button-container'>
                  {pagination.pageIndex !== 1 && (
                    <button
                      className='button'
                      id='button1'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex - 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-left-s-line'></i>
                    </button>
                  )}

                  <div className='button active' id='button1'>
                    {pagination.pageIndex}
                  </div>

                  {pagination.pageIndex !== pagination.totalPages && (
                    <button
                      className='button'
                      id='button4'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex + 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-right-s-line'></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
