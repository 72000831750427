import MeetingRooms from '../../components/booking/meeting_room/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <MeetingRooms name="All Meeting Rooms" />
        </>
    );
}