import BulkMail from '../../components/community/bulk_mail/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <BulkMail name="All Bulk Email" />
        </>
    );
}