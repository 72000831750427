import { url, clientpoints } from "../lib/lib";

export const validateSpaceId = async (spaceId) => {

  try {
    const response = await fetch(url + "/validate-spaceId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ space_id: spaceId }),
    });

    if (!response.ok) {
      throw new Error("Space Id Validation Failed");
    }

    const data = await response.json();
    return data.Status;
  } catch (error) {
    console.error("Error during space id validation:", error.message);
    return false;
  }
};


export const validateSpaceIdForTeam = async (spaceName) => {
  try {
    const response = await fetch(url + "/validate-spaceName", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ urlName: spaceName }),
    });

    if (!response.ok) {
      throw new Error("Space Id Validation Failed");
    }

    const data = await response.json();
    return data.Status;
  } catch (error) {
    console.error("Error during space id validation:", error.message);
    return false;
  }
};

export const getSpaceNametoSpaceId = async (spaceName) => {
  try {
    const response = await fetch(url + "/spaceName-spaceId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ urlName: spaceName }),
    });

    if (!response.ok) {
      throw new Error("Space Id Validation Failed");
    }

    const data = await response.json();
    console.log('67 util ', data);
    if (data.Status) {
      return data;
    } else {
      return data.Status;
    }
  } catch (error) {
    console.error("Error space:", error.message);
    return false;
  }
};
