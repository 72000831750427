import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import Select from "react-select";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, }) {

  const CGST = 9;
  const SGST = 9;
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();
  const [activeStep, setActiveStep] = useState(1);
  const [accountType, setAccountType] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    url: '',
    description: '',

    legal_company: '',
    billing_address: '',
    gstn: '',
    pan: '',
    cin: '',
    tan: '',

    spaceName: '',
    companyName: '',
    gstNumber: '',
    address: '',
    urlName: '',
    domain: '',
    subscriptionName: '',
    subscriptionDesk: '',
    price: '',
    subscriptionValidity: '',
    location_id: null
  });
  const [spaceList, setSpaceList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [current_id, setCurrent_id] = useState("");

  let clientId = data;
  // useEffect(() => {
  //   fetchClientData();
  //   fetchMyLocation();
  // }, []);

  useEffect(() => {
    if (isModalOpen && clientId) {
      setLoading(true);
      fetchData(clientId).then(() => { setLoading(false); });
      fetchSeatAndCabin(clientId);
      fetchMyLocation();
    }
  }, [isModalOpen, clientId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.company_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }
      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          name: response_data.name,
          email: response_data.email,
          phone: response_data.phone,
          url: response_data.url,
          member: response_data.member,
          description: response_data.description,

          legal_company: response_data.billing_detail.legal_company,
          billing_address: response_data.billing_detail.billing_address,
          gstn: response_data.billing_detail.gstn,
          pan: response_data.billing_detail.pan,
          cin: response_data.billing_detail.cin,
          tan: response_data.billing_detail.tan,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const fetchSeatAndCabin = async (company_id) => {
    try {
      const response = await fetch(url + clientpoints.cabin_seat_name_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { company_id } }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();

      const formattedSeatCabin = [];
      const newDataObjects = [];
      const newDataObjectsBillings = [];
      const seenEntries = {};

      data.Data.forEach(seatCabinType => {
        // console.log('seatCabinType - ', seatCabinType);
        let tempLocationOption = {
          value: seatCabinType.location_id._id,
          label: seatCabinType.location_id.location
        }
        let tempFloorOption = {
          value: seatCabinType.floor_id._id,
          label: seatCabinType.floor_id.floor_name
        }
        let tempType;
        let tempVariantId;
        let tempVariant;
        if (seatCabinType.type === 'cabin') {
          tempType = 'cabin';
          tempVariantId = {
            value: seatCabinType.cabin_id._id,
            label: seatCabinType.cabin_id.name,
          };
          // value: cabin.cabin_id._id,
          // label: cabin.cabin_id.name

        } else if (seatCabinType.type === 'seat') {
          tempType = 'seat';
          tempVariantId = {
            value: seatCabinType.seat_id._id,
            label: seatCabinType.seat_id.name,
          };
          // value: seat.seat_id._id,
          // label: seat.seat_id.name

        }

        const newDataObject = {
          location: tempLocationOption,
          floor: tempFloorOption,
          type: tempType,
          cabinId: tempVariantId.value,
          variant: tempVariantId.label,
          qty: 1,
          value: seatCabinType._id,
          name: seatCabinType.name,
          label: seatCabinType.name,
          discount: parseFloat(seatCabinType.discount?seatCabinType.discount:0).toFixed(2),
          discount_type: seatCabinType.discount_type,
          price: parseFloat(seatCabinType.price).toFixed(2),
        };

        newDataObjects.push(newDataObject);
      });

      newDataObjects.forEach(slotValue => {
        const { location, floor, type, cabinId, variant, value, name, price, discount, discount_type } = slotValue;
        const entryKey = `${location.value}_${floor.value}_${type}_${variant}`;
        if (!seenEntries[entryKey]) {
          seenEntries[entryKey] = { location, floor, type, cabinId, variant, names: [name], unit: parseFloat(price), discount: parseFloat(discount), discount_type: discount_type };
        } else {
          if (!seenEntries[entryKey].names.includes(name)) {
            seenEntries[entryKey].names.push(name);
            seenEntries[entryKey].discount += parseFloat(discount);
          }
          // seenEntries[entryKey].unit += parseFloat(price);
        }
      });

      Object.values(seenEntries).forEach(entry => {

        const { location, floor, type, cabinId, variant, names, unit, discount, discount_type } = entry;
        let adjustedDiscount;

        if (discount_type === 'value') {

          adjustedDiscount = parseFloat(discount).toFixed(2);

        } else if (discount_type === 'percentage') {

          let temp_unit_price = parseFloat(unit).toFixed(2);
          let temp_discount_price = parseFloat(discount / (names.length || 1)).toFixed(2);
          adjustedDiscount = parseFloat((temp_discount_price / temp_unit_price) * 100).toFixed(2);
        } else {
          
          adjustedDiscount = 0;
        }

        const newDataObjectBilling = {
          location: location,
          floor: floor,
          type,
          cabinId,
          variant,
          unitPrice: parseFloat(unit).toFixed(2),
          discountType: discount_type,
          discount: parseFloat(adjustedDiscount).toFixed(2),
          qty: names.length,
          totalPrice: parseFloat((unit * names.length) - discount).toFixed(2),
          selectedSlot: names.join(', ')
        };

        newDataObjectsBillings.push(newDataObjectBilling);
      });
      console.log('fetch - ', newDataObjectsBillings);
      // console.log('fetch - ', newDataObjects);
      setSelectedSlots(newDataObjects);
      setTableData(newDataObjects);
      setTableDataBillings(newDataObjectsBillings);
      setSelectedSummaryIsShow(true);
    } catch (error) {
      console.error("Error fetching seat types:", error.message);
    }
  };

  const [isSubmittingError, setIsSubmittingError] = useState(false);

  const handleStepChange = (step) => { setActiveStep(step); };
  const handlePrevStep = () => { setActiveStep(prevStep => prevStep - 1); };
  const handleNextStep = () => { if (validateForm()) { setActiveStep(prevStep => prevStep + 1); } };


  const handleEmailBlur = async () => {
    const email = formData.email.trim();
    if (email) {
      const exists = await checkExistsInDataBase("email", email, "Email", "client_count");
      if (exists) {
        setErrors({ ...errors, email: 'Email Name already exists' });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, email: '' });
        setIsSubmittingError(false);
      }
    }
  };

  const handlePhoneBlur = async () => {
    const phone = formData.phone.trim();
    if (phone) {
      const exists = await checkExistsInDataBase("phone", phone, "phone", "client_count");
      if (exists) {
        setErrors({ ...errors, phone: 'Phone already exists' });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, phone: '' });
        setIsSubmittingError(false);
      }
    }
  };

  const handleUrlNameBlur = async () => {
    const urlName = formData.urlName.trim();
    if (urlName) {
      const exists = await checkExistsInDataBase("urlName", urlName, "URL Name", "space_count");
      if (exists) {
        setErrors({ ...errors, urlName: 'URL name already exists' });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, urlName: '' });
        setIsSubmittingError(false);
      }
    }
  };

  const handleDomainBlur = async () => {
    const domain = formData.domain.trim();
    if (domain) {
      const exists = await checkExistsInDataBase("domain", domain, "Domain Name", "space_count");
      if (exists) {
        setErrors({ ...errors, domain: 'Domain name already exists' });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, domain: '' });
        setIsSubmittingError(false);
      }
    }
  };

  const handleAccountType = (event) => { setAccountType(event.target.value); };

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + clientpoints.company_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: spaceId }),
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${client_prefix}`);
      }
      const data = await response.json();
      setSpaceList(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (activeStep === 1) {
      // if (!formData.name.trim()) { errors.name = "Name is required"; isValid = false; }
      // if (!formData.email.trim()) { errors.name = "Email is required"; isValid = false; }
      // if (!formData.phone.trim()) { errors.name = "Phone is required"; isValid = false; }

      if (!formData.url.trim()) { errors.url = "URL is required"; isValid = false; }
      if (!formData.description.trim()) { errors.description = "Description is required"; isValid = false; }

      if (!formData.name.trim()) { errors.name = 'Name is required'; isValid = false; }
      if (!formData.email.trim()) { errors.email = 'Email is required'; isValid = false; }
      else if (!/\S+@\S+\.\S+/.test(formData.email)) { errors.email = 'Invalid email address'; isValid = false; }
      if (!formData.phone.trim()) { errors.phone = 'Phone is required'; isValid = false; }
      else if (!/^\d{10}$/.test(formData.phone)) { errors.phone = 'Phone number must be 10 digits'; isValid = false; }
    }

    if (activeStep === 2) {
      if (tableData.length <= 0) { errors.assets = 'Assets is required'; isValid = false; toast.warning("Assets is required"); }
    }

    if (activeStep === 3) {
      if (!formData.legal_company.trim()) { errors.legal_company = 'Legal Company Name is required'; isValid = false; }
      if (!formData.billing_address.trim()) { errors.billing_address = 'Billing Address is required'; isValid = false; }
      if (!formData.gstn.trim()) { errors.gstn = 'GTSN is required'; isValid = false; }
      if (!formData.pan.trim()) { errors.pan = 'PAN is required'; isValid = false; }
      if (!formData.cin.trim()) { errors.cin = 'CIN is required'; isValid = false; }
      if (!formData.tan.trim()) { errors.tan = 'TAN is required'; isValid = false; }
    }

    setErrors(errors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      const locationIds = tableDataBillings.map(item => item.location.value);
      const uniqueLocationIds = [...new Set(locationIds)];
      // console.log(uniqueLocationIds); // Output: [101, 102, 103]

      const floorIds = tableDataBillings.map(item => item.floor.value);
      const uniqueFloorIds = [...new Set(floorIds)];
      // console.log(uniqueFloorIds); // Output: [101, 102, 103]

      const subTotalPriceSum = tableDataBillings.reduce((accumulator, item) => { return accumulator + parseFloat(item.totalPrice); }, 0);
      const cgst = (subTotalPriceSum * CGST) / 100;
      const sgst = (subTotalPriceSum * SGST) / 100;
      const totalPriceSum = subTotalPriceSum + cgst + sgst;

      // convert back to number
      const formattedSubTotal = parseFloat(subTotalPriceSum);
      const formattedCGST = parseFloat(cgst);
      const formattedSGST = parseFloat(sgst);
      const formattedTotalPrice = parseFloat(totalPriceSum);
      // console.log("Formatted Subtotal:", formattedSubTotal);
      // console.log("Formatted CGST:", formattedCGST);
      // console.log("Formatted SGST:", formattedSGST);
      // console.log("Formatted Total Price:", formattedTotalPrice);

      let companyFormData = {
        filters: {
          _id: clientId
        }, data: {
          urlName: spaceId,
          location_id: uniqueLocationIds,
          floor_id: uniqueFloorIds,
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          url: formData.url,
          description: formData.description,
          billing_detail: {
            legal_company: formData.legal_company,
            billing_address: formData.billing_address,
            gstn: formData.gstn,
            pan: formData.pan,
            cin: formData.cin,
            tan: formData.tan
          },
          billing: {
            subtotal: formattedSubTotal,
            cgst: formattedCGST,
            sgst: formattedSGST,
            total: formattedTotalPrice
          }
        }
      }
      // company basic details
      // console.log('abc ---- ', companyFormData);

      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.company_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(companyFormData),
        });

        const responseData = await response.json();
        // console.log('responseData - ', responseData);
        if (responseData.Status) {

          // console.log(' ----- ', selectedSlots);
          // company cabin / seat details
          let slotObjects = [];
          selectedSlots.forEach(slotValue => {

            const tempPrice = tableDataBillings.reduce((acc, slot) => {
              if (slot.cabinId === slotValue.cabinId) {
                let adjustedDiscount;

                if (slot.discountType === 'value') {
                  adjustedDiscount = slot.discount / (slot.qty || 1);
                } else if (slot.discountType === 'percentage') {

                  let temp_unit_price = slot.unitPrice;
                  let temp_discount_price = slot.discount;
                  adjustedDiscount = parseFloat(temp_unit_price * temp_discount_price) / 100;
                }

                acc[slotValue.value] = {
                  unitPrice: slot.unitPrice,
                  discount: adjustedDiscount,
                  discountType: slot.discountType
                };
              }
              return acc;
            }, {});

            let slotObject = {
              filters: {
                _id: slotValue.value,
              },
              data: {
                price: tempPrice[slotValue.value] ? tempPrice[slotValue.value].unitPrice : 0,
                discount: tempPrice[slotValue.value] ? tempPrice[slotValue.value].discount : 0,
                discount_type: tempPrice[slotValue.value] ? tempPrice[slotValue.value].discountType : 'None',
                occupancy_status: true,
                company_id: clientId
              }
            };

            slotObjects.push(slotObject);
          });
          console.log('slotObjects - ', slotObjects);
          const responseCabinSeat = await fetch(url + clientpoints.cabin_seat_name_edit, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(slotObjects)
          });
          const responseDataCabinSeat = await responseCabinSeat.json();
          if (responseDataCabinSeat.Status) {
            toast.success(responseData.Message);
          } else {
            toast.success(responseData.Message);
          }

          setFormData({
            name: "",
            email: "",
            phone: "",
            url: "",
            description: "",
            legal_company: "",
            billing_address: "",
            gstn: "",
            pan: "",
            cin: "",
            tan: ""
          });
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      url: "",
      member: "",
      description: "",
    });
  };

  const checkExistsInDataBase = async (key, value, msg, table) => {
    try {
      const response = await fetch(url + adminpoints[table], {
        method: 'POST', headers: adminHeader, body: JSON.stringify({ filters: { [key]: value } }), credentials: 'include',
      });

      if (!response.ok) { throw new Error('Network response was not ok'); }
      const data = await response.json();
      if (data.Count >= 1) {
        toast.warning(`${msg} exist!`);
        return true;
      }
      else { return false; }

    } catch (error) {

      toast.error("Error");
      return true;
    }
  };


  // const handleChange = (index, event) => {
  //   const { name, value } = event.target;

  //   // Update formData state
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value
  //   }));

  //   // Clear errors for this field
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: ""
  //   }));
  // };

  const [isLoading, setIsLoading] = useState(true);

  /* These All are dropdown*/
  const [location, setLocation] = useState([]);                 // Location Drop-Down value show
  const [floorOptions, setFloorOptions] = useState([]);         // Floor Drop-Down value show
  const seatTypeOptions = [{ value: 'cabin', label: 'Cabin' }, { value: 'seat', label: 'Seat' }];   // Seat Drop-Down value show
  const [variantOptions, setVariantOptions] = useState([]);     // Variant Drop-Down value show
  const [cardOptions, setCardOptions] = useState([]);           // Variant Drop-Down value show
  /* These All are dropdown*/


  const [selectedLocationId, setSelectedLocationId] = useState(null);   // location select value
  const [selectedFloorId, setSelectedFloorId] = useState(null);         // Floor select value
  const [selectedSeatType, setSelectedSeatType] = useState(null);
  const [selectedVariantType, setSelectedVariantType] = useState(null);
  const [selectedVariantTypeLabel, setSelectedVariantTypeLabel] = useState(null);


  const [selectedCardIsShow, setSelectedCardIsShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataBillings, setTableDataBillings] = useState([]);
  const [selectedSummaryIsShow, setSelectedSummaryIsShow] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState([]);               // This is the name when we choose like 4a, 4b, 4c 

  // fetchMyLocation is call when page is open or refresh this or you can say bydefault call (location dropdown value)
  const fetchMyLocation = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();

      const formattedLocations = data.Data.map(location => ({
        value: location._id,
        label: location.location
      }));

      setLocation(formattedLocations); // Set location data in required format
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  // fetchFloorsForLocation this function call when location select and floor dorpdown value show acording to particular those location. 
  const fetchFloorsForLocation = async (locationId) => {
    try {
      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { location_id: locationId } }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();
      const formattedFloors = data.Data.map(floor => ({
        value: floor._id,
        label: floor.floor_name
      }));

      setFloorOptions(formattedFloors); // Set floor options
    } catch (error) {
      console.error("Error fetching floors:", error.message);
    }
  };


  // fetchVariant this function call when cabin/seat, floor, location select and then variant dorpdown value show. 
  const fetchVariant = async (locationId, floorId, type) => {
    try {

      const response = await fetch(url + clientpoints.mapping_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { location_id: locationId, floor_id: floorId } }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();
      let formattedOptions = [];

      if (type === 'cabin') {

        formattedOptions = data.Data.flatMap(mainObject => (
          mainObject.cabin_mapping.map(cabin => ({
            value: cabin.cabin_id._id,
            label: cabin.cabin_id.name
          }))
        ));
      } else if (type === 'seat') {

        formattedOptions = data.Data.flatMap(mainObject => (
          mainObject.seat_mapping.map(seat => ({
            value: seat.seat_id._id,
            label: seat.seat_id.name
          }))
        ));
      }

      setVariantOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching seat types:", error.message);
    }
  };

  // fetchSeatAndCabinTypes this function call when we have location_id, floor_id, type = {seat/cabin}, variant = {4 seater, 6 seater in master cabin_seat_name fetch when particular id send like cabin_id or seat_id } then data fetch name like 4a, 4b, 4c 
  const fetchSeatAndCabinTypes = async (locationId, floorId, seat_cabin_id) => {
    try {
      const response = await fetch(url + clientpoints.cabin_seat_name_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { location_id: locationId, floor_id: floorId, [`${selectedSeatType}_id`]: seat_cabin_id } }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();
      const formattedSeatCabin = [];

      data.Data.forEach(seatCabinType => {
        if (!seatCabinType.occupancy_status) {
          formattedSeatCabin.push({
            value: seatCabinType._id,
            label: seatCabinType.name,
            price: seatCabinType.price,
            status: seatCabinType.occupancy_status
          });
        }
      });

      setCardOptions(formattedSeatCabin);
      setSelectedCardIsShow(true);
    } catch (error) {
      console.error("Error fetching seat types:", error.message);
    }
  };

  const handleSlotClick = (value, label, price, location, floor, type, cabinId, variant) => {
    const slotIndex = selectedSlots.findIndex(slot => slot.value === value);
    if (slotIndex !== -1) {
      setSelectedSlots(prevSlots => prevSlots.filter(slot => slot.value !== value));
    } else {
      setSelectedSlots(prevSlots => [...prevSlots, { value, label, price, location, floor, type, cabinId, variant }]);
    }
  };

  const handleTableField = () => {
    const newDataObjects = [];
    const newDataObjectsBillings = [];
    const seenEntries = {};

    if (selectedSlots.length > 0) {
      // console.log('--- ', selectedSlots);
      selectedSlots.forEach(slotValue => {
        // console.log('add selected - ', slotValue);
        const discountType = slotValue.discount_type != null && slotValue.discount_type.trim() !== '' ? slotValue.discount_type : 'None';
        const discount = parseFloat(slotValue.discount) || 0;
        const price = parseFloat(slotValue.price).toFixed(2) || 0;

        const newDataObject = {
          location: slotValue.location,
          floor: slotValue.floor,
          type: slotValue.type,
          cabinId: slotValue.cabinId,
          variant: slotValue.variant,
          selectedSlot: slotValue.value,
          name: slotValue.label,
          discount_type: discountType,
          discount: discount,
          price: price
        };

        newDataObjects.push(newDataObject);
      });

      selectedSlots.forEach(slotValue => {

        const { location, floor, type, cabinId, variant, value, label, price, discount_type, discount } = slotValue;
        const entryKey = `${location.value}_${floor.value}_${type}_${variant}`;
        if (!seenEntries[entryKey]) {
          seenEntries[entryKey] = { location, floor, type, cabinId, variant, names: [label], unit: parseFloat(price), discount_type, discount: parseFloat(discount) };
        } else {
          if (!seenEntries[entryKey].names.includes(label)) { seenEntries[entryKey].names.push(label); }
          // seenEntries[entryKey].unit += parseFloat(price);
        }
      });


      Object.values(seenEntries).forEach(entry => {
        console.log('entry - ', entry);
        const { location, floor, type, cabinId, variant, names, unit, discount_type, discount } = entry;

        const quantity = names.length;
        let totalDiscount = 0;
        let totalPrice = 0;

        if (discount_type === 'value') {

          totalDiscount = discount * quantity;
          totalPrice = (unit * quantity) - totalDiscount;

        } else if (discount_type === 'percentage') {

          let temp_unit_price = unit;
          let temp_discount_price = discount;

          let tempTotalDiscount = discount * quantity;
          totalPrice = (unit * quantity) - tempTotalDiscount;
          totalDiscount = parseFloat(temp_discount_price / temp_unit_price) * 100;
        } else {

          totalPrice = (unit * quantity);
        }

        console.log('totalDiscount - ', totalDiscount);
        console.log('totalPrice - ', totalPrice);

        const newDataObjectBilling = {
          location: location,
          floor: floor,
          type,
          cabinId,
          variant,
          unitPrice: parseFloat(unit).toFixed(2),
          discountType: discount_type ? discount_type : '',
          discount: totalDiscount ? totalDiscount : 0,
          qty: quantity,
          totalPrice: parseFloat(totalPrice).toFixed(2),
          selectedSlot: names.join(', ')
        };
        newDataObjectsBillings.push(newDataObjectBilling);
      });

      // setTableData(prevData => {
      //   const prevDataIds = new Set(prevData.map(item => item.value));
      //   const uniqueNewData = newDataObjects.filter(item => !prevDataIds.has(item.value));
      //   return [...prevData, ...uniqueNewData];
      // });

      // setTableDataBillings(prevData => {
      //   const prevDataIds = new Set(prevData.map(item => item.id));
      //   const uniqueNewData = newDataObjectsBillings.filter(item => !prevDataIds.has(item.id));
      //   return [...prevData, ...uniqueNewData];
      // });

      // setTableDataBillings(prevData => {
      //   const prevDataIds = new Set(prevData.map(item => item.value));
      //   const uniqueNewData = newDataObjectsBillings.filter(item => !prevDataIds.has(item.value));
      //   return [...prevData, ...uniqueNewData];
      // });

      // setTableData(prevData => [...prevData, ...newDataObjects]);
      // setTableDataBillings(prevData => [...prevData, ...newDataObjectsBillings]);

      setTableData(newDataObjects);
      setTableDataBillings(newDataObjectsBillings);
      setSelectedSummaryIsShow(true);
      // console.log('setTableData - ', tableData);
      // console.log('setTableDataBillings - ', tableDataBillings);

    } else {
      toast.warning("Add Assets detail!");
    }
  };

  const handleRemoveItem = (indexToRemove) => {

    const itemToRemove = tableData[indexToRemove];
    setTableData(prevData => prevData.filter((_, index) => index !== indexToRemove));
    setTableDataBillings(prevData => prevData.map(item => {

      const selectedSlots = item.selectedSlot.split(',').map(slot => slot.trim());
      const updatedSelectedSlot = selectedSlots.filter(slot => slot !== itemToRemove.name).join(', ');

      let updatedQty = item.qty;
      let tempTotal = item.totalPrice;

      if (updatedSelectedSlot !== item.selectedSlot) {
        updatedQty -= 1;
        tempTotal -= item.unitPrice;
      }

      if (updatedSelectedSlot.length === 0) { return null; }
      return { ...item, selectedSlot: updatedSelectedSlot, qty: updatedQty, totalPrice: tempTotal };
    }).filter(Boolean));


    if (itemToRemove && itemToRemove.name) {
      setSelectedSlots(prevSlots => prevSlots.filter(slot => slot.label !== itemToRemove.name));
    }
  };

  const handlePriceChange = (e, index) => {
    const { value } = e.target;
    const floatValue = parseFloat(value) || 0;
    setTableDataBillings(prevData => {
      const updatedData = [...prevData];
      updatedData[index].unitPrice = floatValue;
      updatedData[index].totalPrice = updatedData[index].qty * floatValue
      return updatedData;
    });
  };

  const handleDiscountTypeChange = (e, index) => {
    const { value } = e.target;
    setTableDataBillings(prevTableData => {
      const updatedTableData = [...prevTableData];
      updatedTableData[index] = {
        ...updatedTableData[index],
        discountType: value,
      };
      return updatedTableData;
    });
  };

  const handleDiscountChange = (e, index) => {
    const { value } = e.target;
    const discountValue = value === '' ? '' : parseFloat(value);
    setTableDataBillings(prevData => {
      const updatedData = [...prevData];
      updatedData[index].discount = discountValue;
      if (!isNaN(discountValue)) {
        updatedData[index].totalPrice = calculateTotal(updatedData[index]).toFixed(2);
      }
      return updatedData;
    });
  };

  const calculateTotal = (item) => {
    // const totalPrice = parseFloat(item.totalPrice);
    const totalPrice = (item.unitPrice * item.qty);
    let discount = parseFloat(item.discount || 0);
    if (item.discountType === 'percentage') {

      if (discount < 0) { discount = 0; } else if (discount > 100) { discount = 100; }
      const percentageDiscount = ((totalPrice * discount) / 100).toFixed(2);
      return totalPrice - parseFloat(percentageDiscount);
    } else if (item.discountType === 'value') {

      if (discount < 0) { discount = 0; }
      return totalPrice - discount;
    } else {
      return totalPrice;
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg company_add">

            <div className="modal-content">

              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{modalName}</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal} ></button>
              </div>

              <div className="modal-body">
                <div className="tabs-container mb-3  justify-content-start">
                  <div className={`tab ${activeStep === 1 ? 'active' : ''}`} onClick={() => handleStepChange(1)}>General</div>
                  <div className={`tab ${activeStep === 2 ? 'active' : ''}`} onClick={() => { handleStepChange(2); }}>Assets</div>
                  <div className={`tab ${activeStep === 3 ? 'active' : ''}`} onClick={() => { handleStepChange(3); }}>Billing</div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className={activeStep === 1 ? "" : "d-none"}>

                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="name" className="col-form-label">Name</label>
                        <input type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`} id="name" name="name" value={formData.name} onChange={handleChange} />
                        {errors.name && (<div className="invalid-feedback">{errors.name}</div>)}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="email" className="col-form-label">Email</label>
                        <input type="text" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" name="email" value={formData.email} onChange={handleChange} />
                        {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="phone" className="col-form-label">Phone</label>
                        <input type="number" className={`form-control ${errors.phone ? 'is-invalid' : ''}`} id="phone" name="phone" value={formData.phone} onChange={handleChange} maxLength={10} pattern="[0-9]*" />
                        {errors.phone && (<div className="invalid-feedback">{errors.phone}</div>)}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="url" className="col-form-label">Website URL</label>
                        <input type="text" className={`form-control ${errors.url ? "is-invalid" : ""}`} id="url" name="url" value={formData.url} onChange={handleChange} />
                        {errors.url && (<div className="invalid-feedback"> {errors.url}</div>)}
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="description" className="col-form-label">Description</label>
                        <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id="description" name="description" value={formData.description} onChange={handleChange} />
                        {errors.description && (<div className="invalid-feedback"> {errors.description} </div>)}
                      </div>

                    </div>

                  </div>
                  <div className={activeStep === 2 ? "" : "d-none"}>

                    <div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className='col-12 mb-3'>
                            Location
                            <Select
                              options={location}
                              onChange={(val) => {
                                setSelectedLocationId({ value: val.value, label: val.label });  // Fetch floors for selected location
                                fetchFloorsForLocation(val.value);                              // Fetch floors for selected location
                                setSelectedFloorId(null);                                       // reset value Floor
                                setSelectedSeatType(null);                                      // reset value Seat
                                setSelectedVariantType(null);                                   // reset value Variant
                                setSelectedVariantTypeLabel(null);                              // reset value Label
                                setSelectedCardIsShow(false);
                              }}
                              placeholder='Select Location...'
                              className={`flex-1 w-full`}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                            />
                          </label>
                        </div>

                        <div className="col-md-6">
                          <label className='col-12 mb-3'>
                            Floor
                            <Select
                              options={floorOptions}
                              value={selectedFloorId ? floorOptions.find((option) => option.value === selectedFloorId.value) : null}
                              onChange={(val) => {
                                setSelectedFloorId({ value: val.value, label: val.label });
                                setSelectedSeatType(null);              // reset value Seat
                                setSelectedVariantType(null);           // reset value Variant
                                setSelectedVariantTypeLabel(null);      // reset value Label
                                setSelectedCardIsShow(false);
                              }}
                              placeholder='Select Floor...'
                              className={`flex-1 w-full`}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                            />
                          </label>
                        </div>

                        <div className="col-md-6">
                          <label className='col-12 mb-3'>
                            Select Type
                            <Select
                              options={seatTypeOptions}
                              value={selectedSeatType ? seatTypeOptions.find((option) => option.value === selectedSeatType) : null}
                              onChange={(val) => {
                                setSelectedSeatType(val.value);
                                fetchVariant(selectedLocationId.value, selectedFloorId.value, val.value);
                                setSelectedVariantType(null);           // reset value Variant
                                setSelectedVariantTypeLabel(null);      // reset value Label
                                setSelectedCardIsShow(false);
                              }}
                              placeholder='Select Seat Type...'
                              className={`flex-1 w-full`}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                            />
                          </label>
                        </div>

                        <div className="col-md-6">
                          <label className='col-12 mb-3'>
                            Select Variant
                            <Select
                              options={variantOptions}
                              value={selectedVariantType ? variantOptions.find((option) => option.value === selectedVariantType) : null}
                              onChange={(val) => {
                                setSelectedVariantType(val.value);      // Fetch floors for selected location
                                setSelectedVariantTypeLabel(val.label);
                                fetchSeatAndCabinTypes(selectedLocationId.value, selectedFloorId.value, val.value);
                              }}
                              placeholder='Select Seat Types...'
                              className={`flex-1 w-full`}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                            />
                          </label>
                        </div>

                        {selectedCardIsShow && (
                          <>
                            <h6>Available {selectedSeatType} : ({selectedVariantTypeLabel})</h6>
                            {cardOptions.length === 0 ? (
                              <div>No data available</div>
                            ) : (
                              cardOptions.map((slot, index) => (
                                <div
                                  key={index}
                                  onClick={() => !slot.status && handleSlotClick(slot.value, slot.label, slot.price, selectedLocationId, selectedFloorId, selectedSeatType, selectedVariantType, selectedVariantTypeLabel)}
                                  className={`time-slot-item`}
                                >
                                  <div className={`card ${slot.status ? 'disabled noSelected' : ''} ${selectedSlots.some(s => s.value === slot.value) ? 'selected' : ''}`}>
                                    <div className="card-body">
                                      <p className="card-title">{slot.label}</p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </>
                        )}
                      </div>

                      <button type="button" className={`btn btn-success mb-3 float-right form-control`} onClick={handleTableField}>Add Assets</button>

                    </div>

                    {selectedSummaryIsShow && (
                      <>
                        <div className='scroll_table-space'>
                          <table className='hover table-responsive'>
                            <thead>
                              <tr>
                                <th>S. No</th>
                                <th>Location</th>
                                <th>Floor</th>
                                <th>Type</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Action</th> {/* Add Action column header */}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.location.label}</td>
                                  <td>{item.floor.label}</td>
                                  <td>{item.type}</td>
                                  <td>{item.variant}</td>
                                  <td>1</td> {/* Hardcoded quantity for this example */}
                                  <td>{item.name}</td>
                                  <td>{item.price}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm "
                                      onClick={() => handleRemoveItem(index)} // Call handleRemoveItem function with index
                                    >
                                      Remove <i className='ri-delete-bin-line'></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}

                  </div>
                  <div className={activeStep === 3 ? "" : "d-none"}>
                    <div className='row'>

                      <div className="col-md-6">
                        <label htmlFor="legal_company" className="col-form-label">Legal Company Name:</label>
                        <input type="text" className={`form-control ${errors.legal_company ? 'is-invalid' : ''}`} id="legal_company" name='legal_company' value={formData.legal_company} onChange={handleChange} />
                        {errors.legal_company && <div className="invalid-feedback">{errors.legal_company}</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="billing_address" className="col-form-label">Billing Address:</label>
                        <input type="text" className={`form-control ${errors.billing_address ? 'is-invalid' : ''}`} id="billing_address" name='billing_address' value={formData.billing_address} onChange={handleChange} />
                        {errors.billing_address && <div className="invalid-feedback">{errors.billing_address}</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="gstn" className="col-form-label">GSTN:</label>
                        <input type="text" className={`form-control ${errors.gstn ? 'is-invalid' : ''}`} id="gstn" value={formData.gstn} name='gstn' onChange={handleChange} />
                        {errors.gstn && <div className="invalid-feedback">{errors.gstn}</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="pan" className="col-form-label">PAN:</label>
                        <input type="text" className={`form-control ${errors.pan ? 'is-invalid' : ''}`} id="pan" value={formData.pan} name='pan' onChange={handleChange} />
                        {errors.pan && <div className="invalid-feedback">{errors.pan}</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="cin" className="col-form-label">CIN:</label>
                        <input type="text" className={`form-control ${errors.cin ? 'is-invalid' : ''}`} id="cin" value={formData.cin} name='cin' onChange={handleChange} />
                        {errors.cin && <div className="invalid-feedback">{errors.cin}</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="tan" className="col-form-label">TAN:</label>
                        <input type="text" className={`form-control ${errors.tan ? 'is-invalid' : ''}`} id="tan" value={formData.tan} name='tan' onChange={handleChange} />
                        {errors.tan && <div className="invalid-feedback">{errors.tan}</div>}
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="billing_detail" className="col-form-label">Billing Details:</label>
                        {selectedSummaryIsShow && (
                          <>
                            <div className='scroll_table-space'>
                              <table className='hover table-responsive'>
                                <thead>
                                  <tr>
                                    <th>S. No</th>
                                    <th>Location</th>
                                    <th>Floor</th>
                                    <th>Type</th>
                                    <th>Variant</th>
                                    <th>Name</th>
                                    <th>Qty</th>
                                    <th>Price/Unit</th>
                                    <th>Discount Type</th>
                                    <th>Discount </th>
                                    <th>Total </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableDataBillings.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.location.label}</td>
                                      <td>{item.floor.label}</td>
                                      <td>{item.type}</td>
                                      <td>{item.variant}</td>
                                      <td>{item.selectedSlot}</td>
                                      <td>{item.qty}</td>
                                      <td>
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={item.unitPrice || 0}
                                          onChange={(e) => handlePriceChange(e, index)}
                                        />
                                      </td>
                                      <td>
                                        <select
                                          className="form-control select_discount"
                                          value={item.discountType || ''}
                                          onChange={(e) => handleDiscountTypeChange(e, index)}
                                        >
                                          <option value="">Select...</option>
                                          <option value="percentage">Percentage</option>
                                          <option value="value">Value</option>
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={item.discount || 0}
                                          onChange={(e) => handleDiscountChange(e, index)}
                                        />
                                      </td>

                                      <td>{(item.totalPrice)}</td>
                                    </tr>
                                  ))
                                  }
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td className="tfoter_subtotal" colspan="10">Subtotal</td>
                                    <td>{tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0).toFixed(2)}</td>
                                  </tr>
                                  <tr>
                                    <td className="tfoter_subtotal" colspan="10">CGST ({CGST}%)</td>
                                    <td>{(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (CGST / 100)).toFixed(2)}</td>

                                  </tr>
                                  <tr>
                                    <td className="tfoter_subtotal" colspan="10">SGST ({SGST}%)</td>
                                    <td>{(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (SGST / 100)).toFixed(2)}</td>
                                  </tr>
                                  <tr>
                                    <td className="tfoter_subtotal" colspan="10">Total</td>
                                    <td>{(
                                      parseFloat(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0)) +
                                      parseFloat(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (CGST / 100)) +
                                      parseFloat(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (SGST / 100))
                                    ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </>
                        )}
                      </div>



                    </div>
                  </div>
                  <div className="modal-footer">
                    {activeStep > 1 && (<button type="button" className="btn btn-secondary" onClick={handlePrevStep} disabled={isSubmittingError}>Previous</button>)}
                    {activeStep < 3 && (<button type="button" className="btn btn-primary" onClick={handleNextStep} disabled={isSubmittingError}>Next</button>)}
                    {activeStep === 3 && (<button type="submit" className="btn btn-primary" disabled={isSubmitting || isSubmittingError}>{isSubmitting ? 'Loading...' : 'Submit'}</button>)}
                  </div>
                </form>
              </div>

            </div>
          </div >
        </div >
      )
      }
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
