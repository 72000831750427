import React from "react";
import "../../../../../nav/components/location/component.css";
import { useNavigate } from "react-router-dom";

// Card for Cabins, Seats, Meeting Rooms
function Component({ data, setSelectedItem, setIsEditModal, setIsViewModal, handleDelete, typeTeamRoleObject, whoAmI, tableName }) {
  const navigate = useNavigate();

  function goToToFloors() {
    navigate(`/space/${data.space_id.urlName}/backend/resources/floors?locationId=${data._id}`, {
      state: data._id,
    });
  }

  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6'>
      <div className='main-location-card'>
        <div className='row'>
          <div className='col-2'>
            <button className='circle-btn-A Aicon'>{data.location.slice(0, 1)}</button>
          </div>
          <div className='col-10'>
            <div className='row'>
              <div className='col-12'>
                <div className='location-top'>
                  <div className='location-top2'>
                    <p className='space-txt'>{data.location}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div>
                  <p className='location-txt'>{data.address}</p>
                </div>
                <div className='botflx'>
                  <div className=' editicn'>
                    {whoAmI === "team" ? (
                      <>
                        {typeTeamRoleObject?.[tableName]?.view && (
                          <i
                            class='ri-eye-line view-icn'
                            onClick={() => {
                              setSelectedItem(data);
                              setIsViewModal(true);
                            }}
                          ></i>
                        )}
                        {typeTeamRoleObject?.[tableName]?.edit && (
                          <i
                            class='ri-pencil-line pencil-icn'
                            onClick={() => {
                              setSelectedItem(data);
                              setIsEditModal(true);
                            }}
                          ></i>
                        )}
                        {typeTeamRoleObject?.[tableName]?.delete && (
                          <i
                            class='ri-delete-bin-6-line trash-icn'
                            onClick={() => {
                              handleDelete(data._id, data?.location);
                            }}
                          ></i>
                        )}
                      </>
                    ) : (
                      <>
                        <i
                          class='ri-eye-line view-icn'
                          onClick={() => {
                            setSelectedItem(data);
                            setIsViewModal(true);
                          }}
                        ></i>
                        <i
                          class='ri-pencil-line pencil-icn'
                          onClick={() => {
                            setSelectedItem(data);
                            setIsEditModal(true);
                          }}
                        ></i>
                        <i
                          class='ri-delete-bin-6-line trash-icn'
                          onClick={() => {
                            handleDelete(data._id, data?.location);
                          }}
                        ></i>
                      </>
                    )}
                  </div>
                  <button className='view-btn-2' onClick={goToToFloors}>
                    Manage Floors
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
