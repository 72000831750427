import { useState, useEffect, useRef } from "react";
import { LoadingMutatingDots } from "../../components/ui/Loadings";
import useScrollBlock from "../../utils/useScrollBlock";
import ConfirmModal from "../../components/ui/modals/ConfirmDeleteModal";
import { url } from "../../../../lib/lib";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "../../../nav/components/location/component.css";
import FloorModal from "../../components/resource/floor/modals/Component";
import FloorCard from "../../components/resource/floor/cards/Component";
import { ToastContainer, toast } from "react-toastify";
import { validateSpaceId } from "../../../../utils/ValidateSpaceId";

function ActivityLog() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const { spaceId } = useParams();
  const location = useLocation();
  const [myLocations, setMyLocations] = useState([]);
  const [floors, setFloors] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 12,
    totalPages: 0,
  });

  const [selectedItem, setSelectedItem] = useState();
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const disableScroll = useScrollBlock();

  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState("All");
  const [isOpen1, setIsOpen1] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen1(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  // function customSort(a, b) {
  //   const locationA = a.location_id.location.toLowerCase();
  //   const locationB = b.location_id.location.toLowerCase();

  //   if (locationA < locationB) return -1;
  //   if (locationA > locationB) return 1;

  //   return a.floor_number - b.floor_number;
  // }

  const handleSelect1 = (locationId) => {
    setSelectedLocation(locationId);
    setIsOpen1(false);
  };

  useEffect(() => {
    if (location.state) {
      setSelectedLocation(location.state);
    } else {
      setSelectedLocation("All");
    }
  }, [location.state]);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    disableScroll(isAddModal || isEditModal || isDeleteModal);
  }, [isAddModal, isEditModal, isDeleteModal]);

  useEffect(() => {
    fetchMyLocations();
  }, []);

  const handleSearchExpand = () => {
    setIsExpanded(true);
  };

  const handleSearchClose = () => {
    setShowFilterMsg(false);
    setIsExpanded(false);
    setSearchTerm("");
    setSelectedLocation("All");

    fetchData();

    // if (selectedLocation === "All") {
    //   fetchData();
    // } else {
    //   handleSearch();
    // }
  };

  const fetchMyLocations = async () => {
    try {
      const requestBody = {
        filters: { space_id: spaceId },
      };

      const response = await fetch(url + "/location/view", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setMyLocations(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const sortingObject = {
        floor_number: 1,
      };

      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters: { space_id: spaceId },
        sorting: sortingObject,
      };

      const response = await fetch(url + "/floor/view", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(data.totalCount / prev.pageSize),
      }));

      setFloors(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAndValidateSpace = async () => {
      const response = await validateSpaceId(spaceId);
      if (response) {
        if (selectedLocation === "All") {
          fetchData();
        } else {
          handleSearch();
        }
      } else {
        navigate("/spaces");
      }
    };

    fetchDataAndValidateSpace();
  }, [pagination.pageIndex, pagination.pageSize, spaceId, selectedLocation]);

  const addFunc = async (item) => {
    try {
      const response = await fetch(url + "/floor/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify({ ...item, space_id: spaceId }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      const newItem = data.data[0];

      if (isExpanded) {
        setSearchTerm("");
        setIsExpanded(false);
        setShowFilterMsg(false);
      }
      if (selectedLocation !== "All") {
        setSelectedLocation("All");
      }

      handleHardRefresh();

      setIsLoading(false);
      toast.success("Added successfully!");
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = {
        floor_number: 1,
      };

      const requestBody = {
        page: 1,
        size: 5,
        filters: { space_id: spaceId },
        sorting: sortingObject,
      };
      const response = await fetch(url + "/floor/view", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        pageSize: 12,
        totalPages: Math.ceil(data.totalCount / pagination.pageSize),
      }));
      setFloors(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editFunc = async (newItem) => {
    try {
      const response = await fetch(url + "/floor/edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify({
          filters: { _id: selectedItem._id },
          data: { ...newItem },
        }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (selectedLocation !== "All") {
        setSelectedLocation("All");
      }

      if (isExpanded) {
        handleSearchClose();
      } else {
        const locationObj = myLocations.find((loc) => loc._id === newItem.location_id);

        setFloors((prev) => prev.map((item) => (item._id === newItem._id ? { ...newItem, location_id: locationObj } : item)));
        setIsLoading(false);
      }
      toast.success("Edited successfully!");
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const deleteFunc = async () => {
    try {
      const response = await fetch(url + "/floor/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify({ filters: { _id: selectedItem._id } }),
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (selectedLocation !== "All") {
        setSelectedLocation("All");
      }

      if (isExpanded) {
        handleSearchClose();
      } else {
        fetchData();
      }
      toast.success("Deleted successfully!");
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        search: searchTerm,
        page: 1,
        size: pagination.pageSize,
        filters: {
          space_id: spaceId,
          location_id: selectedLocation !== "All" ? selectedLocation : undefined,
        },
        sorting: {
          floor_number: 1,
        },
      };

      const response = await fetch(url + "/floor/view", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          type: "client",
          authorization: "$^CLiENt$%^&I&^@4567",
          key: "jsjdj744%5GSHr45",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log(responseData);
      setFloors(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPageRange = () => {
    let startIndex;

    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {
      startIndex = 0;
    }

    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

    if (startIndex > endIndex) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }));
      return;
    }

    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` of total ${total} (filtered ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex}${filterText} entries`;
  };

  return (
    <>
      <ToastContainer />
      {isAddModal && (
        <FloorModal
          saveFunc={addFunc}
          setIsModal={setIsAddModal}
          view={"add"}
          locations={myLocations.map((item) => ({
            label: item.location,
            value: item._id,
          }))}
        />
      )}
      {isEditModal && (
        <FloorModal
          editItem={selectedItem}
          saveFunc={editFunc}
          view={"edit"}
          setIsModal={setIsEditModal}
          locations={myLocations.map((item) => ({
            label: item.location,
            value: item._id,
          }))}
        />
      )}
      {isDeleteModal && <ConfirmModal heading={`Delete floor - ${selectedItem.floor_name} ?`} func={deleteFunc} setIsModal={setIsDeleteModal} />}
      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>Floors</p>
          </div>

          <div className='featured-flx1'>
            <div className='search-container'>
              <input
                ref={inputRef}
                className={`search-bar ${isExpanded ? "expanded" : ""}`}
                type='text'
                placeholder='Search Floors'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={handleSearchSubmit}
              />
              {!isExpanded && (
                <button className='search-button' onClick={handleSearchExpand}>
                  <i className='ri-search-line'></i>
                </button>
              )}
              {isExpanded && (
                <button className='search-button' onClick={handleSearchClose}>
                  <i className='ri-close-line'></i>
                </button>
              )}
            </div>
            <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
              <i className='ri-add-line'></i>
            </button>
          </div>
        </div>

        <div className='filter-box'>
          <div className='filter-sideline'></div>
          <div className='row'>
            <div className='col-sm-3'>
              <div className='select-data1' ref={selectRef}>
                <p className='slt-txt'> Location </p>
                <div className={`custom-select ${isOpen1 ? "open" : ""}`}>
                  <div className='form-group'>
                    <div className='select-top' onClick={() => setIsOpen1(!isOpen1)}>
                      {<span>{selectedLocation === "All" ? "All" : myLocations?.find((loc) => loc._id === selectedLocation)?.location}</span>}
                      {/* <span>
                        {selectedLocation
                          ? selectedLocation
                          : "Select Location"}
                      </span> */}
                      <i className='ri-arrow-down-s-line'></i> {/* Font Awesome dropdown icon */}
                    </div>
                    <ul
                      className='select-options'
                      style={{
                        maxHeight: "50vh",
                        overflowY: "auto",
                      }}
                    >
                      <li onClick={() => handleSelect1("All")}>All</li>
                      {myLocations?.map((loc) => (
                        <li key={loc._id} onClick={() => handleSelect1(loc._id)}>
                          {loc.location}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-sm-3'></div>
            <div className='col-sm-6'></div>
          </div>
        </div>

        {isLoading ? (
          <LoadingMutatingDots />
        ) : (
          <>
            <div className='row'>
              {floors?.map((item) => (
                <FloorCard key={item._id} data={item} setSelectedItem={setSelectedItem} setIsEditModal={setIsEditModal} setIsDeleteModal={setIsDeleteModal} />
              ))}
            </div>

            <div className='page-flx'>
              <div className='page-flx2'>
                <p className='page-txt1'>{renderPageRange()}</p>
              </div>
              {pagination.totalPages !== 1 && pagination.totalPages !== 0 && (
                <div className='button-container'>
                  {pagination.pageIndex !== 1 && (
                    <button
                      className='button'
                      id='button1'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex - 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-left-s-line'></i>
                    </button>
                  )}

                  <div className='button active' id='button1'>
                    {pagination.pageIndex}
                  </div>

                  {pagination.pageIndex !== pagination.totalPages && (
                    <button
                      className='button'
                      id='button4'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex + 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-right-s-line'></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ActivityLog;
