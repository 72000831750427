import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./component.css";


const Component = () => {
  return (
    <>
    <div className="footer1-last">
          <div className="foot1-flx1"></div>
          <div className="foot1-flx2">
            <img src="img/logowhite.png" className="img-fluid last-logo" />
            <p className="copyright">Copyright © 2024 <a href="https://encrobytes.com/"className='copyrightlink' target='blank'x>Encrobytes</a>. All Rights Reserved.</p>
          </div>
        </div>
    </>

    );
};

export default Component;