// import SideNav from '../components/sidenav/Component.js';
import Location from '../components/location/Component.js';
const Component = () => {
    return (
        <>
            <Location />
            
        </>
    );
};

export default Component;