import { useState } from "react";
import { toast } from "react-toastify";

export default function ConfirmModal({ isModalOpen, setIsModalOpen, modalName, heading, func }) {
  return (
    <>
      <div class='modal fade show' id='staticBackdrop' data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true' style={{ display: "block" }}>
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='staticBackdropLabel'>
                {modalName}
              </h5>
              <button onClick={() => setIsModalOpen(false)} type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div class='modal-body'>
              <p>{heading}</p>
            </div>
            <div class='modal-footer'>
              <button type='button' class='btn btn-secondary' data-bs-dismiss='modal' onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
              <button
                type='button'
                class='btn btn-primary'
                onClick={() => {
                  func();
                  setIsModalOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}
