import React from "react";

function Component({ data, floor_id, location_id, mapping_id, setSelectedItem, setIsEditModal, setIsDeleteModal }) {
  return (
    <>
      {data?.map((item, i) => (
        <div key={i} className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className='seatdesk-card'>
            <div class='cabin-sideline'></div>
            <div className='row'>
              <div className='col-12'>
                <div className='seatdesk-sheet'>
                  <div className='cabin-number'>
                    <i class='ri-keyboard-box-line keybord-icn'></i>
                  </div>
                  {/* <div className='seatdesk-editicn'>
                    <i class='ri-pencil-line seatdeskpencil-icn'></i>
                    <i class='ri-delete-bin-6-line seatdesktrash-icn'></i>
                  </div> */}
                </div>
                <p class='seatdesk-totalseat'>{item?.[item.type + "_id"]?.name}</p>
                <p class='seatdesk-desc-totalseat'>
                  Type: <span className='type-of-cabin'>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</span>
                </p>
                <div className='box-back'>
                  <div className='row'>
                    <div className='col-6 rgt-bdr'>
                      <p className='seatbox-txt'>Location</p>
                      <p className='seatbox-desc'>{location_id?.location}</p>
                    </div>
                    <div className='col-3 rgt-bdr'>
                      <center>
                        <p className='seatbox-txt'>Floor</p>
                        <p className='seatbox-desc'>{floor_id?.floor_number}</p>
                      </center>
                    </div>
                    <div className='col-3 p-0'>
                      <center>
                        <p className='seatbox-txt'>Units</p>
                        <p className='seatbox-desc'>{item?.[item.type + "_quantity"]}</p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Component;
