import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingMutatingDots } from "../../../ui/Loadings.js";

import "./component.css";

import Select from "react-select";
import { Country, State, City } from "country-state-city";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();

  const initialFormData = {
    location: "",
    seat_capacity: "",
    area: "",
    phone: "",
    email: "",
    address: "",
    working_hours_start: "",
    working_hours_end: "",
    description: "",
    img: "",
    country: { name: "India", isoCode: "IN" },
    state: { name: "", isoCode: "" },
    city: "",
    description: "",
    billing_detail: {
      legal_company: "",
      Billing_address: "",
      gstn: "",
      pan: "",
      cin: "",
      tan: "",
    },
    bank_detail: {
      account_name: "",
      account_number: "",
      ifsc: "",
      bank_name: "",
    },
    mail_detail: {
      smtp_email: "",
      smtp_password: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [selectValues, setSelectValues] = useState({
    country: { label: "India", value: "IN" },
    state: {
      label: "",
      value: "",
    },
    city: {
      label: "",
      value: "",
    },
  });

  useEffect(() => {
    const statesData = State.getStatesOfCountry(formData.country.isoCode);
    const statesList = statesData.map((state) => ({ label: state.name, value: state.isoCode }));

    setStates(statesList);
  }, [formData.country.isoCode]);

  useEffect(() => {
    const state = states.find((item) => item.value === formData.state.isoCode) || { label: "", value: "" };
    setSelectValues((prev) => ({
      ...prev,
      state,
    }));

    const citiesOfState = City.getCitiesOfState(formData.country.isoCode, formData.state.isoCode);

    setCities(citiesOfState.map((city) => ({ label: city.name, value: city.name })));
  }, [formData.country.isoCode, formData.state.isoCode, states]);

  useEffect(() => {
    const city = cities.find((item) => item.value === formData.city) || { label: "", value: "" };

    setSelectValues((prev) => ({
      ...prev,
      city,
    }));
  }, [formData.city, formData.state.isoCode, cities]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  const [activeStep, setActiveStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let location_Id = data;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleNextStep = () => {
    if (validateForm()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (activeStep === 1) {
      if (!formData.location.trim()) {
        errors.location = "Location is required";
        isValid = false;
      }
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && location_Id) {
      setLoading(true);
      fetchData(location_Id).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, location_Id]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          location: response_data.location,
          seat_capacity: response_data.seat_capacity,
          area: response_data.area,
          phone: response_data.phone,
          email: response_data.email,
          address: response_data.address,
          working_hours_start: response_data.working_hours_start,
          working_hours_end: response_data.working_hours_end,
          description: response_data.description,
          img: response_data.img,
          country: response_data.country,
          state: response_data.state,
          city: response_data.city,
          billing_detail: {
            legal_company: response_data.billing_detail.legal_company,
            Billing_address: response_data.billing_detail.Billing_address,
            gstn: response_data.billing_detail.gstn,
            pan: response_data.billing_detail.pan,
            cin: response_data.billing_detail.cin,
            tan: response_data.billing_detail.tan,
          },
          bank_detail: {
            account_name: response_data.bank_detail.account_name,
            account_number: response_data.bank_detail.account_number,
            ifsc: response_data.bank_detail.ifsc,
            bank_name: response_data.bank_detail.bank_name,
          },
          mail_detail: {
            smtp_email: response_data.mail_detail.smtp_email,
            smtp_password: response_data.mail_detail.smtp_password,
          },
          status: response_data.status || false,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.location_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            filters: { _id: current_id },
            data: {
              ...formData,
            },
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content' style={{ maxHeight: "85vh", overflowY: "scroll" }}>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `View ${modalName}` : `Edit ${modalName}`}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <LoadingMutatingDots />
                ) : (
                  <div className='modal-body'>
                    <div className='tabs-container mb-3 justify-content-start '>
                      <div className={`tab ${activeStep === 1 ? "active" : ""}`} onClick={() => handleStepChange(1)}>
                        Location Details
                      </div>
                      <div
                        className={`tab ${activeStep === 2 ? "active" : ""}`}
                        onClick={() => {
                          handleStepChange(2);
                        }}
                      >
                        Billing Details
                      </div>
                      <div
                        className={`tab ${activeStep === 3 ? "active" : ""}`}
                        onClick={() => {
                          handleStepChange(3);
                        }}
                      >
                        Bank Details
                      </div>
                      <div
                        className={`tab ${activeStep === 4 ? "active" : ""}`}
                        onClick={() => {
                          handleStepChange(4);
                        }}
                      >
                        Mail Details
                      </div>
                    </div>
                    {/* <!-- Location Info --> */}

                    <div className=''>
                      <div className={activeStep === 1 ? "" : "d-none"}>
                        <div className='row'>
                          <span className='sectionTitle text-primary border border-primary bg-white rounded-pill position-absolute start-50 translate-middle-x font-weight-bold'>Location Details</span>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='location'>Location</label>
                            <input disabled={mode === "view"} type='text' name='location' id='location' value={formData.location} onChange={handleChange} className={`form-control ${errors.location ? "is-invalid" : ""}`} />
                            {errors.location && <div className='invalid-feedback'>{errors.location}</div>}
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='seat_capacity'>Seat Capacity</label>
                            <input disabled={mode === "view"} type='text' name='seat_capacity' id='seat_capacity' value={formData.seat_capacity} onChange={handleChange} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='area'>Area</label>
                            <input disabled={mode === "view"} type='text' name='area' id='area' value={formData.area} onChange={handleChange} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='phone'>Phone</label>
                            <input disabled={mode === "view"} type='text' name='phone' id='phone' value={formData.phone} onChange={handleChange} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='email'>Email</label>
                            <input disabled={mode === "view"} type='email' name='email' id='email' value={formData.email} onChange={handleChange} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='address'>Address</label>
                            <input disabled={mode === "view"} type='text' name='address' id='address' value={formData.address} onChange={handleChange} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='working_hours_start'>Working hours start</label>
                            <input disabled={mode === "view"} type='text' name='working_hours_start' id='working_hours_start' value={formData.working_hours_start} onChange={handleChange} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='working_hours_end'>Working hours end</label>
                            <input disabled={mode === "view"} type='text' name='working_hours_end' id='working_hours_end' value={formData.working_hours_end} onChange={handleChange} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label for='img'>Image</label>
                            <input disabled={mode === "view"} type='file' accept='image/*' name='img' onChange={(e) => handleUpdateImg(e)} className='form-control' />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label>Country</label>
                            <Select
                              isDisabled={mode === "view"}
                              required
                              options={[{ label: "India", value: "IN" }]}
                              value={selectValues.country}
                              onChange={(val) => setFormData((prev) => ({ ...prev, country: { name: val.label, isoCode: val.value }, state: { name: "", isoCode: "" }, city: "" }))}
                              placeholder='Select Country...'
                              // className='form-select'
                            />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label>State</label>
                            <Select
                              required
                              isDisabled={!formData.country.isoCode || mode === "view"}
                              options={states}
                              value={selectValues.state}
                              onChange={(val) => setFormData((prev) => ({ ...prev, state: { name: val.label, isoCode: val.value }, city: "" }))}
                              placeholder='Select State...'
                              // className='form-select'
                              styles={customStyles}
                              menuPortalTarget={document.body}
                            />
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12 mb-1'>
                            <label>City</label>
                            <Select
                              required
                              isDisabled={!formData.country.isoCode || !formData.state.isoCode || mode === "view"}
                              options={cities}
                              value={selectValues.city}
                              onChange={(val) => setFormData((prev) => ({ ...prev, city: val.value }))}
                              placeholder='Select City...'
                              // className='form-select'
                              styles={customStyles}
                              menuPortalTarget={document.body}
                            />
                          </div>
                          <div className='col-lg-12 col-sm-12 col-12 mb-1'>
                            <label for='description'>Description</label>
                            <textarea disabled={mode === "view"} name='description' id='description' rows='3' value={formData.description} onChange={handleChange} className='form-control'></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Billing Details --> */}
                    <div className={activeStep === 2 ? "" : "d-none"}>
                      <div className='row'>
                        <span className='sectionTitle text-primary border border-primary bg-white rounded-pill position-absolute start-50 translate-middle-x font-weight-bold'>Billing Details</span>
                        {Object.keys(formData.billing_detail).map(
                          (key, i) =>
                            key !== "_id" && (
                              <div key={i} className='col-lg-4 col-sm-6 col-12 mb-1'>
                                <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                <input disabled={mode === "view"} type='text' name='billing_detail' className='form-control' value={formData.billing_detail[key]} onChange={(e) => setFormData((prev) => ({ ...prev, billing_detail: { ...prev.billing_detail, [key]: e.target.value } }))} />
                              </div>
                            )
                        )}
                      </div>
                    </div>

                    {/* <!-- Bank Details --> */}
                    <div className={activeStep === 3 ? "" : "d-none"}>
                      <div className='row'>
                        <span className='sectionTitle text-primary border border-primary bg-white rounded-pill position-absolute start-50 translate-middle-x font-weight-bold'>Bank Details</span>
                        {Object.keys(formData.bank_detail).map(
                          (key, i) =>
                            key !== "_id" && (
                              <div key={i} className='col-lg-4 col-sm-6 col-12 mb-1'>
                                <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                <input disabled={mode === "view"} type='text' name='bank_detail' value={formData.bank_detail[key]} onChange={(e) => setFormData((prev) => ({ ...prev, bank_detail: { ...prev.bank_detail, [key]: e.target.value } }))} className='form-control' />
                              </div>
                            )
                        )}
                      </div>
                    </div>

                    {/* <!-- Mail Details --> */}
                    <div className={activeStep === 4 ? "" : "d-none"}>
                      <div className='row'>
                        <span className='sectionTitle text-primary border border-primary bg-white rounded-pill position-absolute start-50 translate-middle-x font-weight-bold'>Mail Details</span>
                        {Object.keys(formData.mail_detail).map(
                          (key, i) =>
                            key !== "_id" && (
                              <div key={i} className=' col-sm-6 col-12 mb-1'>
                                <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                <input
                                  disabled={mode === "view"}
                                  type={key === "smtp_email" ? "email" : "text"}
                                  name='mail_detail'
                                  className='form-control'
                                  value={formData.mail_detail[key]}
                                  onChange={(e) => setFormData((prev) => ({ ...prev, mail_detail: { ...prev.mail_detail, [key]: e.target.value } }))}
                                />
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    {activeStep > 1 && (
                      <button type='button' className='btn btn-secondary' onClick={handlePrevStep}>
                        Previous
                      </button>
                    )}
                    {activeStep < 4 && (
                      <button type='button' className='btn btn-primary' onClick={handleNextStep}>
                        Next
                      </button>
                    )}
                    {activeStep === 4 && mode !== "view" && (
                      <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                        {isSubmitting ? "Loading..." : "Update"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
