import React from "react";
import "../../../../nav/components/location/component.css";

// Card for Cabins, Seats, Meeting Rooms
function Card1({ data, setSelectedItem, setIsEditModal, setIsViewModal, setIsDeleteModal, handleDelete, type = "other", typeTeamRoleObject, whoAmI, tableName }) {
  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6'>
      <div className='cabins-card'>
        <div class='cabin-sideline'></div>
        <div className='row'>
          <div className='col-12'>
            <div className='cabin-sheet'>
              <div className='cabin-number'>
                <i class='ri-keyboard-box-line keybord-icn'></i>
              </div>
              <div className='cabin-text'>
                <p className='cabin-totalseat'>{data?.name}</p>
              </div>
            </div>
            <div className='botflx-cabin'>
              <div className='cabin-count'>
                {data?.number_of_person} Desk
                {data?.number_of_person == 1 ? "" : "s"}
              </div>
              <div className='cabin-editicn'>
                {whoAmI === "team" ? (
                  <>
                    {typeTeamRoleObject?.[tableName]?.view && (
                      <i
                        id='cabin_view'
                        class='ri-eye-line view-icn'
                        onClick={() => {
                          setSelectedItem(data._id);
                          setIsViewModal(true);
                        }}
                      ></i>
                    )}
                    {typeTeamRoleObject?.[tableName]?.edit && (
                      <i
                        id='cabin_edit'
                        class='ri-pencil-line pencil-icn'
                        onClick={() => {
                          setSelectedItem(data._id);
                          setIsEditModal(true);
                        }}
                      ></i>
                    )}
                    {typeTeamRoleObject?.[tableName]?.delete && (
                      <i
                        id='cabin_delete'
                        class='ri-delete-bin-6-line trash-icn'
                        onClick={() => {
                          handleDelete(data._id, data?.name);
                        }}
                      ></i>
                    )}
                  </>
                ) : (
                  <>
                    <i
                      id='cabin_view'
                      class='ri-eye-line view-icn'
                      onClick={() => {
                        setSelectedItem(data._id);
                        setIsViewModal(true);
                      }}
                    ></i>
                    <i
                      id='cabin_edit'
                      class='ri-pencil-line pencil-icn'
                      onClick={() => {
                        setSelectedItem(data._id);
                        setIsEditModal(true);
                      }}
                    ></i>
                    <i
                      id='cabin_delete'
                      class='ri-delete-bin-6-line trash-icn'
                      onClick={() => {
                        handleDelete(data._id, data?.name);
                      }}
                    ></i>
                  </>
                )}
              </div>
              {/* <button className="view-btn-2">Manage Floors</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card1;
