import CryptoJS from "crypto-js";

// const url = "http://localhost:8080"; // For Testing
const url = "https://node.deskbox.encrobytes.com";     // For Producation

const userAuthorization = "898sf&lg7";
const localStorageEncryptionKey = "EncryptionKeyThisIsAStrongEncryptionKey123!";

const endpoints = {
  contact_us: "/contact_us",
  call_back: "/call_back",
  login: "/client/login",
  otp: "/client/otp",
};

const adminHeader = {
  "Content-Type": "application/json",
  type: "admin",
  authorization: "bdjsbdsjs7bHR@4567",
  key: "jsjdj744%5GSHr45",
};

const clientHeader = {
  "Content-Type": "application/json",
  type: "client",
  authorization: "$^CLiENt$%^&I&^@4567",
  key: "jsjdj744%5GSHr45",
};

const teamHeader = {
  "Content-Type": "application/json",
  type: "team",
  authorization: "$^tEAm$%^&)%T$%^6^",
  key: "J7TeA^&*mT%j*&^&(E",
};

const memberHeader = {
  "Content-Type": "application/json",
  type: "member",
  authorization: "MEMber*/&^(!}@RF",
  key: "jsjdj744%5GSHr45",
};

const adminpoints = {
  login: "/admin/login",
  logout: "/admin/logout",
  dashboard: "/dashboard/view",
  auth: "/admin/auth",

  client_view: "/client/view",
  client_add: "/client/add",
  client_edit: "/client/edit",
  client_delete: "/client/delete",
  client_count: "/client/count",

  space_count: "/space/count",
  space_view: "/space/view",
  space_add: "/space/add",
  space_edit: "/space/edit",
  space_delete: "/space/delete",

  client_ticket_view: "/client_ticket/view",
  client_ticket_add: "/client_ticket/add",
  client_ticket_edit: "/client_ticket/edit",
  client_ticket_delete: "/client_ticket/delete",
  client_ticket_status: "/client_ticket/status",

  client_ticket_history_view: "/client_ticket_history/view",
  client_ticket_history_add: "/client_ticket_history/add",
  client_ticket_history_edit: "/client_ticket_history/edit",
  client_ticket_history_delete: "/client_ticket_history/delete",

  subscription_view: "/subscription/view",
  subscription_add: "/subscription/add",
  subscription_edit: "/subscription/edit",
  subscription_delete: "/subscription/delete",

  otp: "/admin/otp",
};

const clientpoints = {
  member_dashboard: "/space_dashboard/view",
  dashboard: "/space_dashboard/view",
  logout: "/client/logout",

  amenitie_view: "/amenitie/view",
  amenitie_add: "/amenitie/add",
  amenitie_edit: "/amenitie/edit",
  amenitie_delete: "/amenitie/delete",

  area_view: "/area/view",
  area_add: "/area/add",
  area_edit: "/area/edit",
  area_delete: "/area/delete",

  cabin_view: "/cabin/view",
  cabin_add: "/cabin/add",
  cabin_edit: "/cabin/edit",
  cabin_delete: "/cabin/delete",

  seat_view: "/seat/view",
  seat_add: "/seat/add",
  seat_edit: "/seat/edit",
  seat_delete: "/seat/delete",

  cabin_seat_name_view: "/cabin_seat_name/view",
  cabin_seat_name_add: "/cabin_seat_name/add",
  cabin_seat_name_edit: "/cabin_seat_name/edit",
  cabin_seat_name_delete: "/cabin_seat_name/delete",

  occupancy_view: "/occupancy/view",

  meeting_view: "/meeting/view",
  meeting_add: "/meeting/add",
  meeting_edit: "/meeting/edit",
  meeting_delete: "/meeting/delete",

  meeting_name_view: "/meeting_name/view",
  meeting_name_add: "/meeting_name/add",
  meeting_name_edit: "/meeting_name/edit",
  meeting_name_delete: "/meeting_name/delete",

  meeting_room_booking_view: "/meeting_room_booking/view",
  meeting_room_booking_add: "/meeting_room_booking/add",
  meeting_room_booking_edit: "/meeting_room_booking/edit",
  meeting_room_booking_delete: "/meeting_room_booking/delete",

  meeting_room_booking_summary_edit: "/meeting_room_booking_summary/show",
  meeting_room_booking_summary_delete: "/meeting_room_booking_summary/view",

  client_view: "/client/view",
  client_add: "/client/add",
  client_edit: "/client/edit",
  client_delete: "/client/delete",
  client_count: "/client/count",

  space_count: "/space/count",
  space_view: "/space/view",
  space_add: "/space/add",
  space_edit: "/space/edit",
  space_delete: "/space/delete",

  role_add: "/role/add",
  role_count: "/role/count",
  role_view: "/role/view",
  role_edit: "/role/edit",
  role_delete: "/role/delete",

  team_add: "/team/add",
  team_count: "/team/count",
  team_view: "/team/view",
  team_edit: "/team/edit",
  team_delete: "/team/delete",
  team_logout: "/team/logout",

  location_add: "/location/add",
  location_count: "/location/count",
  location_view: "/location/view",
  location_edit: "/location/edit",
  location_delete: "/location/delete",

  floor_add: "/floor/add",
  floor_count: "/floor/count",
  floor_view: "/floor/view",
  floor_edit: "/floor/edit",
  floor_delete: "/floor/delete",

  mapping_add: "/mapping/add",
  mapping_count: "/mapping/count",
  mapping_view: "/mapping/view",
  mapping_edit: "/mapping/edit",
  mapping_delete: "/mapping/delete",

  company_add: "/company/add",
  company_count: "/company/count",
  company_view: "/company/view",
  company_edit: "/company/edit",
  company_delete: "/company/delete",

  invoice_send: "/invoice/send",
  invoice_add: "/invoice/add",
  invoice_count: "/invoice/count",
  invoice_view: "/invoice/view",
  invoice_edit: "/invoice/edit",
  invoice_delete: "/invoice/delete",

  member_add: "/member/add",
  member_count: "/member/count",
  member_view: "/member/view",
  member_team_view: "/member/member_team",
  member_edit: "/member/edit",
  member_delete: "/member/delete",
  member_logout: "/member/logout",

  subscription_view: "/subscription/view",
  subscription_add: "/subscription/add",
  subscription_edit: "/subscription/edit",
  subscription_delete: "/subscription/delete",

  client_ticket_view: "/client_ticket/view",
  client_ticket_add: "/client_ticket/add",
  client_ticket_edit: "/client_ticket/edit",
  client_ticket_delete: "/client_ticket/delete",
  client_ticket_status: "/client_ticket/status",

  client_ticket_history_view: "/client_ticket_history/view",
  client_ticket_history_add: "/client_ticket_history/add",
  client_ticket_history_edit: "/client_ticket_history/edit",
  client_ticket_history_delete: "/client_ticket_history/delete",

  ticket_add: "/ticket/add",
  ticket_count: "/ticket/count",
  ticket_view: "/ticket/view",
  ticket_edit: "/ticket/edit",
  ticket_delete: "/ticket/delete",
  ticket_status: "/ticket/status",

  ticket_history_view: "/ticket_history/view",
  ticket_history_add: "/ticket_history/add",
  ticket_history_edit: "/ticket_history/edit",
  ticket_history_delete: "/ticket_history/delete",

  visitor_view: "/visitor/view",
  visitor_add: "/visitor/add",
  visitor_edit: "/visitor/edit",
  visitor_delete: "/visitor/delete",

  enquiry_view: "/enquiry/view",
  enquiry_add: "/enquiry/add",
  enquiry_edit: "/enquiry/edit",
  enquiry_delete: "/enquiry/delete",

  expense_view: "/expense/view",
  expense_add: "/expense/add",
  expense_edit: "/expense/edit",
  expense_delete: "/expense/delete",

  post_view_all: "/post/all",
  post_view: "/post/view",
  post_add: "/post/add",
  post_edit: "/post/edit",
  post_delete: "/post/delete",

  post_comment_view: "/post_comment/view",
  post_comment_add: "/post_comment/add",
  post_comment_edit: "/post_comment/edit",
  post_comment_delete: "/post_comment/delete",
  post_comment_count: "/post_comment/count",

  post_like_view: "/post_like/view",
  post_like_add: "/post_like/add",
  post_like_edit: "/post_like/edit",
  post_like_count: "/post_like/count",

  event_view: "/event/view",
  event_add: "/event/add",
  event_edit: "/event/edit",
  event_delete: "/event/delete",

  offer_view: "/offer/view",
  offer_add: "/offer/add",
  offer_edit: "/offer/edit",
  offer_delete: "/offer/delete",

  event_apply_view: "/event_apply/view",
  event_apply_add: "/event_apply/add",
  event_apply_edit: "/event_apply/edit",
  event_apply_delete: "/event_apply/delete",

  billing_view: "/billing/view",
  billing_add: "/billing/add",
  billing_edit: "/billing/edit",
  billing_delete: "/billing/delete",
};

const teampoints = {
  login: "/team/login",
  logout: "/team/logout",
};

const memberpoints = {
  login: "/member/login",
  logout: "/member/logout",
};

const admin_prefix = "/Br!Y7o&p45fM/portal/";
const client_prefix = "/login";

function whoAmIHeaders(params) {
  if (params === "client") {
    let obj = {
      "Content-Type": "application/json",
      type: "client",
      authorization: "$^CLiENt$%^&I&^@4567",
      key: "jsjdj744%5GSHr45",
    };

    return obj;
  } else if (params === "team") {
    let obj = {
      "Content-Type": "application/json",
      type: "team",
      authorization: "$^tEAm$%^&)%T$%^6^",
      key: "jsjdj744%5GSHr45",
    };

    return obj;
  } else if (params === "member") {
    let obj = {
      "Content-Type": "application/json",
      type: "member",
      authorization: "MEMber*/&^(!}@RF",
      key: "jsjdj744%5GSHr45",
    };

    return obj;
  }

  // else if (params.type === 'member') {}
}

// Encryption function
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), localStorageEncryptionKey).toString();
};

// Decryption function
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, localStorageEncryptionKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return null;
  }
};

// Set data to local storage after encrypting
const setEncryptedDataToLocalStorage = (key, data) => {
  const encryptedData = encryptData(data);
  localStorage.setItem(key, encryptedData);
};

// Get data from local storage and decrypt
const getDecryptedDataFromLocalStorage = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    return decryptData(encryptedData);
  }
  return null;
};

export {
  url,
  userAuthorization,
  endpoints,
  adminpoints,
  clientpoints,
  teampoints,
  memberpoints,
  admin_prefix,
  client_prefix,
  adminHeader,
  clientHeader,
  teamHeader,
  memberHeader,
  whoAmIHeaders,
  setEncryptedDataToLocalStorage,
  getDecryptedDataFromLocalStorage,
};
