import React from 'react';
import SideNav from '../components/sidenav/Component.js';
const Layout = ({ children }) => {
    return (
        <>
            <div className="container-fluid side-padd">
                <div className="row main-parent">
                    <div className="col_parent_class col-xl-2 col-lg-3 first_child_width">
                        <SideNav />
                    </div>
                    <div className="col_parent_class col-xl-10 col-lg-9 second_child_width">
                        <div style={{ padding: '20px' }}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
