import Subscription from '../../components/setting/subscription/Component';
import { toast, ToastContainer } from "react-toastify";
export default function Component() {

  return (
    <>
      <ToastContainer />
      <Subscription />
    </>
  );
}