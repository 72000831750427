import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, useNavigate } from "react-router-dom";
import Dashboard from "../spaces/screens/dashboard/Dashboard.js";
import TeamPrivateRoutes from "../../utils/team/TeamPrivateRoute.js";
import TeamPublicRoutes from "../../utils/team/TeamPublicRoute.js";
import Login from "../../website/screens/TeamLogin.js";
import { validateSpaceIdForTeam } from "../../utils/ValidateSpaceId";
import { ToastContainer } from "react-toastify";

const TeamRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { spaceId } = useParams();

  // URL Convert into normal text
  function normalizeURL(url) {
    const decodedURL = decodeURIComponent(url);
    const normalizedURL = decodedURL.replace(/%20/g, ' ');
    return normalizedURL;
  }

  useEffect(() => {
    const fetchDataAndValidateSpace = async () => {

      const response = await validateSpaceIdForTeam(spaceId);
      if (!response) {
        navigate("/");
      }
    };

    fetchDataAndValidateSpace();
  }, [spaceId]);

  if (normalizeURL(pathname) === `/${spaceId}/team/login`)
    return (
      <>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<TeamPublicRoutes />}>
            <Route path='login' element={<Login />} />
          </Route>
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<TeamPrivateRoutes />}>
          <Route path='/spaces' element={<Dashboard />} />
        </Route>
      </Routes>
    </>
  );
};

export default TeamRoutes;
