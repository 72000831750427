import { Link, useNavigate, useLocation } from "react-router-dom";
import { url, admin_prefix, adminpoints } from '../../../../lib/lib';
import React, { useState, useEffect, useRef } from 'react';
import './component.css';

function Component() {
  const [activeLink, setActiveLink] = useState("dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sidebarRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const parts = pathname.split('/');
    const match = parts[3];
    setActiveLink(match);
  }, [location]);


  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);

  const handleLogout = async () => {
    try {
      let response = await fetch(url + adminpoints.logout, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
      });
      navigate(admin_prefix + 'login');
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  return (
    <div>
      <div id="mySidenav" className={`sidenav ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
        <a href="javascript:void(0)" className="closebtn" onClick={() => setIsSidebarOpen(false)}>&times;</a>
        <div className="desk-flx">
          <img src="/img/deskbox.png" className="img-fluid logo" alt="Desk Logo" />
        </div>

        <div className="top-marginn">
          <Link to={admin_prefix + 'dashboard'}
            className={`pages ${activeLink === 'dashboard' ? 'active' : ''}`}
            onClick={() => handleLinkClick('dashboard')}
          >
            <i className="mstr ri-home-4-line"></i>Dashboard
          </Link>

          <div className="dropdown">
            <Link to={admin_prefix + 'client'}
              className={`pages ${activeLink === 'client' ? 'active' : ''}`}
              onClick={() => handleLinkClick('client')}
            >
              <i className="mstr ri-group-line"></i>Client
            </Link>
          </div>

          <div className="dropdown">
            <Link to={admin_prefix + 'space'}
              className={`pages ${activeLink === 'space' ? 'active' : ''}`}
              onClick={() => handleLinkClick('space')}
            >
              <i className="mstr ri-building-2-fill"></i>Space
            </Link>
          </div>

          <div className="dropdown">
            <Link to={admin_prefix + 'subscription'}
              className={`pages ${activeLink === 'subscription' ? 'active' : ''}`}
              onClick={() => handleLinkClick('subscription')}
            >
              <i className="mstr ri-admin-line"></i>Subscription
            </Link>
          </div>

          {/* <div className="dropdown">
            <Link to={admin_prefix + 'team'}
              className={`pages ${activeLink === 'team' ? 'active' : ''}`}
              onClick={() => handleLinkClick('team')}
            >
              <i className="mstr ri-team-line"></i>Team
            </Link>
          </div> */}

          <div className="dropdown">
            <Link to={admin_prefix + 'support'}
              className={`pages ${activeLink === 'support' ? 'active' : ''}`}
              onClick={() => handleLinkClick('support')}
            >
              <i className="mstr ri-customer-service-2-line"></i>Support
            </Link>
          </div>

          {/* <div className="dropdown">
            <Link to={admin_prefix + 'setting'}
              className={`pages ${activeLink === 'setting' ? 'active' : ''}`}
              onClick={() => handleLinkClick('setting')}
            >
              <i className="mstr ri-settings-3-line"></i>Settings
            </Link>
          </div> */}

          <Link className="pages" onClick={handleLogout} ><i className="mstr ri-shut-down-line"></i>Logout</Link>

        </div>
      </div>

      <div className="logo-flx">
        <div className="logo-flx1">
          <img src="/img/deskbox.png" className="img-fluid logo2" alt="Desk Logo" />
        </div>
        <div className="logo-flx2">
          <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={toggleSidebar}>&#9776;</span>

        </div>
      </div>
    </div>
  );
}

export default Component;
