// import Topbar from "../components/topbar/Component.js";
import Nav2 from "../components/nav2/Component.js";
import Privacy from "../components/privacy/Component.js";
import Footer2 from "../components/footer2/Component.js";
import { Helmet } from "react-helmet";
import "./../css/style.css";

const Component = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - DeskBox</title>
      </Helmet>
      <Nav2 />
      <Privacy />
      <Footer2 />
    </>
  );
};

export default Component;
