import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import SearchableSelectComponentSpace from "../company_select/Component.js";
import { toast } from "react-toastify";
import Select from "react-select";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const CGST = 9;
  const SGST = 9;
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();
  const [companyName, setCompanyName] = useState("");

  const [formData, setFormData] = useState({
    company_id: "",
    billing_location_id: "",
    invoiceNumber: "",
    paymentStatus: "paid",
    billDate: "",
    dueDate: "",
    description: "",
    mail: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!companyName) { errors.company = "Company is required"; isValid = false; }
    if (!formData.company_id.trim()) { errors.company_id = "Company is required"; isValid = false; }
    if (!formData.billing_location_id.trim()) { errors.billing_location_id = "Billing Location is required"; isValid = false; }
    if (!formData.invoiceNumber.trim()) { errors.invoiceNumber = "Invoice Number is required"; isValid = false; }
    if (!formData.paymentStatus.trim()) { errors.paymentStatus = "Payment Status is required"; isValid = false; }
    if (!formData.billDate.trim()) { errors.billDate = "Bill Date is required"; isValid = false; }
    if (!formData.dueDate.trim()) { errors.dueDate = "Due Date is required"; isValid = false; }

    // if (!formData.name.trim()) { errors.name = "Name is required"; isValid = false; }
    // if (!formData.email.trim()) { errors.name = "Email is required"; isValid = false; }
    // if (!formData.phone.trim()) { errors.name = "Phone is required"; isValid = false; }
    // if (!formData.username.trim()) { errors.username = "Username is required"; isValid = false; }
    // if (!formData.password.trim()) { errors.password = "Password is required"; isValid = false; }

    setErrors(errors);
    return isValid;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCompanyName("");
    setFormData({
      name: "",
      email: "",
      phone: "",
      username: "",
      password: "",
      description: "",
      cgst: "",
      sgst: "",
      subtotal: "",
      total: "",
    });
  };

  /* These All are dropdown*/
  const [location, setLocation] = useState([]);                 // Location Drop-Down value show
  const [billingLocation, setBillingLocation] = useState([]);                 // Location Drop-Down value show
  const [floorOptions, setFloorOptions] = useState([]);         // Floor Drop-Down value show
  const seatTypeOptions = [{ value: 'cabin', label: 'Cabin' }, { value: 'seat', label: 'Seat' }];   // Seat Drop-Down value show
  const [variantOptions, setVariantOptions] = useState([]);     // Company Drop-Down value show
  const [companyDetails, setCompanyDetails] = useState('');
  const [cardOptions, setCardOptions] = useState([]);           // Company Drop-Down value show
  /* These All are dropdown*/
  const [invoiceIsShow, setInvoiceIsShow] = useState(false);
  const [selectedSummaryIsShow, setSelectedSummaryIsShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  // const [tableDataBillings, setTableDataBillings] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedBillingLocationId, setSelectedBillingLocationId] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState({ value: 'all', label: 'All' });
  const [selectedFloorId, setSelectedFloorId] = useState({ value: 'all', label: 'All' });
  // const [selectedLocationId, setSelectedLocationId] = useState(null);   // location select value
  // const [selectedFloorId, setSelectedFloorId] = useState(null);         // Floor select value
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCompanyLabel, setSelectedCompanyLabel] = useState(null);

  // fetchMyLocation is call when page is open or refresh this or you can say bydefault call (location dropdown value)
  const fetchMyLocation = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();

      const formattedLocations = [
        { value: 'all', label: 'All' }, // "All" option
        ...data.Data.map(location => ({
          value: location._id,
          label: location.location
        }))
      ];
      // const formattedLocations = data.Data.map(location => ({
      //   value: location._id,
      //   label: location.location
      // }));

      setLocation(formattedLocations); // Set location data in required format
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  const fetchMyBillingLocation = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.location_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();
      const formattedLocations = data.Data.map(location => ({
        value: location._id,
        label: location.location
      }));

      setBillingLocation(formattedLocations); // Set location data in required format
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  // fetchFloorsForLocation this function call when location select and floor dorpdown value show acording to particular those location. 
  const fetchFloorsForLocation = async (locationId) => {
    try {
      const filters = locationId === 'all' ? {} : { location_id: locationId };
      const response = await fetch(url + clientpoints.floor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();
      // const formattedFloors = data.Data.map(floor => ({
      //   value: floor._id,
      //   label: floor.floor_name
      // }));

      const formattedFloors = [
        { value: 'all', label: 'All' }, // Add "All" option for floors
        ...data.Data.map(floor => ({
          value: floor._id,
          label: floor.floor_name
        }))
      ];

      setFloorOptions(formattedFloors); // Set floor options
    } catch (error) {
      console.error("Error fetching floors:", error.message);
    }
  };

  const fetchCompany = async (locationId, floorId) => {
    try {

      const filters = {};

      if (locationId !== 'all') {
        filters.location_id = locationId;
      }

      if (floorId !== 'all') {
        filters.floor_id = floorId;
      }

      const response = await fetch(url + clientpoints.company_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();

      const formattedFloors = data.Data.map(floor => ({
        value: floor._id,
        label: floor.name
      }));

      setVariantOptions(formattedFloors); // Set floor options

    } catch (error) {
      console.error("Error fetching seat types:", error.message);
    }
  };

  const fetchCompanyDetails = async (company_id) => {
    try {

      const response = await fetch(url + clientpoints.company_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: company_id } }),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();
      console.log('Company Details : ', data.Data[0]);
      setCompanyDetails(data.Data[0]);
      setFormData(prevFormData => ({
        ...prevFormData,
        company_id: data.Data[0]._id
      }));
      setCompanyName(data.Data[0]._id);
      setInvoiceIsShow(true);
      setSelectedSummaryIsShow(true);
      fetchMyBillingLocation();
    } catch (error) {
      console.error("Error fetching seat types:", error.message);
    }
  };

  useEffect(() => {
    fetchMyLocation();
    fetchCompany('all', 'all');
  }, []);

  useEffect(() => { fetchFloorsForLocation(selectedLocationId.value); }, [selectedLocationId]);

  const handleLocationChange = (val) => {
    if (val) {
      setSelectedLocationId(val);
      setSelectedFloorId({ value: 'all', label: 'All' });
    }
  };

  const handleBillingLocationChange = (val) => {
    console.log(val);
    if (val) {
      setFormData(prevFormData => ({
        ...prevFormData,
        billing_location_id: val.value
      }));
    }
  };

  const handleFloorChange = (val) => { if (val) { setSelectedFloorId(val); } };

  const [tableDataBillings, setTableDataBillings] = useState([
    {
      description: '',
      qty: '',
      unitPrice: 0,
      discountType: '',
      discount: 0,
      totalPrice: 0
    }
  ]);

  const handleTableField = () => {
    const newItem = {
      description: '',
      qty: '',
      unitPrice: 0,
      discountType: '',
      discount: 0,
      totalPrice: 0
    };
    setTableDataBillings([...tableDataBillings, newItem]);
  };

  const handlePriceChange = (e, index) => {
    const updatedData = [...tableDataBillings];
    updatedData[index].unitPrice = parseFloat(e.target.value) || 0;
    updatedData[index].totalPrice = calculateTotal(updatedData[index]);
    setTableDataBillings(updatedData);
  };

  const handleDiscountTypeChange = (e, index) => {
    const updatedData = [...tableDataBillings];
    updatedData[index].discountType = e.target.value;
    updatedData[index].totalPrice = calculateTotal(updatedData[index]);
    setTableDataBillings(updatedData);
  };

  const handleDiscountChange = (e, index) => {
    const updatedData = [...tableDataBillings];
    updatedData[index].discount = parseFloat(e.target.value) || 0;
    updatedData[index].totalPrice = calculateTotal(updatedData[index]);
    setTableDataBillings(updatedData);
  };

  const handleQtyChange = (e, index) => {
    const updatedData = [...tableDataBillings];
    updatedData[index].qty = parseFloat(e.target.value) || 0;
    updatedData[index].totalPrice = calculateTotal(updatedData[index]);
    setTableDataBillings(updatedData);
  };

  const calculateTotal = (item) => {
    let totalPrice = item.qty * item.unitPrice;

    if (item.discountType === 'percentage') {
      totalPrice -= (totalPrice * item.discount) / 100;
    } else if (item.discountType === 'value') {
      totalPrice -= parseFloat(item.discount) || 0;
    }

    return totalPrice;
  };

  const handleRemoveRow = (index) => {
    if (tableDataBillings.length === 1) {
      return;
    }
    const updatedData = [...tableDataBillings];
    updatedData.splice(index, 1); // Remove one element at index
    setTableDataBillings(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);

        let mail = formData.mail;
        const subTotalPriceSum = tableDataBillings.reduce((accumulator, item) => { return accumulator + parseFloat(item.totalPrice); }, 0);
        const cgst = (subTotalPriceSum * CGST) / 100;
        const sgst = (subTotalPriceSum * SGST) / 100;
        const totalPriceSum = subTotalPriceSum + cgst + sgst;

        // convert back to number
        const formattedSubTotal = parseFloat(subTotalPriceSum);
        const formattedCGST = parseFloat(cgst);
        const formattedSGST = parseFloat(sgst);
        const formattedTotalPrice = parseFloat(totalPriceSum);

        let invoice_object = {
          urlName: spaceId,
          location_id: formData.billing_location_id,
          company_id: formData.company_id,
          bill_date: formData.billDate,
          due_date: formData.dueDate,
          invoice_number: formData.invoiceNumber,
          type: formData.paymentStatus,
          description: formData.description,
          billing: {
            subtotal: formattedSubTotal,
            cgst: formattedCGST,
            sgst: formattedSGST,
            total: formattedTotalPrice,
          },
          mail_send: mail,
          billing_detail: tableDataBillings
        };
        console.log('formData - ', invoice_object);
        const response = await fetch(url + clientpoints.invoice_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(invoice_object),
        });

        if (!response.ok) { navigate(`${client_prefix}`); }

        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
          // setCompanyName("");
          // setFormData({
          //   name: "",
          //   email: "",
          //   phone: "",
          //   username: "",
          //   password: "",
          //   description: "",
          // });
        } else {
          toast.error(responseData.Message);
        }
        // onRefresh();
        // setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };


  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg invoice_modal">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">

                    <div className="row">
                      <div className="col-md-4">
                        <label className='col-12 mb-3'>
                          Location
                          <Select
                            options={location}
                            value={selectedLocationId}
                            onChange={handleLocationChange}
                            placeholder='Select Location...'
                            className={`flex-1 w-full`}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            isLoading={isLoading}
                          />
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label className='col-12 mb-3'>
                          Floor
                          <Select
                            options={floorOptions}
                            value={selectedFloorId}
                            onChange={(val) => {
                              handleFloorChange(val);
                              fetchCompany(selectedLocationId.value, val.value);
                            }}
                            placeholder='Select Floor...'
                            className={`flex-1 w-full`}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            isLoading={isLoading}
                          />
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label className='col-12 mb-3'>
                          Company
                          <Select
                            options={variantOptions}
                            value={selectedCompanyId ? variantOptions.find((option) => option.value === selectedCompanyId) : null}
                            onChange={(val) => {
                              fetchCompanyDetails(val.value); // Example: Fetch company data
                              setSelectedCompanyId(val.value); // Set selected company ID
                              setSelectedCompanyLabel(val.label); // Set selected company label
                              fetchCompany('all', 'all');
                            }}
                            placeholder='Select Company...'
                            className={`flex-1 w-full`}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                          />
                        </label>
                      </div>
                    </div>
                    {invoiceIsShow && (
                      <>
                        <div className="mt-4 row">
                          <div className="col-md-6">
                            <p className="mb-0">Name: {companyDetails.name}</p>
                            <p className="mb-0">Address: {companyDetails.billing_detail && companyDetails.billing_detail.billing_address}</p>
                            <p className="mb-0">GSTN: {companyDetails.billing_detail && companyDetails.billing_detail.gstn}</p>
                            <p className="mb-0">PAN: {companyDetails.billing_detail && companyDetails.billing_detail.pan}</p>
                            <p className="mb-0">Contract Number: {companyDetails.phone}</p>
                          </div>
                        </div>
                      </>
                    )}

                    {selectedSummaryIsShow && (
                      <>
                        <div className="w-20"><button type="button" className={`btn btn-success mt-1 mb-3 float-end `} onClick={handleTableField}>Add Assets</button></div>
                        <div className='scroll_table-space'>
                          <table className='hover table-responsive'>
                            <thead>
                              <tr>
                                <th>S. No</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Discount Type</th>
                                <th>Discount</th>
                                <th>Total Price</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableDataBillings.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={item.description}
                                      onChange={(e) => {
                                        const updatedData = [...tableDataBillings];
                                        updatedData[index].description = e.target.value;
                                        setTableDataBillings(updatedData);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={item.qty}
                                      onChange={(e) => {
                                        const updatedData = [...tableDataBillings];
                                        updatedData[index].qty = parseFloat(e.target.value) || 0;
                                        setTableDataBillings(updatedData);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={item.unitPrice || 0}
                                      onChange={(e) => handlePriceChange(e, index)}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className="form-control select_discount"
                                      value={item.discountType || ''}
                                      onChange={(e) => handleDiscountTypeChange(e, index)}
                                    >
                                      <option value="">Select...</option>
                                      <option value="percentage">Percentage</option>
                                      <option value="value">Value</option>
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={item.discount || 0}
                                      onChange={(e) => handleDiscountChange(e, index)}
                                    />
                                  </td>
                                  <td>{item.totalPrice}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td className="tfoter_subtotal" colspan="6">Subtotal</td>
                                <td>{tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0).toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td className="tfoter_subtotal" colspan="6">CGST ({CGST}%)</td>
                                <td>{(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (CGST / 100)).toFixed(2)}</td>

                              </tr>
                              <tr>
                                <td className="tfoter_subtotal" colspan="6">SGST ({SGST}%)</td>
                                <td>{(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (SGST / 100)).toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td className="tfoter_subtotal" colspan="6">Total</td>
                                <td>{(
                                  parseFloat(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0)) +
                                  parseFloat(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (CGST / 100)) +
                                  parseFloat(tableDataBillings.reduce((totalPrice, item) => totalPrice + parseFloat(item.totalPrice), 0) * (SGST / 100))
                                ).toFixed(2)}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </>
                    )}

                  </div>

                  {invoiceIsShow && (
                    <>

                      <div className="row">
                        <div className="col-md-4">
                          <label className='col-12 mb-3'>
                            Billing Location
                            <Select
                              options={billingLocation}
                              value={selectedBillingLocationId}
                              onChange={handleBillingLocationChange}
                              placeholder='Select Location...'
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              isLoading={isLoading}
                              className={`form-control flex-1 w-full form-control mt-2 p-0 ${errors.billing_location_id ? "is-invalid" : ""}`}
                            />
                            {errors.billing_location_id && (<div className="invalid-feedback">{errors.billing_location_id}</div>)}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label>Invoice Number:</label>
                          <input
                            type="text"
                            name="invoiceNumber"
                            value={formData.invoiceNumber}
                            onChange={handleChange}
                            className={`form-control ${errors.invoiceNumber ? "is-invalid" : ""}`}
                          />
                          {errors.invoiceNumber && (<div className="invalid-feedback">{errors.invoiceNumber}</div>)}
                        </div>
                        <div className="col-md-4">
                          <label>Payment Status:</label>
                          <select
                            name="paymentStatus"
                            value={formData.paymentStatus}
                            onChange={handleChange}
                            styles={customStyles}
                            className={`form-select ${errors.paymentStatus ? "is-invalid" : ""}`}
                          >
                            <option value="paid">Paid</option>
                            <option value="unpaid">Unpaid</option>
                          </select>
                          {errors.paymentStatus && (<div className="invalid-feedback">{errors.paymentStatus}</div>)}
                        </div>

                        <div className="col-md-4">
                          <label>Bill Date:</label>
                          <input
                            type="date"
                            name="billDate"
                            value={formData.billDate}
                            onChange={handleChange}
                            className={`form-control ${errors.billDate ? "is-invalid" : ""}`}
                          />
                          {errors.billDate && (<div className="invalid-feedback">{errors.billDate}</div>)}
                        </div>
                        <div className="col-md-4">
                          <label>Due Date:</label>
                          <input
                            type="date"
                            name="dueDate"
                            value={formData.dueDate}
                            onChange={handleChange}
                            className={`form-control ${errors.dueDate ? "is-invalid" : ""}`}
                          />
                          {errors.dueDate && (<div className="invalid-feedback">{errors.dueDate}</div>)}
                        </div>
                        <div className="col-md-4">
                          <div className="form-check d-flex justify-content-around align-items-center" style={{ 'margin': '45px' }}>
                            <input
                              type="checkbox"
                              id="sendEmailCheckbox"
                              name="sendEmail"
                              checked={formData.sendEmail}
                              onChange={handleCheckboxChange}
                              className="form-check-input"
                            />
                            <label
                              htmlFor="sendEmailCheckbox"
                              className="form-check-label m-0"
                            >
                              Send Email
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label>Description:</label>
                          <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className={`form-control`}
                            rows="3"
                          ></textarea>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </>
                  )}

                </form>
              </div>
            </div>
          </div>
        </div >
      )
      }
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
