import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { url, clientpoints, client_prefix } from "../../../../lib/lib";

import "./component.css";
const Component = ({ getTitle = "Monthly Revenue", getValue = "0", page, filters = '' }) => {
    const { spaceId } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
        // const timeout = setTimeout(() => {
        // }, 1000);
        // return () => clearTimeout(timeout);
    }, [getValue]);

    return (
        <>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                {/* <Link className='blackcolor' to={`${client_prefix}${page}${filters ? `?filter=${filters}` : ''}`}> */}
                <Link className='blackcolor' to={`/space/${spaceId}/backend/${page}${filters ? `?filter=${filters}` : ''}`}>
                    <div className='dashboard-card'>
                        <div className="dashboard-icn">
                            <i class="ri-dashboard-line des-icn"></i>
                        </div>
                        <div className='dashboard_secondcard'>
                            {loading ? (
                                <div className="spinner-grow custom-spinner" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <>
                                    <p className='dash-data'>{getValue}</p>
                                    <p className='dash-data1'>{getTitle}</p>
                                </>
                            )}
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}

export default Component

