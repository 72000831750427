import TableComponent from "../components/support_ui/table/Component.js";

const Support = () => {

    return (
        <>
            <TableComponent />
        </>
    );
};

export default Support;