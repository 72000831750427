import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="section2" id="features">
            <center><p className="deskbox-feature">
           Explore Our Features
            </p>
            <hr className="deskbox-line"></hr>
            </center>


      <div className="container">
       
        <div className="row feature1-top">
          <div className="col-lg-6"> 
          <div className="feature-padd">
         <p className="deskbox-txt2">Locations & Members Management</p>
         <p className="deskbox-descri">Seamless experience that simplifies location and membership management.</p>
         <div className="desk-top-2">
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Multiple Locations Management</p>
                <p className="feature1-txt2">Create and manage unlimited multiple locations for your coworking space.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Companies & Members Management</p>
                <p className="feature1-txt2">Create and manage multiple companies and their members across all locations.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Day Pass Bookings & Tracking</p>
                <p className="feature1-txt2">Experience hassle-free tracking of your Day Passes with our user friendly interface.</p>
            </div>
         </div>
         </div>
           </div>
           </div>
          <div className="col-lg-6">
          <center><img src="img/locations.png" className="img-fluid feature-img"/></center>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Component;
