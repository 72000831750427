import MasterSeat from '../../components/master/seat/table/Component';
import { toast, ToastContainer } from "react-toastify";
export default function Component() {

  return (
    <>
      <ToastContainer />
      <MasterSeat name="Seat Types" />
    </>
  );
}