import React, { useState, useEffect, useRef, useContext } from 'react';
import { url, clientpoints, clientHeader, client_prefix, whoAmIHeaders } from '../../../../../../lib/lib.js';
import { AuthUserContext, typeTeamRoleObject } from '../../../../../../lib/AuthUserContext.js';
import { useParams, useNavigate } from "react-router-dom";

const Component = ({ locationName, setFloorName, searchValueProps, suggestionsProps, showDropdownProps, form = true }) => {

    const { spaceId } = useParams();
    const navigate = useNavigate();

    const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
    const { typeTeamRoleObject } = useContext(AuthUserContext);
    let pageHeaders = whoAmIHeaders(whoAmI);
    let getLocalStorageData;

    useEffect(() => {
        if (!whoAmI) {
            getLocalStorageData = getDecryptedDataFromLocalStorage('type');
            setWhoAmI(getLocalStorageData);
            pageHeaders = whoAmIHeaders(getLocalStorageData);
        } else {
            pageHeaders = whoAmIHeaders(whoAmI);
        }
    }, []);


    const { searchValueFloor, setSearchValueFloor } = searchValueProps;
    const { suggestionsFloor, setSuggestionsFloor } = suggestionsProps;
    const { showDropdownFloor, setShowDropdownFloor } = showDropdownProps;

    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    let [countIndex, setCountIndex] = useState(0);
    const loadOptions = async () => {
        try {
            const response = await fetch(url + clientpoints.floor_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: searchValueFloor, filters: { location_id: locationName } }),
                credentials: 'include'
            });
            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsFloor(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
    const [perPage, setPerPage] = useState(10); // State variable to track entries per page
    const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

    const fetchDataForPage = async (page) => {
        try {
            const response = await fetch(url + clientpoints.floor_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', filters: { location_id: locationName }, page: page, size: perPage, sorting: { "createdAt": "1" } }), // Fetch data for specific page
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const newData = responseData.Data;

            setSuggestionsFloor(prevSuggestions => [...prevSuggestions, ...newData]);
            setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleScroll = async (event) => {
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const clientHeight = event.target.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - 10) {

            const nextPageCount = currentPage + 1;

            if (nextPageCount <= totalPages) {
                setCurrentPage(nextPageCount);
                fetchDataForPage(nextPageCount);
            }
        }
    };

    const loadOptionsOnClick = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(url + clientpoints.floor_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', space_id: spaceId, filters: { location_id: locationName }, page: countIndex + 1, sorting: { "createdAt": "1" } }), // Increment countIndex by 1
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsFloor(data);
            setTotalPages(responseData.totalCount);
            setSearchValueFloor("");
        } catch (error) {
            console.error('Error fetching options:', error);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleOptionClick = (selectedOption) => {
        if (selectedOption) {
            const { floor_name, _id } = selectedOption;
            setSearchValueFloor(floor_name);
            setFloorName(_id);
            setShowDropdownFloor(floor_name);
            setSuggestionsFloor([]);
        }
        else if (selectedOption == "") {
            setSearchValueFloor("All Floor");
            setShowDropdownFloor("All Floor");
            setFloorName('');
            setSuggestionsFloor([]);
        }
        else {
            setSearchValueFloor('');
            setShowDropdownFloor('');
            setFloorName('');
            setSuggestionsFloor([]);
        }
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                setSuggestionsFloor([]);
                setCurrentPage(1);
                setPerPage(10);
                setTotalPages(0)
                setSearchValueFloor(showDropdownFloor);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showDropdownFloor]);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <i className="ri-arrow-down-s-line ab1" onClick={loadOptionsOnClick}></i>
            <input
                autoComplete="off"
                ref={inputRef}
                placeholder={form ? "Search for Floor..." : "Select Floor"}
                onChange={e => setSearchValueFloor(e.target.value)}
                value={searchValueFloor}
                onKeyUp={() => loadOptions(searchValueFloor)}
                onClick={loadOptionsOnClick}
                id="spaceName"
                className="search-input form-control customSelect w-100"
            />
            {isLoading ? (
                <div className="spinner-border text-primary my_spin" role="status"><span className="sr-only">Loading...</span></div>
            ) : suggestionsFloor.length > 0 && (
                <ul
                    id="dropdownMenu"
                    onScroll={handleScroll}
                    style={{ overflowY: 'scroll', height: '200px' }}
                    className="dropdown-menu mydrop_hgt"
                >
                    {form ? (
                        <li onClick={() => handleOptionClick('')}>All Floor<span className="hidden" data-client-id={''}></span></li>
                    ) : ('')}
                    {suggestionsFloor.length > 0 ? (
                        suggestionsFloor.map((floor) => (
                            <li key={floor._id} onClick={() => handleOptionClick(floor)}>
                                {floor.floor_name}
                                {floor.floor_number ? ` (${floor.floor_number}) ` : ''}
                                <span className="hidden" data-floor-id={floor._id} data-client-id={floor.client_id ? floor.client_id._id : ''}></span>
                            </li>
                        ))
                    ) : (
                        <li>No data found</li>
                    )}

                </ul>
            )}
        </div>
    );
};

export default Component;