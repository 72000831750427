import React, { useState, useEffect, useRef, useContext } from 'react';
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from '../../../../../../lib/lib.js';
import { useParams, useNavigate } from "react-router-dom";
import { AuthUserContext } from '../../../../../../lib/AuthUserContext.js';

const Component = ({ setCompanyNameProps, searchValueProps, suggestionsProps, showDropdownProps, form = true, setMemberName, setSearchValueMember, setShowDropdownMember, locationName }) => {
    const navigate = useNavigate();
    const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
    const { typeTeamRoleObject } = useContext(AuthUserContext);
    let pageHeaders = whoAmIHeaders(whoAmI);
    let getLocalStorageData;

    useEffect(() => {
        if (!whoAmI) {
            getLocalStorageData = getDecryptedDataFromLocalStorage('type');
            setWhoAmI(getLocalStorageData);
            pageHeaders = whoAmIHeaders(getLocalStorageData);
        } else {
            pageHeaders = whoAmIHeaders(whoAmI);
        }
    }, []);

    const { spaceId } = useParams();
    const [companyName, setCompanyName] = useState([]);
    const { searchValueCompany, setSearchValueCompany } = searchValueProps;
    const { suggestionsCompany, setSuggestionsCompany } = suggestionsProps;
    const { showDropdownCompany, setShowDropdownCompany } = showDropdownProps;

    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    let [countIndex, setCountIndex] = useState(0);

    const loadOptions = async () => {
        try {
            const response = await fetch(url + clientpoints.company_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ urlName: spaceId, search: searchValueCompany }),
                credentials: 'include'
            });
            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsCompany(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const fetchDataForPage = async (page) => {
        try {
            const response = await fetch(url + clientpoints.company_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', urlName: spaceId, page: page, size: perPage, sorting: { "createdAt": "1" } }), // Fetch data for specific page
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const newData = responseData.Data;

            setSuggestionsCompany(prevSuggestions => [...prevSuggestions, ...newData]);
            setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleScroll = async (event) => {
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const clientHeight = event.target.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - 10) {

            const nextPageCount = currentPage + 1;

            if (nextPageCount <= totalPages) {
                setCurrentPage(nextPageCount);
                fetchDataForPage(nextPageCount);
            }
        }
    };

    const loadOptionsOnClick = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(url + clientpoints.company_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', urlName: spaceId, filters: { location_id: locationName.map(location => location._id) }, page: countIndex + 1, sorting: { "createdAt": "1" } }), // Increment countIndex by 1
                // body: JSON.stringify({ search: '', filters: { space_id: spaceId, location_id: locationName }, page: countIndex + 1, sorting: { "createdAt": "1" } }), // Increment countIndex by 1
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsCompany(data);
            setTotalPages(responseData.totalCount);
            setSearchValueCompany("");
        } catch (error) {
            console.error('Error fetching options:', error);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleOptionClick = (selectedOption) => {
        if (selectedOption) {
            const { name, _id } = selectedOption;
            setSearchValueCompany(name);
            setCompanyName(_id);

            const existingIndex = companyName.findIndex(company => company._id === _id);
            if (existingIndex === -1) {
                setCompanyName([...companyName, { name, _id }]);
            }
            setShowDropdownCompany(name);
            setSuggestionsCompany([]);
        }
        else if (selectedOption == "") {
            setSearchValueCompany("All Company");
            setShowDropdownCompany("All Company");
            setCompanyName('');
            setSuggestionsCompany([]);
        }
        else {
            setSearchValueCompany('');
            setShowDropdownCompany('');
            setCompanyName('');
            setSuggestionsCompany([]);
        }
        setMemberName('');
        setSearchValueMember('');
        setShowDropdownMember('');
    };


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                setSuggestionsCompany([]);
                setCurrentPage(1);
                setPerPage(10);
                setTotalPages(0)
                setSearchValueCompany(showDropdownCompany);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showDropdownCompany]);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <i className="ri-arrow-down-s-line ab1" onClick={loadOptionsOnClick}></i>
            <input
                autoComplete="off"
                ref={inputRef}
                placeholder={form ? "Search for Company..." : "Select Company"}
                onChange={e => setSearchValueCompany(e.target.value)}
                value={searchValueCompany}
                onKeyUp={() => loadOptions(searchValueCompany)}
                onClick={loadOptionsOnClick}
                id="spaceName"
                className="search-input form-control customSelect w-100"
            />
            {isLoading ? (
                <div className="spinner-border text-primary my_spin" role="status"><span className="sr-only">Loading...</span></div>
            ) : suggestionsCompany.length > 0 && (
                <ul
                    id="dropdownMenu"
                    onScroll={handleScroll}
                    style={{ overflowY: 'scroll', height: '200px' }}
                    className="dropdown-menu mydrop_hgt"
                >
                    {form ? (
                        <li onClick={() => handleOptionClick('')}>All Company<span className="hidden" data-client-id={''}></span></li>
                    ) : ('')}
                    {suggestionsCompany.length > 0 ? (
                        suggestionsCompany.map((company) => (
                            <li key={company._id} onClick={() => handleOptionClick(company)}>
                                {company.name}
                                <span className="hidden" data-company-id={company._id} data-client-id={company.client_id ? company.client_id._id : ''}></span>
                            </li>
                        ))
                    ) : (
                        <li>No data found</li>
                    )}

                </ul>
            )}
        </div>
    );
};

export default Component;