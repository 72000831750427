import Company from '../../components/client/company/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <Company name="All Companies" />
        </>
    );
}