import React, { useState, useEffect, useRef, useContext } from "react";
import "./component.css"; // Import your CSS file
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage } from "../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../lib/AuthUserContext.js";

function Component({ roleObject = "", role = "" }) {
  const sidebarRef = useRef();
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDrop, setActiveDrop] = useState("");
  const { pathname } = useLocation();
  const isMastersDropdownOptionActive = () => {
    return (
      pathname.includes("masters/cabins") ||
      pathname.includes("masters/seats") ||
      pathname.includes("masters/meeting-rooms") ||
      pathname.includes("masters/areas") ||
      pathname.includes("masters/amenities")
    );
  };
  const isResourcesDropdownOptionActive = () => {
    return pathname.includes("resources/locations") || pathname.includes("resources/floors") || pathname.includes("resources/assets-mapping");
  };
  const isTeamDropdownOptionActive = () => {
    return pathname.includes("team/roles") || pathname.includes("team/staff");
  };
  const isClientsDropdownOptionActive = () => {
    return pathname.includes("client/companies") || pathname.includes("client/members") || pathname.includes("client/credits-history") || pathname.includes("client/parking");
  };
  const isInvoicesDropdownOptionActive = () => {
    return pathname.includes("invoices/all-invoices");
  };
  const isOccupancyDropdownOptionActive = () => {
    return pathname.includes("occupancy/reports");
  };
  const isCommunityDropdownOptionActive = () => {
    return pathname.includes("community/posts") || pathname.includes("community/events") || pathname.includes("community/offers") || pathname.includes("community/bulk-email");
  };
  const isBookingsDropdownOptionActive = () => {
    return pathname.includes("bookings/meeting-rooms") || pathname.includes("bookings/day-pass");
  };
  const isVisitorsDropdownOptionActive = () => {
    return pathname.includes("visitors/all-visitors");
  };
  const isEnquiriesDropdownOptionActive = () => {
    return pathname.includes("enquiries/all-enquiries");
  };
  const isExpensesDropdownOptionActive = () => {
    return pathname.includes("expenses/all-expenses");
  };
  const isSupportDropdownOptionActive = () => {
    return pathname.includes("support/client-support") || pathname.includes("support/deskbox-support");
  };
  const isSettingsDropdownOptionActive = () => {
    return pathname.includes("settings/profile") || pathname.includes("settings/subscription");
  };

  useEffect(() => {
    setActiveDrop(pathname);
  }, [pathname]);

  const [mastersDropdownOpen1, setMastersDropdownOpen1] = useState(false);
  const [mastersDropdownOpen, setMastersDropdownOpen] = useState(false);

  const [mastersDropdownOpeninvoice, setMastersDropdownOpeninvoice] = useState(false);
  const [mastersDropdownOpenexpense, setMastersDropdownOpenexpense] = useState(false);
  const [mastersDropdownOpenvisitor, setMastersDropdownOpenvisitor] = useState(false);
  const [mastersDropdownOpenenqurie, setMastersDropdownOpenenqurie] = useState(false);
  const [mastersDropdownOpenoccupancy, setMastersDropdownOpenoccupancy] = useState(false);
  const [mastersDropdownOpenlocation, setMastersDropdownOpenlocation] = useState(false);
  const [mastersDropdownOpensetting, setMastersDropdownOpensetting] = useState(false);
  const [mastersDropdownOpenbooking, setMastersDropdownOpenbooking] = useState(false);
  const [mastersDropdownOpensupport, setMastersDropdownOpensupport] = useState(false);
  const [mastersDropdownOpenclient, setMastersDropdownOpenclient] = useState(false);
  const [mastersDropdownOpencommunity, setMastersDropdownOpencommunity] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      // If the click is outside the sidebar, close the sidebar
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);
  const toggleDropdown = () => {
    setMastersDropdownOpen(!mastersDropdownOpen);
  };
  const toggleDropdowninvoice = () => {
    setMastersDropdownOpeninvoice(!mastersDropdownOpeninvoice);
  };
  const toggleDropdownexpense = () => {
    setMastersDropdownOpenexpense(!mastersDropdownOpenexpense);
  };
  const toggleDropdownvisitor = () => {
    setMastersDropdownOpenvisitor(!mastersDropdownOpenvisitor);
  };
  const toggleDropdownenqurie = () => {
    setMastersDropdownOpenenqurie(!mastersDropdownOpenenqurie);
  };
  const toggleDropdownlocation = () => {
    setMastersDropdownOpenlocation(!mastersDropdownOpenlocation);
  };
  const toggleDropdown1 = () => {
    setMastersDropdownOpen1(!mastersDropdownOpen1);
  };
  const toggleDropdownoccupancy = () => {
    setMastersDropdownOpenoccupancy(!mastersDropdownOpenoccupancy);
  };
  const toggleDropdownsetting = () => {
    setMastersDropdownOpensetting(!mastersDropdownOpensetting);
  };
  const toggleDropdownbooking = () => {
    setMastersDropdownOpenbooking(!mastersDropdownOpenbooking);
  };
  const toggleDropdownsupport = () => {
    setMastersDropdownOpensupport(!mastersDropdownOpensupport);
  };
  const toggleDropdownclient = () => {
    setMastersDropdownOpenclient(!mastersDropdownOpenclient);
  };
  const toggleDropdowncommunity = () => {
    setMastersDropdownOpencommunity(!mastersDropdownOpencommunity);
  };

  const handleLogout = async () => {
    if (role === "team") {
      localStorage.clear();
      try {
        let response = await fetch(url + clientpoints.team_logout, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        navigate("/login");
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred during logout.");
      }
    } else if (role === "client") {
      localStorage.clear();
      try {
        let response = await fetch(url + clientpoints.logout, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        navigate("/login");
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred during logout.");
      }
    }
  };

  return (
    <div>
      <div id='mySidenav' className={`sidenav ${isSidebarOpen ? "open" : ""}`} ref={sidebarRef}>
        <a href='javascript:void(0)' className='closebtn' onClick={() => setIsSidebarOpen(false)}>
          &times;
        </a>
        <div className='desk-flx'>
          <img src='/img/deskbox.png' className='img-fluid logo' alt='Desk Logo' />
        </div>

        {/* <div className="side-flx">
          <div className="side-flx1">
            <img src="/img/profile.png" className="img-fluid profile" alt="Profile" />
          </div>
          <div className="side-flx1">
            <p className="side-txt">Amit Kumar</p>
            <p className="side-txt1">Active <i className="mstr ri-checkbox-blank-circle-fill" style={{ color: "green", fontSize: "10px", marginLeft: "7px", marginTop: "2.5px" }}></i></p>
          </div>
        </div> */}

        <div className='top-marginn'>
          <Link id='space_dashboard_view' to='dashboard' className={pathname.includes("dashboard") ? `pages active` : "pages"}>
            <i className='mstr ri-home-4-line'></i>Dashboard
          </Link>

          <div className='dropdown'>
            <Link className={`pages ${isMastersDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdown}>
              <i className='mstr ri-archive-stack-line mstr'></i>Masters <i className={mastersDropdownOpen ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpen ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.seat?.view && (
                    <Link id='seat_view' to='masters/seats' className={pathname.includes("masters/seats") ? `pages1 active` : "pages1"}>
                      <i className='mstr ri-circle-fill fillicn'></i>Seat Types
                    </Link>
                  )}
                  {roleObject?.cabin?.view && (
                    <Link id='cabin_view' to='masters/cabins' className={[pathname.includes("masters/cabins") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn'></i>Cabin Types
                    </Link>
                  )}

                  {roleObject?.meeting?.view && (
                    <Link id='meeting_view' to='masters/meeting-rooms' className={pathname.includes("masters/meeting-rooms") ? `pages1 active` : "pages1"}>
                      <i className='mstr ri-circle-fill fillicn'></i>Meeting Room Types
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='seat_view' to='masters/seats' className={pathname.includes("masters/seats") ? `pages1 active` : "pages1"}>
                    <i className='mstr ri-circle-fill fillicn'></i>Seat Types
                  </Link>
                  <Link id='cabin_view' to='masters/cabins' className={[pathname.includes("masters/cabins") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn'></i>Cabin Types
                  </Link>
                  <Link id='meeting_view' to='masters/meeting-rooms' className={pathname.includes("masters/meeting-rooms") ? `pages1 active` : "pages1"}>
                    <i className='mstr ri-circle-fill fillicn'></i>Meeting Room Types
                  </Link>
                </>
              )}

              {/* <Link id="area_view" to='masters/areas' className={pathname.includes("masters/areas") ? `pages1 active` : "pages1"}><i className='mstr ri-circle-fill fillicn '></i>Areas</Link>
              <Link id="amenitie_view" to='masters/amenities' className={pathname.includes("masters/amenities") ? `pages1 active` : "pages1"}><i className='mstr ri-circle-fill fillicn '></i>Amenities</Link> */}
            </div>
          </div>

          <div className='dropdown'>
            <a className={`pages ${isResourcesDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownlocation}>
              <i className='mstr ri-map-pin-line'></i>Resources <i className={mastersDropdownOpenlocation ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenlocation ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.location?.view && (
                    <Link id='location_view' to='resources/locations' className={pathname.includes("resources/locations") ? `pages1 active` : "pages1"}>
                      <i className='mstr ri-circle-fill fillicn'></i>Locations
                    </Link>
                  )}
                  {roleObject?.floor?.view && (
                    <Link id='floor_view' to='resources/floors' className={pathname.includes("resources/floors") ? `pages1 active` : "pages1"}>
                      <i className='mstr ri-circle-fill fillicn'></i>Floors
                    </Link>
                  )}
                  {roleObject?.assets?.view && (
                    <Link id='assets_view' to='resources/assets-mapping' className={pathname.includes("resources/assets-mapping") ? `pages1 active` : "pages1"}>
                      <i className='mstr ri-circle-fill fillicn'></i>Assets
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='location_view' to='resources/locations' className={pathname.includes("resources/locations") ? `pages1 active` : "pages1"}>
                    <i className='mstr ri-circle-fill fillicn'></i>Locations
                  </Link>
                  <Link id='floor_view' to='resources/floors' className={pathname.includes("resources/floors") ? `pages1 active` : "pages1"}>
                    <i className='mstr ri-circle-fill fillicn'></i>Floors
                  </Link>
                  <Link id='assets_view' to='resources/assets-mapping' className={pathname.includes("resources/assets-mapping") ? `pages1 active` : "pages1"}>
                    <i className='mstr ri-circle-fill fillicn'></i>Assets
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isTeamDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdown1}>
              <i className='mstr ri-team-line'></i>Team <i className={mastersDropdownOpen1 ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpen1 ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.role?.view && (
                    <Link id='role_view' to='team/roles' className={pathname.includes("team/roles") ? `pages1 active` : "pages1"}>
                      <i className='mstr ri-circle-fill fillicn'></i>Roles
                    </Link>
                  )}
                  {roleObject?.team?.view && (
                    <Link id='team_view' to='team/staff' className={pathname.includes("team/staff") ? `pages1 active` : "pages1"}>
                      <i className='mstr ri-circle-fill fillicn'></i>Team
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='role_view' to='team/roles' className={pathname.includes("team/roles") ? `pages1 active` : "pages1"}>
                    <i className='mstr ri-circle-fill fillicn'></i>Roles
                  </Link>
                  <Link id='team_view' to='team/staff' className={pathname.includes("team/staff") ? `pages1 active` : "pages1"}>
                    <i className='mstr ri-circle-fill fillicn'></i>Team
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isClientsDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownclient}>
              <i className='mstr ri-group-line'></i>Clients <i className={mastersDropdownOpenclient ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenclient ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.company?.view && (
                    <Link id='company_view' to='client/companies' className={[pathname.includes("client/companies") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn'></i>Companies
                    </Link>
                  )}
                  {roleObject?.member?.view && (
                    <Link id='member_view' to='client/members' className={[pathname.includes("client/members") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn'></i>Members
                    </Link>
                  )}
                  {/* {roleObject?.contract?.view && (<Link id="contract_view" to='client/contracts'  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn '></i>Contracts</Link>)} */}
                  {roleObject?.credit?.view && (
                    <Link id='credit_view' to='client/credits-history' className={[pathname.includes("client/credits-history") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn '></i>Credits History
                    </Link>
                  )}
                  {roleObject?.parking?.view && (
                    <Link id='parking_view' to='client/parking' className={[pathname.includes("client/parking") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn '></i>Parking
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='company_view' to='client/companies' className={[pathname.includes("client/companies") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn'></i>Companies
                  </Link>
                  <Link id='member_view' to='client/members' className={[pathname.includes("client/members") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn'></i>Members
                  </Link>
                  {/* <Link id="contract_view" to='client/contracts'  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn '></i>Contracts</Link> */}
                  <Link id='credit_view' to='client/credits-history' className={[pathname.includes("client/credits-history") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn '></i>Credits History
                  </Link>
                  <Link id='parking_view' to='client/parking' className={[pathname.includes("client/parking") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn '></i>Parking
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* <Link href="#" className="pages"><i className="mstr ri-file-list-3-line"></i>Invoices</Link> */}
          <div className='dropdown'>
            <Link className={`pages ${isInvoicesDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdowninvoice}>
              <i className='mstr ri-file-list-3-line mstr'></i>Invoices <i className={mastersDropdownOpeninvoice ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpeninvoice ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.invoice?.view && (
                    <Link id='invoice_view' to='invoices/all-invoices' className={[pathname.includes("invoices/all-invoices") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn'></i>All Invoices
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='invoice_view' to='invoices/all-invoices' className={[pathname.includes("invoices/all-invoices") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn'></i>All Invoices
                  </Link>
                </>
              )}

              {/* <Link  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn'></i>Pending Invoices</Link>
              <Link  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn'></i>Paid Invoices</Link> */}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isOccupancyDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownoccupancy}>
              <i className='mstr ri-bill-line'></i>Occupancy <i className={mastersDropdownOpenoccupancy ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenoccupancy ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.invoice?.view && (
                    <Link id='occupancy_view' to='occupancy/reports' className={[pathname.includes("occupancy/reports") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn'></i>Overview
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='occupancy_view' to='occupancy/reports' className={[pathname.includes("occupancy/reports") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn'></i>Overview
                  </Link>
                </>
              )}

              {/* <Link  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn'></i>Analytics</Link> */}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isCommunityDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdowncommunity}>
              <i className='mstr ri-group-2-line'></i>Community <i className={mastersDropdownOpencommunity ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpencommunity ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.post?.view && (
                    <Link id='post_view' to='community/posts' className={[pathname.includes("community/posts") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn'></i>Posts
                    </Link>
                  )}
                  {roleObject?.event?.view && (
                    <Link id='event_view' to='community/events' className={[pathname.includes("community/events") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn'></i>Events
                    </Link>
                  )}
                  {roleObject?.offer?.view && (
                    <Link id='offer_view' to='community/offers' className={[pathname.includes("community/offers") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn '></i>Offers
                    </Link>
                  )}
                  {roleObject?.notification?.view && (
                    <Link id='notification_view' to='community/bulk-email' className={[pathname.includes("community/bulk-email") ? `pages1 active` : "pages1"]}>
                      <i className='mstr ri-circle-fill fillicn '></i>Bulk Email
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='post_view' to='community/posts' className={[pathname.includes("community/posts") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn'></i>Posts
                  </Link>
                  <Link id='event_view' to='community/events' className={[pathname.includes("community/events") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn'></i>Events
                  </Link>
                  <Link id='offer_view' to='community/offers' className={[pathname.includes("community/offers") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn '></i>Offers
                  </Link>
                  <Link id='notification_view' to='community/bulk-email' className={[pathname.includes("community/bulk-email") ? `pages1 active` : "pages1"]}>
                    <i className='mstr ri-circle-fill fillicn '></i>Bulk Email
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isBookingsDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownbooking}>
              <i className='mstr ri-calendar-event-line'></i>Bookings <i className={mastersDropdownOpenbooking ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenbooking ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.meeting_room?.view && (
                    <Link id='meeting_room_view' className={[pathname.includes("bookings/meeting-rooms") ? `pages1 active` : "pages1"]} to='bookings/meeting-rooms'>
                      <i className='mstr ri-circle-fill fillicn'></i>Meeting Rooms
                    </Link>
                  )}
                  {roleObject?.day_pass?.view && (
                    <Link id='day_pass_view' className={[pathname.includes("bookings/day-pass") ? `pages1 active` : "pages1"]} to='bookings/day-pass'>
                      <i className='mstr ri-circle-fill fillicn'></i>Day Pass
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='meeting_room_view' className={[pathname.includes("bookings/meeting-rooms") ? `pages1 active` : "pages1"]} to='bookings/meeting-rooms'>
                    <i className='mstr ri-circle-fill fillicn'></i>Meeting Rooms
                  </Link>
                  <Link id='day_pass_view' className={[pathname.includes("bookings/day-pass") ? `pages1 active` : "pages1"]} to='bookings/day-pass'>
                    <i className='mstr ri-circle-fill fillicn'></i>Day Pass
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isVisitorsDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownvisitor}>
              <i className='mstr ri-user-follow-fill mstr'></i>Visitors <i className={mastersDropdownOpenvisitor ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenvisitor ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.visitor?.view && (
                    <Link id='visitor_view' className={[pathname.includes("visitors/all-visitors") ? `pages1 active` : "pages1"]} to='visitors/all-visitors'>
                      <i className='mstr ri-circle-fill fillicn'></i>All Visitors
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='visitor_view' className={[pathname.includes("visitors/all-visitors") ? `pages1 active` : "pages1"]} to='visitors/all-visitors'>
                    <i className='mstr ri-circle-fill fillicn'></i>All Visitors
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isEnquiriesDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownenqurie}>
              <i className='mstr ri-article-fill mstr'></i>Enquiries <i className={mastersDropdownOpenenqurie ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenenqurie ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.enquiry?.view && (
                    <Link id='enquiry_view' className={[pathname.includes("enquiries/all-enquiries") ? `pages1 active` : "pages1"]} to='enquiries/all-enquiries'>
                      <i className='mstr ri-circle-fill fillicn'></i>All Enquiries
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='enquiry_view' className={[pathname.includes("enquiries/all-enquiries") ? `pages1 active` : "pages1"]} to='enquiries/all-enquiries'>
                    <i className='mstr ri-circle-fill fillicn'></i>All Enquiries
                  </Link>
                </>
              )}

              {/* <Link  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn'></i>Pending Enquiries</Link> */}
              {/* <Link href="#" className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Converted Enquries</Link> */}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isExpensesDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownexpense}>
              <i className='mstr ri-money-rupee-circle-line mstr'></i>Expenses <i className={mastersDropdownOpenexpense ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenexpense ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.expense?.view && (
                    <Link id='expense_view' className={[pathname.includes("expenses/all-expenses") ? `pages1 active` : "pages1"]} to='expenses/all-expenses'>
                      <i className='mstr ri-circle-fill fillicn'></i>All Expenses
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='expense_view' className={[pathname.includes("expenses/all-expenses") ? `pages1 active` : "pages1"]} to='expenses/all-expenses'>
                    <i className='mstr ri-circle-fill fillicn'></i>All Expenses
                  </Link>
                </>
              )}

              {/* <Link  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn'></i>Pending Expenses</Link>
              <Link  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]}><i className='mstr ri-circle-fill fillicn'></i>Paid Expenses</Link> */}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isSupportDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownsupport}>
              <i className='mstr ri-customer-service-2-line'></i>Support <i className={mastersDropdownOpensupport ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpensupport ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.ticket?.view && (
                    <Link id='ticket_view' className={[pathname.includes("support/client-support") ? `pages1 active` : "pages1"]} to='support/client-support'>
                      <i className='mstr ri-circle-fill fillicn'></i>Clients Support
                    </Link>
                  )}
                  {roleObject?.client_ticket?.view && (
                    <Link id='client_ticket_view' className={[pathname.includes("support/deskbox-support") ? `pages1 active` : "pages1"]} to='support/deskbox-support'>
                      <i className='mstr ri-circle-fill fillicn'></i>DeskBox Support
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='ticket_view' className={[pathname.includes("support/client-support") ? `pages1 active` : "pages1"]} to='support/client-support'>
                    <i className='mstr ri-circle-fill fillicn'></i>Clients Support
                  </Link>
                  <Link id='client_ticket_view' className={[pathname.includes("support/deskbox-support") ? `pages1 active` : "pages1"]} to='support/deskbox-support'>
                    <i className='mstr ri-circle-fill fillicn'></i>DeskBox Support
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='dropdown'>
            <Link className={`pages ${isSettingsDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownsetting}>
              <i className='mstr ri-settings-3-line'></i>Settings <i className={mastersDropdownOpensetting ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpensetting ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.space?.view && (
                    <Link id='space_view' className={[pathname.includes("settings/profile") ? `pages1 active` : "pages1"]} to='settings/profile'>
                      <i className='mstr ri-circle-fill fillicn'></i>Profile
                    </Link>
                  )}
                  {roleObject?.subscription?.view && (
                    <Link id='subscription_view' className={[pathname.includes("settings/subscription") ? `pages1 active` : "pages1"]} to='settings/subscription'>
                      <i className='mstr ri-circle-fill fillicn'></i>
                      Subscription
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id='space_view' className={[pathname.includes("settings/profile") ? `pages1 active` : "pages1"]} to='settings/profile'>
                    <i className='mstr ri-circle-fill fillicn'></i>Profile
                  </Link>
                  <Link id='subscription_view' className={[pathname.includes("settings/subscription") ? `pages1 active` : "pages1"]} to='settings/subscription'>
                    <i className='mstr ri-circle-fill fillicn'></i>Subscription
                  </Link>
                </>
              )}

              {/* <Link id="log_view"  className={[
                        pathname.includes("masters/cabins")
                          ? `pages1 active`
                          : "pages1",
                      ]} to='settings/activity-logs'><i className='mstr ri-circle-fill fillicn '></i>Activity Logs</Link> */}
            </div>
          </div>

          <Link className='pages' onClick={handleLogout}>
            <i className='mstr ri-shut-down-line'></i>Logout
          </Link>
        </div>
      </div>

      <div className='logo-flx'>
        <div className='logo-flx1'>
          <img src='/img/deskbox.png' className='img-fluid logo2' alt='Desk Logo' />
        </div>
        <div className='logo-flx2'>
          <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={toggleSidebar}>
            &#9776;
          </span>
        </div>
      </div>
    </div>
  );
}

export default Component;
