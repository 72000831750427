import Login from "../components/login_member/Component.js";

import "./../css/style.css";

const Component = () => {
    return (
        <>
            <Login />
        </>
    );
};

export default Component;
