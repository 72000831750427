import React, { useState, useEffect, useRef, useContext } from 'react';
import { url, clientpoints, clientHeader, client_prefix, whoAmIHeaders } from '../../../../../../lib/lib.js';
import { AuthUserContext, typeTeamRoleObject } from '../../../../../../lib/AuthUserContext.js';
import { useParams, useNavigate } from "react-router-dom";

const Component = ({ setCompanyName, searchValueProps, suggestionsProps, showDropdownProps, form = true }) => {
    const { spaceId } = useParams();
    const navigate = useNavigate();

    const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
    const { typeTeamRoleObject } = useContext(AuthUserContext);
    let pageHeaders = whoAmIHeaders(whoAmI);
    let getLocalStorageData;

    useEffect(() => {
        if (!whoAmI) {
            getLocalStorageData = getDecryptedDataFromLocalStorage('type');
            setWhoAmI(getLocalStorageData);
            pageHeaders = whoAmIHeaders(getLocalStorageData);
        } else {
            pageHeaders = whoAmIHeaders(whoAmI);
        }
    }, []);


    const { searchValue, setSearchValue } = searchValueProps;
    const { suggestions, setSuggestions } = suggestionsProps;
    const { showDropdown, setShowDropdown } = showDropdownProps;
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    let [countIndex, setCountIndex] = useState(0);
    const loadOptions = async () => {
        try {
            const response = await fetch(url + clientpoints.company_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: searchValue }),
                credentials: 'include'
            });
            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestions(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
    const [perPage, setPerPage] = useState(10); // State variable to track entries per page
    const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

    const fetchDataForPage = async (page) => {
        try {
            const response = await fetch(url + clientpoints.company_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', page: page, size: perPage, sorting: { "createdAt": "1" } }), // Fetch data for specific page
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const newData = responseData.Data;

            setSuggestions(prevSuggestions => [...prevSuggestions, ...newData]);
            setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleScroll = async (event) => {
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const clientHeight = event.target.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - 10) {

            const nextPageCount = currentPage + 1;

            if (nextPageCount <= totalPages) {
                setCurrentPage(nextPageCount);
                fetchDataForPage(nextPageCount);
            }
        }
    };

    const loadOptionsOnClick = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(url + clientpoints.company_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', space_id: spaceId, page: countIndex + 1, sorting: { "createdAt": "1" } }), // Increment countIndex by 1
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestions(data);
            setTotalPages(responseData.totalCount);
            setSearchValue("");
        } catch (error) {
            console.error('Error fetching options:', error);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleOptionClick = (selectedOption) => {
        if (selectedOption) {
            const { name, _id } = selectedOption;
            setSearchValue(name);
            setCompanyName(_id);
            setShowDropdown(name);
            setSuggestions([]);
        }
        else if (selectedOption == "") {
            setSearchValue("All Company");
            setShowDropdown("All Company");
            setCompanyName('');
            setSuggestions([]);
        }
        else {
            setSearchValue('');
            setShowDropdown('');
            setCompanyName('');
            setSuggestions([]);
        }
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                setSuggestions([]);
                setCurrentPage(1);
                setPerPage(10);
                setTotalPages(0)
                setSearchValue(showDropdown);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showDropdown]);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <i className="ri-arrow-down-s-line ab1" onClick={loadOptionsOnClick}></i>
            <input
                autoComplete="off"
                ref={inputRef}
                placeholder={form ? "Search for Company..." : "Select Company"}
                onChange={e => setSearchValue(e.target.value)}
                value={searchValue}
                onKeyUp={() => loadOptions(searchValue)}
                onClick={loadOptionsOnClick}
                id="spaceName"
                className="search-input form-control customSelect w-100"
            />
            {isLoading ? (
                <div className="spinner-border text-primary my_spin" role="status"><span className="sr-only">Loading...</span></div>
            ) : suggestions.length > 0 && (
                <ul
                    id="dropdownMenu"
                    onScroll={handleScroll}
                    style={{ overflowY: 'scroll', height: '200px' }}
                    className="dropdown-menu mydrop_hgt"
                >
                    {form ? (
                        <li onClick={() => handleOptionClick('')}>All Company<span className="hidden" data-client-id={''}></span></li>
                    ) : ('')}
                    {suggestions.length > 0 ? (
                        suggestions.map((company) => (
                            <li key={company._id} onClick={() => handleOptionClick(company)}>
                                {company.name} {company.email && company.email ? `( ${company.email} ) ` : ''}
                                <span className="hidden" data-company-id={company._id} data-client-id={company.client_id ? company.client_id._id : ''}></span>
                            </li>
                        ))
                    ) : (
                        <li>No data found</li>
                    )}

                </ul>
            )}
        </div>
    );
};

export default Component;