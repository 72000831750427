import { useState, useEffect, useRef, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "../../../../nav/components/location/component.css";
import { url, clientpoints, getDecryptedDataFromLocalStorage, whoAmIHeaders, client_prefix, memberHeader } from "../../../../../lib/lib";
import { AuthUserContext } from "../../../../../lib/AuthUserContext";
import { LoadingMutatingDots } from "../../../../spaces/components/ui/Loadings";

function Component() {
  const navigate = useNavigate();

  const [stateDisabled, setStateDisabled] = useState(true);

  const { spaceId } = useParams();
  const [spaceData, setSpaceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSpaceData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.member_view, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      setSpaceData(data.Data[0]);
      setIsLoading(false); // Update loading state
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch space data");
      setIsLoading(false); // Update loading state
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSpaceData();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    billing_detail: {
      legal_company: "",
      Billing_address: "",
      gstn: "",
      pan: "",
      cin: "",
      tan: "",
    },
    phone: "",
    email: "",
    username: "",
    password: "",
    description: "",
  });

  useEffect(() => {
    if (spaceData) {
      setFormData((prevState) => ({
        ...prevState,
        name: spaceData.name || "",
        phone: spaceData.phone || "",
        username: spaceData.username || "",
        password: spaceData.password || "",
        email: spaceData.email || "",
        description: spaceData.description || "",
      }));
    }
  }, [spaceData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the field is in billing_detail or not
    if (name.startsWith("billing_detail.")) {
      setFormData((prevState) => ({
        ...prevState,
        billing_detail: {
          ...prevState.billing_detail,
          [name.split(".")[1]]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(url + clientpoints.member_edit, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, data: formData }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const responseData = await response.json();

      if (responseData.Status) {
        fetchSpaceData();
        // alert("Data submitted successfully!");
        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
        throw new Error(responseData.Message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update space data");
    }
  };

  const handleRefresh = () => {
    fetchSpaceData();
  };

  return (
    <>
      <ToastContainer />
      <div className='inner-padding staff-table-parent'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>Space Details</p>
          </div>

          <div className='featured-flx1'>
            <button className='circle-btn-add icon' type='button' onClick={handleRefresh}>
              {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}
            </button>
          </div>
        </div>

        <div className='row'>
          {isLoading ? (
            <LoadingMutatingDots />
          ) : spaceData ? (
            <>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Name*</label>
                    <input type='text' className='form-control family' name='name' value={formData.name} onChange={handleChange} placeholder='Enter Name' required disabled={!stateDisabled} />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Phone*</label>
                    <input
                      type='text'
                      className='form-control family'
                      name='phone'
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder='Enter  Phone number'
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label className='family'>Email*</label>
                    <input type='email' className='form-control family' name='email' value={formData.email} onChange={handleChange} placeholder='Enter Email' required disabled={!stateDisabled} />
                  </div>
                </div>
              </div>
              <div className='row w-100 mb-4'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='family'>Description*</label>
                    <textarea
                      className='form-control family'
                      name='description'
                      value={formData.description}
                      onChange={handleChange}
                      placeholder='Enter  Description'
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
              </div>
              {/* 

                            <div className='featured-flx1'>
                                <p className='main_heading1'>Billing  Details</p>
                            </div>

                            <div className="row w-100 mt-4">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="family">Legal Company</label>
                                        <input
                                            type="text"
                                            className="form-control family"
                                            name="billing_detail.legal_company"
                                            value={formData.billing_detail.legal_company}
                                            onChange={handleChange}
                                            placeholder="Enter legal company"
                                            required
                                            disabled={!stateDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="family">Billing Address</label>
                                        <input
                                            type="text"
                                            className="form-control family"
                                            name="billing_detail.Billing_address"
                                            value={formData.billing_detail.Billing_address}
                                            onChange={handleChange}
                                            placeholder="Enter billing address"
                                            required
                                            disabled={!stateDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="family">Gstn</label>
                                        <input
                                            type="text"
                                            className="form-control family"
                                            name="billing_detail.gstn"
                                            value={formData.billing_detail.gstn}
                                            onChange={handleChange}
                                            placeholder="Enter gstn"
                                            required
                                            disabled={!stateDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="family">Pan</label>
                                        <input
                                            type="text"
                                            className="form-control family"
                                            name="billing_detail.pan"
                                            value={formData.billing_detail.pan}
                                            onChange={handleChange}
                                            placeholder="Enter pan"
                                            required
                                            disabled={!stateDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="family">Cin</label>
                                        <input
                                            type="text"
                                            className="form-control family"
                                            name="billing_detail.cin"
                                            value={formData.billing_detail.cin}
                                            onChange={handleChange}
                                            placeholder="Enter Cin"
                                            required
                                            disabled={!stateDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="family">Tan</label>
                                        <input
                                            type="text"
                                            className="form-control family"
                                            name="billing_detail.tan"
                                            value={formData.billing_detail.tan}
                                            onChange={handleChange}
                                            placeholder="Enter tan"
                                            required
                                            disabled={!stateDisabled}
                                        />
                                    </div>
                                </div>
                            </div>

                             */}
              <button type='button' className='btn btn-primary mt-4 mb-4' onClick={handleSubmit}>
                Update
              </button>
            </>
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Component;
