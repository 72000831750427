import React from "react";
import "../../../../../nav/components/location/component.css";
import { useNavigate } from "react-router-dom";

function Component({ data = [], setSelectedItem, setIsViewModal, setIsEditModal, handleDelete, typeTeamRoleObject, whoAmI, tableName }) {
  const navigate = useNavigate();

  function goToToAssets() {
    navigate(`/space/${data.space_id.urlName}/backend/resources/assets-mapping?locationId=${data.location_id._id}&floorId=${data._id}`, {
      state: { floor: data._id, location: data.location_id._id },
    });
  }

  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
      <div className='seatdesk-card'>
        <div class='cabin-sideline'></div>
        <div className='row'>
          <div className='col-12'>
            <div className='floordesk-sheet'>
              <div className='cabin-number'>
                <i class='ri-keyboard-box-line keybord-icn'></i>
              </div>
              <div className='seatdesk-editicn'>
                <div class='cabin-text'>
                  <p class='cabin-totalseat'>{data?.floor_name}</p>
                </div>
              </div>
            </div>
            <div class='floor-botflx'>
              <div class=' editicn'>
                {whoAmI === "team" ? (
                  <>
                    {typeTeamRoleObject?.[tableName]?.view && (
                      <i
                        class='ri-eye-line floor-view-icn'
                        onClick={() => {
                          setSelectedItem(data);
                          setIsViewModal(true);
                        }}
                      ></i>
                    )}
                    {typeTeamRoleObject?.[tableName]?.edit && (
                      <i
                        class='ri-pencil-line floor-pencil-icn'
                        onClick={() => {
                          setSelectedItem(data);
                          setIsEditModal(true);
                        }}
                      ></i>
                    )}
                    {typeTeamRoleObject?.[tableName]?.delete && (
                      <i
                        class='ri-delete-bin-6-line floor-trash-icn'
                        onClick={() => {
                          handleDelete(data._id, data?.floor_name);
                        }}
                      ></i>
                    )}
                  </>
                ) : (
                  <>
                    <i
                      class='ri-eye-line floor-view-icn'
                      onClick={() => {
                        setSelectedItem(data);
                        setIsViewModal(true);
                      }}
                    ></i>
                    <i
                      class='ri-pencil-line floor-pencil-icn'
                      onClick={() => {
                        setSelectedItem(data);
                        setIsEditModal(true);
                      }}
                    ></i>
                    <i
                      class='ri-delete-bin-6-line floor-trash-icn'
                      onClick={() => {
                        handleDelete(data._id, data?.floor_name);
                      }}
                    ></i>
                  </>
                )}
              </div>
              <button class='floor-btn-2' onClick={goToToAssets}>
                Manage Assets
              </button>
            </div>
            <div className='box-back'>
              <div className='row'>
                <div className='col-6 rgt-bdr'>
                  <p className='seatbox-txt'>Location</p>
                  <p className='seatbox-desc'>{data.location_id?.location}</p>
                </div>
                <div className='col-6 '>
                  <center>
                    <p className='seatbox-txt'>Floor</p>
                    <p className='seatbox-desc'>{data?.floor_number}</p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
