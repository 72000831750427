import Floor from '../../components/resource/floor/table/Component';
import { toast, ToastContainer } from "react-toastify";
export default function Component() {

  return (
    <>
      <ToastContainer />
      <Floor name="Floors" />
    </>
  );
}
