import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import SearchableSelectComponentSpace from "../company_select/Component.js";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();
  const [companyName, setSpaceName] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    description: "",
  });

  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!companyName.trim()) {
      errors.company = "Company is required";
      isValid = false;
    }
    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.name = "Email is required";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      errors.name = "Phone is required";
      isValid = false;
    }
    if (!formData.username.trim()) {
      errors.username = "Username is required";
      isValid = false;
    }
    if (!formData.password.trim()) {
      errors.password = "Password is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.member_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            company_id: companyName,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            username: formData.username,
            password: formData.password,
            description: formData.description,
          }),
        });
        setSpaceName("");
        setFormData({
          name: "",
          email: "",
          phone: "",
          username: "",
          password: "",
          description: "",
        });
        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSpaceName("");
    setFormData({
      name: "",
      email: "",
      phone: "",
      username: "",
      password: "",
      description: "",
    });
  };
  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div>
                    <label htmlFor="title" className="col-form-label">Select Company</label>
                    <SearchableSelectComponentSpace
                      setSpaceName={setSpaceName}
                      searchValueProps={{ searchValue, setSearchValue }}
                      suggestionsProps={{ suggestions, setSuggestions }}
                      showDropdownProps={{ showDropdown, setShowDropdown }}
                      form={false}
                    />
                    {errors.company && (
                      <div className="invalid-feedback">{errors.company}</div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="email" className="col-form-label">Email</label>
                      <input
                        type="email"
                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="name" className="col-form-label">Name</label>
                      <input
                        type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (<div className="invalid-feedback">{errors.name}</div>)}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="phone" className="col-form-label">Phone</label>
                      <input
                        type="text"
                        className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (<div className="invalid-feedback">{errors.phone}</div>)}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="username" className="col-form-label">Username</label>
                      <input
                        type="text"
                        className={`form-control ${errors.username ? "is-invalid" : ""}`}
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                      />
                      {errors.username && (<div className="invalid-feedback">{errors.username}</div>)}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="password" className="col-form-label">Password</label>
                      <input
                        type="text"
                        className={`form-control ${errors.password ? "is-invalid" : ""}`}
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="description" className="col-form-label">Description</label>
                      <textarea
                        className={`form-control`}
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
