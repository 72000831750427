import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="section1">
      <div className="container-fluid">
        <div className="row  sectionone-flx">
          <div className="col-lg-6"> 
          <p className="desk-txt1">
            Manage Your Coworking Space Efficiently
          </p>
          <p className="txt">
          Deskbox is a comprehensive coworking space management system that allows you to efficiently manage your coworking space in one place.
          </p>
         <a href="#contact"> <button className="demo-btn">Get Free Trial</button></a>
           </div>
          <div className="col-lg-6">
            <center><img src="img/header.png" className="img-fluid deskbox-img"/></center>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Component;
