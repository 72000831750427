// import Topbar from "../components/topbar/Component.js";
import Nav from "../components/nav/Component.js";
import Sectionone from "../components/sectionone/Component.js";
import Sectiontwo from "../components/sectiontwo/Component.js";
import Sectionthree from "../components/sectionthree/Component.js";
import Sectionfour from "../components/sectionfour/Component.js";
import Sectionfive from "../components/sectionfive/Component.js";
import Sectionsix from "../components/sectionsix/Component.js";
import Sectionseven from "../components/sectionseven/Component.js";
import Pricing from "../components/pricing/Component.js";
import Contact from "../components/contact/Component.js";
import Floatbtn from "../components/floatbtn/Component.js";
import Footer from "../components/footer/Component.js";
import AOS from 'aos';

import "./../css/style.css";

const Component = () => {
  return (
    <>
    
      <Nav/>
      <Sectionone/>
      <Sectiontwo/>
      <Sectionthree/>
      <Sectionfour/>
      <Sectionfive/>
      <Sectionsix/>
      <Sectionseven/>
      <Pricing/>
      <Contact/>
      <Floatbtn/>
      <Footer/>
      
    

    </>
  );
};

export default Component;
