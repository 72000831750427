import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url, clientpoints } from "../../../../lib/lib.js";

const Component = ({ name = 'Amit Kumar Deskbox' }) => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      let response = await fetch(url + clientpoints.logout, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        }, credentials: "include",
      });
      navigate('/login');
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during logout.');
    }
  };

  return (
    <>
      <div className="space-nav">
        <div className="space-nav1">
          <img className="logo" src="img/deskbox.png" alt="Logo" />
        </div>
        <div class="space-nav2">
          <div className='space-side-border'>

            <div className='space-user-n1'>
              <i class="ri-user-line user-icn-nav"></i>
              <p className='user-txt'>{name}</p>
            </div>

          </div>
          <div className='user-n1'>
            <button className="spacenav-circle-btnname icon" onClick={handleLogout}>Logout</button>
          </div>
        </div>

      </div>

    </>
  );
};

export default Component;