import React, { useState, useEffect, useRef, useContext } from "react";
import "./component.css"; // Import your CSS file
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { url, clientpoints } from "../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../lib/AuthUserContext.js";

function Component() {

  const navigate = useNavigate();
  const sidebarRef = useRef();
  const { pathname } = useLocation();

  const [activeLink, setActiveLink] = useState("dashboard");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  useEffect(() => {
    const { pathname } = location;
    const parts = pathname.split('/');
    const match = parts[3];
    setActiveLink(match);
  }, [location]);


  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const [mastersDropdownOpen1, setMastersDropdownOpen1] = useState(false);
  const [mastersDropdownOpen, setMastersDropdownOpen] = useState(false);

  const [mastersDropdownOpeninvoice, setMastersDropdownOpeninvoice] = useState(false);
  const [mastersDropdownOpenexpense, setMastersDropdownOpenexpense] = useState(false);
  const [mastersDropdownOpenvisitor, setMastersDropdownOpenvisitor] = useState(false);
  const [mastersDropdownOpenenqurie, setMastersDropdownOpenenqurie] = useState(false);
  const [mastersDropdownOpenoccupancy, setMastersDropdownOpenoccupancy] = useState(false);
  const [mastersDropdownOpenlocation, setMastersDropdownOpenlocation] = useState(false);
  const [mastersDropdownOpensetting, setMastersDropdownOpensetting] = useState(false);
  const [mastersDropdownOpenbooking, setMastersDropdownOpenbooking] = useState(false);
  const [mastersDropdownOpensupport, setMastersDropdownOpensupport] = useState(false);
  const [mastersDropdownOpenclient, setMastersDropdownOpenclient] = useState(false);
  const [mastersDropdownOpencommunity, setMastersDropdownOpencommunity] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      // If the click is outside the sidebar, close the sidebar
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);
  const toggleDropdown = () => {
    setMastersDropdownOpen(!mastersDropdownOpen);
  };
  const toggleDropdowninvoice = () => {
    setMastersDropdownOpeninvoice(!mastersDropdownOpeninvoice);
  };
  const toggleDropdownexpense = () => {
    setMastersDropdownOpenexpense(!mastersDropdownOpenexpense);
  };
  const toggleDropdownvisitor = () => {
    setMastersDropdownOpenvisitor(!mastersDropdownOpenvisitor);
  };
  const toggleDropdownenqurie = () => {
    setMastersDropdownOpenenqurie(!mastersDropdownOpenenqurie);
  };
  const toggleDropdownlocation = () => {
    setMastersDropdownOpenlocation(!mastersDropdownOpenlocation);
  };
  const toggleDropdown1 = () => {
    setMastersDropdownOpen1(!mastersDropdownOpen1);
  };
  const toggleDropdownoccupancy = () => {
    setMastersDropdownOpenoccupancy(!mastersDropdownOpenoccupancy);
  };
  const toggleDropdownsetting = () => {
    setMastersDropdownOpensetting(!mastersDropdownOpensetting);
  };
  const toggleDropdownbooking = () => {
    setMastersDropdownOpenbooking(!mastersDropdownOpenbooking);
  };
  const toggleDropdownsupport = () => {
    setMastersDropdownOpensupport(!mastersDropdownOpensupport);
  };
  const toggleDropdownclient = () => {
    setMastersDropdownOpenclient(!mastersDropdownOpenclient);
  };
  const toggleDropdowncommunity = () => {
    setMastersDropdownOpencommunity(!mastersDropdownOpencommunity);
  };

  const handleLogout = async () => {
    try {
      let response = await fetch(url + clientpoints.member_logout, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      navigate("/login");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during logout.");
    }
  };

  return (
    <div>
      <div id='mySidenav' className={`sidenav ${isSidebarOpen ? "open" : ""}`} ref={sidebarRef}>

        <a href='javascript:void(0)' className='closebtn' onClick={() => setIsSidebarOpen(false)}>&times;</a>
        <div className='desk-flx'><img src='/img/deskbox.png' className='img-fluid logo' alt='Desk Logo' /></div>

        <div className='top-marginn'>

          <Link
            to='dashboard'
            // className={pathname.includes("dashboard") ? `pages active` : "pages"}
            className={`pages ${activeLink === 'dashboard' ? 'active' : ''}`}
            onClick={() => handleLinkClick('dashboard')}
          >
            <i className='mstr ri-home-4-line'></i>Dashboard
          </Link>
          <Link
            to='teams'
            className={`pages ${activeLink === 'teams' ? 'active' : ''}`}
            onClick={() => handleLinkClick('teams')}
          >
            <i className='mstr ri-group-line'></i>Teams
          </Link>
          <Link
            to='invoices'
            className={`pages ${activeLink === 'invoices' ? 'active' : ''}`}
            onClick={() => handleLinkClick('invoices')}
          >
            <i className='mstr ri-file-list-3-line mstr'></i>Invoices
          </Link>
          <Link
            to='posts'
            className={`pages ${activeLink === 'posts' ? 'active' : ''}`}
            onClick={() => handleLinkClick('posts')}
          >
            <i className='mstr ri-bill-line'></i>Posts
          </Link>
          <Link
            to='events'
            className={`pages ${activeLink === 'events' ? 'active' : ''}`}
            onClick={() => handleLinkClick('events')}
          >
            <i className='mstr ri-group-2-line'></i>Events
          </Link>
          <Link
            to='offers'
            className={`pages ${activeLink === 'offers' ? 'active' : ''}`}
            onClick={() => handleLinkClick('offers')}
          >
            <i className='mstr ri-bill-line'></i>Offers
          </Link>
          <Link
            to='meeting-rooms'
            className={`pages ${activeLink === 'meeting-rooms' ? 'active' : ''}`}
            onClick={() => handleLinkClick('meeting-rooms')}
          >
            <i className='mstr ri-group-2-line'></i>Meeting Rooms
          </Link>
          <Link
            to='meeting-rooms-booking'
            className={`pages ${activeLink === 'meeting-rooms-booking' ? 'active' : ''}`}
            onClick={() => handleLinkClick('meeting-rooms-booking')}
          >
            <i className='mstr ri-group-2-line'></i>Meeting Rooms Booking
          </Link>
          {/* 
          <Link
            to='day-pass'
            className={`pages ${activeLink === 'day-pass' ? 'active' : ''}`}
            onClick={() => handleLinkClick('day-pass')}
          >
            <i className='mstr ri-group-2-line'></i>Day Pass
          </Link>
           */}
          <Link
            to='visitors'
            className={`pages ${activeLink === 'visitors' ? 'active' : ''}`}
            onClick={() => handleLinkClick('visitors')}
          >
            <i className='mstr ri-group-2-line'></i>All Visitors
          </Link>
          <Link
            to='support'
            className={`pages ${activeLink === 'support' ? 'active' : ''}`}
            onClick={() => handleLinkClick('support')}
          >
            <i className='mstr ri-customer-service-2-line'></i>Support
          </Link>
          <Link
            to='setting'
            className={`pages ${activeLink === 'setting' ? 'active' : ''}`}
            onClick={() => handleLinkClick('setting')}
          >
            <i className='mstr ri-settings-3-line'></i>Settings
          </Link>

          <Link className='pages' onClick={handleLogout}><i className='mstr ri-shut-down-line'></i>Logout</Link>

        </div>
      </div>

      <div className='logo-flx'>
        <div className='logo-flx1'><img src='/img/deskbox.png' className='img-fluid logo2' alt='Desk Logo' /></div>
        <div className='logo-flx2'><span style={{ fontSize: "30px", cursor: "pointer" }} onClick={toggleSidebar}>&#9776;</span></div>
      </div>

    </div>
  );
}

export default Component;
