import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./component.css";


const Component = () => {
  return (
    <>
    <div className="log-logo1">
    <Link to="/"><img className="logo-login" src="/img/deskbox.png" alt="Logo" /></Link>
    </div>
    </>

    );
};

export default Component;