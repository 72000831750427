import React, { useMemo, useState, useEffect } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { url, adminpoints, admin_prefix, adminHeader } from "../../../../../lib/lib.js";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";
import EditModalComponent from "../edit_modal/Component.js";
import { toast } from "react-toastify";
import AddModalComponent from "../add_modal/Component.js";
import SearchableSelectComponentClient from "../client_select/Component.js";
import "./component.css";
import { LoadingMutatingDots } from "../../../../spaces/components/ui/Loadings.js";

const Component = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const filterFromUrl = urlParams.get("filter");
  const idFromUrl = urlParams.get("id");

  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // For Select Dropdown
  const [clientName, setClientName] = useState("");
  const [searchValue, setSearchValue] = useState("All Clients");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("All Clients");
  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  // const [spaceStatus, setSpaceStatus] = useState('');
  // const [accountStatus, setAccountStatus] = useState(true);
  const initialAccountStatus = idFromUrl !== null && idFromUrl.trim() ? "" : true;
  const [accountStatus, setAccountStatus] = useState(initialAccountStatus);

  // let initialSpaceStatus;
  // if (filterFromUrl !== null && filterFromUrl.trim()) {
  //     initialSpaceStatus = filterFromUrl;
  // }
  // else {
  //     initialSpaceStatus = '';
  // }

  const initialSpaceStatus = filterFromUrl !== null && filterFromUrl.trim() ? filterFromUrl : "";
  // const initialSpaceStatus = filterFromUrl !== null ? '' : filterFromUrl;

  const [spaceStatus, setSpaceStatus] = useState(initialSpaceStatus);

  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [sorting, setSorting] = useState({
    column: null,
    direction: "desc",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [getid, setGetId] = useState("");
  const [flag, setflag] = useState(0);

  useEffect(() => {
    // fetchData();
    if (filterFromUrl) {
      setSpaceStatus(filterFromUrl);
    }
    if (idFromUrl) {
      fetchData(idFromUrl);
    } else {
      fetchData();
    }
  }, [spaceStatus, accountStatus, filterFromUrl, idFromUrl, clientName, pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);

  const fetchData = async (tempGetid = "") => {
    setIsLoading(true);
    try {
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };
      let filters;
      if (tempGetid !== null && tempGetid.trim()) {
        filters = {
          ...(tempGetid !== null && tempGetid.trim() && { client_id: tempGetid }),
          ...(spaceStatus && { isSubscriptionValid: spaceStatus }),
          ...(accountStatus && { account_type: accountStatus }),
          ...(clientName && { client_id: clientName }),
        };
      } else {
        filters = {
          ...(spaceStatus && { isSubscriptionValid: spaceStatus }),
          ...(accountStatus && { account_type: accountStatus }),
          ...(clientName && { client_id: clientName }),
        };
      }

      const requestBody = {
        filters,
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + adminpoints.space_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();

      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));

      if (tempGetid !== null && tempGetid.trim() != "" && responseData.Data.length > 0) {
        const clientData = responseData.Data[0];
        if (tempGetid === clientData.client_id._id) {
          setSearchValue(clientData.client_id.name);
          setShowDropdown(clientData.client_id.name);
        }
      } else {
        if (flag == 0) {
          setAccountStatus(true);
          setflag(1);
        }

        if (tempGetid !== null && tempGetid.trim() != "" && responseData.Data.length == 0) {
          const response_client = await fetch(url + adminpoints.client_view, {
            method: "POST",
            headers: adminHeader,
            body: JSON.stringify({ filters: { _id: tempGetid } }), // Increment countIndex by 1
            credentials: "include",
          });
          const responseClientData = await response_client.json();
          const clientData = responseClientData.Data[0];
          if (tempGetid === clientData._id) {
            setSearchValue(clientData.name);
            setShowDropdown(clientData.name);
          }
        }
      }
    } catch (error) {
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = () => {
    if (idFromUrl) {
      fetchData(idFromUrl);
    } else {
      fetchData();
    }
  };

  const handleHardRefresh = async () => {
    if (idFromUrl) {
      navigate(admin_prefix + "space");
    }
    if (filterFromUrl) {
      navigate(admin_prefix + "space");
    }
    try {
      setIsLoading(true);
      setGetId("");
      setClientName("");
      setSuggestions([]);
      setSpaceStatus("");
      setAccountStatus(true);
      const sortingObject = { createdAt: "-1" };
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };
      const response = await fetch(url + adminpoints.space_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      // setPagination(prevPagination => ({ ...prevPagination, pageIndex: 1, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
      setPagination({ pageIndex: 1, pageSize: 10, totalPages: 0 });
      setSearchTerm("");
      setShowDropdown("All Clients");
      setSearchValue("All Clients");
    } catch (error) {
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({ ...prevPagination, pageIndex: currentPageIndex }));
    if (idFromUrl) {
      fetchData(idFromUrl);
    } else {
      fetchData();
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleActiveAndInActiveSpaces = (event) => {
    setSpaceStatus(event.target.value);
  };
  const handleClientAndDemoAccount = (event) => {
    setAccountStatus(event.target.value);
  };

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  // Start Form Edit And Delete
  const handleDelete = async (deleteItemId, type) => {
    let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    if (deleteConfirm == type) {
      try {
        const response = await fetch(url + adminpoints.space_delete, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({ filters: { _id: deleteItemId } }),
        });
        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }
        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        setData((prevData) => prevData.filter((item) => item._id !== deleteItemId));
        handleRefresh();
      } catch (error) {
        toast.success(error);
        navigate(`${admin_prefix}login`);
      }
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };
  // End Form Edit And Delete

  return (
    <>
      <div className='scroll_divstop'>
        <div className='new1'>
          <div className='featured-flx '>
            <div className='featured-flx1'>
              <h3>All Spaces</h3>
            </div>

            <div className='featured-flx1'>
              <button className='circle-btn-add icon' type='button' onClick={handleOpenModalAdd}>
                <i className='ri-add-line'></i>
              </button>
            </div>
          </div>
        </div>

        <div className='space_flx1'>
          <div className='sp1 d-flex flex-column me-3'>
            <label htmlFor='clientName'>Client:</label>
            <div className='nextd'>
              <SearchableSelectComponentClient
                setClientName={setClientName}
                setPagination={setPagination}
                getid={getid}
                searchValueProps={{ searchValue, setSearchValue }}
                suggestionsProps={{ suggestions, setSuggestions }}
                showDropdownProps={{ showDropdown, setShowDropdown }}
              />
            </div>
          </div>

          <div className='sp2 d-flex flex-column me-3'>
            <label htmlFor='spaceName'>Space Status:</label>
            <div className='nextd'>
              <select id='spaceName' className={`form-select`} value={spaceStatus} onChange={handleActiveAndInActiveSpaces}>
                <option value=''>All Spaces</option>
                <option value='true'>Active Spaces</option>
                <option value='false'>Inactive Spaces</option>
              </select>
            </div>
          </div>

          <div className='sp3 d-flex flex-column'>
            <label htmlFor='accountStatus'>Account Status:</label>
            <div className='nextd'>
              <select id='accountStatus' className={`form-select`} value={accountStatus} onChange={handleClientAndDemoAccount}>
                <option value=''>All Accounts</option>
                <option value='true'>Client</option>
                <option value='false'>Demo</option>
              </select>
            </div>
          </div>
        </div>
        <div className='dd'>
          <div className='space_table'>
            <div className='table-responsive mt-2 tablebord'>
              <div className='space_filter m-1'>
                <div className='d-flex'>
                  <button onClick={handleRefresh} className='desk-refresh'>
                    {isLoading ? (
                      <>
                        <span className='spinner-border spinner-border-sm abs' role='status' aria-hidden='true'></span> <i className='ri-refresh-line hidd'></i>
                      </>
                    ) : (
                      <i className='ri-refresh-line'></i>
                    )}
                    &#160;
                    {/* {isLoading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : (<i className="ri-refresh-line"></i>)}&#160; */}
                    Refresh
                  </button>
                  &#160;&nbsp;&nbsp;
                  <button className='btn btn-primary' onClick={handleHardRefresh}>
                    <i className='ri-gradienter-line'></i> Reset
                  </button>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex justify-content-between align-items-center sp'>
                    <input
                      type='text'
                      placeholder='Search...'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyUp={handleSearchSubmit}
                      className='search-input form-control search-inp'
                    />
                  </div>
                </div>
              </div>
              <div className='scroll_table'>
                <Table hover>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Client Name </th>
                      <th>Space Name </th>
                      <th onClick={() => handleSort("subscription_desk")}>
                        Desks <FaFilter />
                        &nbsp; {sorting.column === "subscription_desk" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                      </th>
                      <th onClick={() => handleSort("subscription_validity")}>
                        Validity <FaFilter />
                        &nbsp; {sorting.column === "subscription_validity" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                      </th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      // <div className="d-flex justify-content-center align-items-center myload"  >
                      //     <div className="spinner-border text-primary " role="status">
                      //         <span className="sr-only">Loading...</span>
                      //     </div>
                      // </div>
                      <tr>
                        <LoadingMutatingDots />
                        {/* <td colspan={8}>
                                                    <div className="d-flex justify-content-center align-items-center"  >
                                                        <div className="spinner-border text-primary " role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                </td> */}
                      </tr>
                    ) : data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{adjustedStartIndex + index}</td>
                          <td>{item.client_id && item.client_id.name}</td>
                          <td>{item.space_name}</td>
                          <td>{item.subscription_desk}</td>
                          <td>{new Date(item.subscription_validity).toLocaleDateString()}</td>
                          <td>
                            <p>{item.account_type ? "Client" : "Demo"}</p>
                          </td>
                          <td>
                            <p className={item.isSubscriptionValid ? "space-active" : "space-inactive"}>{item.isSubscriptionValid ? "Active" : "Inactive"}</p>
                          </td>
                          <td>
                            <div className='mybots'>
                              <Link to={admin_prefix + "subscription?id=" + item._id} className='underline_none'>
                                <button variant='primary edit-btn' className='edit-btn1'>
                                  <i class='ri-money-rupee-circle-line rupee'></i>
                                </button>
                              </Link>
                              <button variant='primary edit-btn' className='edit-btn1' onClick={() => handleEditClick(item)}>
                                <i className='ri-pencil-line'></i>{" "}
                              </button>
                              <button variant='danger edit-btn' className='delete-btn1' onClick={() => handleDelete(item._id, item.space_name)}>
                                <i className='ri-delete-bin-line'></i>{" "}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className='middledata'>
                        <td colSpan={8}>
                          <div className='nodatafound1'>No data found</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className='pagination-container d-flex justify-content-between align-items-center mt-1 page-flx1'>
                <div className='mknk'>
                  <Form.Label>Rows per page:</Form.Label>
                  <select as='select' onChange={handlePageSizeChange} value={pagination.pageSize} className='form-select form-control dropon'>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                  </select>
                </div>

                <div className=' flex-pagination'>
                  <div>{renderPageRange()}</div>
                  <div className='mypage1'>
                    {pagination.pageIndex !== 1 && data.length !== 0 && (
                      <button
                        disabled={pagination.pageIndex === 1}
                        onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex - 1 }))}
                        class='button left-clickbtn'
                        id='button1'
                      >
                        <i className='ri-arrow-left-s-line'></i>
                      </button>
                    )}

                    <div class='button active' id='button1'>
                      {pagination.pageIndex}
                    </div>

                    {pagination.pageIndex !== pagination.totalPages && data.length !== 0 && (
                      <button
                        disabled={pagination.pageIndex === pagination.totalPages}
                        onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex + 1 }))}
                        class='button left-clickbtn'
                        id='button4'
                      >
                        <i className='ri-arrow-right-s-line'></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpenAdd && <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Add New Space"} onRefresh={handleRefresh} />}
        {isModalOpen && <EditModalComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={selectedItem} onRefresh={handleRefresh} />}
      </div>
    </>
  );
};

export default Component;
