import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import PrivateRoutes from "../../utils/PrivateRoute.js";
import PublicRoutes from "../../utils/PublicRoute.js";
import Login from "../../website/components/login/Component.js";
import { ToastContainer } from "react-toastify";
import SpaceLayout from "./components/ui/spaceLayout/Component.js";
import "./main.css";
import "react-toastify/dist/ReactToastify.css";
import TestComponent from "./components/TestComponent.js";


import Dashboard from "./screens/dashboard/Dashboard.js";

import Cabins from "./screens/master/Cabins.js";
import Seats from "./screens/master/Seats.js";
import MeetingRooms from "./screens/master/MeetingRooms.js";
import AreasComponent from "./screens/master/Areas.js";
import Amenities from "./screens/master/Amenities.js";

import Locations from "./screens/resource/Locations.js";
import Floors from "./screens/resource/Floors.js";
import AssetsMapping from "./screens/resource/AssetsMapping.js";

import TeamRoles from "./screens/team/Roles.js";
import TeamStaff from "./screens/team/Staff.js";
import TeamAttendance from "./screens/team/Attendance.js";

// occupancy pending

import Company from "./screens/client/Company.js";
import Member from "./screens/client/Member.js";
import Parking from "./screens/client/Parking.js";
import CreditHistory from "./screens/client/CreditHistory.js";

import CommunityPost from "./screens/community/Post.js";
import CommunityEvent from "./screens/community/Event.js";
import CommunityEventApply from "./screens/community/EventApply.js";
import CommunityOffer from "./screens/community/Offer.js";
import CommunityBulkEmail from "./screens/community/BulkEmail.js";

import Invoices from "./screens/invoice/Invoice.js";

import Expenses from "./screens/expense/Expenses.js";

import MeetingRoomsBooking from "./screens/booking/MeetingRooms.js";
import DayPassBooking from "./screens/booking/DayPass.js";

import Visitor from "./screens/visitor/Visitor.js";

import Enquirie from "./screens/enquirie/Enquirie.js";

import ClientSupport from "./screens/support/ClientSupport.js";
import DeskboxSupport from "./screens/support/DeskboxSupport.js";

import Subscription from "./screens/setting/Subscription.js";
import Profile from "./screens/setting/Profile.js";
import ActivityLog from "./screens/setting/ActivityLog.js";


const SpacesRoutes = () => {
  return (
    <>
      {/* <ToastContainer /> */}
      <SpaceLayout>
        <Routes>
          <Route path='dashboard' element={<Dashboard />} />

          <Route path='masters'>
            <Route path='cabins' element={<Cabins />}></Route>
            <Route path='seats' element={<Seats />}></Route>
            <Route path='meeting-rooms' element={<MeetingRooms />}></Route>
            <Route path='areas' element={<AreasComponent />}></Route>
            <Route path='amenities' element={<Amenities />}></Route>
          </Route>
          <Route path='resources'>
            <Route path='locations' element={<Locations />}></Route>
            <Route path='floors' element={<Floors />}></Route>
            <Route path='assets-mapping' element={<AssetsMapping />}></Route>
          </Route>
          <Route path='team'>
            <Route path='roles' element={<TeamRoles />}></Route>
            <Route path='staff' element={<TeamStaff />}></Route>
            {/* pending */}
            <Route path='attendance' element={<TeamAttendance />}></Route>
          </Route>
          <Route path='occupancy'>
            {/* <Route path='reports' element={<TestComponent />}></Route>
            <Route path='analytics' element={<TestComponent />}></Route> */}
          </Route>
          <Route path='client'>
            <Route path='companies' element={<Company />}></Route>
            <Route path='members' element={<Member />}></Route>
            <Route path='parking' element={<Parking />}></Route>
            <Route path='credits-history' element={<CreditHistory />}></Route>
            {/* <Route path='contracts' element={<TestComponent />}></Route> */}
          </Route>
          <Route path='community'>
            <Route path='posts' element={<CommunityPost />}></Route>
            <Route path='event' element={<CommunityEventApply />}></Route>
            <Route path='events' element={<CommunityEvent />}></Route>
            <Route path='offers' element={<CommunityOffer />}></Route>
            <Route path='bulk-email' element={<CommunityBulkEmail />}></Route>
          </Route>
          <Route path='invoices'>
            <Route path='all-invoices' element={<Invoices />}></Route>
            {/* <Route path='pending' element={<TestComponent />}></Route>
            <Route path='paid' element={<TestComponent />}></Route> */}
          </Route>
          <Route path='expenses'>
            <Route path='all-expenses' element={<Expenses />}></Route>
            {/* <Route path='pending' element={<TestComponent />}></Route>
            <Route path='paid' element={<TestComponent />}></Route> */}
          </Route>
          <Route path='bookings'>
            <Route path='meeting-rooms' element={<MeetingRoomsBooking />}></Route>
            <Route path='day-pass' element={<DayPassBooking />}></Route>
          </Route>
          <Route path='visitors'>
            <Route path='all-visitors' element={<Visitor />}></Route>
          </Route>
          <Route path='enquiries'>
            <Route path='all-enquiries' element={<Enquirie />}></Route>
            {/* <Route path='pending' element={<TestComponent />}></Route> */}
          </Route>
          <Route path='support'>
            <Route path='client-support' element={<ClientSupport />}></Route>
            <Route path='deskbox-support' element={<DeskboxSupport />}></Route>
          </Route>
          <Route path='settings'>
            <Route path='profile' element={<Profile />}></Route>
            <Route path='subscription' element={<Subscription />}></Route>
            {/* <Route path='activity-logs' element={<TestComponent />}></Route> */}
          </Route>
          {/* </Route> */}
        </Routes>
      </SpaceLayout>
    </>
  );
};

export default SpacesRoutes;
