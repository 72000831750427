import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, clientHeader, client_prefix } from "../../../../../../lib/lib.js";
import SearchableSelectComponentLocation from "../location_select/Component.js";
import SearchableSelectComponentFloor from "../floor_select/Component.js";
import SearchableSelectComponentSpace from "../company_select/Component.js";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { spaceId } = useParams();
  const [locationName, setLocationName] = useState("");
  const [floorName, setFloorName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    check_in: "",
    check_out: "",
    reason: "",
    description: "",
  });


  const [spaceList, setSpaceList] = useState([]);

  const [searchValueLocation, setSearchValueLocation] = useState("");
  const [suggestionsLocation, setSuggestionsLocation] = useState([]);
  const [showDropdownLocation, setShowDropdownLocation] = useState("");

  const [searchValueFloor, setSearchValueFloor] = useState("");
  const [suggestionsFloor, setSuggestionsFloor] = useState([]);
  const [showDropdownFloor, setShowDropdownFloor] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + clientpoints.space_view, {
        method: "POST",
        headers: clientHeader,
        body: JSON.stringify({ space_id: spaceId }),
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${client_prefix}`);
      }
      const data = await response.json();
      console.log('data - ', data);
      setSpaceList(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!locationName.trim()) {
      errors.location = "Location is required";
      isValid = false;
    }
    if (!floorName.trim()) {
      errors.floor = "Floor is required";
      isValid = false;
    }
    if (!companyName.trim()) {
      errors.company = "Company is required";
      isValid = false;
    }
    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.name = "Email is required";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      errors.name = "Phone is required";
      isValid = false;
    }
    if (!formData.check_in.trim()) {
      errors.check_in = "Check In is required";
      isValid = false;
    }
    if (!formData.reason.trim()) {
      errors.reason = "Reason is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.visitor_add, {
          method: "POST",
          headers: clientHeader,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            location_id: locationName,
            floor_id: floorName,
            company_id: companyName,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            check_in: formData.check_in,
            check_out: formData.check_out,
            reason: formData.reason,
            description: formData.description,
          }),
        });
        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        setLocationName("");
        setFloorName("");
        setCompanyName("");
        setFormData({
          name: "",
          email: "",
          phone: "",
          check_in: "",
          check_out: "",
          reason: "",
          description: "",
        });
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLocationName("");
    setFloorName("");
    setCompanyName("");
    setFormData({
      name: "",
      email: "",
      phone: "",
      check_in: "",
      check_out: "",
      reason: "",
      description: "",
    });
  };
  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">

                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="title" className="col-form-label">Select Location</label>
                      <SearchableSelectComponentLocation
                        setLocationName={setLocationName}
                        setFloorName={setFloorName}
                        searchValueProps={{ searchValueLocation, setSearchValueLocation }}
                        suggestionsProps={{ suggestionsLocation, setSuggestionsLocation }}
                        showDropdownProps={{ showDropdownLocation, setShowDropdownLocation }}
                        showDropdownPropsFloor={{ showDropdownFloor, setShowDropdownFloor }}
                        form={false}
                      />
                      {errors.location && (<div className="invalid-feedback">{errors.location}</div>)}
                    </div>



                    <div className="col-md-6">
                      <label htmlFor="title" className="col-form-label">Select Floor</label>
                      <SearchableSelectComponentFloor
                        locationName={locationName}
                        setFloorName={setFloorName}
                        searchValueProps={{ searchValueFloor, setSearchValueFloor }}
                        suggestionsProps={{ suggestionsFloor, setSuggestionsFloor }}
                        showDropdownProps={{ showDropdownFloor, setShowDropdownFloor }}
                        form={false}
                      />
                      {errors.floor && (<div className="invalid-feedback">{errors.floor}</div>)}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="title" className="col-form-label">Select Company</label>
                    <SearchableSelectComponentSpace
                      setCompanyName={setCompanyName}
                      searchValueProps={{ searchValue, setSearchValue }}
                      suggestionsProps={{ suggestions, setSuggestions }}
                      showDropdownProps={{ showDropdown, setShowDropdown }}
                      form={false}
                    />
                    {errors.company && (<div className="invalid-feedback">{errors.company}</div>)}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="name" className="col-form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? "is-invalid" : ""
                          }`}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.name}</div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="email" className="col-form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className={`form-control ${errors.email ? "is-invalid" : ""
                          }`}
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="phone" className="col-form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.phone ? "is-invalid" : ""
                          }`}
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <div className="invalid-feedback">{errors.phone}</div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="check_in" className="col-form-label">
                        Check-in Date
                      </label>
                      <input
                        type="datetime-local"
                        className={`form-control ${errors.check_in ? "is-invalid" : ""
                          }`}
                        id="check_in"
                        name="check_in"
                        value={formData.check_in}
                        onChange={handleChange}
                      />
                      {errors.check_in && (
                        <div className="invalid-feedback">
                          {errors.check_in}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="check_out" className="col-form-label">
                        Check-out Date
                      </label>
                      <input
                        type="datetime-local"
                        className={`form-control ${errors.check_out ? "is-invalid" : ""
                          }`}
                        id="check_out"
                        name="check_out"
                        value={formData.check_out}
                        onChange={handleChange}
                      />
                      {errors.check_out && (
                        <div className="invalid-feedback">
                          {errors.check_out}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="reason" className="col-form-label">
                        Reason
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.reason ? "is-invalid" : ""
                          }`}
                        id="reason"
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                      />
                      {errors.reason && (
                        <div className="invalid-feedback">{errors.reason}</div>
                      )}
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="description" className="col-form-label">
                        Description
                      </label>
                      <textarea
                        className={`form-control ${errors.description ? "is-invalid" : ""
                          }`}
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                      {errors.description && (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
