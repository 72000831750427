import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { spaceId } = useParams();
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "",
    space_dashboard: { add: false, edit: false, delete: false, view: false },
    cabin: { add: false, edit: false, delete: false, view: false },
    seat: { add: false, edit: false, delete: false, view: false },
    meeting: { add: false, edit: false, delete: false, view: false },
    area: { add: false, edit: false, delete: false, view: false },
    amenitie: { add: false, edit: false, delete: false, view: false },
    location: { add: false, edit: false, delete: false, view: false },
    floor: { add: false, edit: false, delete: false, view: false },
    assets: { add: false, edit: false, delete: false, view: false },
    role: { add: false, edit: false, delete: false, view: false },
    team: { add: false, edit: false, delete: false, view: false },
    company: { add: false, edit: false, delete: false, view: false },
    member: { add: false, edit: false, delete: false, view: false },
    contract: { add: false, edit: false, delete: false, view: false },
    credit: { add: false, edit: false, delete: false, view: false },
    parking: { add: false, edit: false, delete: false, view: false },
    invoice: { add: false, edit: false, delete: false, view: false },
    occupancy: { add: false, edit: false, delete: false, view: false },
    post: { add: false, edit: false, delete: false, view: false },
    event: { add: false, edit: false, delete: false, view: false },
    offer: { add: false, edit: false, delete: false, view: false },
    notification: { add: false, edit: false, delete: false, view: false },
    meeting_room: { add: false, edit: false, delete: false, view: false },
    day_pass: { add: false, edit: false, delete: false, view: false },
    visitor: { add: false, edit: false, delete: false, view: false },
    enquiry: { add: false, edit: false, delete: false, view: false },
    expense: { add: false, edit: false, delete: false, view: false },
    ticket: { add: false, edit: false, delete: false, view: false },
    client_ticket: { add: false, edit: false, delete: false, view: false },
    space: { add: false, edit: false, delete: false, view: false },
    subscription: { add: false, edit: false, delete: false, view: false },
    log_view: { add: false, edit: false, delete: false, view: false },
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = (role, action) => {
    setFormData({
      ...formData,
      [role]: {
        ...formData[role],
        [action]: !formData[role][action],
      },
    });
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) { errors.name = 'Name is required'; isValid = false; }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.role_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            ...formData,
          }),
        });
        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        setFormData(initialFormData);
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  const handleHeadingClick = (action) => {
    const updatedFormData = { ...formData };
    for (const [roleName, roleData] of Object.entries(updatedFormData)) {
      if (roleName === "name") continue;
      updatedFormData[roleName] = {
        ...roleData,
        [action]: !roleData[action] // Toggle the action property
      };
    }
    setFormData(updatedFormData); // Update the state with the modified formData
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg role_add_modal'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body' style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  <div className='row'>
                    <div className="col-md-12 mb-3" style={{ marginTop: '-20px' }}>
                      <label htmlFor="name" className="col-form-label">Role Name</label>
                      <input type="text" name='name' className={`form-control ${errors.name ? 'is-invalid' : ''}`} id="name" value={formData.name} onChange={handleChange} />
                      {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>
                    {formData && typeof formData === "object" && (
                      <div className='flex flex-col items-center'>
                        <table className='border-collapse w-full'>
                          <thead>
                            <tr className='bg-gray-200'>
                              <th className='border border-gray-400'>Name</th>
                              <th className='border border-gray-400' onClick={() => handleHeadingClick("add")}>Add</th>
                              <th className='border border-gray-400' onClick={() => handleHeadingClick("edit")}>Edit</th>
                              <th className='border border-gray-400' onClick={() => handleHeadingClick("view")}>View</th>
                              <th className='border border-gray-400' onClick={() => handleHeadingClick("delete")}>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(formData)
                              .filter(
                                ([roleName, roleData]) =>
                                  roleName === "space_dashboard" ||
                                  roleName === "cabin" ||
                                  roleName === "seat" ||
                                  roleName === "meeting" ||
                                  roleName === "area" ||
                                  roleName === "amenitie" ||
                                  roleName === "location" ||
                                  roleName === "floor" ||
                                  roleName === "assets" ||
                                  roleName === "role" ||
                                  roleName === "team" ||
                                  roleName === "company" ||
                                  roleName === "member" ||
                                  roleName === "contract" ||
                                  roleName === "credit" ||
                                  roleName === "parking" ||
                                  roleName === "invoice" ||
                                  roleName === "occupancy" ||
                                  roleName === "post" ||
                                  roleName === "event" ||
                                  roleName === "offer" ||
                                  roleName === "notification" ||
                                  roleName === "meeting_room" ||
                                  roleName === "day_pass" ||
                                  roleName === "visitor" ||
                                  roleName === "enquiry" ||
                                  roleName === "expense" ||
                                  roleName === "ticket" ||
                                  roleName === "client_ticket" ||
                                  roleName === "space" ||
                                  roleName === "subscription" ||
                                  roleName === "log_view"
                              )
                              .map(([roleName, roleData]) => (
                                <tr key={roleName}>
                                  <td className='border border-gray-400'>{roleName}</td>
                                  <td className='border border-gray-400'>
                                    <input type='checkbox' checked={roleData.add} onChange={() => handleCheckboxChange(roleName, "add")} />
                                  </td>
                                  <td className='border border-gray-400'>
                                    <input type='checkbox' checked={roleData.edit} onChange={() => handleCheckboxChange(roleName, "edit")} />
                                  </td>
                                  <td className='border border-gray-400'>
                                    <input type='checkbox' checked={roleData.view} onChange={() => handleCheckboxChange(roleName, "view")} />
                                  </td>
                                  <td className='border border-gray-400'>
                                    <input type='checkbox' checked={roleData.delete} onChange={() => handleCheckboxChange(roleName, "delete")} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
