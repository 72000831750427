import React from "react";
import "../../../../nav/components/location/component.css";

// Card for Areas, Amenities
function Card2({ data, setSelectedItem, setIsEditModal, handleDelete, type = "other" }) {
  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6'>
      <div className='cabins-card'>
        {/* <div class="cabin-sideline"></div> */}
        <div class='cabin-sideline1'></div>
        <div className='row'>
          <div className='col-12'>
            <div className='cabin-sheet'>
              <div className='cabin-number'>
                <i class='ri-keyboard-box-line keybord-icn'></i>
              </div>
              <div className='cabin-text'>
                <p className='cabin-totalseat'>{data?.name}</p>
              </div>
            </div>
            <div className='botflx-cafit'>
              <div className='cabin-editicn'>
                <i
                  class='ri-pencil-line pencil-icn'
                  onClick={() => {
                    setSelectedItem(data._id);
                    setIsEditModal(true);
                  }}
                ></i>
                <i
                  class='ri-delete-bin-6-line trash-icn'
                  onClick={() => {
                    handleDelete(data._id, data?.name);
                  }}
                ></i>
              </div>
              {/* <button className="view-btn-2">Manage Floors</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card2;
