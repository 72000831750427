import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import "./component.css";
import { LoadingMutatingDots } from "../../../ui/Loadings.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  const navigate = useNavigate();
  const { spaceId } = useParams();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;
  const [mode, setMode] = useState(view);

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "",
    number_of_person: "",
    preffix: "",
    suffix: "",
    type: "",
    price: "",
    meeting_credit: "",
    printing_credit: "",
    // area: "",
    // status: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let seat_Id = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.price || parseFloat(formData.price) <= 0) {
      errors.price = "Price must be greater than 0.";
      isValid = false;
    }

    if (!formData.number_of_person || parseFloat(formData.number_of_person) < 1) {
      // errors.number_of_person = "Number of persons must be greater than or equal to 1.";
      errors.number_of_person = "Number of persons must be greater than 0.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && seat_Id) {
      setLoading(true);
      fetchData(seat_Id).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, seat_Id]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.seat_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          name: response_data.name,
          number_of_person: response_data.number_of_person,
          preffix: response_data.preffix,
          suffix: response_data.suffix,
          type: response_data.type,
          price: response_data.price,
          meeting_credit: response_data.meeting_credit,
          printing_credit: response_data.printing_credit,
          area: response_data.area,
          status: response_data.status || false,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.seat_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            filters: { _id: current_id },
            data: {
              ...formData,
            },
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `View ${modalName}` : `Edit ${modalName}`}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}

              {loading ? (
                <LoadingMutatingDots />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className='modal-body'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor='name' className='col-form-label'>
                          Name
                        </label>
                        <input placeholder='Dedicated' disabled={mode === "view"} type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                        {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='number_of_person' className='col-form-label'>
                          Number of Persons
                        </label>
                        <input placeholder='4' disabled={mode === "view"} type='number' className={`form-control ${errors.number_of_person ? "is-invalid" : ""} `} id='number_of_person' name='number_of_person' value={formData.number_of_person} onChange={handleChange} />
                        {errors.number_of_person && <div className='invalid-feedback'>{errors.number_of_person}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='preffix' className='col-form-label'>
                          Prefix
                        </label>
                        <input placeholder='4' disabled={mode === "view"} type='text' className={`form-control `} id='preffix' name='preffix' value={formData.preffix} onChange={handleChange} />
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='suffix' className='col-form-label'>
                          Suffix
                        </label>
                        <input placeholder='A' disabled={mode === "view"} type='text' className={`form-control `} id='suffix' name='suffix' value={formData.suffix} onChange={handleChange} />
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='type' className='col-form-label'>
                          Type
                        </label>
                        <select disabled={mode === "view"} id='type' className={`form-select`} value={formData.type} onChange={(e) => setFormData((prev) => ({ ...prev, type: e.target.value }))}>
                          <option value='Numeric'>Numeric</option>
                          <option value='Alphabet'>Alphabet</option>
                        </select>
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='price' className='col-form-label'>
                          Price
                        </label>
                        <input placeholder='30000' disabled={mode === "view"} type='number' className={`form-control ${errors.price ? "is-invalid" : ""}`} id='price' name='price' value={formData.price} onChange={handleChange} />
                        {errors.price && <div className='invalid-feedback'>{errors.price}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='meeting_credit' className='col-form-label'>
                          Meeting Credit
                        </label>
                        <input placeholder='10' disabled={mode === "view"} type='text' className={`form-control `} id='meeting_credit' name='meeting_credit' value={formData.meeting_credit} onChange={handleChange} />
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='printing_credit' className='col-form-label'>
                          Printing Credit
                        </label>
                        <input placeholder='10' disabled={mode === "view"} type='text' className={`form-control `} id='printing_credit' name='printing_credit' value={formData.printing_credit} onChange={handleChange} />
                      </div>

                      {/* <div className='col-md-12'>
                        <label htmlFor='area' className='col-form-label'>
                          Area
                        </label>
                        <input type='text' className={`form-control `} id='area' name='area' value={formData.area} onChange={handleChange} />
                      </div> */}
                    </div>
                  </div>

                  <div className='modal-footer d-flex justify-content-between align-items-center'>
                    <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                    <div className='d-flex gap-2'>
                      {mode !== "view" && (
                        <>
                          <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                            Close
                          </button>
                          {/* <button type="submit" className="btn btn-primary">Update</button> */}
                          <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                            {isSubmitting ? "Loading..." : "Update"}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
