import React, { useState, useEffect, useRef, useContext } from 'react';
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from '../../../../../../lib/lib.js';
import { AuthUserContext } from '../../../../../../lib/AuthUserContext.js';
import { useParams, useNavigate } from "react-router-dom";


const Component = ({ setLocationName, searchValueProps, suggestionsProps, showDropdownProps, form = true, setCompanyName, setSearchValueCompany, setShowDropdownCompany, setMemberName, setSearchValueMember, setShowDropdownMember }) => {
    const navigate = useNavigate();
    const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
    const { typeTeamRoleObject } = useContext(AuthUserContext);
    let pageHeaders = whoAmIHeaders(whoAmI);
    let getLocalStorageData;

    useEffect(() => {
        if (!whoAmI) {
            getLocalStorageData = getDecryptedDataFromLocalStorage('type');
            setWhoAmI(getLocalStorageData);
            pageHeaders = whoAmIHeaders(getLocalStorageData);
        } else {
            pageHeaders = whoAmIHeaders(whoAmI);
        }
    }, []);
    const { spaceId } = useParams();
    const { searchValueLocation, setSearchValueLocation } = searchValueProps;
    const { suggestionsLocation, setSuggestionsLocation } = suggestionsProps;
    const { showDropdownLocation, setShowDropdownLocation } = showDropdownProps;


    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    let [countIndex, setCountIndex] = useState(0);
    const [selectedLocations, setSelectedLocations] = useState([]);

    const [message, setMessage] = useState('Please select location');
    const loadOptions = async () => {
        try {
            const response = await fetch(url + clientpoints.location_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ urlName: spaceId, search: searchValueLocation }),
                credentials: 'include'
            });
            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsLocation(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const fetchDataForPage = async (page) => {
        try {
            const response = await fetch(url + clientpoints.location_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', urlName: spaceId, page: page, size: perPage, sorting: { "createdAt": "1" } }), // Fetch data for specific page
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const newData = responseData.Data;

            setSuggestionsLocation(prevSuggestions => [...prevSuggestions, ...newData]);
            setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleScroll = async (event) => {
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const clientHeight = event.target.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - 10) {

            const nextPageCount = currentPage + 1;

            if (nextPageCount <= totalPages) {
                setCurrentPage(nextPageCount);
                fetchDataForPage(nextPageCount);
            }
        }
    };

    const loadOptionsOnClick = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(url + clientpoints.location_view, {
                method: 'POST',
                headers: pageHeaders,
                body: JSON.stringify({ search: '', urlName: spaceId, page: countIndex + 1, sorting: { "createdAt": "1" } }), // Increment countIndex by 1
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${client_prefix}`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsLocation(data);
            setTotalPages(responseData.totalCount);
            setSearchValueLocation("");
        } catch (error) {
            console.error('Error fetching options:', error);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Update the count whenever selectedLocations changes
        const count = selectedLocations.length;
        setShowDropdownLocation(count === 0 ? "Please select a location" : `${count} Location Selected`);
        setLocationName(selectedLocations);
    }, [selectedLocations]);

    const handleOptionClick = (clickedLocation) => {
        if (clickedLocation === '') {
            const isAllSelected = selectedLocations.length === suggestionsLocation.length;
            setSelectedLocations(isAllSelected ? [] : [...suggestionsLocation]);
        } else {
            const isSelected = selectedLocations.some(selectedLocation => selectedLocation._id === clickedLocation._id);

            if (isSelected) {
                setSelectedLocations(prevSelectedLocations => prevSelectedLocations.filter(location => location._id !== clickedLocation._id));
            } else {
                setSelectedLocations(prevSelectedLocations => [...prevSelectedLocations, clickedLocation]);
            }

            const isAllSelected = selectedLocations.length === suggestionsLocation.length;

            if (isAllSelected && suggestionsLocation.some(location => location._id === clickedLocation._id)) {
                setSelectedLocations(prevSelectedLocations => prevSelectedLocations.filter(location => location._id !== clickedLocation._id));
            }
        }
        setMemberName('');
        setSearchValueMember('');
        setShowDropdownMember('');
        setCompanyName('');
        setSearchValueCompany('');
        setShowDropdownCompany('');
    };



    // const handleOptionClick = (selectedOption) => {
    // if (selectedOption) {
    //     const { location, _id } = selectedOption;
    //     setLocationName(_id);
    //     setSearchValueLocation(location);
    //     setShowDropdownLocation(location);
    //     setSuggestionsLocation([]);
    // }
    // else if (selectedOption == "") {
    //     setSearchValueLocation("All Location");
    //     setShowDropdownLocation("All Location");
    //     setLocationName('');
    //     setSuggestionsLocation([]);
    // }
    // else {
    //     setSearchValueLocation('');
    //     setShowDropdownLocation('');
    //     setLocationName('');
    //     setSuggestionsLocation([]);
    // }
    // setCompanyName('');
    // setSearchValueCompany('');
    // setShowDropdownCompany('');
    // };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                setSuggestionsLocation([]);
                setCurrentPage(1);
                setPerPage(10);
                setTotalPages(0)
                setSearchValueLocation(showDropdownLocation);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showDropdownLocation]);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <i className="ri-arrow-down-s-line ab1" onClick={loadOptionsOnClick}></i>
            <input
                autoComplete="off"
                ref={inputRef}
                placeholder={form ? "Search for Location..." : "Select Location"}
                onChange={e => setSearchValueLocation(e.target.value)}
                value={searchValueLocation}
                onKeyUp={() => loadOptions(searchValueLocation)}
                onClick={loadOptionsOnClick}
                id="spaceName"
                className="search-input form-control customSelect w-100"
            />
            {isLoading ? (
                <div className="spinner-border text-primary my_spin" role="status"><span className="sr-only">Loading...</span></div>
            ) : suggestionsLocation.length > 0 && (
                <ul
                    id="dropdownMenu"
                    onScroll={handleScroll}
                    style={{ overflowY: 'scroll', height: '200px' }}
                    className="dropdown-menu mydrop_hgt"
                >
                    {form ? (<li onClick={() => handleOptionClick('')}>All Location<span className="hidden" data-client-id={''}></span></li>) : ('')}
                    {
                        suggestionsLocation.length > 0 ? (
                            suggestionsLocation.map((location) => (

                                <li key={location._id} onClick={() => handleOptionClick(location)} style={{ "display": "flex", "justify-content": "space-between" }}>
                                    <div style={{ marginRight: '10px' }}>
                                        {location.location}
                                    </div>
                                    <div style={{ position: 'relative', width: '30px', height: '30px' }}>
                                        <input
                                            type="checkbox"
                                            checked={selectedLocations.some(selectedLocation => selectedLocation._id === location._id)}
                                            onChange={() => handleOptionClick(location)}
                                            style={{ opacity: 0, width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, cursor: 'pointer' }}
                                        />
                                        {selectedLocations.some(selectedLocation => selectedLocation._id === location._id) && (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '30px',
                                                    fontWeight: 'bold',
                                                    color: 'blue',
                                                }}
                                            >
                                                <i class="ri-check-line"></i>
                                            </div>

                                        )}
                                    </div>
                                </li>


                            ))
                        ) : (
                            <li>No data found</li>
                        )
                    }
                </ul>
            )
            }
        </div >
    );
};

export default Component;