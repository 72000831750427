import MasterAmenities from '../../components/master/amenity/table/Component';
import { toast, ToastContainer } from "react-toastify";
export default function Component() {

  return (
    <>
      <ToastContainer />
      <MasterAmenities name="Amenities" />
    </>
  );
}
