import CreditHistory from '../../components/client/credit_history/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <CreditHistory name="All Credit History" />
        </>
    );
}