import React from 'react';
import './component.css'; // Import your CSS file

function Component() {
  return (
    <div className="container1 dashboardfont">
      <form className="navbar-form navbar-left" action="#">
        <div className="input-group">
          <input type="text" className=" search-inp" placeholder="Search Features" name="search" />
          <button className="btn btn-default" type="submit">
            <i className="ri-search-line"></i>
          </button>
        </div>
      </form>

      <div className="icons">

        <button className="icon1 helpbot"><i className="ri-notification-line"></i></button>
        <button className="square-btn icon">&Work Coworking </button>
        <div className='user-name'>

          <div class="space-nav2">
            <div className='top-side-border'>

              <div className='space-user-n1'>
                <i class="ri-user-line user-icn-nav"></i>
                <p className='user-txt'>  Amit Kumar</p>
              </div>

            </div>
            
          </div>
          {/* <div className='user-n1'>
        <button className="circle-btnname icon">E</button>

          </div> */}
        </div>

      </div>
    </div>
  );
}

export default Component;
