import React, { useEffect, useState, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import { url, getDecryptedDataFromLocalStorage } from "../lib/lib";
import { LoadingMutatingDots } from "../panels/spaces/components/ui/Loadings";
import { AuthUserContext } from "../lib/AuthUserContext";

function PublicRoutes() {
  const [cookies] = useCookies(["access"]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let getLocalStorageData;

  let requestBody;
  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      console.log('aa', getLocalStorageData);
      setWhoAmI(getLocalStorageData);
      if (getLocalStorageData == 'client') {
        requestBody = { type: getLocalStorageData };
      } else {
        requestBody = {};
      }
    } else {
      if (whoAmI == 'client') {
        requestBody = { type: whoAmI };
      } else {
        requestBody = {};
      }
    }
  }, []);


  useEffect(() => {
    async function authenticate() {
      try {
        let response = await fetch(url + "/frontend-auth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
          credentials: "include",
        });

        const data = await response.json();

        console.log("status");

        if (data.Status) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    }
    authenticate();
  }, [cookies]);

  if (isLoading) {
    return <LoadingMutatingDots />;
  }

  if (isAuthenticated) {
    return <Navigate to='/spaces' replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default PublicRoutes;
