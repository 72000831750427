import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, memberHeader, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { toast } from "react-toastify";
import { AuthUserContext } from '../../../../../../lib/AuthUserContext.js';
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  
  const { spaceId } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [memberName, setMemberName] = useState("");

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const [spaceList, setSpaceList] = useState([]);

  const [searchValueCompany, setSearchValueCompany] = useState("");
  const [searchValueMember, setSearchValueMember] = useState("");

  const [suggestionsCompany, setSuggestionsCompany] = useState([]);
  const [showDropdownCompany, setShowDropdownCompany] = useState("");
  const [suggestionsMember, setSuggestionsMember] = useState([]);
  const [showDropdownMember, setShowDropdownMember] = useState("");

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + clientpoints.space_view, {
        method: "POST",
        headers: memberHeader,
        body: JSON.stringify({ urlName: spaceId }),
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${client_prefix}`);
      }
      const data = await response.json();
      setSpaceList(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.post_add, {
          method: "POST",
          headers: memberHeader,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            title: formData.title,
            type: 'member',
            description: formData.description,
          }),
        });
        setCompanyName("");
        setMemberName("");
        setFormData({
          title: "",
          description: "",
        });
        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCompanyName("");
    setMemberName("");
    setFormData({
      title: "",
      description: "",
    });
  };
  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="title" className="col-form-label">Title</label>
                      <input type="text" className={`form-control ${errors.title ? "is-invalid" : ""}`} id="title" name="title" value={formData.title} onChange={handleChange} />
                      {errors.title && (<div className="invalid-feedback">{errors.title}</div>)}
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="description" className="col-form-label">Description</label>
                      <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id="description" name="description" value={formData.description} onChange={handleChange} />
                      {errors.description && (<div className="invalid-feedback"> {errors.description} </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
