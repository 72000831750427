import React, { useState, useEffect, useContext, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { LoadingMutatingDots } from "../../components/ui/Loadings.js";
import { useParams, useNavigate } from "react-router-dom";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../lib/lib.js";
import DashboardCard from '../../components/dashboard/Component.js';
import { AuthUserContext } from "../../../../lib/AuthUserContext.js";

const Component = () => {
    const navigate = useNavigate();
    const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
    const { typeTeamRoleObject } = useContext(AuthUserContext);
    let pageHeaders = whoAmIHeaders(whoAmI);
    let getLocalStorageData;

    useEffect(() => {
        if (!whoAmI) {
            getLocalStorageData = getDecryptedDataFromLocalStorage('type');
            setWhoAmI(getLocalStorageData);
            pageHeaders = whoAmIHeaders(getLocalStorageData);
        } else {
            pageHeaders = whoAmIHeaders(whoAmI);
        }
    }, []);

    const [isLoading, setIsLoading] = useState(true);
    const [record, setRecord] = useState('');
    const { spaceId } = useParams();

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const requestBody = { urlName: spaceId, };

            const response = await fetch(url + clientpoints.dashboard, {
                method: "POST",
                headers: pageHeaders,
                credentials: "include",
                body: JSON.stringify(requestBody),
            });

            // if (!response.ok) { navigate(`${client_prefix}`); }

            const data = await response.json();

            setRecord(data.Data);
            setIsLoading(false);
        } catch (error) {

            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (pageHeaders) {
            fetchData();
        }
    }, []);
    const handleRefresh = () => { fetchData(); };
    return (
        <>
            <ToastContainer />
            <div className="dashbord-option">
                <div className='inner-padding'>
                    <div className='featured-flx mb-0'>
                        <div className='featured-flx1'>
                            <p className='main_heading1'>Dashboard</p>
                        </div>
                        <div className='featured-flx1'>
                            <button className="circle-btn-add icon" type="button" onClick={handleRefresh}>
                                {isLoading ? (
                                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                                ) : (
                                    <i className='ri-refresh-line'></i>
                                )}
                            </button>
                        </div>
                    </div>

                    <br />
                    {isLoading ? (
                        <LoadingMutatingDots />
                    ) : (
                        <div className='row'>
                            {
                                (() => {
                                    switch (whoAmI) {
                                        case 'member':
                                            return (
                                                <>
                                                    {/* <DashboardCard getTitle="Total Member" getValue={record.team} page="team/staff" />
                                                    <DashboardCard getTitle="Total Visitor" getValue={record.active_space} page="visitors/all-visitors" filters="true" />
                                                    <DashboardCard getTitle="Open Ticket" getValue={record.client_ticket} page="support/client-support" /> */}
                                                    <DashboardCard getTitle="Total Member" getValue={1} page="team/staff" />
                                                    <DashboardCard getTitle="Total Visitor" getValue={1} page="visitors/all-visitors" filters="true" />
                                                    <DashboardCard getTitle="Open Ticket" getValue={1} page="support/client-support" />
                                                </>
                                            );
                                        case 'team':
                                            return (
                                                <>
                                                    {typeTeamRoleObject?.company?.view && (<DashboardCard getTitle="Total Company" getValue={record.company} page="client/companies" />)}
                                                    {typeTeamRoleObject?.team?.view && (<DashboardCard getTitle="Total Team" getValue={record.team} page="team/staff" />)}
                                                    {typeTeamRoleObject?.visitor?.view && (<DashboardCard getTitle="Total Visitor" getValue={record.active_space} page="visitors/all-visitors" filters="true" />)}
                                                    {typeTeamRoleObject?.enquiry?.view && (<DashboardCard getTitle="Total Enquiries" getValue={record.in_active_space} page="enquiries/all-enquiries" filters="false" />)}
                                                    {typeTeamRoleObject?.post?.view && (<DashboardCard getTitle="Total Community" getValue={record.in_active_space} page="community/posts" filters="false" />)}
                                                    {typeTeamRoleObject?.ticket?.view && (<DashboardCard getTitle="Open Ticket" getValue={record.client_ticket} page="support/client-support" />)}
                                                    {typeTeamRoleObject?.subscription?.view && (<DashboardCard getTitle="Total Revenue" getValue={record.revenue} page="dashboard" />)}
                                                </>
                                            );
                                        default:
                                            return (
                                                <>
                                                    <DashboardCard getTitle="Total Company" getValue={record.company} page="client/companies" />
                                                    <DashboardCard getTitle="Total Team" getValue={record.team} page="team/staff" />
                                                    <DashboardCard getTitle="Total Visitor" getValue={record.active_space} page="visitors/all-visitors" filters="true" />
                                                    <DashboardCard getTitle="Total Enquiries" getValue={record.in_active_space} page="enquiries/all-enquiries" filters="false" />
                                                    <DashboardCard getTitle="Total Community" getValue={record.in_active_space} page="community/posts" filters="false" />
                                                    <DashboardCard getTitle="Open Ticket" getValue={record.client_ticket} page="support/client-support" />
                                                    <DashboardCard getTitle="Total Revenue" getValue={record.revenue} page="dashboard" />
                                                </>
                                            );
                                    }
                                })()
                            }

                        </div>

                    )}
                </div>
            </div>
        </>
    );
}

export default Component