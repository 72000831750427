import React, { useRef, useState } from "react";
import "./cards.css";

function Card1({ data, setSelectedItem, setIsModalOpenApply, setIsDeleteModal }) {
    return (
        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 roleClass'>
            <div className='post-card member_event'>
                <div class="post-sideline1">
                    <i className="ri-add-box-line more-detail" onClick={() => { setSelectedItem(data); setIsModalOpenApply(true); }}></i>
                </div>
                <img src="/img/events.jpg" className='img-fluid post-image' />
                <div className='row over-dropdown'>
                    <div className='col-12'>
                        <div class="droptop ">
                            <div type="button" class="show" >
                                {/* <i className="ri-more-2-line more-detail"></i> */}
                                <div className='cabin-editicn'>
                                    {/* <i class='ri-pencil-line pencil-icn' onClick={() => { setSelectedItem(data); setIsModalOpenEdit(true); }} ></i>
                                    <i class='ri-delete-bin-6-line trash-icn' onClick={() => { setSelectedItem(data); setIsDeleteModal(true); }} ></i> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='post-inner-txt'>
                    <p class="posts-title"><h4>{(data.name) && (data.name)}</h4></p>
                    <div className='row'>
                        <div className='col-6'>
                            <p className='seatbox-txt'>{(data.meeting_id) && (data.meeting_id.number_of_person)} : Seater</p>
                        </div>
                        <div className='col-6 '>

                            <p className='seatbox-txt'>{(data.meeting_id) && (data.meeting_id.credit_deduction)} : Credit</p>

                        </div>
                    </div>
                </div>
                <div className='box-back'>
                    <div className='row'>
                        <div className='col-6 rgt-bdr'>
                            <p className='seatbox-txt'>Location</p>
                            <p className='seatbox-desc'>{(data.location_id) && (data.location_id.location)}</p>
                        </div>
                        <div className='col-6 '>
                            <center>
                                <p className='seatbox-txt'>Floor</p>
                                <p className='seatbox-desc'>{(data.floor_id) && (data.floor_id.floor_name)}</p>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card1;
