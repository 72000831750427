import React, { useEffect, useState, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import { url } from "../../../lib/lib";
import { LoadingMutatingDots } from "../../spaces/components/ui/Loadings";
import { useParams, useNavigate } from "react-router-dom";
function MemberPublicRoutes() {
  const [cookies] = useCookies(["access_team"]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { spaceId } = useParams();

  useEffect(() => {
    async function authenticate() {
      try {
        let response = await fetch(url + "/frontend-auth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type: 'member' }),
          credentials: "include",
        });

        const data = await response.json();

        if (data.Status) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    }
    authenticate();
  }, [cookies]);

  if (isLoading) {
    return <LoadingMutatingDots />;
  }

  if (isAuthenticated) {
    return <Navigate to={`/${spaceId}/member/dashboard`} replace />;
    
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default MemberPublicRoutes;
