import React, { useState, useEffect, useRef } from 'react';
import { url, adminpoints, admin_prefix, adminHeader } from '../../../../../lib/lib.js';
import { useNavigate } from "react-router-dom";

const Component = ({ setClientName, setSpaceName, searchValueProps, suggestionsProps, showDropdownProps, searchValueProps_s, suggestionsProps_s, showDropdownProps_s, form = true, setPagination = '' }) => {
    const navigate = useNavigate();
    const { searchValueClient, setSearchValueClient } = searchValueProps;
    const { suggestionsClient, setSuggestionsClient } = suggestionsProps;
    const { showDropdownClient, setShowDropdownClient } = showDropdownProps;
    const [isLoading, setIsLoading] = useState(false);
    const { searchValue, setSearchValue } = searchValueProps_s;
    const { suggestions, setSuggestions } = suggestionsProps_s;
    const { showDropdown, setShowDropdown } = showDropdownProps_s;
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    let [countIndex, setCountIndex] = useState(0);
    const loadOptions = async () => {
        try {
            const response = await fetch(url + adminpoints.client_view, {
                method: 'POST',
                headers: adminHeader,
                body: JSON.stringify({ search: searchValueClient }),
                credentials: 'include'
            });
            if (!response.ok) {
                navigate(`${admin_prefix}login`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsClient(data);
        } catch (error) {
            navigate(`${admin_prefix}login`);
        }
    };

    const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
    const [perPage, setPerPage] = useState(10); // State variable to track entries per page
    const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

    const fetchDataForPage = async (page) => {
        try {
            const response = await fetch(url + adminpoints.client_view, {
                method: 'POST',
                headers: adminHeader,
                body: JSON.stringify({ search: '', page: page, size: perPage, sorting: { "createdAt": "-1" } }), // Fetch data for specific page
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${admin_prefix}login`);
            }
            const responseData = await response.json();
            const newData = responseData.Data;

            // setSuggestionsClient(prevSuggestions => [...prevSuggestions, ...newData]);
            // setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
            setSuggestionsClient(prevSuggestions => [...prevSuggestions, ...newData]);
            setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
        } catch (error) {
            navigate(`${admin_prefix}login`);
        }
    };

    const handleScroll = async (event) => {
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const clientHeight = event.target.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - 10) {

            const nextPageCount = currentPage + 1;

            if (nextPageCount <= totalPages) {
                setCurrentPage(nextPageCount);
                fetchDataForPage(nextPageCount);
            }
        }
    };

    const loadOptionsOnClick = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(url + adminpoints.client_view, {
                method: 'POST',
                headers: adminHeader,
                body: JSON.stringify({ search: '', page: countIndex + 1, size: 10, sorting: { "createdAt": "-1" } }), // Increment countIndex by 1
                credentials: 'include'
            });

            if (!response.ok) {
                navigate(`${admin_prefix}login`);
            }
            const responseData = await response.json();
            const data = responseData.Data;
            setSuggestionsClient(data);
            setTotalPages(responseData.totalCount);
            setSearchValueClient("");
        } catch (error) {
            setIsLoading(false);
            navigate(`${admin_prefix}login`);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleOptionClick = (selectedOption) => {
        const currentPageIndex = 1;
        setPagination((prevPagination) => ({ ...prevPagination, pageIndex: currentPageIndex, }));
        setSearchValue("All Spaces");
        setShowDropdown("All Spaces");
        setSpaceName('');
        setSuggestions([]);
        const urlParams = new URLSearchParams(window.location.search);
        const idGet = urlParams.get('id');
        if (selectedOption) {
            const { name, _id } = selectedOption;
            setSearchValueClient(name);
            setClientName(_id);
            setSpaceName('');
            setShowDropdownClient(name);
            setSuggestionsClient([]);

            if (idGet != null && idGet.trim() != '') {
                const idFromUrl = urlParams.delete('id');
                navigate(admin_prefix + 'subscription');
            }

        }
        else if (selectedOption == "") {
            setSearchValueClient("All Clients");
            setSpaceName('');
            setClientName('');
            setShowDropdownClient("All Clients");
            setSuggestionsClient([]);
            if (idGet != null) {
                const idFromUrl = urlParams.delete('id');
                navigate(admin_prefix + 'subscription');
            }
        }
        else {
            setSearchValueClient('');
            setSpaceName('');
            setShowDropdownClient('');
            setClientName('');
            setSuggestionsClient([]);
        }
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                setSuggestionsClient([]);
                setCurrentPage(1);
                setPerPage(10);
                setTotalPages(0)
                setSearchValueClient(showDropdownClient);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showDropdownClient]);

    return (
        <div className='sd2drop'>
            <div className="dropdown" ref={dropdownRef}>
                <i className="fa fa-angle-down ab1" onClick={loadOptionsOnClick}></i>
                <input
                    autoComplete="off"
                    ref={inputRef}
                    placeholder={form ? "Search Clients..." : "Select Clients"}
                    onChange={e => setSearchValueClient(e.target.value)}
                    value={searchValueClient}
                    onKeyUp={() => loadOptions(searchValueClient)}
                    onClick={loadOptionsOnClick}
                    id="spaceName"
                    className="search-input form-control customSelect w-100"
                />
                {isLoading ? (
                    <div className="spinner-border text-primary my_spin" role="status"><span className="sr-only">Loading...</span></div>
                ) : suggestionsClient.length > 0 && (
                    <ul
                        id="dropdownMenu sd2drop"
                        onScroll={handleScroll}
                        style={{ overflowY: 'scroll', height: '200px' }}
                        className="dropdown-menu mydrop_hgt"
                    >
                        {form ? (
                            <li onClick={() => handleOptionClick('')}>All Clients<span className="hidden" data-client-id={''}></span></li>
                        ) : ('')}
                        {suggestionsClient.length > 0 ? (
                            suggestionsClient.map((client) => (
                                <li key={client._id} onClick={() => handleOptionClick(client)}>
                                    {client.name}
                                    <span className="hidden" data-client-id={client._id}></span>
                                </li>
                            ))
                        ) : (
                            <li>No data found</li>
                        )}

                    </ul>
                )}

            </div>
        </div>
    );
};

export default Component;