import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="section2">
            


      <div className="container">
       
        <div className="row feature1-top">
          <div className="col-lg-6"> 
          <div className="feature-padd">
         <p className="deskbox-txt2">Community Engagement & Management</p>
         <p className="deskbox-descri">Connect with your audience, build strong relationships, and increase brand loyalty.</p>
         <div className="desk-top-2">
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Posts Engagements</p>
                <p className="feature1-txt2">Engage with your community in meaningful ways to foster a deeper connection and relationships.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Events Management</p>
                <p className="feature1-txt2">Take control of your event planning with our exceptional event management features.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Offers Management</p>
                <p className="feature1-txt2">Build powerful relationships with your customers and foster unwavering brand loyalty. </p>
            </div>
         </div>
         </div>
           </div>
           </div>
          <div className="col-lg-6">
          <center><img src="img/posts.png" className="img-fluid feature-img"/></center>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Component;
