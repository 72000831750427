import React, { useState, useEffect, useMemo, useContext } from "react";
import Select from "react-select";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { toast } from "react-toastify";
import { validate } from "../../../../../../utils/ValidateForms";
import { url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext";
import Table from "../table/Component";
import { useParams, useNavigate } from "react-router-dom";

function Component({ addFunc, updateFunc, isModalOpen, setIsModalOpen, view = "add", editItem = {}, locations = [] }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();

  const [mode, setMode] = useState("Update");

  const [modal, setModal] = useState(true);
  const [seats, setSeats] = useState([]);
  const [cabins, setCabins] = useState([]);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [areas, setAreas] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [cabinNames, setCabinNames] = useState([]);
  const [seatNames, setSeatNames] = useState([]);
  const [meetingNames, setMeetingNames] = useState([]);

  const myLocations = useMemo(() => locations?.map((item) => item), []);

  const [formData, setFormData] = useState({
    location_id: "",
    floor_id: "",
    cabin_mapping: [],
    seat_mapping: [],
    meeting_mapping: [],
    // area_mapping: [],
    // amenitie_mapping: [],
  });

  const [selectValues, setSelectValues] = useState({
    location_id: "",
    floor_id: "",
  });

  const [matchingFloors, setMatchingFloors] = useState([]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (selectValues.location_id.value && selectValues.floor_id.value) {
      fetchMappingData();
    }
  }, [selectValues.location_id.value, selectValues.floor_id.value]);

  const fetchMappingData = async () => {
    try {
      const sortingObject = { floor_number: 1 };

      const requestBody = {
        urlName: spaceId,
        filters: { location_id: selectValues.location_id.value, floor_id: selectValues.floor_id.value },
      };

      const response = await fetch(url + "/mapping/view", {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();

      if (data.Data.length > 0) {
        setMode("Edit");

        // for mapping data fetched --------------------------------------------------------
        const mappingData = data.Data[0];

        const {
          // amenitie_mapping, area_mapping,
          seat_mapping,
          cabin_mapping,
          meeting_mapping,
        } = mappingData;

        const mappedSeatsData = mapItems(seats, seat_mapping, "seat");
        const mappedCabinsData = mapItems(cabins, cabin_mapping, "cabin");
        const mappedMeetingsData = mapItems(meetingRooms, meeting_mapping, "meeting");

        setFormData({
          // ...mappingData,
          location_id: mappingData.location_id._id,
          floor_id: mappingData.floor_id._id,
          // amenitie_mapping: amenitie_mapping?.map((item) => item._id),
          // area_mapping: area_mapping?.map((item) => item._id),
          seat_mapping: mappedSeatsData,
          cabin_mapping: mappedCabinsData,
          meeting_mapping: mappedMeetingsData,
        });

        // to get meeting names, cabin names, seat names if mapping data found ----------------------------------------------
        const meetingNamesResponse = await fetch(url + "/meeting_name/view", {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(requestBody),
        });

        const meetingData = await meetingNamesResponse.json();

        const cabinSeatNamesResponse = await fetch(url + "/cabin_seat_name/view", {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(requestBody),
        });

        const cabinSeatsData = await cabinSeatNamesResponse.json();

        // console.log("meeting namesss", meetingData);

        setMeetingNames(meetingData.Data);

        const filteredCabinNames = cabinSeatsData.Data.filter((item) => item.type === "cabin");
        const filteredSeatNames = cabinSeatsData.Data.filter((item) => item.type === "seat");

        setCabinNames(filteredCabinNames);
        setSeatNames(filteredSeatNames);

        // setFetchedCabinNames(filteredCabinNames);
        // setFetchedSeatNames(filteredSeatNames);

        console.log("cabin names", filteredCabinNames);
        console.log("seat names", filteredSeatNames);
      } else {
        // console.log("Add Mode-----------------------------------------------");
        setMode("Add");
        putInitialMappingData();
      }

      //   setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      //   setIsLoading(false);
    }
  };

  const mapItems = (items, mapping, type, priceKey = "price") => {
    return items.map((item) => {
      const matchingItem = mapping.find((mappedItem) => mappedItem[type + "_id"]._id === item._id);

      return matchingItem
        ? {
            // ...matchingItem,
            [type + "_id"]: item._id,
            name: item.name,
            number_of_person: item.number_of_person,
            [`${type}_quantity`]: matchingItem[`${type}_quantity`],
            // [`${type}_price`]: matchingItem[`${type}_price`],
            [`${type}_suffix`]: matchingItem[`${type}_suffix`],
            [`${type}_prefix`]: matchingItem[`${type}_prefix`],
            [`${type}_type`]: matchingItem[`${type}_type`],
            ...(type === "meeting" ? { meeting_credit: matchingItem.meeting_credit } : { [`${type}_price`]: matchingItem[`${type}_price`] }),
          }
        : {
            [type + "_id"]: item._id,
            name: item.name,
            number_of_person: item.number_of_person,
            [`${type}_quantity`]: 0,
            // [`${type}_price`]: item.price,
            [`${type}_suffix`]: item.suffix,
            [`${type}_prefix`]: item.preffix,
            [`${type}_type`]: item.type,
            ...(type === "meeting" ? { meeting_credit: item.credit_deduction } : { [`${type}_price`]: item.price }),
          };
    });
  };

  function putInitialMappingData() {
    setFormData((prev) => ({
      // ...prev,
      location_id: selectValues.location_id.value,
      floor_id: selectValues.floor_id.value,
      seat_mapping:
        seats?.map((item) => ({
          seat_id: item._id,
          name: item.name,
          number_of_person: item.number_of_person,
          seat_quantity: 0,
          seat_price: item.price || "",
          seat_suffix: item.suffix || "",
          seat_prefix: item.preffix || "",
          seat_type: item.type || "",
        })) || [],
      cabin_mapping:
        cabins?.map((item) => ({
          cabin_id: item._id,
          name: item.name,
          number_of_person: item.number_of_person,
          cabin_quantity: 0,
          cabin_price: item.price || "",
          cabin_suffix: item.suffix || "",
          cabin_prefix: item.preffix || "",
          cabin_type: item.type || "",
        })) || [],
      meeting_mapping:
        meetingRooms?.map((item) => ({
          meeting_id: item._id,
          name: item.name,
          number_of_person: item.number_of_person,
          meeting_quantity: 0,
          meeting_credit: item.credit_deduction || "",
          meeting_suffix: item.suffix || "",
          meeting_prefix: item.preffix || "",
          meeting_type: item.type || "",
        })) || [],
      // area_mapping: [],
      // amenitie_mapping: [],
    }));

    setMeetingNames([]);
    setCabinNames([]);
    setSeatNames([]);
  }

  // to set cabin names array
  useEffect(() => {
    if (formData.cabin_mapping.length > 0) {
      setCabinNames((prev) => [...generateNames(formData.cabin_mapping, "cabin", prev)]);
    } else {
      setCabinNames([]);
    }
  }, [formData.cabin_mapping, formData.location_id, formData.floor_id]);

  // to set seat names array
  useEffect(() => {
    if (formData.seat_mapping.length > 0) {
      setSeatNames((prev) => [...generateNames(formData.seat_mapping, "seat", prev)]);
    } else {
      setSeatNames([]);
    }
  }, [formData.seat_mapping, formData.location_id, formData.floor_id]);

  // to set meeting names array
  useEffect(() => {
    if (formData.meeting_mapping.length > 0) {
      setMeetingNames((prev) => [...generateNames(formData.meeting_mapping, "meeting", prev)]);
    } else {
      setMeetingNames([]);
    }
  }, [formData.meeting_mapping, formData.location_id, formData.floor_id]);

  function generateNames(data, type, prevNames) {
    const existingQuantities = {};
    let existingQuantity = 0;
    let newQuantity = 0;
    let nameId = "";
    let currentMastersId = "";

    prevNames.forEach((name) => {
      nameId = name._id || "";
      const id = name[type + "_id"];
      existingQuantities[id] = (existingQuantities[id] || 0) + 1;
    });

    // Find master IDs with quantity 0 and filter them out
    const filteredZeroQtyIds = data.filter((item) => parseInt(item[type + "_quantity"]) == 0).map((item) => item[type + "_id"]);

    // console.log("existing quantutitess------", existingQuantities);

    const autoGenNames = data.flatMap((item) => {
      newQuantity = parseInt(item[type + "_quantity"]);

      existingQuantity = existingQuantities[item[type + "_id"]] || 0;
      currentMastersId = item[type + "_id"];

      // console.log("current masters id", currentMastersId);

      // if (isNaN(newQuantity)) {
      //   // console.log("invalid qty_____________");
      //   return [];
      // }

      // if (newQuantity === 0) {
      //   // console.log("Zero quantity--------------------------------");
      //   return [];
      // }

      // existingQuantity = existingQuantities[item[type + "_id"]] || 0;
      // currentMastersId = item[type + "_id"];

      // console.log("current masters id", currentMastersId);

      if (newQuantity > existingQuantity) {
        return [...Array(newQuantity)]
          .map((_, index) => {
            let incrementalValue;
            if (item[type + "_type"] === "Numeric") {
              incrementalValue = index + 1;
            } else if (item[type + "_type"] === "Alphabet") {
              if (index < 26) {
                incrementalValue = String.fromCharCode(65 + index);
              } else {
                const firstLetter = String.fromCharCode(65 + Math.floor(index / 26) - 1);
                const secondLetter = String.fromCharCode(65 + (index % 26));
                incrementalValue = firstLetter + secondLetter;
              }
            }

            const inputValue = `${item[type + "_prefix"]}${incrementalValue}${item[type + "_suffix"]}`;

            let keyName;

            if (type === "meeting") {
              keyName = "credit_deduction";
            } else {
              keyName = "price";
            }

            return {
              id: existingQuantity + index + 1 + item[type + "_id"],
              urlName: spaceId,
              location_id: formData.location_id,
              floor_id: formData.floor_id,
              [type + "_id"]: item[type + "_id"],
              type: type,
              name: inputValue,
              [keyName]: type === "meeting" ? item[type + "_credit"] : item[type + "_price"],
              occupancy_status: false,
            };
          })
          .slice(existingQuantity);
      }
      return [];
    });

    let newNamesArray = prevNames.concat(autoGenNames) || [];

    return newNamesArray;

    // let filteredNames = newNamesArray;
    // let hasOccupied = false;

    // if (type === "cabin" || type === "seat") {
    //   hasOccupied = newNamesArray.some((name) => name.occupancy_status === true);
    // }

    // if (hasOccupied) {
    //   alert("Change Occupancy First!");
    //   filteredNames = newNamesArray;
    // } else {
    //   filteredNames = newNamesArray.filter((name) => filteredZeroQtyIds.includes(name[type + "_id"]));
    // }

    // -------------------------------------------------------------------------------------
    // if (type === "meeting") {
    //   // Filter out names with meeting IDs that have quantity 0
    //   filteredNames = newNamesArray.filter((name) => !filteredZeroQtyIds.includes(name[type + "_id"]));
    // } else if (type === "cabin" || type === "seat") {
    //   const hasOccupied = newNamesArray.some((name) => name.occupancy_status);
    //   console.log("has occupieddddd", hasOccupied);
    //   // if (hasOccupied) {
    //   //   // Show alert saying change occupancy to reduce quantity
    //   //   alert("Change occupancy to reduce quantity");
    //   //   // Return all names without filtering
    //   //   filteredNames = newNamesArray;
    //   // } else {
    //   //   // Filter out names with meeting IDs that have quantity 0
    //   filteredNames = newNamesArray.filter((name) => filteredZeroQtyIds.includes(name[type + "_id"]));
    //   // }
    // }

    // ------------------------------------
    // return filteredNames;
  }

  const fetchData = async (url, setter) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();
      setter(data.Data);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    fetchData(url + "/meeting/view", setMeetingRooms);
    fetchData(url + "/seat/view", setSeats);
    fetchData(url + "/cabin/view", setCabins);
    fetchData(url + "/area/view", setAreas);
    fetchData(url + "/amenitie/view", setAmenities);
  }, []);

  const handleChangeInput = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSetValue = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    const verify = validate(formData, requiredFields);
    if (!verify.success) return toast.error(verify.message);
    const newData = {
      ...editItem,
      ...formData,
    };
    const namesDataArray = [...seatNames, ...cabinNames, ...meetingNames];

    if (mode === "Add") {
      addFunc(newData, namesDataArray);
    } else if (mode === "Edit") {
      updateFunc(newData, namesDataArray);
    }
    setIsModalOpen(false);
  };

  const putValues = () => {
    setFormData({ ...editItem, location_id: editItem.location_id._id, floor_id: editItem.floor_id._id });
  };

  // fetch floors based on selected location
  useEffect(() => {
    const location_id = myLocations.find((item) => item.value === formData.location_id) || "";
    setSelectValues((prev) => ({ ...prev, location_id }));

    const fetchMyFloorsByLocation = async () => {
      try {
        const requestBody = {
          urlName: spaceId,
          filters: { location_id: location_id.value },
        };

        const response = await fetch(url + "/floor/view", {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const data = await response.json();

        const floorsList = data.Data;

        setMatchingFloors(
          floorsList.map((floor) => {
            return { label: floor.floor_name, value: floor._id };
          })
        );
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchMyFloorsByLocation();
  }, [formData.location_id]);

  useEffect(() => {
    const floor_id = matchingFloors.find((item) => item.value === formData.floor_id) || "";

    setSelectValues((prev) => ({
      ...prev,
      floor_id,
    }));
  }, [formData.location_id, formData.floor_id, matchingFloors]);

  useEffect(() => {
    if (view === "edit") putValues();
  }, []);

  useEffect(() => {
    if (myLocations?.length === 1) {
      setFormData((prev) => ({ ...prev, location_id: myLocations[0].value }));
    }
  }, [myLocations]);

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode} Resources
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>

              <form onSubmit={handleSave} className='flex flex-col gap-6 p-4 sm:p-8 text-[14px] text-gray-600 w-full' style={{ maxHeight: "80vh", overflowY: "scroll" }}>
                <div className='d-flex gap-4 justify-space-between'>
                  <label className='flex col-5 flex-col gap-1'>
                    Location
                    <Select
                      options={myLocations}
                      value={myLocations?.length === 1 ? myLocations[0] : selectValues.location_id}
                      onChange={(val) => setFormData((prev) => ({ ...prev, location_id: val.value, floor_id: "" }))}
                      placeholder='Select Location...'
                      className='flex-1 w-full'
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      required
                    />
                  </label>
                  <label className='flex col-5 flex-col gap-1'>
                    Floor
                    <Select
                      isDisabled={!formData.location_id}
                      options={matchingFloors}
                      value={selectValues.floor_id}
                      // value={matchingFloors?.length === 1 ? matchingFloors[0] : selectValues.floor_id}
                      onChange={(val) => setFormData((prev) => ({ ...prev, floor_id: val.value }))}
                      placeholder='Select Floor...'
                      className='flex-1 w-full'
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      required
                    />
                  </label>
                </div>

                {!selectValues.location_id || !selectValues.floor_id ? (
                  <p className='text-center my-4'>Please select Location and Floor first!</p>
                ) : (
                  <>
                    <div className='flex flex-col gap-1'>
                      <h4>Seats</h4>
                      <Table data={formData.seat_mapping} type='seat' fieldName='seat_mapping' setFormData={setFormData} names={seatNames} setNames={setSeatNames} />
                    </div>
                    <div className='flex flex-col gap-1'>
                      <h4>Cabins</h4>
                      <Table data={formData.cabin_mapping} type='cabin' fieldName='cabin_mapping' setFormData={setFormData} names={cabinNames} setNames={setCabinNames} />
                    </div>
                    <div className='flex flex-col gap-1'>
                      <h4>Meeting Rooms</h4>
                      <Table data={formData.meeting_mapping} type='meeting' fieldName='meeting_mapping' setFormData={setFormData} names={meetingNames} setNames={setMeetingNames} />
                    </div>
                    {/* <MultiCheck label='Amenities' options={amenities} selected={formData.amenitie_mapping} name='amenitie_mapping' onCheck={handleSetValue} optionType='object' />

                    <MultiCheck label='Areas' options={areas} selected={formData.area_mapping} name='area_mapping' onCheck={handleSetValue} optionType='object' /> */}

                    <div className='modal-footer d-flex justify-content-between align-items-center'>
                      <div className='d-flex gap-2'>
                        <button type='submit' className='btn btn-primary float-end'>
                          Update
                        </button>
                      </div>
                    </div>

                    {/* <button type='submit' className='p-2 mt-2 bg-tl_primary text border border-tl_primary text-white font-bold hover:scale-105 rounded-md transition-all duration-300 flex-1'>
                      Save
                    </button> */}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};

const requiredFields = [
  {
    key: "location_id",
    name: "Location",
  },
  {
    key: "floor_id",
    name: "Floor Name",
  },
];

export const MultiCheck = ({ label, options, selected, name, onCheck, optionType = "string", optionLabel = "name", optionValue = "_id" }) => {
  const handleCheck = (item) => {
    if (optionType === "string") {
      if (selected.includes(item))
        onCheck(
          name,
          selected.filter((slt) => slt !== item)
        );
      else onCheck(name, [...selected, item]);
    } else {
      if (selected.includes(item[optionValue]))
        onCheck(
          name,
          selected.filter((slc) => slc !== item[optionValue])
        );
      else onCheck(name, [...selected, item[optionValue]]);
    }
  };
  return (
    <div className='font-[600] text-gray-400'>
      {label}
      <div className='flex items-center gap-2 flex-wrap mt-1'>
        {options.map((item, i) => (
          <label
            key={i}
            className={
              "whitespace-nowrap flex items-center gap-2 p-2 rounded-md cursor-pointer font-[600] " +
              (optionType === "string" ? (selected.includes(item) ? "border-tl_primary text-tl_primary bg-tl_primary/10" : "!border-gray-400 text-gray-400") : selected.includes(item[optionValue]) ? "border-tl_primary text-tl_primary bg-tl_primary/10" : "!border-gray-400 text-gray-400")
            }
            style={{ border: "2px solid" }}
          >
            <input type='checkbox' onChange={() => handleCheck(item)} checked={selected.includes(optionType === "string" ? item : item[optionValue])} className='hidden' />
            <span className={"flex items-center justify-center " + (selected.includes(optionType === "string" ? item : item[optionValue]) ? "text-tl_primary" : "text-gray-400")}>
              {selected.includes(optionType === "string" ? item : item[optionValue]) ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
            </span>
            {optionType === "string" ? item : item[optionLabel]}
          </label>
        ))}
      </div>
    </div>
  );
};
