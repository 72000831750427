import Profile from '../../components/setting/profile/Component';
import { toast, ToastContainer } from "react-toastify";
export default function Component() {

  return (
    <>
      <ToastContainer />
      <Profile />
    </>
  );
}