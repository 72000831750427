import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import Select from "react-select";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh, locations = [] }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();

  const initialFormData = { location_id: "", floor_name: "", floor_number: "" };

  const [formData, setFormData] = useState(initialFormData);

  const [selectValues, setSelectValues] = useState({
    location_id: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const location_id = locations.find((item) => item.value === formData.location_id) || "";
    setSelectValues((prev) => ({ ...prev, location_id }));
  }, [formData.location_id]);

  useEffect(() => {
    if (locations?.length === 1) {
      setFormData((prev) => ({ ...prev, location_id: locations[0].value }));
    }
  }, [locations]);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.location_id.trim()) {
      errors.location_id = "Location is required";
      isValid = false;
    }

    if (!formData.floor_name.trim()) {
      errors.floor_name = "Floor Name is required";
      isValid = false;
    }

    if (!formData.floor_number.trim()) {
      errors.floor_number = "Floor Number is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.floor_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            ...formData,
          }),
        });
        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);

          setFormData(initialFormData);
          setIsModalOpen(false);
        } else {
          toast.error(responseData.Message);
          setIsModalOpen(false);
        }
        onRefresh();
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setFormData(initialFormData);
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label htmlFor='location_id' className='col-form-label'>
                        Location
                      </label>
                      <Select
                        options={locations}
                        value={locations?.length === 1 ? locations[0] : selectValues.location_id}
                        onChange={(val) => setFormData((prev) => ({ ...prev, location_id: val.value }))}
                        placeholder='Select Location...'
                        className='flex-1 w-full'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        // required
                      />
                      {errors.location_id && <div className='invalid-feedback'>{errors.location_id}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='name' className='col-form-label'>
                        Floor Name
                      </label>
                      <input type='text' className={`form-control ${errors.floor_name ? "is-invalid" : ""}`} id='floor_name' name='floor_name' value={formData.floor_name} onChange={handleChange} />
                      {errors.floor_name && <div className='invalid-feedback'>{errors.floor_name}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='floor_number' className='col-form-label'>
                        Floor Number
                      </label>
                      <input type='number' className={`form-control ${errors.floor_number ? "is-invalid" : ""}`} id='floor_number' name='floor_number' value={formData.floor_number} onChange={handleChange} />
                      {errors.floor_number && <div className='invalid-feedback'>{errors.floor_number}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
