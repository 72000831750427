import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
    <div className="sec1tion2">
            
      <div className="container">
        <div className="row feature-top02">
          <div className="col-lg-6">
          <center><img src="img/invoices.png" className="img-fluid feature-img2"/></center>
          </div>
          <div className="col-lg-6"> 

          <div className="feature-padd2">
         <p className="deskbox-txt2">Invoices & Expenses Management</p>
         <p className="deskbox-descri">Keep track of your expenses and invoices, giving you peace of mind that your finances are well-managed.</p>
         <div class="desk-top-2">
                   <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Automated Invoicing & Payments</p>
                <p className="feature1-txt2">Create automated recurring invoices in just a few minutes with precise seat mapping calculations.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Expense Tracking</p>
                <p className="feature1-txt2">Easily monitor and manage your spending, stay on top of your finances and make informed decisions.</p>
            </div>
         </div>
         <div className="deskbox-feature1-flx">
            <div className="flx2">
            <i className="ri-check-line feature-icn"></i>
            </div>
            <div className="flx3">
                <p className="feature-txt1">Contracts Management</p>
                <p className="feature1-txt2">Streamline your contract management process and ensure that everything is organized.</p>
            </div>
         </div>
           </div>
           </div>

           </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Component;
