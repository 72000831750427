import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import { url, adminpoints } from "../../../lib/lib.js";
import Layout from "../screens/Layout.js";
import { LoadingMutatingDots } from "../../spaces/components/ui/Loadings.js";

function PrivateRoutes() {
  const [cookies] = useCookies(["access"]);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function authenticate() {
      try {
        setLoading(true);
        let response = await fetch(url + adminpoints.auth, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        const data = await response.json();
        if (data.Status) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    }
    authenticate();
  }, [cookies]);

  if (loading) {
    return (
      <>
        <LoadingMutatingDots />
        {/* <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                 <div className="spinner-border text-primary" role="status">
                     <span className="sr-only">Loading...</span>
                 </div>
             </div> */}
      </>
    );
  }
  if (!isAuthenticated) {
    return <Navigate to='/Br!Y7o&p45fM/portal/login' replace />;
  }

  return (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
}

export default PrivateRoutes;
