import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();

  const initialFormData = {
    name: "",
    number_of_person: "",
    preffix: "",
    suffix: "",
    type: "Numeric",
    credit_deduction: "",
    // area: "",
    // status: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.credit_deduction || parseFloat(formData.credit_deduction) <= 0) {
      errors.credit_deduction = "Credit Deduction must be greater than 0.";
      isValid = false;
    }

    if (!formData.number_of_person || parseFloat(formData.number_of_person) < 1) {
      // errors.number_of_person = "Number of persons must be greater than or equal to 1.";
      errors.number_of_person = "Number of persons must be greater than 0.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.meeting_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            ...formData,
          }),
        });
        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);

          setFormData(initialFormData);
          setIsModalOpen(false);
        } else {
          toast.error(responseData.Message);
          setIsModalOpen(false);
        }
        onRefresh();
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setFormData(initialFormData);
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor='name' className='col-form-label'>Name</label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='number_of_person' className='col-form-label'>Number of Persons</label>
                      <input type='number' className={`form-control ${errors.number_of_person ? "is-invalid" : ""}`} id='number_of_person' name='number_of_person' value={formData.number_of_person} onChange={handleChange} />
                      {errors.number_of_person && <div className='invalid-feedback'>{errors.number_of_person}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='preffix' className='col-form-label'>Prefix</label>
                      <input type='text' className={`form-control `} id='preffix' name='preffix' value={formData.preffix} onChange={handleChange} />
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='suffix' className='col-form-label'>Suffix</label>
                      <input type='text' className={`form-control `} id='suffix' name='suffix' value={formData.suffix} onChange={handleChange} />
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='type' className='col-form-label'>Type</label>
                      <select id='type' className={`form-select`} value={formData.type} onChange={(e) => setFormData((prev) => ({ ...prev, type: e.target.value }))}>
                        <option value='Numeric'>Numeric</option>
                        <option value='Alphabet'>Alphabet</option>
                      </select>
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='credit_deduction' className='col-form-label'>Credit Deduction</label>
                      <input type='number' className={`form-control ${errors.credit_deduction ? "is-invalid" : ""}`} id='credit_deduction' name='credit_deduction' value={formData.credit_deduction} onChange={handleChange} />
                      {errors.credit_deduction && <div className='invalid-feedback'>{errors.credit_deduction}</div>}
                    </div>

                    {/* <div className='col-md-12'>
                      <label htmlFor='area' className='col-form-label'>Area</label>
                      <input type='text' className={`form-control `} id='area' name='area' value={formData.area} onChange={handleChange} />
                    </div> */}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>Close</button>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>{isSubmitting ? "Loading..." : "Submit"}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
