import React, { useEffect, useState, useContext } from "react";
import Sidenav from "../sidenav/Component";
import "../../../../client/components/SpaceCard/Component.css";
import Top from "../top/Component";

import { useParams, useNavigate } from "react-router-dom";
import { url, memberHeader, clientpoints, client_prefix } from "../../../../../lib/lib";
import { AuthUserContext } from "../../../../../lib/AuthUserContext";

function Component({ children }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [spaceName, setSpaceName] = useState('');
  const { spaceId } = useParams();

  useEffect(() => { fetchData(); }, []);
  const fetchData = async () => {
    try {

      const requestBody = { urlName: spaceId, };
      const response = await fetch(url + clientpoints.space_view, {
        method: "POST",
        headers: memberHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) { navigate(`${client_prefix}`); }

      const data = await response.json();
      // console.log('data - ', data);

      if (data.Status) {


        const responsMember = await fetch(url + clientpoints.member_view, {
          method: "POST",
          headers: memberHeader,
          credentials: "include",
          body: JSON.stringify(requestBody),
        });

        const dataMember = await responsMember.json();
        if (!responsMember.ok) { navigate(`${client_prefix}`); }

        setName(dataMember.Data[0].name);
        setSpaceName(dataMember.Data[0].space_id.space_name);

      } else {
        setIsLoading(false);
        setName('Deskbox');
        setSpaceName('Deskbox');
      }
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };


  return (
    <div className='container-fluid side-padd dashboardfont'>
      <div className='row main-parent'>
        <div className='col_parent_class col-xl-2 col-lg-3 first_child_width'>
          <Sidenav />
        </div>
        <div className='col_parent_class col-xl-10 col-lg-9 second_child_width'>
          <Top name={name} spaceName={spaceName} />
          {children}
        </div>
      </div>
    </div>
  );
}

export default Component;
