import TableComponent from "../components/space_ui/table/Component.js";

const Space = () => {

    return (
        <>
            <TableComponent />
        </>
    );
};

export default Space;