import TableComponent from "../components/subscription_ui/table/Component.js";

const Subscription = () => {

    return (
        <>
            <TableComponent />
        </>
    );
};

export default Subscription;