import React, { useState } from "react";
import ConfirmModal from "../../../ui/modals/ConfirmAssetsModal";

function Component({ data, type, fieldName, onChange, setFormData, names, setNames }) {
  // console.log("dataaaa------", data);
  // console.log("namesssss----", names);

  const [confirmNamesResetModal, setConfirmNamesResetModal] = useState({ open: false, data: {} });

  const groupedNames = groupNames(names, type);
  // console.log("gouped names----------", groupedNames);

  function handleChangeNameConfig(e) {
    const { name, value } = e.target;
    const [type, property, id] = name.split("_");

    if (property === "prefix" || property === "suffix" || property === "type") {
      return setConfirmNamesResetModal({
        open: true,
        data: { target: { name: e.target.name, value: e.target.value } },
      });
    }
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    const [type, property, id] = name.split("_");

    setFormData((prev) => ({
      ...prev,
      [type + "_mapping"]: prev[type + "_mapping"].map((item) =>
        item[type + "_id"] === id
          ? {
              ...item,
              [`${type}_${property}`]: value,
            }
          : item
      ),
    }));

    if (property === "suffix" || property === "prefix" || property === "type") {
      setNames((prevNames) => {
        const meetingCountMap = {};
        let nameConfig = {};

        const newNamesArray = prevNames.map((name, index) => {
          const item = data.find((item) => item[type + "_id"] === name[type + "_id"]);
          const meetingId = item[type + "_id"];

          if (meetingId === id) {
            if (property === "suffix") {
              nameConfig = {
                suffix: value,
                prefix: item[type + "_prefix"],
                type: item[type + "_type"],
              };
            } else if (property === "prefix") {
              nameConfig = {
                suffix: item[type + "_suffix"],
                prefix: value,
                type: item[type + "_type"],
              };
            } else if (property === "type") {
              nameConfig = {
                suffix: item[type + "_suffix"],
                prefix: item[type + "_prefix"],
                type: value,
              };
            }

            if (!meetingCountMap.hasOwnProperty(meetingId)) {
              meetingCountMap[meetingId] = 0;
            }

            meetingCountMap[meetingId]++;

            let incrementalValue;

            if (nameConfig.type === "Numeric") {
              incrementalValue = meetingCountMap[meetingId];
            } else if (nameConfig.type === "Alphabet") {
              const place = meetingCountMap[meetingId];
              if (place <= 26) {
                incrementalValue = String.fromCharCode(64 + place);
              } else {
                const firstLetter = String.fromCharCode(64 + Math.floor((place - 1) / 26));
                const secondLetter = String.fromCharCode(64 + ((place - 1) % 26) + 1);
                incrementalValue = firstLetter + secondLetter;
              }
            }

            const inputValue = `${nameConfig.prefix}${incrementalValue}${nameConfig.suffix}`;
            return { ...name, name: inputValue };
          } else {
            return name;
          }
        });

        return newNamesArray;
      });
    }
  };

  const handleNameChange = (e, meetingId, nameId) => {
    // console.log("value", e.target.value);
    // console.log("meetingId", meetingId);
    // console.log("name id", nameId);

    const { value } = e.target;

    setNames((prev) => prev.map((item) => (item.meeting_id === meetingId && (item._id || item.id) === nameId ? { ...item, name: value } : item)));
  };

  return (
    <>
      {confirmNamesResetModal.open && <ConfirmModal modalName='Confirm' setIsModalOpen={(bool) => setConfirmNamesResetModal((prev) => ({ ...prev, open: bool }))} func={() => handleChangeInput(confirmNamesResetModal.data)} heading={"Proceeding with this action will reset existing names"} />}
      <div>
        <table className='asset-table'>
          <tr>
            <th>Name</th>
            <th>Persons</th>
            <th>Prefix</th>
            <th>Suffix</th>
            <th>Type</th>
            <th>Qty</th>
            <th>{type === "meeting" ? "Credits" : "Price"}</th>
          </tr>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.number_of_person}</td>

              <td>
                <input type='text' name={type + "_prefix_" + item[type + "_id"]} value={item[type + "_prefix"]} onChange={handleChangeNameConfig} />
              </td>
              <td>
                <input type='text' name={type + "_suffix_" + item[type + "_id"]} value={item[type + "_suffix"]} onChange={handleChangeNameConfig} />
              </td>

              <td>
                <select name={type + "_type_" + item[type + "_id"]} value={item[type + "_type"]} onChange={handleChangeNameConfig} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100 text-gray-500'>
                  {options.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input type='text' name={type + "_quantity_" + item[type + "_id"]} value={item[type + "_quantity"]} onChange={handleChangeInput} />
              </td>
              <td>
                <input type='text' name={type === "meeting" ? type + "_credit_" + item[type + "_id"] : type + "_price_" + item[type + "_id"]} value={type === "meeting" ? item[type + "_credit"] : item[type + "_price"]} onChange={handleChangeInput} />
              </td>
            </tr>
          ))}
        </table>

        {type === "meeting" && (
          <div style={{ marginTop: "10px" }}>
            <h5 style={{ textTransform: "uppercase" }}>{type} NAMES</h5>
            {groupedNames && (
              <div>
                {Object.entries(groupedNames).map(([meeting_id, nameGroup]) => (
                  <div key={meeting_id} style={{ margin: "10px 0" }}>
                    <h6>For {data.find((item) => item[type + "_id"] == meeting_id)?.name}</h6>
                    <div className='flex flex-wrap'>
                      {nameGroup.map((item) => (
                        <div key={item._id}>
                          <input style={{ border: "1px solid grey", margin: "6px", padding: "8px 5px", display: "inline" }} type='text' value={item.name} onChange={(e) => handleNameChange(e, meeting_id, item._id || item.id)} />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Component;

const options = ["Numeric", "Alphabet"];

function groupNames(namesArray, type) {
  // console.log("namessss array", namesArray);
  // console.log("typeee", type);

  return namesArray.reduce((acc, curr) => {
    acc[curr[type + "_id"]] = acc[curr[type + "_id"]] || [];
    acc[curr[type + "_id"]].push(curr);
    return acc;
  }, {});
}
