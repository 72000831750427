import Member from '../../components/client/member/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <Member name="All Members" />
        </>
    );
}