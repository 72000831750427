import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage('type');
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();
  const [companyName, setSpaceName] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    type: "",
    amount: "",
    date: "",
    paid_by: "",
    description: "",
  });

  const [spaceList, setSpaceList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // if (!companyName.trim()) {
    //   errors.company = "Company is required";
    //   isValid = false;
    // }
    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.type.trim()) {
      errors.name = "Type is required";
      isValid = false;
    }
    if (!formData.amount.trim()) {
      errors.name = "Amount is required";
      isValid = false;
    }
    if (!formData.date.trim()) {
      errors.date = "Bill Date is required";
      isValid = false;
    }
    if (!formData.paid_by.trim()) {
      errors.paid_by = "Bill Paid By is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.expense_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            name: formData.name,
            type: formData.type,
            amount: formData.amount,
            date: formData.date,
            paid_by: formData.paid_by,
            description: formData.description,
          }),
        });
        setSpaceName("");
        setFormData({
          name: "",
          type: "",
          amount: "",
          date: "",
          paid_by: "",
          description: "",
        });
        if (!response.ok) {
          navigate(`${client_prefix}`);
        }
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSpaceName("");
    setFormData({
      name: "",
      type: "",
      amount: "",
      date: "",
      paid_by: "",
      description: "",
    });
  };
  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="name" className="col-form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? "is-invalid" : ""
                          }`}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.name}</div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="type" className="col-form-label">
                        Type
                      </label>
                      <input
                        type="type"
                        className={`form-control ${errors.type ? "is-invalid" : ""
                          }`}
                        id="type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                      />
                      {errors.type && (
                        <div className="invalid-feedback">{errors.type}</div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="amount" className="col-form-label">
                        Amount
                      </label>
                      <input
                        type="number"
                        className={`form-control ${errors.amount ? "is-invalid" : ""
                          }`}
                        id="amount"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                      />
                      {errors.amount && (
                        <div className="invalid-feedback">{errors.amount}</div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="date" className="col-form-label">
                        Date And Time
                      </label>
                      <input
                        type="datetime-local"
                        className={`form-control ${errors.date ? "is-invalid" : ""
                          }`}
                        id="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                      />
                      {errors.date && (
                        <div className="invalid-feedback">
                          {errors.date}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="paid_by" className="col-form-label">
                        Paid By
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.paid_by ? "is-invalid" : ""
                          }`}
                        id="paid_by"
                        name="paid_by"
                        value={formData.paid_by}
                        onChange={handleChange}
                      />
                      {errors.paid_by && (
                        <div className="invalid-feedback">{errors.paid_by}</div>
                      )}
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="description" className="col-form-label">
                        Description
                      </label>
                      <textarea
                        className={`form-control ${errors.description ? "is-invalid" : ""
                          }`}
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                      {errors.description && (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
