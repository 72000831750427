import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { LoadingMutatingDots } from "../../../../../spaces/components/ui/Loadings.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { spaceId } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    check_in: "",
    check_out: "",
    reason: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let clientId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.name = "Email is required";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      errors.name = "Phone is required";
      isValid = false;
    }
    if (!formData.check_in.trim()) {
      errors.check_in = "Check In is required";
      isValid = false;
    }
    if (!formData.reason.trim()) {
      errors.reason = "Reason is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isModalOpen && clientId) {
      setLoading(true);
      fetchData(clientId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, clientId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.visitor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: spaceId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${client_prefix}`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          name: response_data.name,
          email: response_data.email,
          phone: response_data.phone,
          check_in: response_data.check_in,
          check_out: response_data.check_out,
          reason: response_data.reason,
          description: response_data.description,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.visitor_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: spaceId,
            filters: { _id: current_id },
            data: {
              name: formData.name,
              email: formData.email,
              phone: formData.phone,
              check_in: formData.check_in,
              check_out: formData.check_out,
              reason: formData.reason,
              description: formData.description,
            },
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <LoadingMutatingDots />
                    {/* <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div> */}
                  </div>
                ) : (
                  <div className='modal-body'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor='name' className='col-form-label'>
                          Name
                        </label>
                        <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                        {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='email' className='col-form-label'>
                          Email
                        </label>
                        <input type='email' className={`form-control ${errors.email ? "is-invalid" : ""}`} id='email' name='email' value={formData.email} onChange={handleChange} />
                        {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='phone' className='col-form-label'>
                          Phone
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                          id='phone'
                          name='phone'
                          value={formData.phone}
                          maxLength={10}
                          pattern='[0-9]*'
                          onChange={handleChange}
                        />
                        {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='check_in' className='col-form-label'>
                          Check-in Date
                        </label>
                        <input
                          type='datetime-local'
                          className={`form-control ${errors.check_in ? "is-invalid" : ""}`}
                          id='check_in'
                          name='check_in'
                          value={formData.check_in}
                          onChange={handleChange}
                        />
                        {errors.check_in && <div className='invalid-feedback'>{errors.check_in}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='check_out' className='col-form-label'>
                          Check-out Date
                        </label>
                        <input
                          type='datetime-local'
                          className={`form-control ${errors.check_out ? "is-invalid" : ""}`}
                          id='check_out'
                          name='check_out'
                          value={formData.check_out}
                          onChange={handleChange}
                        />
                        {errors.check_out && <div className='invalid-feedback'>{errors.check_out}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='reason' className='col-form-label'>
                          Reason
                        </label>
                        <input type='text' className={`form-control ${errors.reason ? "is-invalid" : ""}`} id='reason' name='reason' value={formData.reason} onChange={handleChange} />
                        {errors.reason && <div className='invalid-feedback'>{errors.reason}</div>}
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='description' className='col-form-label'>
                          Description
                        </label>
                        <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                        {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                      </div>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                      Close
                    </button>
                    {/* <button type="submit" className="btn btn-primary">Update</button> */}
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                      {isSubmitting ? "Loading..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
